import React from 'react'

const LoadingSpinner = ({ text }) => {
  return (
    <>
      <div className='loader'></div>
      <h2 className='text-center'>{text || 'Please wait...'}</h2>
    </>
  )
}

export default LoadingSpinner
