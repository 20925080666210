import React, { useState } from 'react'
import '../../styles/coaching-card.scss'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { Col, Row, Form } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import moment from 'moment'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import 'bootstrap-daterangepicker/daterangepicker.css'
import '../../styles/overview.scss'
import '../../styles/base.scss'
import ShowSpinner from './ShowSpinner'

const componentDateRange = () => {
  return (
    <Form.Group className='mb-0'>
      <h5 className='dropdown_label text-secondary'>Date Range</h5>
      <DateRangePicker>
        <input
          type='text'
          className='form-control date mt-design'
          value=''
          placeholder='DATE RANGE'
        />
      </DateRangePicker>
    </Form.Group>
  )
}

const getAgents = props => {
  return (
    <Form.Group className='mb-0'>
      <h5 className='dropdown_label text-secondary'>Agent</h5>
      <Select
        className='border-0 mt-design'
        value={props.programBar.selectedAgent}
        isDisabled={
          props.programBar.enableSelection || props.programBar.isFetchingProgram
        }
        onChange={e =>
          props.programBar.onAgentChange(e, props.programBar.onAgentChangeHook)
        }
        options={props.programBar.agentOptions}
      />
    </Form.Group>
  )
}

const getPrograms = props => {
  return (
    <Form.Group className='mb-0'>
      <h5 className='dropdown_label text-secondary'>Program</h5>
      <Select
        className='border-0 mt-design'
        id='inlineFormCustomSelect'
        placeholder='- Select -'
        value={props.programBar.selectedProgram}
        isDisabled={
          props.programBar.enableSelection || props.programBar.isFetchingProgram
        }
        onChange={e =>
          props.programBar.onPmChange(e, props.programBar.onPmChangeHook)
        }
        options={props.programBar.programOptions}
      />
    </Form.Group>
  )
}

const getWeekRangeSelection = props => {
  if (props.programBar.showWeeks) {
    return (
      <Form.Group className='mb-0'>
        <h5 className='dropdown_label text-secondary'>Week</h5>
        <DayPickerInput
          className='border-0 mt-design'
          hideOnDayClick={false}
          value={props.programBar.dateModifier.value}
          dayPickerProps={props.programBar.dateModifier}
          inputProps={{ className: 'border-0 mt-design form-control weekrange_input' }}
        />
      </Form.Group>
    )
  }
  return null
}

const getWeekSelection = props => {
  /* 
   * hidden this week selector as added another week range selector 21-09-2021, show if required
  */
  /*if (props.programBar.showWeeks) {
    return (
      <Form.Group className='mb-0'>
        <h5 className='dropdown_label text-secondary'>Week</h5>
        <Select
          className='border-0 mt-design'
          placeholder='- Select -'
          isDisabled={props.programBar.isFetchingProgram}
          value={props.programBar.selectedWeek}
          onChange={e =>
            props.programBar.onWeekChange(e, props.programBar.onWeekChangeHook)
          }
          options={props.programBar.weekOptions}
        />
      </Form.Group>
    )
  }*/
  return null
}

const getTeamType = props => {
  const selectedValue = props.programBar.selectedTeamType
    ? props.programBar.selectedTeamType
    : props.programBar.teamTypeOptions[0]
  return (
    <Form.Group className='mb-0'>
      <h5 className='dropdown_label text-secondary'>Team Type</h5>
      <Select
        className='border-0 mt-design'
        value={selectedValue}
        isDisabled={false}
        onChange={e =>
          props.programBar.onTeamTypeChange(
            e,
            props.programBar.onTeamTypeChangeHook
          )
        }
        options={props.programBar.teamTypeOptions}
      />
    </Form.Group>
  )
}

const getViewType = props => {
  // const selectedValue = props.programBar.selecteViewType ? props.programBar.selecteViewType : props.programBar.viewTypeOptions[0]
  const selectedValue = props.programBar.selecteViewType
  return (
    <Form.Group className='mb-0'>
      <h5 className='dropdown_label text-secondary'>View Type</h5>
      <Select
        className='border-0 mt-design'
        value={selectedValue}
        isDisabled={false}
        onChange={e =>
          props.programBar.onViewTypeChange(
            e,
            props.programBar.onViewTypeChangeHook
          )
        }
        options={props.programBar.viewTypeOptions}
      />
    </Form.Group>
  )
}

const ProgramSelectBar = ({ ...props }) => {
  return (
    <Row className='mb-3'>
      {props.programBar.isFetchingProgram ? <ShowSpinner size='sm' /> : null}
      <Col md={3} xl={2}>
        {getPrograms(props)}
        {props.programBar.enableSelection && (
          <Link
            to='#'
            className='change_agent'
            onClick={props.programBar.toggleSelection}
          >
            Change Agent
          </Link>
        )}
      </Col>
      {props.programBar.showAgents && (
        <Col md={3} xl={2}>
          {getAgents(props)}
        </Col>
      )}
      {props.programBar.showTeamType && (
        <Col md={3} xl={2} className=''>
          {getTeamType(props)}
        </Col>
      )}
      {props.programBar.showWeeks && (
        <Col md={3} xl={2}>
          {getWeekRangeSelection(props)}
        </Col>
      )}
      {props.programBar.showWeeks && (
        <Col md={3} xl={2}>
          {getWeekSelection(props)}
        </Col>
      )}
      {props.programBar.showDateRange && (
        <Col md={3} xl={2} className=''>
          {componentDateRange()}
        </Col>
      )}
      {props.programBar.showViewType && (
        <Col md={3} xl={2} className=''>
          {getViewType(props)}
        </Col>
      )}
    </Row>
  )
}

export default ProgramSelectBar
