import moment from 'moment'

const DAY = 'day'
const ISOWEEK = 'isoWeek'

// returns the week of the year
Date.prototype.getWeek = function () {
  const janFirst = new Date(this.getFullYear(), 0, 1)
  // 1 day = 86,400 sec. 1000 Days = 86400000 sec
  return Math.ceil(((this - janFirst) / 86400000 + janFirst.getDay() + 1) / 7)
}

// Format date to show on display
export const formatCoachingDates = coaching => {
  const data1 = coaching
  if (data1.coaching_start_date) {
    // data1.coaching_start_date = moment(
    //   new Date(data1.coaching_start_date)
    // ).format('MMDDYYYY')
    data1.coaching_start_date = new Date(data1.coaching_start_date)
    if (data1.coaching_start_date.toString() === 'Invalid Date')
      data1.coaching_start_date = ''
  }

  if (data1.coaching_end_date) {
    // data1.coaching_end_date = moment(new Date(data1.coaching_end_date)).format(
    //   'MMDDYYYY'
    // )
    data1.coaching_end_date = new Date(data1.coaching_end_date)
    if (data1.coaching_end_date.toString() === 'Invalid Date')
      data1.coaching_end_date = ''
  }
  if (data1.call_date) {
    // data1.call_date = moment(new Date(data1.call_date)).format('MMDDYYYY')
    data1.call_date = new Date(data1.call_date)
    if (data1.call_date.toString() === 'Invalid Date') {
      data1.call_date = ''
    }
  }
  if (data1.conversation) {
    const { conversation } = data1
    conversation.conversation_date = new Date(conversation.conversation_date)
    if (conversation.conversation_date.toString() === 'Invalid Date')
      conversation.conversation_date = ''
  }

  if (data1.weeks) {
    data1.weeks.map(week => {
      week.coaching_date = new Date(week.coaching_date)
      if (week.coaching_date.toString() === 'Invalid Date')
        week.coaching_date = ''

      week.call_date = new Date(week.call_date)
      if (week.call_date.toString() === 'Invalid Date') week.call_date = ''

      const coachingTime = moment(week.coaching_time, 'h:mm a')
      if (coachingTime.isValid()) {
        week.coaching_time = coachingTime
      } else {
        week.coaching_time = null
      }

      week.followup_date = new Date(week.followup_date)
      if (week.followup_date.toString() === 'Invalid Date')
        week.followup_date = ''

      if (week.coaching_date === 'Invalid date') {
      }
      return week
    })
  }
  return data1
}

// returns the week number for the given moment
export const getWeekNumber = moment1 => {
  // return moment1.week()
  return moment1.add(1, 'weeks').week();
}

// returns the year for the given moment
export const getYear = moment1 => {
  return moment1.year()
}

const getWeek = (weekStart, weekEnd) => {
  // https://momentjscom.readthedocs.io/en/latest/moment/02-get-set/10-week/

  const number = getWeekNumber(weekStart)
  const startYear = getYear(weekEnd)
  return {
    start: weekStart,
    end: weekEnd,
    weekNo: number,
    startYear
  }
}

export const getSelectedWeek = (weekStart, weekEnd) => {
  // https://momentjscom.readthedocs.io/en/latest/moment/02-get-set/10-week/

  const number = getWeekNumber(weekStart)
  const startYear = getYear(weekEnd)
  return {
    start: weekStart,
    end: weekEnd,
    weekNo: number,
    startYear
  }
}

// returns the date range for this week
export const getThisWeekDateRange = weekNo => {
  const weekStart = moment()
    .startOf(ISOWEEK)
    .subtract(weekNo, DAY) // starts from Sunday
  const weekEnd = moment()
    .endOf(ISOWEEK)
    .subtract(weekNo + 1, DAY) // to get Saturday

  const number = weekStart.isoWeek()
  const startYear = weekStart.year()

  return {
    start: weekStart,
    end: weekEnd,
    weekNo: number,
    startYear
  }
}


// returns the date range for Selected week
export const getSelectedWeekDateRange = (weekNo, weekYear) => {
  const weekStart = moment() // get first day of the given year
    .week(weekNo) // get the first week according locale
    .startOf('week').format(); // get the first day of the week according locale
  const weekEnd = moment() // get first day of the given year
    .week(weekNo) // get the first week according locale
    .endOf('week').format(); // get the first day of the week according locale

  return {
    start: weekStart,
    end: weekEnd,
    weekNo: weekNo,
    startYear: weekYear
  }
}

// returns the date range for previous week
export const getWeekDateRange = date => {
  const weekStart = moment(date)
    .startOf(ISOWEEK)
    .subtract(7, DAY)
  const weekEnd = moment(date)
    .endOf(ISOWEEK)
    .subtract(8, DAY) // 7 + 1 extra day to get Saturday

  return getWeek(weekStart, weekEnd)
}

// returns the date range for previous week
export const getPreviousWeekDateRange = () => {
  const weekStart = moment()
    .startOf(ISOWEEK)
    .subtract(7, DAY)
  const weekEnd = moment()
    .endOf(ISOWEEK)
    .subtract(8, DAY) // 7 + 1 extra day to get Saturday

  return getWeek(weekStart, weekEnd)
}

// returns the date range for previous week
export const getWeekBeforePreviousWeekDateRange = () => {
  const weekStart = moment()
    .startOf(ISOWEEK)
    .subtract(14, DAY)
  const weekEnd = moment()
    .endOf(ISOWEEK)
    .subtract(15, DAY) // 14 + 1 extra day to get Saturday

  return getWeek(weekStart, weekEnd)
}

export const getMMDDYYYY = moment1 => {
  return moment1.format('MM/DD/YYYY')
}

const getWeekLabel = (label, week) => {
  return `${label}: ${getMMDDYYYY(week.start)} - ${getMMDDYYYY(week.end)}`
}
// construct week options
export const getWeekOptions = () => {
  const weekOptions = []
  const thisWeek = getThisWeekDateRange(0)

  const prevWeek = getThisWeekDateRange(7)
  const weekBeforePrevWeek = getThisWeekDateRange(14)
  const FourthWeek = getThisWeekDateRange(21)
  const FifthWeek = getThisWeekDateRange(28)
  const SixthWeek = getThisWeekDateRange(35)
  const SeventhWeek = getThisWeekDateRange(42)
  const EightWeek = getThisWeekDateRange(49)
  const NinthWeek = getThisWeekDateRange(56)

  const weekLabel1 = getWeekLabel(`This Week ${thisWeek.weekNo} - `, thisWeek)
  const weekLabel2 = getWeekLabel(`Prev Week ${prevWeek.weekNo} - `, prevWeek)
  const weekLabel3 = getWeekLabel(
    `Week ${weekBeforePrevWeek.weekNo} - `,
    weekBeforePrevWeek
  )

  const weekLabel4 = getWeekLabel(`Week ${FourthWeek.weekNo} - `, FourthWeek)
  const weekLabel5 = getWeekLabel(`Week ${FifthWeek.weekNo} - `, FifthWeek)
  const weekLabel6 = getWeekLabel(`Week ${SixthWeek.weekNo} - `, SixthWeek)
  const weekLabel7 = getWeekLabel(`Week ${SeventhWeek.weekNo} - `, SeventhWeek)
  const weekLabel8 = getWeekLabel(`Week ${EightWeek.weekNo} - `, EightWeek)
  const weekLabel9 = getWeekLabel(`Week ${NinthWeek.weekNo} - `, NinthWeek)
  weekOptions.push({ value: 1, label: weekLabel1, week: thisWeek })
  weekOptions.push({ value: 2, label: weekLabel2, week: prevWeek })
  weekOptions.push({ value: 3, label: weekLabel3, week: weekBeforePrevWeek })
  weekOptions.push({ value: 4, label: weekLabel4, week: FourthWeek })
  weekOptions.push({ value: 5, label: weekLabel5, week: FifthWeek })
  weekOptions.push({ value: 6, label: weekLabel6, week: SixthWeek })
  weekOptions.push({ value: 7, label: weekLabel7, week: SeventhWeek })
  weekOptions.push({ value: 8, label: weekLabel8, week: EightWeek })
  weekOptions.push({ value: 9, label: weekLabel9, week: NinthWeek })

  return weekOptions
}

export const test_all = () => {
  const thisWeek = getThisWeekDateRange()
  const prevWeek = getPreviousWeekDateRange()
  const weekBeforePrevWeek = getWeekBeforePreviousWeekDateRange()
}

const stringify = obj => {
  return JSON.stringify(obj)
}
