import { any } from 'prop-types'
import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_METRICS,
  GET_PROGRAM_METRICS,
  GET_METRICS_AGGREGATE,
  LOCAL_STORAGE_METRICS_FOLDER,
  METRICS_IMPORT,
  LOCAL_STORAGE_COACHING_FOLDER,
  LOCAL_STORAGE_DASHBOARD,
  GET_PROGRAM_4_WEEKS_METRICS
} from '../constants'
import { deleteData } from '../helpers'

const options = AppService.options()

export const metricsService = {
  get_metrics: async () => {
    const response = await AppService.makeRequest(BASE_API_URL + GET_METRICS, {
      ...HTTPHeaders()
    })
    return response
  },
  get_paginated_metrics: async query => {
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_METRICS + query,
      { ...HTTPHeaders() }
    )
    return response
  },
  add_metrics: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + GET_METRICS, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_METRICS_FOLDER)
    deleteData(LOCAL_STORAGE_COACHING_FOLDER)
    deleteData(LOCAL_STORAGE_DASHBOARD)
    return response
  },
  import_metrics: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(
      BASE_API_URL + METRICS_IMPORT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data)
      }
    )
    deleteData(LOCAL_STORAGE_METRICS_FOLDER)
    return response
  },
  get_program_metrics: async query => {
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_PROGRAM_METRICS + query,
      { ...HTTPHeaders() }
    )
    return response
  },
  get_4_weeks_program_metrics: async query => {
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_PROGRAM_4_WEEKS_METRICS + query,
      { ...HTTPHeaders() }
    )
    return response
  },
  get_program_metrics_aggregate: async (query, isAgent) => {
    if (isAgent) return null

    const response = await AppService.makeRequest(
      BASE_API_URL + GET_METRICS_AGGREGATE + query,
      { ...HTTPHeaders() }
    )
    return response
  }
}
