/**
 * Base settings for white label.
 * Should always inherit from base
 */
export default {
  brandName: 'glowtouch',
  logo: {
    alt: 'Glowtouch CMMS'
  },
  // Enable/Disable Header and Footer
  showHeaderAndFooter: true,
  // Enable/Disable Dashboard Sidebar Logo
  showDashboardSidebarLogo: true
}
