import React from 'react'
import { Form, Button, Row, Col, Alert, Spinner, Card } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import ShowSpinner from './ShowSpinner'
import '../../styles/base.scss'
import '../../styles/metrics-card.scss'

const AddMetric = ({ ...props }) => {
  // Schema for yup
  const validationSchema = Yup.object({
    matricsname: Yup.string()
      .email('Invalid Name')
      .required('Required'),
    datatype: Yup.string()
      .oneOf(
        ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
        'Please select a Data Type'
      )
      .required('Please select a Data Type')
  })

  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Header>
            <h2 className='mb-0'>Add Metrics</h2>
            <p className='text-muted mb-0'>Add new metrics here.</p>
          </Card.Header>

          <Card.Body>
            <section>
              <Alert variant='success' dismissible>
                <p className='m-0'>
                  <span>This is post submit mesage!</span>
                  <br />
                </p>
              </Alert>
              <Formik
                enableReinitialize
                initialValues={props.data}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  props.handleSubmit(values, setSubmitting)
                }}
              >
                {formik => (
                  <FormikForm>
                    {formik.isSubmitting && (
                      <Alert variant='info'>
                        Submitting Information. Please wait...
                      </Alert>
                    )}

                    <Form.Group noValidate>
                      <Form.Label>Name of Metric</Form.Label>
                      <Field
                        className='form-control'
                        type='text'
                        placeholder='Attendance Points, QA Score etc.,'
                        name='matricsname'
                      />

                      <ErrorMessage
                        name='matricsname'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>

                    <Form.Group noValidate>
                      <Form.Label>Data Type</Form.Label>
                      <Form.Control name='datatype' as='select' custom>
                        <option value=''>Status, Text, Number, %</option>
                        <option value='Option 1'>Option 1</option>
                        <option value='Option 2'>Option 2</option>
                        <option value='Option 3'>Option 3</option>
                        <option value='Option 4'>Option 4</option>
                      </Form.Control>
                      <ErrorMessage
                        name='datatype'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>

                    <Form.Group noValidate>
                      <Form.Label>Program Name</Form.Label>
                      <Form.Control name='programname' as='select' custom>
                        <option value=''>
                          Please select multiple programs if applicable
                        </option>
                        <option value='Option 1'>Option 1</option>
                        <option value='Option 2'>Option 2</option>
                        <option value='Option 3'>Option 3</option>
                        <option value='Option 4'>Option 4</option>
                      </Form.Control>
                      <ErrorMessage
                        name='programname'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>

                    <Form.Row>
                      <Button variant='light'
                      type='button'
                      className='mr-3'>
                        Cancel
                      </Button>
                      <Button
                        variant='primary'
                        className='submit-button'
                        type='submit'
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? (
                          <ShowSpinner buttonSpinner={true} />
                        ) : null}   
                        Add                     
                      </Button>
                    </Form.Row>
                  </FormikForm>
                )}
              </Formik>
            </section>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AddMetric
