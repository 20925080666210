import {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_PROGRAM_MANAGER,
  ROLE_EXECUTIVE
} from '../constants'

/**
 * Helper class to identify user roles
 */
class UserRoles {
  constructor(token = null, refreshToken = null) {
    this.token = token
    this.refreshToken = refreshToken
  }

  /**
   * checks if the role is Admin
   * @param {string} user role
   */
  isAdmin = role => {
    return role === ROLE_ADMIN
  }

  /**
   * checks if the role is Agent
   * @param {string} user role
   */
  isAgent = role => {
    return role === ROLE_AGENT
  }

  /**
   * checks if the role is Program Manager
   * @param {string} user role
   */
  isProgramManager = role => {
    return role === ROLE_PROGRAM_MANAGER
  }

  /**
   * checks if the role is Executive
   * @param {string} user role
   */
  isExecutive = role => {
    return role === ROLE_EXECUTIVE
  }
}

export const userRoles = new UserRoles()
