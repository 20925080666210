export const accessTokenInitial = { token: null, refreshToken: null }

export const accessToken = {
  token:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODg2NjE0MDAsIm5iZiI6MTU4ODY2MTQwMCwiZXhwIjoxNTg4NjYxNTgwLCJzdWIiOiIwMTcxY2E4NS0xZTZkLTQ3Y2QtODcyZC00NzAzZTRiY2M1OTciLCJhbGlhcyI6ImlzdWJzY3JpYmVkLnVzZXJAZ21haWwuY29tIiwiYXVyYXN2YzpzZXNzaW9uX2lkIjoiNTBhZmU3YWEtZTY5Mi00YzNhLThkMzUtNjE2ZTEyNzAzNjkwIiwiYXVyYXN2YzpkaXJlY3Rvcnlfa2V5IjoiaWRlbnRpdHlndWFyZCIsImF1cmFzdmM6ZW50aXRsZW1lbnRzIjp7fX0.Ba-IgrHt-NEvD-FHRGD2iDGv51H28qG4-RGsRQW6fBCVjP1RG10LmaA3iEJ1c3vnWwjyLZVDtuLirqUU0xbMjTngDdkACEg6cPl80-MNgqTsxBJNwxiTCY4ttFoOLAoMlCPc5TpM423_kw4JNqxNVze5J7CKw6Q5GJRpBy9FnPU9f1B0BGT-ygIBpVeSkZKOQ02_Ahb-cxlt0zhvS5DlO3X7g9t8Oeewa1vRptM-FOeCdeUdMSBC_17hBS289zt3u_V50EhY_sgVlFCEBe8rfyyUEg69os0jv3A7kMvaaLzXz9YK0gbSy_3K-AkyNGxqfLvX4smsfc3CocdthqAz2A',
  refreshToken: 'gic2k8e1l0qhagyvxuf3hey5h2106nx8'
}

// clear localStorage
export const clearLocalStorage = () => {
  localStorage.clear()
}
