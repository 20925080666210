import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill';
import 'abortcontroller-polyfill'; 
import { toast } from 'react-toastify'
import React, { Component } from 'react'
import Settings from '@settings'
import { getSessionVariable } from '../helpers'
import { Footer, Header, HomeFooter, Sidebar, IE11Widget } from '../components'
import { AUTH_TOKEN_NAME } from '../constants'
import { userService } from '../services'

class Layout extends Component {
  state = {
    userData: {},
    triggerSidebar: false,
    isIE11: false,
    pageHeader:'Dashboard'
  }

  getHeader = () => {
    if (
      this.props.url.includes('dashboard') ||
      getSessionVariable(AUTH_TOKEN_NAME)
    ) {
      return (
        <Header
          url={this.props.url}
          userData={this.state.userData}
          headerText={this.props.headerText}
          handleSidebar={this.handleSidebar}
          pageHeader={this.state.pageHeader}
        />
      )
    }
    return <Header url={this.props.url} />
  }

  abortController = new AbortController()

  componentDidUpdate() {}

  getSidebar = () => {
    if (this.props.url.includes('dashboard')) {
      return (
        <Sidebar
          url={this.props.url}
          handleSidebar={this.handleSidebar}
          triggerSidebar={this.state.triggerSidebar}
        />
      )
    }
  }

  handleSidebar = () => {
    this.setState(state => ({
      ...state,
      triggerSidebar: !state.triggerSidebar
    }))
  }

  getFooter = () => {
    if (
      this.props.url.includes('dashboard') ||
      this.props.url.includes('login') ||
      this.props.url.includes('forgot-password') ||
      this.props.url.includes('reset-password')
    ) {
      return <HomeFooter />
    }
    return <HomeFooter />
  }

  componentDidMount() {    
    // Hide sidebar on page load
    if (getSessionVariable(AUTH_TOKEN_NAME)) {
      userService
        .getUserDetail()
        .then(userData => {          
          this.setState({ userData, isLoggedIn: true })
        })
        .catch(error => {
          this.setState({ isLoading: false, isUserNotFound: true })
          toast.error(error.message)
        })
    }

    if (this.state.triggerSidebar) {
      this.setState(state => ({
        ...state,
        triggerSidebar: !state.triggerSidebar
      }))
    }

    // If browser is IE11 update state isIE11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.setState(state => ({
        ...state,
        isIE11: true
      }))
    }
  }

  render() {
    return (
      <>
        {this.state.isIE11 && <IE11Widget />}
        <div
          className={
            this.props.url.includes('dashboard')
              ? 'wrapper dashboard'
              : 'wrapper'
          }
        >
          {this.getSidebar()}
          <main>
            {/* Show horizontal nav for mobile view if Header is not present */}
            {Settings.showHeaderAndFooter ? this.getHeader() : this.getHeader()}
            <section className='content-wrapper'>{this.props.children}</section>
            {Settings.showHeaderAndFooter && this.getFooter()}
          </main>
        </div>
      </>
    )
  }
}

export default Layout
