import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap'
import {
  NotificationsOutlined,
  AccountCircleOutlined,
  Dashboard,
  InputOutlined,
  SettingsOutlined,
  ExitToAppOutlined,
  LockOutlined  
} from '@material-ui/icons'
import { userService } from '../../services'
import '../../styles/dashboard-header.scss'
import { getSessionVariable, capitalizeFirstLetterOfWord } from '../../helpers'
import { AUTH_TOKEN_NAME } from '../../constants'
import { Link } from 'react-router-dom'
import { isLoggedIn } from '../../helpers'

const Header = ({ ...props }) => {
  let header = 'header'
  let userIconName = ''
  let userFullName = ''  
  
  if (getSessionVariable(AUTH_TOKEN_NAME)) header = 'homeloggedin'
  if (props.url && props.url.includes('dashboard')) header = 'dashboard'

  if (header === 'dashboard' || header === 'homeloggedin') {
    if (Object.keys(props.userData).length > 0) {
      const userFirstName = props.userData.f_name
      const userLastName = props.userData.l_name
      userFullName =
        userLastName !== '' ? `${userFirstName} ${userLastName}` : userFirstName
      userFullName = capitalizeFirstLetterOfWord(userFullName)
      userIconName =
        userLastName !== ''
          ? (
              userFirstName.substring(0, 1) + userLastName.substring(0, 1)
            ).toUpperCase()
          : userFirstName.substring(0, 1).toUpperCase()
    }
  }
  const navDropdownTitle = <div className='user-icon'>{userIconName}</div>
  const navDropdownNotification = <NotificationsOutlined />
  const banner = ''
  let navCollapse = ''

  const navLogin = (
    <div className='d-inline-flex'>
      <Nav.Link as={NavLink} to='/admin-login' className='font-weight-bold'>
        <img src='assets/img/shine-admin-login.png' width="35" className='mr-3'></img><span className='text-blue font-weight-bold h2 m-0'>Admin login</span> 
      </Nav.Link>  
      <Nav.Link as={NavLink} to='/login' className='font-weight-bold'>
        <img src='assets/img/shine-login.png' width="35" className='mr-3'></img><span className='text-blue font-weight-bold h2 m-0'>Login</span> 
      </Nav.Link>  
    </div>
  )
  
  const logoutUser = () => {
    userService.logout()

    /* clearAllSession()
    toast.success(LOGOUT_SUCCESSFUL)

    setTimeout(() => {
      window.location.href = '/login'
   }, 2000) */
  }
  const navNotification = (
    // <Nav.Link as={NavLink} to='/dashboard/notification' className='mr-5'>
    //   <NotificationsOutlined />
    // </Nav.Link>
    <NavDropdown
      title={navDropdownNotification}
      className='myaccount-dropdown mr-3'
      id='noti-dropdown'
    >
      <NavDropdown.Item as={NavLink} to='/dashboard/notification'>
        A new program added
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={NavLink} to='/dashboard/notification'>
        Metrics table
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={NavLink} to='/dashboard/notification'>
        User role chnaged
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        to='/dashboard/notification'
        className='text-right small'
      >
        View all
      </NavDropdown.Item>
    </NavDropdown>
  )

  const navMyAccount =
    header === 'dashboard' || header === 'homeloggedin' ? (
      <NavDropdown
        title={navDropdownTitle}
        className='myaccount-dropdown'
        id='myaccount-dropdown'
      >
        <div className='d-flex'>
          <div className='flex-fill'>
            <div className='user-icon'>{userIconName}</div>
            {/* <AccountCircleOutlined fontSize="large" className='ml-2 text-muted' /> */}
          </div>
          <div className='flex-fill'>
            <div className='pl-3'>
              <h4 className='mb-0'>{userFullName}</h4>
              <span className='d-block text-muted small'>
                {props.userData.email}
              </span>
              <span className='d-block highlight'>
                {props.userData.role ? props.userData.role.role_name : 'Agent'}
              </span>
            </div>
          </div>
        </div>
        <NavDropdown.Divider />
        {header === 'homeloggedin' ? (
          <NavDropdown.Item as={NavLink} to='/dashboard'>
            <Dashboard /> Back to Dashboard
          </NavDropdown.Item>
        ) : null}
        {header === 'homeloggedin' ? <NavDropdown.Divider /> : null}

        {/* <NavDropdown.Item as={NavLink} to='/dashboard/settings'>
          <SettingsOutlined /> Settings
        </NavDropdown.Item>
        <NavDropdown.Divider /> */}
        <NavDropdown.Item
          className='pl-3'         
        >
          <Link as={NavLink} to='/dashboard/changepassword/'>
          <LockOutlined /> Change Password
              </Link>
          
        </NavDropdown.Item>
        <NavDropdown.Item
          className='pl-3'
          onClick={() => {
            logoutUser()
          }}
        >
          <ExitToAppOutlined /> Log out
        </NavDropdown.Item>
      </NavDropdown>
    ) : null

 if (header === 'dashboard') {
   let pageSection = props.headerText.section
   pageSection = pageSection !== '' ? pageSection : 'CCMS'
    navCollapse = (
      <Navbar.Collapse id='pm-navbar-nav' className='d-none d-xl-block'>
        <Nav className='w-100 d-lg-flex'>
          <img src="/assets/img/lable-graph.png" className='pr-2 pl-2'></img>
        <h3 className='page-lable'>
            <small className='text-uppercase'>{pageSection}</small>
            <br />
          {props.headerText.page_title}
           </h3>          
           <div className='ml-auto'> {navMyAccount}</div>
        </Nav>
      </Navbar.Collapse>
    )
  }  

  if (header === 'homeloggedin') {
    navCollapse = (
      <Navbar.Collapse id='pm-navbar-nav' className='justify-content-end'>
        <Nav>{navMyAccount}</Nav>
      </Navbar.Collapse>
    )
  }

  if (header === 'header') {
    navCollapse = (
      <Navbar.Collapse id='pm-navbar-nav' className='justify-content-end'>
        <Nav>{navLogin}</Nav>
      </Navbar.Collapse>
    )
  }

  return (
    <header className='fixed-top'>
      {banner}
      <Container fluid>
        <Navbar expand='lg'>
          {header !== 'dashboard' ? (
            <>
              <Navbar.Brand as={NavLink} to='/'>
                <img src='assets/img/shine-logo.png' alt='Glowtouch CCMS' />{' '}
                <span></span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='pm-navbar-nav' />
            </>
          ) : (
            <button
              type='button'
              aria-label='Toggle Sidebar'
              className='navbar-toggler collapsed'
              onClick={props.handleSidebar}
            >
              <span className='navbar-toggler-icon' />
            </button>
          )}

          {navCollapse}
          {/* My Account menu for dashboard pages only */}
          {getSessionVariable(AUTH_TOKEN_NAME) && header === 'dashboard' && (
            <Nav className='d-flex justify-content-end d-lg-none d-xl-none'>
              {navMyAccount}
            </Nav>
          )}
        </Navbar>
      </Container>
    </header>
  )
}

export default Header
