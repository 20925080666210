import React from 'react'
import { Alert, Button, Card, ListGroup, Spinner, Form } from 'react-bootstrap'
import { DateRange, AddCircleOutline } from '@material-ui/icons'
import { Switch } from '@material-ui/core'
import moment from 'moment'
import '../../styles/base.scss'
import NewNotes from './NewNotes'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Link } from 'react-router-dom'

const QualityPlanWidget = props => {
  const { notes } = props
  return (
    <Card className='mn-404 no-bg'>
      <Card.Body className='p-3'>
        <Card.Header className='p-2 pl-0 mb-2'>
          <DateRange className='mb-3' />
          {/*QUALITY PLAN TO WORK ON */} NOTES TO SELF
        </Card.Header>
        {(props.areNotesLoading || props.savingNote || props.updatingNote) ? (
          <OverlayLoader />
        ) : (
          <ListGroup variant='flush'>            
            {props.notesFullData && props.notesFullData.total === 0 ? (
              <div className='recent-activity-list'>
                Notes are not found
                {/*<Alert variant='danger' />*/}
                <ListGroup.Item>
                  {props.newModal && (
                    <NewNotes
                      newModal={props.newModal}
                      data={{
                        title: '',
                        content: '',
                        notifyOn: new Date()
                      }}
                      showModal={props.showModal}
                      handleSubmit={props.handleSubmit}
                    />
                  )}
                </ListGroup.Item>
                {!props.newModal && (
                  <Button variant='link' onClick={() => props.showModal(true)}>
                    <AddCircleOutline className='mr-1' />
                    Notes to self
                  </Button>
                )}
              </div>
            ) : (
              <div className='recent-activity-list'>               
                {notes.map((elem, index) => {
                  const notifyDate = moment(new Date(elem.notify_on))
                  let notifyOnDate = notifyDate.format('MMM-DD-YYYY')
                  let classDanger = ''
                  //const isChecked = elem.status === 'Active'

                  if (notifyDate.isValid()) {
                    if (notifyDate.isBefore(moment()))
                      classDanger = 'text-danger'
                  } else {
                    notifyOnDate = ''
                  }
                  return (
                    <ListGroup.Item key={index} className='p-3'>
                      <div className='row'>
                        <div className='col-md-8'>
                          <span className='qualityPlanheading'>
                            {elem.title}
                          </span>
                        </div>
                        <div className='col-md-4 text-right' />
                        <div className='col-md-11 pr-0'>
                          <div className='checklist-item checklist-item-success'>
                            <div className='qualityPlan'>
                              {elem.content !== '' ? elem.content : 'No Data'}
                            </div>
                            <div
                              className={`qualityPlan-date float-right ${classDanger}`}
                            >
                              {notifyOnDate}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-1 pl-0'>
                          {/*<span className='qualityPlan-status'>
                            {elem.status}
                          </span>*/}
                          {elem.note_type === 'SELF' && (
                            <Link 
                              id={elem.public_id}
                              onClick={e => props.onClickNotesComplete(e, elem.public_id)}
                              title="Mark Completed"                              
                            >
                            <DeleteOutlinedIcon />
                            </Link>
                            /*<div className=''>
                              {!isChecked && (                                
                                <button
                                  className='btn btn-primary btn-sm float-right mt-2 mb-2'
                                  id={elem.public_id}
                                  onClick={() =>
                                    props.updateSelectedNotes(elem.public_id)
                                  }
                                >
                                  {props.updatingNote ? (
                                    <ShowSpinner buttonSpinner={true} />
                                  ) : null} 
                                  Save                                
                                </button>
                              )}
                              
                             <Switch
                                id={elem.public_id}
                                className='float-right'
                                checked={isChecked}
                                onChange={props.toggleSelected}
                                cool={elem.status}
                              />
                            </div>*/
                          )}
                        </div>
                      </div>
                    </ListGroup.Item>
                  )
                })}

                {props.savingNote && (
                  <ListGroup.Item key={-10}>
                    <div className='mb-2 text-secondary'>Saving Note...</div>
                    <div className='checklist-item checklist-item-success'>
                      <ShowSpinner class_name='mr-3 ml-3' />
                    </div>
                  </ListGroup.Item>
                )}
                
                  <ListGroup.Item>
                  {props.notesFullData && props.notesFullData.current_page > 1 && (
                    <Button className="btn btn-sm mr-2" onClick={() => props.showPreviousNotes()}>
                      Previous
                    </Button> )}
                    {props.hasNextNotes && (
                      <Button className="btn btn-sm" onClick={() => props.showMoreNotes()}>
                      Next
                    </Button>)}
                  </ListGroup.Item>
                
                <ListGroup.Item>
                  {props.newModal && (
                    <NewNotes
                      newModal={props.newModal}
                      data={{
                        title: '',
                        content: '',
                        notifyOn: new Date()
                      }}
                      showModal={props.showModal}
                      handleSubmit={props.handleSubmit}
                    />
                  )}

                  {!props.newModal && (
                    <Button
                      variant='link'
                      onClick={() => props.showModal(true)}
                    >
                      <AddCircleOutline className='mr-1' />
                      Notes to self
                    </Button>
                  )}
                </ListGroup.Item>
              </div>
            )}
          </ListGroup>
        )}
      </Card.Body>
    </Card>
  )
}

export default QualityPlanWidget
