// Add an space after message body for better readability
export const SESSION_EXPIRED_LOG_OUT = 'Your session has been expired. Please login again.'
export const LOGIN_SUCCESSFUL = 'You have been logged in successfully.'
export const LOGOUT_SUCCESSFUL = 'You have been logged out.'
export const ACCOUNT_SUCCESS = 'Your account is created and you have been logged in successfully.'
export const ACCOUNT_SUCCESS_LOGIN_FAILED = 'Your account is created. Please login to site.'
export const UPGRADE_ACCOUNT_SUCCESS = 'Your account is upgraded successfully.'
export const PROFILE_UPDATE_SUCCESS = 'Profile updated successfully.'
export const PASSWORD_CHANGE_SUCCESS = 'Password Changed Successfully.'
export const NEWSLETTER_SIGNUP_SUCCESS = 'Thank you for signing up for our newsletter'
export const FORGOT_PASSWORD_SUCCESS = 'If this email address is associated with an account, you will receive a password reset email'
export const CURRENT_PASSWORD = 'Enter your current password'
export const NEW_PASSWORD = 'Set your password'
export const CONFIRM_PASSWORD = 'Confirm your password'
export const INVALID_EMAIL_ADDRESS = 'Please enter a valid email address'
export const VALIDATE_SESSION_MSG = 'Please wait while we validate your session...'

//Error Messages
export const API_FAILURE_ERROR = 'We’re sorry. Something didn’t work quite right. Please try again in a moment.'
export const INVALID_LOGIN = 'You have entered an incorrect email or password.'
export const ACCOUNT_FAILED = 'Failed to Create your account. Please try again later.'
export const UPGRADE_ACCOUNT_FAILED = 'Failed to Upgrade your account. Please try again later.'
export const PROFILE_UPDATE_FAIL = 'Error Saving the Profile Information'
export const INVALID_RESET = 'Invalid reset link'
export const ERROR_METRIC_HEADER = 'Metrics headers were not set to this program. Please set the same to upload the program metrics.'
export const NO_RECORDS = 'Records not found'
export const PROGRAM_FETCH_ERROR = 'Errors on fetching program data'
export const NO_DATA = 'No data'
export const NO_KEY_METRICS = 'No key metrics Found'
export const NO_METRICS = 'No metrics data'
export const ERROR_FETCH_PROGRAM_METRICS = 'Error while fetching program metrics'
export const ERROR_INVALID_METRICS_DATATYPE = 'Error invalid metrics data types in columns'