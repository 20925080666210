import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import {
  Home,
  Dashboard,
  Login,
  AdminLogin,
  ResetPassword,
  ForgotPassword,
  ErrorPage
} from '../containers'
import { PrivateRoute, PublicRoute } from '.'
//import AdminLogin from '../containers/landing/AdminLogin'

export const Routes = () => {
  return (
  <BrowserRouter>
    <Switch>
      <PublicRoute
        restricted={false}
        exact
        path='/'
        component={Home}
        url='home'
      />
      <PrivateRoute path='/dashboard' component={Dashboard} />
      <PublicRoute
        restricted={false}
        path='/reset-password/:id'
        component={ResetPassword}
        url='reset-password'
      />
      <PublicRoute
        restricted={false}
        path='/forgot-password'
        component={ForgotPassword}
        url='forgot-password'
      />
      <PublicRoute
        restricted={false}
        path='/login'
        component={Login}
        url='login'
      />

      <PublicRoute
        restricted={false}
        path='/admin-login'
		    exact
        component={AdminLogin}
        url='admin-login'
      />
      <PublicRoute
        restricted={false}
        path='*'
        component={ErrorPage}
        url='404'
        layout={false}
      />
    </Switch>
	</BrowserRouter>
  )
}
