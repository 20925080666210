/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// import dateFormat from 'dateformat';
import moment from 'moment';
import { ProgramTable, AdminProgramsCard, ProgramsCard, UnauthorizedCard, OverlayLoader } from '../../components'
import makeData from '../../components/dashboard/makeData'
import { isAdmin, isPm, isAgent, isProgramDirector } from '../../helpers'
import { programService } from '../../services'
// import { Spinner } from 'react-bootstrap'

class Programs extends Component {
  state = {
    programs: [],
    data: [],
    pageCount: 1,
    fetchIdRef:0,
    target_category: this.props.match.params.target_category,
    isAdd: false,
    isLoading: false,
    myGlobalFilter : '',
    totalRecords:0,
    isAuthorizedAccess:null,
  }

  componentDidMount = async () => {
    if(!isAdmin()) {      
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.props.updateHeaderTxt('Programs List', 'Programs')
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: true,        
      }))  
    }   
  }

  handleNewProgramClick = () => {
    this.setState(state => ({
      ...state,
      isAdd: true
    }))
  }

  fetchData = ({ pageIndex, pageSize, sortBy}) => {
    this.setState(state => ({
      ...state,
      isLoading: true
    }))
    
    let queryString = `?page_size=${pageSize}&page_no=${pageIndex+1}`

    if (sortBy.length === 1) {
      queryString += `&sort_by=${sortBy[0].id}&sort_order=${sortBy[0].desc ? 'desc' : 'asc'}`
    }      
    
    if(this.state.myGlobalFilter !== "") {
      queryString += `&filter_query=${this.state.myGlobalFilter}`
    }
    
    setTimeout(() => {
        programService
        .get_paginated_programs(queryString)
        .then(data => {                  
          this.setState(state => ({
            ...state,
            data: data.data,
            pageCount: Math.ceil(data.total / pageSize),
            isLoading: false,  
            totalRecords: data.total             
          }))
        })
        .catch(() => {})
    }, 500)
  }

  columns = [
    {
      Header: 'Program Name',
      accessor: 'program_name'      
    },
    {
      Header: 'Program Type',
      accessor: 'program_type'      
    },
    {
      Header: 'Created On',
      accessor: 'created_on',     
      Cell : (props)=> {
        // props.value will contain your date
        // you can convert your date here
        const customDate = moment(props.value).format('DD/MM/YYYY')
        return <span>{customDate}</span>
      }      
    },
    {
      Header: 'Agent Count',
      accessor: 'agent_count',
      disableSortBy:true      
    },       
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true     
    }
  ]

  setMyGlobalFilter = (globalFilter) => {      
    this.setState({
      myGlobalFilter: globalFilter
    })       
  }

  executeRender = () => {   
    const { isAuthorizedAccess, programs, isAdd, isLoading } = this.state
    
    if(isAuthorizedAccess === null) {
      return <OverlayLoader />
    }

    if(isAuthorizedAccess !== null && isAuthorizedAccess) {
      if (isAdd) {
        return <Redirect to='/dashboard/programs/add' />
      } else {
        return <ProgramTable
          columns={this.columns}
          data={this.state.data}
          fetchData={this.fetchData}
          loading={this.state.isLoading}
          pageCount={this.state.pageCount}
          handleNew={this.handleNewProgramClick}
          myGlobalFilter={this.state.myGlobalFilter}
          setMyGlobalFilter={this.setMyGlobalFilter}
          totalRecords={this.state.totalRecords}                        
        />
      }     
    } else {
      return <UnauthorizedCard />
    }    
  }

  render() {
    return this.executeRender()
  }  
}

export default Programs
