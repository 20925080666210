/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import MaUTable from '@material-ui/core/Table'
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { usePagination, useTable } from 'react-table'
import TableScrollbar from 'react-table-scrollbar'
import '../../styles/base.scss'
import { Cancel } from '@material-ui/icons'

const ScrollableTable = ({ columns, data, deleteHandler, disabled, displayHeaders }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    usePagination
  )

  const handleCellClick = (e, cell) => {
    deleteHandler(cell.row.original)
  }

  const getButton = cell => {
    if (!disabled) {
      return (
        <OverlayTrigger
          key='top'
          placement='top'
          overlay={
            <Tooltip id={`tooltip-top`}>
              Remove
          </Tooltip>
          }
        >
          <Button
            type='button'
            variant='link'
            title='Remove'
            className='btn btn-sm text-danger float-right'
            onClick={e => handleCellClick(e, cell)}
          >
            <Cancel />
          </Button>
        </OverlayTrigger>
      )
    }
    return null
  }

  // Render the UI for your table
  return (
    <TableScrollbar rows={10}>
      <MaUTable {...getTableProps()} className='table table-xs card-table border'>
        {displayHeaders ? (<TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.length === 1
                ? headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableCell>
                ))
                : null}
            </TableRow>
          ))}
        </TableHead>) : null}
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.column.Header === 'Action'
                        ? getButton(cell)
                        : cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
    </TableScrollbar>
  )
}

export default ScrollableTable
