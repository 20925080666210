import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_DASHBOARD,
  LOCAL_STORAGE_DASHBOARD
} from '../constants'
import { getUserData, setUserData } from '../helpers'

export const dashboardService = {
  get_admin_dashboard_data: async signal => {
    const data = getUserData(LOCAL_STORAGE_DASHBOARD)

    if (!data) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_DASHBOARD,
        { ...HTTPHeaders() }
      )

      setUserData(LOCAL_STORAGE_DASHBOARD, response)

      return response
    }
    return data
  }
}
