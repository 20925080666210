import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Switch } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  AccountSettings,
  AddProgram,
  Coaching,
  ErrorPage,
  Layout,
  Overview,
  Users,
  ChangePassword
} from '..'
import {
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_SELECTED_AGENT,
  LOCAL_STORAGE_SELECTED_WEEK,
  LOCAL_STORAGE_SELECTED_TEAM_TYPE,
  LOCAL_STORAGE_SELECTED_VIEW_TYPE,
  ACCOUNT_SETTINGS_URL,
  COACHING_URL,
  DEFAULT_PROGRAM_SETTINGS_URL,
  METRICS_URL,
  PROGRAMS_URL,
  MANAGE_METRICS_URL,
  USER_NOT_FOUND_URL,
  FORM_SUBMIT_YUP_URL,
  USER_DETAILS,
  PROGRAM_FETCH_ERROR,
  USERS_URL,
  CHANGE_PASSWORD_URL
} from '../../constants'
import { AddMetric } from '../../components'

import { PrivateRoute } from '../../routes'
import FormSubmit from './FormSubmit'
import Metrics from './Metrics'
import ManageMetrics from './ManageMetrics'
import ManageDefaultProgram from './ManageDefaultProgram'
import Programs from './Programs'
import ViewOrEditProgram from './ViewOrEditProgram'
import AddCoaching from './AddCoaching'
import AgentView from './AgentView'
import {
  getUserData,
  setUserData,
  getWeekOptions,
  isPm,
  isAgent,
  isProgramDirector,
  getArrayRowByValue,
  populateAgentList,
  getMMDDYYYY
} from '../../helpers'
import { programService } from '../../services'
import { weekModifier } from '../../constants/week.selection.modifier'

class Dashboard extends Component {
  getDays = startOf => {
    const days = [startOf]
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(startOf)
          .add(i, 'days')
          .toDate()
      )
    }
    return days
  }

  onDayMouseEnter = date => {
    const startOf = moment(date)
      .startOf('isoWeek')
      .isoWeekday(0)
      .toDate()
    const days = this.getDays(startOf)
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        dateModifier: {
          ...state.programBar.dateModifier,
          hoverRange: days,
          modifiers: {
            ...state.programBar.dateModifier.modifiers,
            hoverRange: days,
            hoverRangeStart: days && days[0],
            hoverRangeEnd: days && days[6]
          }
        }
      }
    }))
  }

  onDayClick = (date, { selected }) => {
    const startOf = moment(date)
      .startOf('isoWeek')
      .isoWeekday(1)
      .toDate()
    // const startOf1 = moment(date)
    //   .startOf('isoWeek')
    //   .isoWeekday(1)
    //   // .subtract(1, 'week')
    //   .toDate()

    let days = {}
    let value = 'Select Week'
    const year = moment(startOf).format('yyyy')
    const week = moment(startOf).format('ww')
    // const yearlabel = moment(startOf).format('yyyy')
    // const weeklabel = moment(startOf).format('ww')
    if (!selected) {
      value = `Week - ${week} , ${year}`
      days = this.getDays(startOf)
    }

    const dateModifier = {
      ...this.state.programBar.dateModifier,
      selectedDays: days,
      value,
      firstDayOfWeek: 1
    }

    const label = `Week ${week} -  ${getMMDDYYYY(
      moment(days[0])
    )} - ${getMMDDYYYY(moment(days[6]))}`

    const selectedWeek = {
      value: week, label,
      week: { weekNo: week, startYear: year }
    }

    setUserData(LOCAL_STORAGE_SELECTED_WEEK, {
      week: { weekNo: week, startYear: year }
    })
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        dateModifier,
        selectedWeek
      }
    }))
    this.state.programBar.onWeekChangeHook(year, week)
  }

  onPmChange = (program, hook, storedSelectedAgent) => {
    //let program_id = program.value
    let programId = program.program.public_id
    if (programId) {
      this.setState(state => ({
        ...state,
        programBar: {
          ...state.programBar,
          isFetchingProgram: true
        }
      }))

      programService
        .get_program_by_id(programId)
        .then(data => {
          const selectedProgram = this.populateSelectedProgram(data)
          setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram)
          const agentOptions = this.populateAgentOptions(data)
          if (!storedSelectedAgent) {
            storedSelectedAgent = agentOptions[0]
            setUserData(LOCAL_STORAGE_SELECTED_AGENT, storedSelectedAgent)
          }
          const { selectedDays } = this.state.programBar.dateModifier

          this.setState(state => ({
            ...state,
            programBar: {
              ...state.programBar,
              agentOptions,
              selectedProgram,
              selectedAgent: storedSelectedAgent,
              isFetchingProgram: false,
              dateModifier: {
                ...state.programBar.dateModifier,
                firstDayOfWeek: 1,
                // value: 'Select Week',
                modifiers: {
                  ...state.programBar.dateModifier.modifiers,
                  hoverRange: [],
                  selectedRange: selectedDays.length > 0 && {
                    from: selectedDays[0],
                    to: selectedDays[6]
                  }
                }
              }
            },
            agentOptions,
            uploadResponse: {}
          }))

          if (this.isHookValid(hook)) {
            hook(program)
          }
        })
        .catch(error => {
          toast.error(PROGRAM_FETCH_ERROR, error)
          this.setState(state => ({
            ...state,
            programBar: {
              ...state.programBar,
              isFetchingProgram: false
            }
          }))
        })
    }
  }

  updateHeaderTxt = (pageTitle, section) => {
    this.setState(state => ({
      ...state,
      headerText: {
        ...state.headerText,
        page_title: pageTitle,
        section
      }
    }))
  }

  isHookValid = hook => {
    return hook && typeof hook === 'function'
  }

  onPmChangeHook = () => {}

  onAgentChange = (selectedAgent, hook) => {
    setUserData(LOCAL_STORAGE_SELECTED_AGENT, selectedAgent)
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        selectedAgent,
        enableSelection: false
      }
    }))
    if (this.isHookValid(hook)) {
      hook()
    }
  }

  setShowAgent = value => {
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        showAgents: value
      }
    }))
  }

  setEnableSelection = selection => {
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        enableSelection: selection
      }
    }))
  }

  onAgentChangeHook = () => {}

  toggleSelection = () => {
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        enableSelection: !state.programBar.enableSelection
      }
    }))
  }

  onWeekChange = (selectedWeek, hook) => {
    setUserData(LOCAL_STORAGE_SELECTED_WEEK, selectedWeek)
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        selectedWeek
      }
    }))
    if (this.isHookValid(hook)) {
      hook(selectedWeek)
    }
  }

  onWeekChangeHook = () => {}

  onTeamTypeChange = (selectedTeamType, hook) => {
    setUserData(LOCAL_STORAGE_SELECTED_TEAM_TYPE, selectedTeamType)
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        selectedTeamType
      }
    }))
    if (this.isHookValid(hook)) {
      hook(selectedTeamType)
    }
  }

  onTeamTypeChangeHook = () => {}

  onViewTypeChange = (selectedViewType, hook) => {
    setUserData(LOCAL_STORAGE_SELECTED_VIEW_TYPE, selectedViewType)
    this.setState(state => ({
      ...state,
      programBar: {
        ...state.programBar,
        selectedViewType
      }
    }))
    if (this.isHookValid(hook)) {
      hook(selectedViewType)
    }
  }

  onViewTypeChangeHook = () => {}

  state = {
    programBar: {
      onPmChange: this.onPmChange,
      onAgentChange: this.onAgentChange,
      toggleSelection: this.toggleSelection,
      onWeekChange: this.onWeekChange,
      onPmChangeHook: this.onPmChangeHook,
      onAgentChangeHook: this.onAgentChangeHook,
      onWeekChangeHook: this.onWeekChangeHook,
      onTeamTypeChange: this.onTeamTypeChange,
      onTeamTypeChangeHook: this.onTeamTypeChangeHook,
      onViewTypeChange: this.onViewTypeChange,
      onViewTypeChangeHook: this.onViewTypeChangeHook,
      setEnableSelection: this.setEnableSelection,
      setShowAgent: this.setShowAgent,
      dateModifier: weekModifier(this.onDayClick, this.onDayMouseEnter),
      programOptions: [],
      agentOptions: [],
      weekOptions: [],
      selectedProgram: null,
      selectedAgent: null,
      selectedWeek: null,
      enableSelection: false,
      showAgents: false,
      showWeeks: false,
      showDateRange: false,
      isFetchingProgram: true,
      showTeamType: false,
      teamTypeOptions: [
        { label: 'Team Type1', value: 'Team Type1' },
        { label: 'Team Type2', value: 'Team Type2' }
      ],
      showViewType: false,
      viewTypeOptions: [
        { label: 'Dashboard View', value: 'Dashboard View' },
        { label: 'Detailed View', value: 'Detailed View' },
        { label: 'Agent View', value: 'Agent View' }
      ],
      selectedTeamType: null,
      selectedViewType: null
    },
    headerText: {
      page_title: 'Dashboard',
      section: 'CCMS'
    },
    isLoading: true
  }

  abortController = new AbortController()

  constructor(props, context) {
    super(props, context)
    const isProgramDir = isProgramDirector()
    if (isPm() || isProgramDir) {
      this.setState(state => ({
        ...state,
        isLoading: true,
        programBar: {
          ...state.programBar,
          showAgents: true,
          showWeeks: true,
          showTeamType: isProgramDir,
          showViewType: isProgramDir
        }
      }))
      setTimeout(() => {
        this.getPmOrProgramDirUserDetails()
      }, 500)
    }

    if (isAgent()) {
      this.setState(state => ({
        ...state,
        isLoading: true,
        programBar: {
          ...state.programBar,
          showWeeks: true
        }
      }))

      setTimeout(() => {
        this.getAgentDetails()
      }, 500)
    }
  }

  componentDidMount() {}

  populateProgramOptions = data => {
    const programOptions = []
    if (data && data.length > 0) {
      data.forEach(item => {
        programOptions.push({
          value: item.public_id,
          label: item.program_name,
          program: item
        })
      })
    }
    return programOptions
  }

  getPmOrProgramDirUserDetails = () => {
    programService
      .get_pm_programs()
      .then(data => {
        const programOptions = this.populateProgramOptions(data.data)
        let agentOptions = []
        const userDetails = getUserData(USER_DETAILS)
        // incase if the a program manager has only
        // one program, by default make it selected
        let selectedProgram = null
        let selectedAgent = null
        // deleteData(LOCAL_STORAGE_SELECTED_PROGRAM)
        const storedSelectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)
        const storedSelectProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
        if (storedSelectProgram) {
          // previous selected program before logout
          selectedProgram = storedSelectProgram
          agentOptions = this.populateAgentOptions(storedSelectProgram.program)
        } else if (userDetails.default_program) {
          // first time login to the app
          // eslint-disable-next-line prefer-destructuring
          selectedProgram = getArrayRowByValue(
            programOptions,
            'value',
            userDetails.default_program
          ).data
          agentOptions = this.populateAgentOptions(selectedProgram.program)
          setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram)
        } else if (programOptions.length > 0) {
          // first time login to the app
          // eslint-disable-next-line prefer-destructuring
          selectedProgram = programOptions[0]
          agentOptions = this.populateAgentOptions(selectedProgram.program)
          setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram)
        }
        this.onPmChange(
          selectedProgram,
          this.state.programBar.onPmChangeHook,
          storedSelectedAgent
        )
        if (storedSelectedAgent) {
          selectedAgent = storedSelectedAgent
        } else {
          selectedAgent = agentOptions[0]
        }

        // get week options
        const weekOptions = getWeekOptions()
        const selectedWeek = weekOptions[1]
        const selectedViewType = this.state.programBar.viewTypeOptions[0]
        this.onDayClick(selectedWeek.week.start.toDate(), {
          selected: undefined
        })
        setUserData(LOCAL_STORAGE_SELECTED_WEEK, selectedWeek)
        setUserData(LOCAL_STORAGE_SELECTED_VIEW_TYPE, selectedViewType)
        this.setState(state => ({
          ...state,
          programBar: {
            ...state.programBar,
            programOptions,
            agentOptions,
            weekOptions,
            selectedProgram,
            selectedAgent,
            selectedWeek,
            selectedViewType
          },
          programs: data.data
        }))
      })
      .catch(e => {        
        toast.error(PROGRAM_FETCH_ERROR, e)
        this.setState(state => ({
          ...state
        }))
      })
  }

  populateAgentOptions = program => {
    return populateAgentList(program)
  }

  getAgentDetails = () => {
    programService
      .get_agent_programs()
      .then(data => {
        const programOptions = this.populateProgramOptions(data.data)

        // incase if the a program manager has only
        // one program, by default make it selected
        let selectedProgram = null
        const storedSelectProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
        if (storedSelectProgram) {
          // previous selected program before logout
          selectedProgram = storedSelectProgram
        } else if (programOptions.length > 0) {
          // first time login to the app
          // eslint-disable-next-line prefer-destructuring
          selectedProgram = programOptions[0]
          setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram)
        }

        this.onPmChange(selectedProgram, this.state.programBar.onPmChangeHook)
        // get week options
        const weekOptions = getWeekOptions()
        const selectedWeek = weekOptions[0]
        setUserData(LOCAL_STORAGE_SELECTED_WEEK, selectedWeek)

        this.setState(state => ({
          ...state,
          programBar: {
            ...state.programBar,
            programOptions,
            weekOptions,
            selectedProgram,
            selectedWeek
          },
          programs: data.data
        }))
      })
      .catch(e => {
        toast.error('Error while fetching Agent Program Details', e)
        this.setState(state => ({
          ...state
        }))
      })
  }

  populateSelectedProgram = program => {
    return {
      value: program.public_id,
      label: program.program_name,
      program
    }
  }

  getSelectedProgramForFirstTimeAgentLogin = programOptions => {
    let selectedProgram = {}
    const userData = getUserData(USER_DETAILS)
    const defaultProgram = userData.default_program

    if (defaultProgram) {
      selectedProgram = getArrayRowByValue(
        programOptions,
        'value',
        defaultProgram
      ).data
    } else {
      selectedProgram = programOptions[0]
    }

    setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram.program)

    return selectedProgram
  }

  render() {
    const { url } = this.props.match
    return (
      <Layout url={url} headerText={this.state.headerText}>
        <Container fluid>
          <Switch>
            <PrivateRoute
              path={`${url}`}
              exact
              component={Overview}
              programBar={this.state.programBar}
              updateHeaderTxt={this.updateHeaderTxt}
              isLoading={this.state.isLoading}
            />
            <PrivateRoute
              path={`${url}/agentview`}
              exact
              component={AgentView}
              programBar={this.state.programBar}
              updateHeaderTxt={this.updateHeaderTxt}
              isLoading={this.state.isLoading}
            />
            <PrivateRoute
              exact
              path={`${url}${COACHING_URL}/add`}
              component={AddCoaching}
              updateHeaderTxt={this.updateHeaderTxt}
              programBar={this.state.programBar}
            />
            <PrivateRoute
              path={[
                `${url}${COACHING_URL}/:target_category`,
                `${url}${COACHING_URL}`
              ]}
              programBar={this.state.programBar}
              component={Coaching}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute
              exact
              path={`${url}${MANAGE_METRICS_URL}`}
              programBar={this.state.programBar}
              component={ManageMetrics}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute
              exact
              path={`${url}${DEFAULT_PROGRAM_SETTINGS_URL}`}
              programBar={this.state.programBar}
              component={ManageDefaultProgram}
              updateHeaderTxt={this.updateHeaderTxt}
            />

            <PrivateRoute
              path={ACCOUNT_SETTINGS_URL}
              component={AccountSettings}
              updateHeaderTxt={this.updateHeaderTxt}
            />

            <PrivateRoute
              exact
              path={`${url}${PROGRAMS_URL}/add`}
              component={AddProgram}
              updateHeaderTxt={this.updateHeaderTxt}
            />

            <PrivateRoute
              exact
              path={`${url}${PROGRAMS_URL}/edit/:id`}
              component={ViewOrEditProgram}
              updateHeaderTxt={this.updateHeaderTxt}
              key='edit'
            />
            {/* <PrivateRoute exact path={`${url}${PROGRAMS_URL}/:target_category`} component={Programs}/> */}

            <PrivateRoute
              exact
              path={`${url}${PROGRAMS_URL}`}
              component={Programs}
              updateHeaderTxt={this.updateHeaderTxt}
            />

            <PrivateRoute
              exact
              path={`${url}${PROGRAMS_URL}/:id`}
              component={ViewOrEditProgram}
              updateHeaderTxt={this.updateHeaderTxt}
              key='view'
            />

            <PrivateRoute
              exact
              path={`${url}${USERS_URL}/add`}
              component={Users}
              updateHeaderTxt={this.updateHeaderTxt}
              key='add'
            />

            <PrivateRoute
              exact
              path={`${url}${USERS_URL}/edit/:id`}
              component={Users}
              updateHeaderTxt={this.updateHeaderTxt}
              key='edit'
            />

            <PrivateRoute
              exact
              path={`${url}${USERS_URL}/reset/:id`}
              component={Users}
              updateHeaderTxt={this.updateHeaderTxt}
              key='reset'
            />

            <PrivateRoute
              exact
              path={`${url}${USERS_URL}`}
              component={Users}
              updateHeaderTxt={this.updateHeaderTxt}
              key='view'
            />
             <PrivateRoute
              exact
              path={`${url}${USERS_URL}/:id`}
              component={Users}
              updateHeaderTxt={this.updateHeaderTxt}
              key='userView'
            />
            <PrivateRoute
              exact
              path={`${url}${CHANGE_PASSWORD_URL}`}
              component={ChangePassword}
              updateHeaderTxt={this.updateHeaderTxt}
              key='changepassword'
            />   

            <PrivateRoute
              exact
              path={`${url}${METRICS_URL}/add`}
              component={AddMetric}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute
              exact
              path={`${url}${METRICS_URL}`}
              component={Metrics}
              programBar={this.state.programBar}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute
              exact
              path={`${url}${FORM_SUBMIT_YUP_URL}`}
              component={FormSubmit}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute
              path={USER_NOT_FOUND_URL}
              component={ErrorPage}
              updateHeaderTxt={this.updateHeaderTxt}
            />
            <PrivateRoute path='*' component={ErrorPage} />
          </Switch>
        </Container>
      </Layout>
    )
  }
}

export default Dashboard
