import React from 'react'
import { ToastContainer } from 'react-toastify'

import { Routes } from './routes'
import { shareSessionAcrossTabs } from './helpers'
import { SESSION_MODAL_CONTAINER } from './constants'

import 'react-toastify/dist/ReactToastify.css'
import './styles/base.scss'

function App() {
  shareSessionAcrossTabs()
  // if (getSessionVariable(AUTH_TOKEN_NAME)) sessionTimer()
  return (
    <>
      <div id={SESSION_MODAL_CONTAINER} />
      <ToastContainer />
      <Routes />
    </>
  )
}

export default App
