import React from 'react'
import { Form, Button, Col, Alert, Spinner } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import ShowSpinner from './ShowSpinner'
import '../../styles/base.scss'

const FormSubmitComponent = ({ ...props }) => {
  // Schema for yup
  const validationSchema = Yup.object({
    feedback: Yup.string()
      .required('*Message is required')
      .max(1000, '*Message is too long'),
    username: Yup.string()
      .email('Invalid Email address1')
      .required('Required'),
    category: Yup.string()
      .oneOf(
        ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
        'Please select a Message Category'
      )
      .required('Please select a Message Category')
  })

  return (
    <section className='content-box p-4'>
      <h3>Test YUP Validation</h3>
      <p>Here goes basic text explaining this page</p>
      <Alert
        variant='success'
        show={props.data.showAlert}
        onClose={() => props.setShow()}
        dismissible
      >
        <p className='m-0'>
          <span>This is post submit mesage!</span>
          <br />
        </p>
      </Alert>

      <Formik
        enableReinitialize
        initialValues={props.data}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.handleSubmit(values, setSubmitting)
        }}
      >
        {formik => (
          <FormikForm>
            {formik.isSubmitting && (
              <Alert variant='info'>
                Submitting Information. Please wait...
              </Alert>
            )}
            <Form.Group noValidate as={Col} md='10' className='px-0'>
              <Form.Label>Message Category</Form.Label>
              <Field name='category' as='select' className='form-control'>
                <option value=''>Select a Category</option>
                <option value='Option 1'>Option 1</option>
                <option value='Option 2'>Option 2</option>
                <option value='Option 3'>Option 3</option>
                <option value='Option 4'>Option 4</option>
              </Field>
              <ErrorMessage
                name='category'
                render={msg => <div className='text-danger'>{msg}</div>}
              />
            </Form.Group>

            <Form.Group noValidate as={Col} md='10' className='px-0'>
              <Form.Label>Email</Form.Label>
              <Field
                className='form-control'
                type='email'
                placeholder='Email'
                name='username'
              />

              <ErrorMessage
                name='username'
                render={msg => <div className='text-danger'>{msg}</div>}
              />
            </Form.Group>
            <Form.Group noValidate as={Col} md='10' className='px-0'>
              <Form.Label>Your Message</Form.Label>
              <Field
                as='textarea'
                name='feedback'
                placeholder='Your Message'
                rows='3'
                className='form-control'
              />
              <ErrorMessage
                name='feedback'
                render={msg => <div className='text-danger'>{msg}</div>}
              />
            </Form.Group>

            <Form.Row>
              <Button
                variant='primary'
                className='submit-button px-4 py-2'
                type='submit'
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <ShowSpinner buttonSpinner={true} />
                ) : null}
                Submit
              </Button>
            </Form.Row>
          </FormikForm>
        )}
      </Formik>
    </section>
  )
}

export default FormSubmitComponent
