import { toast } from 'react-toastify'
import { isAgent } from '.'
import {
  LOCAL_STORAGE_METRICS,
  LOCAL_STORAGE_METRICS_FOLDER,
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_PROGRAMS
} from '../constants'
import { metricsService, programService } from '../services'
import { getUserData, setUserData, setFolderUserData } from './functions'

export const getWeeklyProgramMetricQuery = (year, week, programId, agentId) => {
  if (agentId) {
    return `?year=${year}&week=${week}&program_id=${programId}&agent_id=${agentId}`
  }
  return `?year=${year}&week=${week}&program_id=${programId}`
}

const getAgentWeeklyProgramMetricQuery = (year, week, programId, agentId) => {
  return `?year=${year}&week=${week}&program_id=${programId}&agent_id=${agentId}`
}

const getRow = data => {
  const columnItem = {}

  let count = 0
  data.forEach(item => {
    columnItem[count] = item
    count++
  })

  return columnItem
}

export const showNoMetricHeaderError = () => {
  toast.error(
    'Metrics Header is not set for this Program. Please set the metric header.'
  )
}

export const showToastGeneralError = () => {
  toast.error('Error, please check.')
}

export const isMetricHeaderSet = selectedProgram => {
  return (
    selectedProgram &&
    selectedProgram.metrics &&
    selectedProgram.metrics.length > 0
  )
}

export const getDropDownProgram = program => {
  const dropDownProgram = {}

  dropDownProgram.value = program.public_id
  dropDownProgram.label = program.program_name
  dropDownProgram.program = program

  return dropDownProgram
}

export const getSelectedProgram = programOptions => {
  let selectedProgram = {}
  const localSelectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
  const program = programService.get_program_by_id(
    localSelectedProgram.public_id
  )
  if (localSelectedProgram) {
    selectedProgram = getDropDownProgram(localSelectedProgram)
  } else if (programOptions.length > 0) {
    selectedProgram = programOptions[0]
  }

  return selectedProgram
}

export const getWeeklyProgramMetrics = (
  year,
  week,
  programId,
  agentId,
  isImportMetrics
) => {
  return new Promise((resolve, reject) => {
    const query = getWeeklyProgramMetricQuery(year, week, programId, agentId)
    metricsService
      .get_program_metrics(query, isImportMetrics)
      .then(metricData => {
        // sometimes resolve is not called,
        // hence after 100ms we call it, it works
        setTimeout(() => {
          resolve(metricData)
        }, 100)
      })
      .catch(() => {
        reject(null)
      })
  })
}

export const getAgentWeeklyProgramMetrics = (
  year,
  week,
  programId,
  agentId
) => {
  return new Promise((resolve, reject) => {
    const query = getAgentWeeklyProgramMetricQuery(
      year,
      week,
      programId,
      agentId
    )
    metricsService
      .get_program_metrics(query)
      .then(metricData => {
        // sometimes resolve is not called,
        // hence after 100ms we call it, it works
        setTimeout(() => {
          resolve(metricData)
        }, 100)
      })
      .catch(() => {
        reject(null)
      })
  })
}

export const getMetricColumns = headers => {
  const columns = []

  let count = 0
  headers.forEach(item => {
    const columnItem = {}
    if(isAgent() && item === 'ES At Risk (Y/N)'){
      return
    }
    columnItem.Header = item
    columnItem.accessor = String(count)

    columns.push(columnItem)

    count++
  })

  return columns
}

export const getMetricRows = data => {
  const rows = []

  data.forEach(item => {
    if(isAgent()) {
      delete item[2]
    }
    rows.push(getRow(item))
  })

  return rows
}

export const storeMetricData = importedData => {
  const query = getWeeklyProgramMetricQuery(
    importedData.year,
    importedData.week_no,
    importedData.program_id
  )

  if (importedData.data.length > 0) {
    const headers = [...importedData.data[0]]

    // shift rows to remove first array i.e. header from actual rows
    importedData.data.shift()

    const data = {}
    data.program_id = importedData.program_id
    data.week_no = importedData.week_no
    data.year = importedData.year
    data.headers = headers
    data.data = importedData.data
    // setUserData(LOCAL_STORAGE_METRICS + query, data)
    setFolderUserData(
      LOCAL_STORAGE_METRICS_FOLDER,
      LOCAL_STORAGE_METRICS + query,
      data
    )
  }
}

export const updateStoredProgramMetrics = (programId, metrics) => {
  const key = LOCAL_STORAGE_PROGRAMS + programId
  const program = getUserData(key)
  if (program) {
    program.metrics = metrics
    setUserData(key, program)
  }
}

// for the provided weekly agent metrics, it returns the
// object of key metrics
export const getAgentKeyMetrics = (metrics, programMetrics) => {
  const metricData = metrics.length > 0 ? metrics[0] : []
  const keyMetrics = {}

  if (metricData !== undefined && programMetrics !== undefined) {
    if (metricData && metricData.length > 0 && programMetrics.length > 0) {

      let metricKey
      let metricValue
      let isKeyMetric
      const headerLen = programMetrics.length

      for (let i = 0; i < metricData.length - 1; i++) {
        metricValue = metricData[i]
        if (i < headerLen) {

          isKeyMetric = programMetrics[i].is_key_metrics
          metricKey = programMetrics[i].metrics_name

          if (isKeyMetric) {
            keyMetrics[metricKey] = metricValue
          }
        }
      }
    }
  }

  return keyMetrics
}
