import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

const ShowSpinner = ({ ...props }) => {  
  if(props.button) {
    return <Button variant='light' disabled><Spinner
      as={props.as ? props.as :'span'}
      animation={props.animation ? props.animation :'border'}
      size={props.size ? props.size :''}
      className={props.class_name ? props.class_name :'ml-3 mt-3'}
      role={props.status ? props.status :'status'}
      aria-hidden={props.aria_hidden ? props.aria_hidden :'true'}    
    />{props.spinner_text ? props.spinner_text : ""}</Button>
  } 

  if(props.buttonSpinner) {
    let buttonText = null
    let class_name = props.class_name ? `spinner-grow spinner-grow-sm ${props.class_name}` : "spinner-grow spinner-grow-sm mr-2"
    if(props.showButtonText) {
      buttonText = props.buttonText ? props.buttonText : 'Processing...'
    }
    return <><span className={class_name} role="status" aria-hidden="true"></span>{buttonText}</>
  } 

  return <Spinner
    variant= {props.variant ? props.variant :"primary"} 
    animation={props.animation ? props.animation :'border'}
    size={props.size !== undefined ? props.size : 'sm'}
    className={props.class_name ? props.class_name :'ml-3 mt-4'}        
  />    
}

export default ShowSpinner
