import React, { Component } from 'react'

import { Hero } from '../../components'

class Home extends Component {
  state = {
    isLoading: false,
    interval: 'month',
    userData: {},
    isLoggedIn: false
  }

  handleClick = () => {}

  setPlanInterval = interval => {
    this.setState({ interval })
  }

  abortController = {}

  loadAbortController = () => {
    if (typeof window !== 'undefined') {
      this.abortController = new AbortController()
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this.state.isLoading === true) {
      this.abortController.abort()
    }
  }

  render() {
    const { isLoggedIn } = this.state

    return (
      <>
        <Hero isLoggedIn={isLoggedIn} />
      </>
    )
  }
}

export default Home
