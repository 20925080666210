import React from 'react'
import { Redirect } from 'react-router-dom'
import AnnieSprattUnsplash from '../../img/404.svg'

const NotFound = ({ ...props }) => {
  let isDashboard
  if (props.url && props.url.includes('dashboard')) {
    isDashboard = true
  }

  return (
    <section
      className={
        isDashboard
          ? 'container d-flex text-center justify-content-center align-items-center pt-5 mt-5'
          : 'container d-flex text-center justify-content-center align-items-center vh-100'
      }
    >
      <div className='not-found'>        
      <img src={AnnieSprattUnsplash} alt='404'  height='600' />
        <p className='lead'>
          The page were you looking for could not be found. Please wait while
          you are redirected.
        </p>
        {props.redirect &&
          (isDashboard ? (
            <Redirect
              to={{
                pathname: '/dashboard'
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/'
              }}
            />
          ))}
      </div>
    </section>
  )
}

export default NotFound
