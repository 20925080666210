import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_COACHING,
  GET_WEEKLY_COACHING,
  LOCAL_STORAGE_COACHING,
  LOCAL_STORAGE_LIST_COACHING,
  LOCAL_STORAGE_COACHING_FOLDER,
  LOCAL_STORAGE_METRICS_FOLDER,
  SAVE_COACHING_ACKNOWLEDGEMENT
} from '../constants'
import { deleteData } from '../helpers'

const options = AppService.options()

export const coachingService = {
  get_coaching_by_program_agent: async (programId, agentId, publidId) => {
    let queryString = `?program_id=${programId}&agent_id=${agentId}`
    if (publidId) {
      queryString = `?public_id=${publidId}`
    }
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_COACHING + queryString,
      { ...HTTPHeaders() }
    )
    return response
  },
  get_coaching_by_public_id: async publicId => {
    const queryString = `?public_id=${publicId}`
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_COACHING + queryString,
      { ...HTTPHeaders() }
    )
    return response
  },
  get_weekly_coaching: async (
    programId,
    agentId,
    showCoachingType,
    pageIndex,
    pageSize
  ) => {
    let queryString = `?program_id=${programId}`

    if (agentId) queryString += `&agent_id=${agentId}`

    if (showCoachingType && showCoachingType !== '') {
      queryString += `&${showCoachingType}=${true}`
    }

    if (pageIndex >= 0 && pageSize > 0)
      queryString += `&page_size=${pageSize}&page_no=${pageIndex + 1}`

    const response = await AppService.makeRequest(
      BASE_API_URL + GET_WEEKLY_COACHING + queryString,
      { ...HTTPHeaders() }
    )
    return response
  },
  get_coaching_by_program: async (programId, agentId, publicId, userRoleId) => {
    const queryString = userRoleId
      ? `?program_id=${programId}&agent_id=${agentId}&role_id=${userRoleId}` 
      : agentId ? `?program_id=${programId}&agent_id=${agentId}`  : `?program_id=${programId}` 
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_COACHING + queryString,
      { ...HTTPHeaders() }
    )

    return response
  },
  save_coaching: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + GET_COACHING, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_COACHING_FOLDER)
    deleteData(LOCAL_STORAGE_METRICS_FOLDER)
    return response
  },
  save_agent_acknowledgement: async (id, data) => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const url = `${BASE_API_URL +
      SAVE_COACHING_ACKNOWLEDGEMENT}?weekly_coaching_id=${id}`
    const response = await AppService.makeRequest(url, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_COACHING_FOLDER)
    return response
  }
}
