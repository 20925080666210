import React from 'react'
import { Alert } from 'react-bootstrap'

class ErrorBoundary extends React.Component {
  state = {
    error: '',
    errorInfo: ''
  }

  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Alert variant='danger' className='mt-3'>
          <h5 className='alert-heading'>Something went wrong</h5>
          {this.state.error && this.state.error.toString()}
          {process.env.NODE_ENV === 'development' && (
            <section className='mt-3'>
              <code>{this.state.errorInfo.componentStack}</code>
            </section>
          )}
        </Alert>
      )
    }
    // Render children if there's no error
    return this.props.children
  }
}

export default ErrorBoundary
