/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import AnnieSprattUnsplash from '../../img/admin-login.svg'
import { isAdmin } from '../../helpers'

import { getSessionVariable, getUserData, setUserData } from '../../helpers'

import { LoginForm } from '../../components'
import {
  AUTH_TOKEN_NAME,
  INVALID_LOGIN,
  LOCAL_STORAGE_USER_URL
} from '../../constants'

import { userService } from '../../services'

class AdminLogin extends Component {
  state = {
    submitted: false,
    loggedIn: false,
    redirectPage: false,
    username: '',
    password: '',
    viewPassword: false,
    serverErrors: null,
    isUserConflict: false,
    userRedirect: null
  }

  componentDidMount = async () => {
    if (getUserData(LOCAL_STORAGE_USER_URL)) {
      this.setState(state => ({
        ...state,
        redirectPage: true,
        userRedirect: getUserData(LOCAL_STORAGE_USER_URL)
      }))
    }
  }

  handleLogin = formdata => {
    this.setState(state => ({
      ...state,
      submitted: true,
      serverErrors: null,
      isUserConflict: true
    }))

    userService
      .admin_login({ ...formdata })
      .then(() => {
        if (isAdmin()) {
          if (getSessionVariable(AUTH_TOKEN_NAME)) {
            if (this.state.userRedirect) {
              setUserData(LOCAL_STORAGE_USER_URL, this.state.userRedirect)
            }
            this.setState(state => ({
              ...state,
              loggedIn: true
            }))
          }
        } else {
          this.setState({
            submitted: false,
            serverErrors: INVALID_LOGIN
          })
        }
      })
      .catch(() => {
        this.setState({
          submitted: false,
          serverErrors: INVALID_LOGIN
        })
      })
  }

  clearErrorMessage = () => {
    this.setState(state => ({
      ...state,
      serverErrors: null
    }))
  }

  togglePasswordDisplay = () => {
    this.setState(state => ({
      ...state,
      viewPassword: !state.viewPassword
    }))
  }

  render() {
    const {
      viewPassword,
      submitted,
      loggedIn,
      redirectPage,
      serverErrors,
      userRedirect,
      isUserConflict
    } = this.state

    if (redirectPage && loggedIn) {
      return (
        <Redirect
          to={{
            pathname: userRedirect.pathname,
            search: userRedirect.search
          }}
        />
      )
    }
    if (loggedIn) {
      return <Redirect to='/dashboard' />
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userConflictMsg &&
      !isUserConflict
    ) {
      return (
        <LoginForm
          onInputChange={this.onInputChange}
          handleLogin={this.handleLogin}
          isSubmitted={submitted}
          viewPassword={viewPassword}
          togglePasswordDisplay={this.togglePasswordDisplay}
          serverErrors={this.props.location.state.userConflictMsg}
          clearErrorMessage={this.clearErrorMessage}
        />
      )
    }
    return (
      <>
        <Container fluid>
          <Row className='align-items-center bg-white rounded shadow-lg login-container'>
            <Col md={7}>
              <LoginForm
                onInputChange={this.onInputChange}
                handleLogin={this.handleLogin}
                isSubmitted={submitted}
                viewPassword={viewPassword}
                togglePasswordDisplay={this.togglePasswordDisplay}
                serverErrors={serverErrors}
                clearErrorMessage={this.clearErrorMessage}
              />
            </Col>
            <Col md={5}>
              <img src={AnnieSprattUnsplash} alt='Admin Login' height='400'/>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default AdminLogin
