import moment from 'moment'

export const coachingState = {
  formData: {
    navigate_page: 1,
    agent_id: '',
    plan_name: '',
    coaching_start_date: '',
    coaching_end_date: '',
    opportunities: '',
    root_cause: '',
    impact: '',
    smart_goal_1: '',
    smart_goal_2: '',
    smart_goal_3: '',
    opportunity_or_focus: '',
    what_agent_liked: '',
    what_manager_liked: '',
    what_opportunity_agent: '',
    what_opportunity_manager: '',
    status: 'Active',
    weeks: [],
    currentWeek: {},
    conversation: {
      conversation_date: '',
      conversation_type: '',
      manager_interaction_details: '',
      agent_response: '',
      status: ''
    }
  },
  isCreatePage: true,
  isConversationPage: false,
  selectedProgram: null,
  selectedAgent: null,
  message: 'Add New Coaching',
  keyMetricData: [],
  loadingKeyMetrics: false,
  isAuthorizedAccess:true,
}

export const loadWeeks = async (formData, weeks) => {  
  if (formData.weeks.length === 0) {
    const date = moment(formData.coaching_start_date, true)
   // const date_start = moment(formData.coaching_start_date, true)
    date.set({ hour: 10, minute: 0 })
    // eslint-disable-next-line no-restricted-syntax
    for (const i of Array(4).keys()) {
      const date1 = date.add(7, 'days')
      //const date2 = i > 0 ? date_start.add(7, 'days') : date_start.add(14, 'days')  
      const date2 = i > 0 ? date.add(7, 'days') : date.add(14, 'days')    
      const weekObj = {
        week_no: i,
        description: '',
        coaching_date: date1.toDate(),
        coaching_time: null,
        behaviour_smart_goal: '',
        agent_behaviour_opportunity: '',
        agent_smart_goal_commitment: '',
        agent_smart_goal_update: '',
        agent_recap: '',
        call_date: '',
        interaction_id: '',
        quality_score: '',
        call_description: '',
        was_resolved: false,
        is_completed: false,
        completed_on: '',
        is_active: '',
        agent_feedback: '',
        followup_date: '',//date2.toDate(),
        key_metrics: {}
      }
      weeks.push(weekObj)
    }
  }  
  return weeks
}
export const assignMainForm = (formData, data, weeks) => {
  return {
    ...formData,
    navigate_page: 1,
    plan_name: data.plan_name,
    coaching_end_date: data.coaching_end_date,
    coaching_start_date: data.coaching_start_date,
    opportunities: data.opportunities,
    root_cause: data.root_cause,
    impact: data.impact,
    opportunity_or_focus: data.opportunity_or_focus,
    smart_goal_1: data.smart_goal_1,
    smart_goal_2: data.smart_goal_2,
    smart_goal_3: data.smart_goal_3,
    what_agent_liked: data.what_agent_liked,
    what_manager_liked: data.what_manager_liked,
    what_opportunity_agent: data.what_opportunity_agent,
    what_opportunity_manager: data.what_opportunity_manager,
    status: data.status,
    weeks
  }
}

export const assignMainForm1 = (formData, data, weeks) => {
  let coaching_status = data.formData.weeks[data.formData.weeks.length-1].is_completed ? 'Closed' : data.formData.status
  return {
    ...formData,
    coaching_end_date: data.formData.coaching_end_date,
    coaching_start_date: data.formData.coaching_start_date,
    opportunities: data.formData.opportunities,
    root_cause: data.formData.root_cause,
    impact: data.formData.impact,
    opportunity_or_focus: data.formData.opportunity_or_focus,
    smart_goal_1: data.formData.smart_goal_1,
    smart_goal_2: data.formData.smart_goal_2,
    smart_goal_3: data.formData.smart_goal_3,
    what_agent_liked: data.formData.what_agent_liked,
    what_manager_liked: data.formData.what_manager_liked,
    what_opportunity_agent: data.formData.what_opportunity_agent,
    what_opportunity_manager: data.formData.what_opportunity_manager,
    status: coaching_status,
    weeks
  }
}

export const assignConversationForm = data => {
  return {
    conversation_date: data.conversation_date,
    conversation_type: data.conversation_type,
    manager_interaction_details: data.manager_interaction_details,
    agent_response: data.agent_response,
    status: data.status
  }
}

export const assignWeekForm = (data, weeks, weekNo) => {
  return {
    ...weeks[weekNo],
    week_no: weekNo,
    description: data.description,
    coaching_date: data.coaching_date,
    coaching_time: data.coaching_time,
    behaviour_smart_goal: data.behaviour_smart_goal,
    agent_behaviour_opportunity: data.agent_behaviour_opportunity,
    agent_smart_goal_commitment: data.agent_smart_goal_commitment,
    agent_smart_goal_update: data.agent_smart_goal_update,
    agent_recap: data.agent_recap,
    is_completed: data.is_completed,
    call_date: data.call_date,
    interaction_id: data.interaction_id,
    quality_score: data.quality_score,
    call_description: data.call_description,
    was_resolved: data.was_resolved,
    completed_on: data.completed_on,
    current_attendance_points: data.current_attendance_points,
    mtd_aht: data.mtd_aht,
    mtd_qa: data.mtd_qa,
    agent_feedback: data.agent_feedback,
    followup_date: data.followup_date,
    key_metrics: data.key_metrics
  }
}

export const coachingDateInfo = () => {
  const currentYear = moment().format('YYYY')
  const coachingWeeks = 4
  const coachingDays = parseInt(coachingWeeks * 7)
  return {
    currentYear,
    coachingWeeks,
    coachingDays
  }
}

export const getDateFormats = {
  a: 'MM/DD/YYYY',
  b: 'MM/DD/YYYY HH:mm:ss',
  c: 'YYYY-MM-DD HH:mm:ss'
}

export const convertDateFormat = objParams => {
  let formattedDate = moment().format('MM/DD/YYYY')
  if (objParams) {
    const { dateValue } = objParams
    const currentFormat = objParams.currentFormat
      ? objParams.currentFormat
      : 'MM/DD/YYYY'
    const requiredFormat = objParams.requiredFormat
      ? objParams.requiredFormat
      : 'MM/DD/YYYY'
    const { operationType } = objParams
    const { operationValue } = objParams
    const { operationRange } = objParams

    if (operationType !== undefined && operationType !== '') {
      if (operationType === 'add') {
        if (dateValue === undefined || dateValue === "") {
          formattedDate = moment()
            .add(operationValue, operationRange)
            .format(requiredFormat)
        } else {
          formattedDate = moment(dateValue, currentFormat)
            .add(operationValue, operationRange)
            .format(requiredFormat)
        }
      }
    } else if (dateValue === undefined || dateValue === '') {
      formattedDate = moment().format(requiredFormat)
    } else {
      formattedDate = moment(dateValue, currentFormat).format(requiredFormat)
    }
  }
  return formattedDate
}
