/* eslint-disable react/jsx-indent-props */
/* eslint-disable import/named */
import React from 'react'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  Error,
  LocalLibrary,
  Star,
  Group,
  Beenhere,
  Compare,
  Loyalty
} from '@material-ui/icons'
import '../../styles/overview.scss'
import { QualityPlanWidget } from '..'
import {
  isAgent,
  isPm,
  isProgramDirector,
  checkArrValueExist,
  checkValueYesOrNot
} from '../../helpers'
import '../../styles/base.scss'
import ProgramSelectBar from './ProgramSelectBar'
import '../../styles/custom.css'
import { NO_RECORDS, NO_METRICS } from '../../constants'
// import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'
// import MetricsCardLoader from './MetricsCardLoader'
import PmOverviewCard from './PmOverviewCard'
//import ReactTour from '../../reacttour'
import PopupModal from './PopupModal'

/**
 * Dashboard overview for Agent, PM and PD roles
 */
const OverviewCard = ({ aggregate, ...props }) => {
  const metrics = props.metricData

  const getCalendar = () => {
    // return <FullCalendar plugins={[dayGridPlugin]} initialView='dayGridMonth' />
    return null
  }

  const showAgentMetrics = () => {
    const metricData = props.metricData.data ? props.metricData.data[0] : []
    const { selectedProgram } = props.programBar
    const metricHeaders = selectedProgram ? selectedProgram.program.metrics : []

    const metricGridColumns = []

    if (metricData !== undefined && metricHeaders !== undefined) {
      const headerLen = metricHeaders.length
      if (metricData && metricData.length > 0 && metricHeaders.length > 0) {
        let counter = 0
        // let metricValue, keyMetric
        
        for (let i = 0; i < metricData.length - 1; i++) {
          counter++
          const metricValue = metricData[i]
          if (i < headerLen) {
            if (isAgent() && metricHeaders[i].metrics_name == 'ES At Risk (Y/N)') {
              continue
            }
            const keyMetric = metricHeaders[i].is_key_metrics
            metricGridColumns.push(
              <Col key={i}>
                <Card className='bg-primary'>
                  <Card.Body>
                    <Row className='align-items-center'>
                      <Col>
                        <h3 className='name'>
                          {metricValue !== '' ? metricValue : '-'}
                        </h3>
                        <h4 className='text-white mb-1'>
                          {metricHeaders[i].metrics_name}
                        </h4>
                      </Col>
                      {/* <Col md='auto'>{arrIcons[i]}
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )
          }
        }
        return <Row className='row-cols-3'>{metricGridColumns}</Row>
      }
    } else {
      return (
        <Row className='row-cols-3'>
          <Col>
            <Card className='bg-primary'>
              <Card.Body>{NO_METRICS}</Card.Body>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  const showNotesCompletePopup = () => {    
    let modalTitle = "Notes Completed"
    let modalBody = "Are you sure to mark the note as completed?"
    let arrButtons = []
    arrButtons.push(<Button variant="primary" key={1} onClick={props.confirmNotesComplete}>
    Confirm
  </Button>)
    return <PopupModal showModal={props.showPopupModal} handleModalClose={props.handleModalClose} buttons={arrButtons} modalTitle={modalTitle} modalBody={modalBody} />
  }

  const showAgentScreen = () => {
    if (props.programBar.isFetchingProgram || props.isLoading) {
      return <OverlayLoader />
    }

    return (
      <section>
        <Row>
          <Col md={8}>{showAgentMetrics()}</Col>
          <Col md={4}>
            <QualityPlanWidget
              notes={props.notes}
              toggleSelected={props.toggleSelected}
              updateSelectedNotes={props.updateSelectedNotes}
              showModal={props.showModal}
              newModal={props.newModal}
              handleSubmit={props.saveNotes}
              showMoreNotes={props.showMoreNotes}
              showPreviousNotes={props.showPreviousNotes}
              areNotesLoading={props.areNotesLoading}
              hasNextNotes={props.hasNextNotes}
              savingNote={props.savingNote}
              updatingNote={props.updatingNote}
              notesFullData={props.notesFullData}
              onClickNotesComplete={props.onClickNotesComplete}              
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>{getCalendar()}</Col>
        </Row>
        {showNotesCompletePopup()}
      </section>
    )
  }

  const getExecutiveDashboardView = () => {
    return (
      <section>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <Card>
                      <Card.Body>
                        <Row className='align-items-center'>
                          <Col>
                            <h2>5</h2>
                            <h3 className='text-muted'>ES AT RISK</h3>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body>
                        <Row className='align-items-center'>
                          <Col>
                            <h2>9</h2>
                            <h3 className='text-muted'>Weekly Coaching</h3>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body>
                        <Row className='align-items-center'>
                          <Col>
                            <h2>4</h2>
                            <h3 className='text-muted'>Quality Outliers</h3>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body>
                        <Row className='align-items-center statistics'>
                          <Col>
                            <div className='box'>
                              <i className='fa fa-envelope fa-fw bg-primary' />
                              <div className='info'>
                                <h3>1,245</h3> <span>Emails</span>
                                <p>Lorem ipsum dolor sit amet</p>
                              </div>
                            </div>

                            {/* <h2>$10,000</h2>
                        <h3 className='text-muted'>Sales MTD</h3> */}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <Card.Body>
                        <Row className='align-items-center'>
                          <Col>
                            <h2>1</h2>
                            <h3 className='text-muted'>Disciplinary Level</h3>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <QualityPlanWidget
                  notes={props.notes}
                  showModal={props.showModal}
                  newModal={props.newModal}
                  handleSubmit={props.saveNotes}
                  showMoreNotes={props.showMoreNotes}
                  areNotesLoading={props.areNotesLoading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{getCalendar()}</Col>
        </Row>
      </section>
    )
  }

  const getExecutiveDetailedView = () => {
    return (
      <section>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Wright</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Greg</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Donald</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    )
  }

  const getExecutiveAgentView = () => {
    return (
      <section>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Wright</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Greg</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col md={2}>
                    <h3>Tammy Donald</h3>
                    <h2>
                      <span className='badge badge-soft-danger'>
                        ES AT RISK
                      </span>
                    </h2>
                    <h4>AT RISK</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>20</h3>
                    <h4>MTD Quality Score</h4>
                  </Col>
                  <Col md={2}>
                    <h2>
                      <span className='badge badge-soft-success'>HIGH</span>
                    </h2>
                    <h4>Disciplinary Level</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>20</h3>
                    <h4>Attendence Points</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>72</h3>
                    <h4>AHT MTD</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>40</h3>
                    <h4>Sales</h4>
                  </Col>
                  <Col md={2}>
                    <h3 className='text-muted'>128</h3>
                    <h4>Interactions Monitered</h4>
                  </Col>
                  <Col md={1}>
                    <h3 className='text-muted'>2</h3>
                    <h4>Weekly Coaching</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    )
  }

  const showExecutiveScreen = () => {
    /*const { selectedViewType } = props.programBar
    
    if (selectedViewType === 'Dashboard View') {
      return getExecutiveDashboardView()
    }

    if (selectedViewType === 'Detailed View') {
      return getExecutiveDetailedView()
    }

    if (selectedViewType === 'Agent View') {
      return getExecutiveAgentView()
    }*/
    if (props.programBar.isFetchingProgram || props.isLoading) {
      return <OverlayLoader />
    } else {
      let arrMetrics = []
      let metricData = metrics.data
      let selectedProgram = props.programBar.selectedProgram
      let selectedWeek = props.programBar.selectedWeek
      if (selectedProgram && selectedWeek) {
        let objMetricHeaders = selectedProgram
          ? selectedProgram.program.metrics
          : []
        let programId = selectedProgram.value
        if (metricData && metricData.length > 0 && objMetricHeaders && objMetricHeaders.length > 0) 
        {
          //return showAggregateData()
          //return showAgentMetricList(metricData, objMetricHeaders) 
          arrMetrics.push(showAggregateData())
          arrMetrics.push(showAgentMetricList(metricData, objMetricHeaders, selectedProgram))  
          return arrMetrics     
        }             
      }      
      
      return (<Row key={1}>
                <Col md={12}>
                  <Card>
                    <Card.Body>{NO_RECORDS}</Card.Body>
                  </Card>
                </Col>
              </Row>)      
    }  
  }

  const showAggregateData = () => {
    const arrMetrics = []
    if (aggregate) {
      let columnCounter = 0
      arrMetrics.push(
        <Row key={0}>
          {Object.keys(aggregate.metrics_dict).map(elem => (
            <Col md={3} key={columnCounter++}>
              <Card className='bg-primary'>
                <Card.Body>
                  <h3 className='name d-flex'>
                    {aggregate.metrics_dict[elem].value}
                    <div className='ml-3'>
                      {aggregate.metrics_dict[elem].units}
                    </div>
                  </h3>
                  <div d-flex>
                    {/* <div
                      className={`icon ${aggregate.metrics_dict[elem].icon}`}
                    /> */}

                    <h4 className='mb-1'>{elem}</h4>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )
    }
    return arrMetrics
  }

  const showPMScreen = () => {
    if (props.programBar.isFetchingProgram || props.isLoading) {
      return <OverlayLoader />
    }
    const arrMetrics = []
    const metricData = metrics.data
    const { selectedProgram } = props.programBar
    const { selectedWeek } = props.programBar
    if (selectedProgram && selectedWeek) {
      const objMetricHeaders = selectedProgram
        ? selectedProgram.program.metrics
        : []
      if (
        metricData &&
        metricData.length > 0 &&
        objMetricHeaders &&
        objMetricHeaders.length > 0
      ) {
        // return showAggregateData()
        // return showAgentMetricList(metricData, objMetricHeaders)
        arrMetrics.push(showAggregateData())
        arrMetrics.push(
          showAgentMetricList(metricData, objMetricHeaders, selectedProgram)
        )
        return arrMetrics
      }
    }

    return (
      <Row key={1}>
        <Col md={12}>
          <Card>
            <Card.Body>{NO_RECORDS}</Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }


  const showAgentMetricList = (
    metricData,
    objMetricHeaders,
    selectedProgram
  ) => {
    const arrMetricsData = []
    const columns = []
    const headerCount = objMetricHeaders.length
    const objColumn = {}

    objMetricHeaders.map((header, i) => {
      const objColumn = {
        Header: header.metrics_name,
        accessor: header.metrics_name
      }
      columns.push(objColumn)
    })
    columns.push({ Header: 'Agent Name', accessor: 'agent_name' })
    columns.push({ Header: 'Id', accessor: 'public_id' })

    metricData.map((metric, metricIndex) => {
      const objMetric = {}
      metric.map((metricRow, metricRowIndex) => {
        if (metricRowIndex < headerCount) {
          const key_name = objMetricHeaders[metricRowIndex].metrics_name
          objMetric[key_name] = metricRow
        } else {
          if (metricRowIndex === metric.length - 1) {
            objMetric.public_id = metricRow
          }
          if (metricRowIndex === metric.length - 2) {
            objMetric.agent_name = metricRow
          }
        }
      })
      arrMetricsData.push(objMetric)
    })
    return (
      <PmOverviewCard
        key={0}
        data={arrMetricsData}
        columns={columns}
        metricData={metricData}
        objMetricHeaders={objMetricHeaders}
        selectedProgram={selectedProgram}
      />
    )
  }

  return (
    <section>
      {/* <ReactTour isOpen={false} /> */}
      <Row>
        <Col md={12}>
          <ProgramSelectBar programBar={props.programBar} />
        </Col>
      </Row>
      {isAgent()
        ? showAgentScreen()
        : isPm()
        ? showPMScreen()
        : isProgramDirector()
        ? showPMScreen()
        : null}
    </section>
  )
}

export default OverviewCard
