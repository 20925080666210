/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import AnnieSprattUnsplash from '../../img/login.svg'

import { getSessionVariable, getUserData, setUserData } from '../../helpers'

import { LoginForm } from '../../components'
import {
  AUTH_TOKEN_NAME,
  INVALID_LOGIN,
  LOCAL_STORAGE_USER_URL,
  USER_ROLE_NAME,
  ROLE_ADMIN,
  ROLE_AGENT
} from '../../constants'

import { userService } from '../../services'

class Login extends Component {
  state = {
    submitted: false,
    isLoading: false,
    loggedIn: false,
    redirectPage: false,
    username: '',
    password: '',
    viewPassword: false,
    serverErrors: null,
    isUserConflict: false,
    userRedirect: null
  }

  abortController = new AbortController()

  componentWillUnmount() {    
    // if (this.state.isLoading === true) {
    this.abortController.abort()
    // }
  }

  componentDidMount = async () => {
    if (getUserData(LOCAL_STORAGE_USER_URL)) {
      this.setState(state => ({
        ...state,
        redirectPage: true,
        userRedirect: getUserData(LOCAL_STORAGE_USER_URL)
      }))
    }
  }

  handleLogin = formdata => {
    this.setState(state => ({
      ...state,
      submitted: true,
      isLoading: true,
      serverErrors: null,
      isUserConflict: true
    }))

    userService
      .login({ ...formdata }, this.abortController.signal)
      .then(() => {
        if (getSessionVariable(AUTH_TOKEN_NAME)) {
          /* Remove this : SKK */
          /*const userRole =
            formdata.username === 'ok5@gt.com' ? ROLE_ADMIN : ROLE_AGENT
            
          setUserData(USER_ROLE_NAME, userRole)*/

          if (this.state.userRedirect) {
            setUserData(LOCAL_STORAGE_USER_URL, this.state.userRedirect)
          }
          this.setState(state => ({
            ...state,
            loggedIn: true,
            isLoading: false,
            submitted: false,
          }))
        }
      })
      .catch(() => {
        this.setState({
          submitted: false,
          serverErrors: INVALID_LOGIN
        })
      })
  }

  clearErrorMessage = () => {
    this.setState(state => ({
      ...state,
      serverErrors: null
    }))
  }

  togglePasswordDisplay = () => {
    this.setState(state => ({
      ...state,
      viewPassword: !state.viewPassword
    }))
  }

  render() {
    const {
      viewPassword,
      submitted,
      loggedIn,
      redirectPage,
      serverErrors,
      userRedirect,
      isUserConflict
    } = this.state

    if (redirectPage && loggedIn) {
      return (
        <Redirect
          to={{
            pathname: userRedirect.pathname,
            search: userRedirect.search
          }}
        />
      )
    }
    if (loggedIn) {
      return <Redirect to='/dashboard' />
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userConflictMsg &&
      !isUserConflict
    ) {
      return (
        <LoginForm
          onInputChange={this.onInputChange}
          handleLogin={this.handleLogin}
          isSubmitted={submitted}
          viewPassword={viewPassword}
          togglePasswordDisplay={this.togglePasswordDisplay}
          serverErrors={this.props.location.state.userConflictMsg}
          clearErrorMessage={this.clearErrorMessage}
        />
      )
    }
    return (
      <>
      <Container fluid>
        <Row className='align-items-center bg-white rounded shadow-lg login-container'>
          <Col md={7}>
            <LoginForm
              onInputChange={this.onInputChange}
              handleLogin={this.handleLogin}
              isSubmitted={submitted}
              viewPassword={viewPassword}
              togglePasswordDisplay={this.togglePasswordDisplay}
              serverErrors={serverErrors}
              clearErrorMessage={this.clearErrorMessage}
            />
          </Col>
          <Col md={5}>
            <img src={AnnieSprattUnsplash} alt='Login' height='500'/>
          </Col>
        </Row>
        </Container>
      </>
    )
  }
}

export default Login
