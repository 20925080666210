import React from 'react'
import { Container } from 'react-bootstrap'
import Settings from '@settings'
import { isLoggedIn } from '../../helpers'

const Footer = () => {
  const d = new Date()

  let liClassName = ''
  let aClassName = ''
  if (isLoggedIn()) {
    liClassName = 'font-weight-normal text-dark'
    aClassName = 'font-weight-normal text-lowercase text-dark'    
  }

  const showAdminLogin = () => {
    if (!isLoggedIn()) {
      return <React.Fragment>
        <span className='mx-2'>|</span>
        <a className='font-weight-light' href={'/admin-login'}>
          Admin login
         </a>
      </React.Fragment>
    }
    return null
  }

  return (
    <footer className='py-4'>
      <Container fluid>
        <ul className='list-unstyled d-flex m-0 justify-content-between'>
          <li className={liClassName}>
            <span >&copy; {d.getFullYear()}</span> Glowtouch
          </li>
          <li className='text-right'>
            <a className={aClassName} href={'mailto:'+`${Settings.supportEmail}`}>
              {Settings.supportEmail}
            </a>
            {/* { showAdminLogin() } */}
          </li>
        </ul>
      </Container>
    </footer>
  )
}

export default Footer
