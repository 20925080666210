import React, { Component } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import AnnieSprattUnsplash from '../../img/forgot-password.jpg'

import { ForgotPasswordForm } from '../../components'
import { RESET_PASSWORD_URL, FORGOT_PASSWORD_SUCCESS } from '../../constants'
import { userService } from '../../services'

class ForgotPassword extends Component {
  state = {
    submitted: false,
    message: '',
    errorMsg: ''
  }

  handleForgotPassword = async formdata => {
    this.setState(state => ({ ...state, submitted: true }))
    userService
      .requestPasswordReset({
        addr: formdata.username,
        reset_url: RESET_PASSWORD_URL
      })
      .then(() => {
        this.setState(state => ({
          ...state,
          submitted: false,
          message: FORGOT_PASSWORD_SUCCESS
        }))
      })
      .catch(() => {
        this.setState(state => ({
          ...state,
          submitted: false,
          errorMsg: 'Something went wrong'
        }))
      })
  }

  togglePasswordDisplay = () => {
    this.setState(state => ({
      ...state,
      viewPassword: !state.viewPassword
    }))
  }

  render() {
    const { submitted, errorMsg, message } = this.state

    return (
      <Container fluid>
        <Row className='align-items-center'>
          <Col md={6}>
        <ForgotPasswordForm
          onInputChange={this.onInputChange}
          handleForgotPassword={this.handleForgotPassword}
          isSubmitted={submitted}
          message={message}
          errorMsg={errorMsg}
        />
        </Col>
        <Col md={6}>
          <img src={AnnieSprattUnsplash} alt='Forgot Password?' className='img-fluid' />
        </Col>
      </Row>
      </Container>
    )
  }
}

export default ForgotPassword
