/* eslint-disable no-unused-vars */
import jsonwebtoken from 'jsonwebtoken'
import qs from 'querystringify'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { renderGlobalComponent, storage } from '.'
import { SessionValidator } from '../components'
import {
  AUTH_REFRESH_TOKEN_NAME,
  AUTH_TOKEN_NAME,
  IS_TIMER_PAUSED,
  LOCAL_STORAGE_PROGRAMS,
  LOCAL_STORAGE_PROGRAMS_QUERY,
  LOCAL_STORAGE_USER_DATA,
  LOCAL_STORAGE_USER_URL,
  LOCAL_STORAGE_SELECTED_PROGRAM,
  SESSION_EXPIRY_TIMER,
  SESSION_MODAL_CONTAINER,
  SESSION_REFRESH_TIME,
  USER_ROLE_AGENT,
  USER_ROLE_PROGRAM_DIRECTOR,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_SUPER_ADMIN,
  LOCAL_STORAGE_USERS,
  LOCAL_STORAGE_USERS_QUERY,
} from '../constants'
import { userService } from '../services'

/**
 * Convert an Object of objects into Array of objects so that it can be looped using Array.map()
 * @param {object} obj Object of objects to be converted into Array of objects
 * @returns {array} array of objects
 */
export const objectToArray = obj => {
  return Object.keys(obj).map(key => {
    return obj[key]
  })
}

/**
 * Set a cookie value by its name and expiration
 * @param {string} cname cookie name
 * @param {string} cvalue cookie value
 * @param {number} exptime cookie expiration time
 */
export const setCookie = () => {
  // const d = new Date(exptime * 1000)
  // const expires = `expires=${d.toUTCString()}`
  // document.cookie = `${cname}=${cvalue};${expires};path=/`
}

/**
 * Get session storage variable value by its name
 * @param {string} name
 * @returns {string} value | null
 */
export const getSessionVariable = name => {
  if (typeof document === 'undefined') {
    return null
  }
  if (name === AUTH_TOKEN_NAME && storage.authToken) return storage.authToken
  if (name === AUTH_REFRESH_TOKEN_NAME && storage.authRefreshToken)
    return storage.authRefreshToken

  return null
}

/**
 * Get value from input with name
 * @param {object} event default event
 * @returns {object} return input name with value
 */
export const handleInputChange = event => {
  const { target } = event
  const value = target.type === 'checkbox' ? target.checked : target.value
  const { name } = target

  return { [name]: value }
}

/**
 * Removes cookies related to login
 * UPDATE: remove tokens from sessionStorage
 */
export const clearAllSession = () => {
  storage.deleteTokens()

  localStorage.removeItem(LOCAL_STORAGE_USER_DATA)

  // logout from all tabs: send signal
  localStorage.setItem('removeSessionStorage', Date.now().toString())
  localStorage.removeItem('removeSessionStorage')
}

/**
 * response If is Admin
 */
export const isAdmin = () => {
  const jwtToken = jsonwebtoken.decode(storage.authToken)
  return jwtToken && jwtToken.role === USER_ROLE_SUPER_ADMIN
}

/**
 * checks if the user role is Project Manager
 */
export const isPm = () => {
  const jwtToken = jsonwebtoken.decode(storage.authToken)
  return jwtToken && jwtToken.role === USER_ROLE_PROGRAM_MANAGER
}

/**
 * checks if the user role is Agent 
 */
export const isAgent = () => {
  const jwtToken = jsonwebtoken.decode(storage.authToken)
  return jwtToken && jwtToken.role === USER_ROLE_AGENT
}

/**
 * checks if the user role is Program Director
 */
export const isProgramDirector = () => {
  const jwtToken = jsonwebtoken.decode(storage.authToken)
  return jwtToken && jwtToken.role === USER_ROLE_PROGRAM_DIRECTOR
}

/**
 * return user public id
 */
export const getUserId = () => {
  const jwtToken = jsonwebtoken.decode(storage.authToken)
  return jwtToken.sub
}

/**
 * UPDATE: Save token in sessionStorage with storage class
 * @param {object} data response consisting of Authorization
 */
export const storeToken = data => {
  // Store in sessionStorage. So in future we can rename this fn and remove cookie logic
  storage.authToken = data.Authorization
  storage.authRefreshToken = data.refresh_token
}

/**
 * Convert json object into formData
 * @param {object} data json object to be converted formData
 * @returns {formData} formData
 */
export const makeFormData = data => {
  const formData = new FormData()

  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return formData
}

/**
 * common function to fetch the API data from LocalStorage
 * @param {string} key
 * @return {object} api Data
 */
export const getUserData = key => {  
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))  
  if (!userData || !userData[key]) {
    return false
  }  
  return userData[key]
}

/**
 * common function to fetch the API data from LocalStorage
 * @param {string} key
 * @return {object} api Data
 */
export const getFolderUserData = (folder, key) => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))
  if (!userData || !userData[folder]) {
    return false
  }
  const folderData = userData[folder]

  if (!folderData || !folderData[key]) {
    return false
  }
  return folderData[key]
}

export const setFolderUserData = (folder, key, data) => {
  let userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))

  userData = {
    ...userData,
    [folder]: {
      ...userData[folder],
      [key]: data
    }
  }
  localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(userData))
}

export const getHeaderData = () => {
  let userData = {}

  userData = {
    role: 'Read from END POINT and show',
    name: 'USER FULL NAME',
    userCreatedDate: new Date()
  }
  return userData
}
/**
 *  deletes api data stored in UserData object of LocalStorage
 * @param {string} key
 */
export const deleteData = key => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))

  if (!userData || !userData[key]) {
    return false
  }
  delete userData[key]
  localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(userData))
}

/**
 * sets API data to localStorage within UserData Object
 * @param {string} key
 * @param {object} data
 */
export const setUserData = (key, data) => {
  let userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))

  userData = {
    ...userData,
    [key]: data
  }
  localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(userData))
}

/**
 * Get success/error class based on boolean value
 * @param {boolean} bool boolean
 */
const getClass = bool => {
  return bool ? 'success' : 'error'
}

/**
 * Validate the Password through Tippy
 * @param {e} event on the password field
 */
export const updateTippy = e => {
  const field = `#${e.target.id}`
  const tips = tippy(field, {
    trigger: 'click',
    placement: 'right',
    multiple: true
  })
  tips[0].show()
  const { value } = e.target
  const lengthRegex = new RegExp('^(?=).{8,20}$')
  const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*-]).{0,}$')
  const lowerCaseRegex = new RegExp('^(?=.*[a-z]).{0,}$')
  const upperCaseRegex = new RegExp('^(?=.*[A-Z]).{0,}$')
  const numberRegex = new RegExp('^(?=.*\\d).{0,}$')
  const str = `<p>Passwords must have…</p><ul><li class="${getClass(
    lengthRegex.test(value)
  )}">8 to 20 characters</li><li class="${getClass(
    lowerCaseRegex.test(value)
  )}">One lowercase character</li><li class="${getClass(
    upperCaseRegex.test(value)
  )}">One uppercase character</li><li class="${getClass(
    numberRegex.test(value)
  )}">One number</li><li class="${getClass(
    specialCharRegex.test(value)
  )}">One symbol (!@#$%^&*-)</li></ul>`
  tips[0].setContent(str)
}

/**
 * Get expiration and logged in elapsed time in seconds
 * @returns {object} expiration and elaspsed time
 */
export const getSessionTimeRemaining = () => {
  const decodedToken = jsonwebtoken.decode(getSessionVariable(AUTH_TOKEN_NAME))
  const expirationInSec = decodedToken
    ? Math.floor((decodedToken.exp * 1000 - Date.now()) / 1000)
    : 0

  const issuedAtInSec = getUserData('setInitialLoginTime')
    ? Math.floor(
        (Date.now() - getUserData('setInitialLoginTime') * 1000) / 1000
      )
    : 0

  return { expirationInSec, issuedAtInSec }
}

/**
 * Set initial time when user logged in to check against SESSION_EXPIRY_TIMER
 * @param {object} data access token
 */
export const setInitialLoginTime = data => {
  const decodedToken = jsonwebtoken.decode(data.Authorization)
  setUserData('setInitialLoginTime', decodedToken.iat)
}

/**
 * Begin session timer when user logins
 * @returns timer interval
 */
export const sessionTimer = (enabled = true) => {
  // there can be only one!
  if (sessionTimer.timer) {
    clearTimeout(sessionTimer.timer)
    sessionTimer.timer = null
  }
  // returning here stops the timer
  if (!enabled) {
    return
  }
  // keep the timer ref, so it can be cleared later
  // timeout instead of interval to control continuation
  sessionTimer.timer = setTimeout(async () => {
    const { expirationInSec, issuedAtInSec } = getSessionTimeRemaining()
    if (!getUserData(IS_TIMER_PAUSED)) {
      if (issuedAtInSec >= SESSION_EXPIRY_TIMER) {
        setUserData(IS_TIMER_PAUSED, 'true')
        await userService.logout().catch(() => {})
        deleteData(IS_TIMER_PAUSED)
        return false // stop ticking
      }
      if (expirationInSec <= SESSION_REFRESH_TIME) {
        setUserData(IS_TIMER_PAUSED, 'true')
        await userService.refresh_token().catch(() => {})
        deleteData(IS_TIMER_PAUSED)
      }

      if (SESSION_EXPIRY_TIMER - issuedAtInSec <= SESSION_REFRESH_TIME) {
        renderGlobalComponent(SessionValidator, SESSION_MODAL_CONTAINER, {
          show: true,
          inTime: SESSION_EXPIRY_TIMER - issuedAtInSec
        })
      }
    }
    sessionTimer() // next tick
  }, 1000)
}

export const flatten = items => {
  const flat = []

  items.forEach(item => {
    if (Array.isArray(item)) {
      flat.push(...flatten(item))
    } else {
      flat.push(item)
    }
  })

  return flat
}

/**
 * Automatically redirect traffic to non-www
 */
export const redirectNonWWW = () => {
  if (typeof window !== 'undefined') {
    const current = window.location.hostname
    const isCMMS = /cmms\.com/i
    const desired = current.replace(/^www\./i, '')
    if (isCMMS.test(current) && desired !== current) {
      // eslint-disable-next-line no-console
      window.location = String(window.location).replace(current, desired)
    }
  }
}

/**
 * Listens to storage event and sets sessionStorage data so that sessionStorage is
 * available across tabs
 */
export const shareSessionAcrossTabs = () => {
  if (!sessionStorage.length) {
    // Ask other tabs for session storage
    localStorage.setItem('getSessionStorage', Date.now())
  }

  window.addEventListener('storage', event => {
    if (event.key === 'getSessionStorage') {
      // Some tab asked for the sessionStorage -> send it

      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
      localStorage.removeItem('sessionStorage')
    } else if (event.key === 'removeSessionStorage') {
      storage.deleteTokens()
      // setTimeout(() => {
      //   window.location.href = '/login'
      // }, 3000)
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      // sessionStorage is empty -> fill it

      const data = JSON.parse(event.newValue)

      if (data && data.token && data.refresh_token) {
        storage.authToken = data.token
        // storage.authRefreshToken = data.refresh_token
      }
    }
  })
}

/**
 * Handle query parameters for PrivateRoute
 * @param {object} qsParams Query Paramter
 */
export const handleURLSearchParams = qsParams => {
  const redirectParam = {}
  const searchParam = qs.parse(qsParams.search)

  if (qsParams.search) redirectParam.search = qsParams.search
  if (qsParams.pathname) redirectParam.pathname = qsParams.pathname

  if (JSON.stringify(redirectParam) !== '{}') {
    setUserData(LOCAL_STORAGE_USER_URL, redirectParam)
  }
}

export const setSelectedProgram = selectProgram => {
  setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectProgram)
}

/**
 * Checks if the user is logged in or not
 */
export const isLoggedIn = () => {
  return storage.authToken !== undefined && storage.authToken !== null
}

export const deleteProgramData = id => {
  deleteData(LOCAL_STORAGE_PROGRAMS)
  deleteData(LOCAL_STORAGE_PROGRAMS_QUERY)

  if (id) {
    deleteData(LOCAL_STORAGE_PROGRAMS + id)
  }
}

export const deleteUserData = id => {
  deleteData(LOCAL_STORAGE_USERS)
  deleteData(LOCAL_STORAGE_USERS_QUERY)

  if (id) {
    deleteData(LOCAL_STORAGE_USERS + id)
  }
}

export const getAgentDisplayName = agent => {
  return `${agent.f_name} ${agent.l_name} - ${agent.employee_code}`
}

export const isValidURL = string => {
  /* var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null) */

  try {
    URL(string)
  } catch (_) {
    return false
  }

  return true

  /*
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
  */
}

export const populateAgentList = program => {
  const agentOptions = []
  const label = isAgent() ? 'Select' : 'All'
  agentOptions.push({
    value: '',
    label,
    agent: {}
  })
  if (program.agents) {
    program.agents.forEach(item => {
      agentOptions.push({
        value: item.public_id,
        label: getAgentDisplayName(item),
        agent: item
      })
    })
  }
  return agentOptions
}

export const getArrayRowByValue = (arrData, arrField, arrValue) => {  
  const index = arrData.findIndex(el => el[arrField] === arrValue)  
  const objResult = { index, data:index >= 0 ? arrData[index] : {}}  
  return objResult
}

export const checkArrObjectValueExist = (arrData, arrField, arrValue) => {
  const index = arrData.findIndex(el => el[arrField] === arrValue)
  const objResult = { is_exist: index >= 0, index }
  return objResult
}
export const capitalizeFirstLetterOfWord = str => {
  const separateWord = str.toLowerCase().split(' ')
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(' ');
}
export const checkArrValueExist = (arrData, arrValue) => {
  const index = arrData.findIndex(el => el === arrValue)
  const objResult = { is_exist: index >= 0, index }
  return objResult
}

export const checkValueYesOrNot = value => {
  let valid = false
  valid =
    typeof value === 'string'
      ? value.toLowerCase() === 'y' || value.toLowerCase() === 'yes'
      : typeof value === 'boolean'
      ? value
      : typeof value === 'number'
      ? value === 1
      : valid
  return valid
}

export const showConsoleLog = (data, strText, showLog) => {
  let displayLog = showLog ? showLog : true
  let displayText = strText ? strText : ''
  if(displayLog) {
    console.log(displayText, data)
  }
}