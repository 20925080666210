import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatDate } from '@fullcalendar/core'
import qs from 'querystringify'
import moment from 'moment'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  ListGroup
} from 'react-bootstrap'
import {
  UnauthorizedCard,
  PmCoachingCard,
  ProgramSelectBar,
  AgentCoachingCard,
  OverlayLoader
} from '../../components'
import {
  isAdmin,
  isAgent,
  getUserData,
  getUserId,
  getWeeklyProgramMetrics,
  getThisWeekDateRange,
  getSelectedWeekDateRange
} from '../../helpers'
import {
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_SELECTED_AGENT,
  LOCAL_STORAGE_SELECTED_WEEK,
  USER_DETAILS
} from '../../constants'
import { coachingService } from '../../services'

class Coaching extends Component {
  state = {
    data: [],
    selectedProgram: null,
    selectedAgent: null,
    programId: null,
    agentId: 'ddd',
    fetchIdRef: 0,
    pageCount: 1,
    showCoachingType: '',
    metricData: {},
    coachingData: [],
    isLoading: false,
    acknowledgementData: [],
    saveAcknowledgement: true,
    isAuthorizedAccess: null,
    totalRecords: 0,
    intPageIndex: 0,
    intPageSize: 1000,
    weekRange: '',
    intWeekDropDownCount:'-1',
    agentFeedback: null,
  }

  abortController = new AbortController()

  componentDidMount = async () => {
    if (isAdmin()) {
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false
      }))

      setTimeout(() => {
        this.props.history.push({
          pathname: '/dashboard'
        })
      }, 2000)
    } else {
      this.props.updateHeaderTxt('Coachings List', 'Coachings')
      const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
      const selectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)
      const selectedWeek = getUserData(LOCAL_STORAGE_SELECTED_WEEK)

      const userData = getUserData(USER_DETAILS)
      const programId = selectedProgram ? selectedProgram.value : null
      let agentId = selectedAgent ? selectedAgent.value : null
      const queryParam = this.props.location.search
      let coaching_type = null
      if (queryParam !== '') {
        const { ctype } = qs.parse(
          this.props.location.search
        )
        coaching_type = ctype
      }

      if (isAgent()) {
        agentId = userData.public_id
      } else {
        this.props.programBar.onAgentChange({
          agent: {},
          label: 'All',
          value: ''
        })
      }
   
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: true,
        selectedProgram,
        selectedAgent,
        programId,
        agentId,
        showCoachingType:coaching_type
      }))
      
      this.props.programBar.showAgents = !isAgent() // only PM and PD have Agent Dropdown
      this.props.programBar.showWeeks = false
      this.props.programBar.onPmChangeHook = this.onPmChangeHook
      this.props.programBar.onAgentChangeHook = this.onAgentChangeHook
      if (isAgent()) {
         
        this.fetchAgentDetails(programId, selectedProgram,selectedWeek)
      }
    }
  }

  componentDidUpdate = prevProps => {
    /* if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({ 
        isLoading: this.props.isLoading
       })
    } */
  }

  componentWillUnmount() {
    if (this.state.isLoading) this.abortController.abort()
  }

  updateCoachingState = (
    loading,
    selectedProgram,
    selectedAgent,
    coachingData,
    pageIndex,
    pageSize
  ) => {
    this.setState(state => ({
      ...state,
      isLoading: loading,
      selectedProgram,
      selectedAgent,
      intPageIndex: pageIndex,
      intPageSize: pageSize,
      data: coachingData.data,
      keyHeaderMetris: coachingData.key_metrics_headers,
      pageCount: Math.ceil(coachingData.total / pageSize),
      totalRecords: coachingData.total
    }))
  }

  fetchData = async ({ pageIndex, pageSize }) => {
    const {
      selectedProgram,
      selectedAgent,
      programId,
      agentId,
      showCoachingType
    } = this.state
    
    const agent_id = isAgent() ? getUserId() : agentId
    this.setState(state => ({
      ...state,
      isLoading: true
    }))

    coachingService
      .get_weekly_coaching(
        programId,
        agent_id,
        showCoachingType,
        pageIndex,
        pageSize
      )
      .then(coachingData => {                  
        this.updateCoachingState(
          false,
          selectedProgram,
          selectedAgent,
          coachingData,
          pageIndex,
          pageSize
        )
      })
      .catch(error => {
        toast.error('Error while fetching program coaching details.')
        this.updateCoachingState(
          false,
          selectedProgram,
          selectedAgent,
          [],
          pageIndex,
          pageSize
        )
      })
  }

  columns = [
    {
      Header: 'Program Id',
      accessor: 'program_id'
    },
    {
      Header: 'Agent Id',
      accessor: 'agent_id'
    },
    {
      Header: 'Agent Name',
      accessor: 'agent_name'
    },
    {
      Header: 'Due Date',
      accessor: 'due_date'
    },
    {
      Header: 'Is Completed',
      accessor: 'is_completed'
    },
    {
      Header: 'Key Metrics',
      accessor: 'key_metrics'
    },
    {
      Header: 'Week Number',
      accessor: 'week_no'
    }
  ]

  setMyGlobalFilter = globalFilter => {
    this.setState({
      myGlobalFilter: globalFilter
    })
  }

  onClickCoachingType = (e, type) => {    
    this.setState(state => ({
      ...state,
      showCoachingType: type,
      intPageIndex: 0,
      pageCount: 1,
      intWeekDropDownCount: '-1'
    }))
  }

  onPmChangeHook = () => {
    this.props.programBar.onAgentChange({
      agent: {},
      label: 'All',
      value: ''
    })
    this.setState(state => ({
      ...state,
      selectedProgram: this.props.programBar.selectedProgram,
      programId: this.props.programBar.selectedProgram.value,
      selectedAgent: {
        agent: {},
        label: 'All',
        value: ''
      },
      agentId: null,
      intPageIndex: 0,
      pageCount: 1
    }))
  }

  onAgentChangeHook = () => {
    const selectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)
    this.setState(state => ({
      ...state,
      selectedAgent,
      agentId: selectedAgent.value
    }))
  }

  showProgramSelectionBar = () => {
    const { data } = this.state
    const arrRows = []
    const totalActivePlans = data ? data.length : ''
    const addlink = `/dashboard/coaching/add`

    if (!this.props.programBar.isFetchingProgram) {
      arrRows.push(
        <Row key={0}>
          <Col md={12}>
            <Card className='card-left-border no-bg'>
              <Card.Body className='py-3 pl-3 text-sm'>
                <Row className='align-items-center'>
                  <Col md={4} className='text-uppercase'>
                    <span className='font-weight-bold'>
                      Active -{' '}
                    </span>
                    {totalActivePlans > 0 ? totalActivePlans : 'No'} Coaching
                    Plans
                  </Col>
                  <Col
                    md={8}
                    className='text-right coaching_controls text-uppercase'
                  >
                    <a
                      href='#'
                      onClick={e => this.onClickCoachingType(e, '')}
                      className={
                        this.state.showCoachingType === ''
                          ? 'mr-3 text-dark'
                          : 'mr-3'
                      }
                    >
                      Active
                    </a>
                    <a
                      href='#'
                      onClick={e => this.onClickCoachingType(e, 'missed')}
                      className={
                        this.state.showCoachingType === 'missed'
                          ? 'mr-3 text-dark'
                          : 'mr-3'
                      }
                    >
                      Coaching Misses
                    </a>
                    <a
                      href='#'
                      onClick={e => this.onClickCoachingType(e, 'completed')}
                      className={
                        this.state.showCoachingType === 'completed'
                          ? 'mr-3 text-dark'
                          : 'mr-3'
                      }
                    >
                      Completed Coaching Plans
                    </a>
                    <Link to={addlink}>
                      <img
                        src='/assets/img/icon_plus.png'
                        className='add_icon'
                      />
                      New Coaching Plan
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )
    }

    arrRows.push(
      <Row key={1}>
        <Col md={12}>
          <ProgramSelectBar programBar={this.props.programBar} />
        </Col>
      </Row>
    )
    return arrRows
  }

  setAgentAcknowledgement = (index, checked, agentFeedback) => {
    const { coachingData } = this.state
    if (coachingData && coachingData.length > 0) {
      if (checked) {
        let agentFinalFeedBack = ''
        if (agentFeedback){
          agentFinalFeedBack = agentFeedback.value
        }
        coachingData[0].weeks[index].agent_acknowledgement = moment().format('YYYY-MM-DD HH:mm:ss')
        coachingData[0].weeks[index].agent_feedback = agentFinalFeedBack
      } else {
        coachingData[0].weeks[index].agent_acknowledgement = ''
        coachingData[0].weeks[index].agent_feedback = ''
      }
      this.setState(state => ({
        ...state,
        coachingData
      }))
      this.saveAgentAcknowledgement()
    }
  }

  saveAgentAcknowledgement = () => {
    const { coachingData, acknowledgementData } = this.state
    if (coachingData && coachingData.length > 0) {
      const weeksData = coachingData[0].weeks
      let isSubmit = false
      this.setState(state => ({
        ...state,
        isLoading: true,
        saveAcknowledgement: true
      }))

      weeksData.map((week, index) => {
        const currentAcknowledgement = week.agent_acknowledgement
        const previousAcknowledgement =
          acknowledgementData[index].agent_acknowledgement

        if (
          !(
            ((previousAcknowledgement === '' ||
              previousAcknowledgement === 'False') &&
              (currentAcknowledgement === '' ||
                currentAcknowledgement === 'False')) ||
            (previousAcknowledgement !== '' &&
              previousAcknowledgement !== 'False' &&
              currentAcknowledgement !== '' &&
              currentAcknowledgement !== 'False')
          )
        ) {
          isSubmit = true
          const objAcknowledgement = {
            agent_acknowledgement: week.agent_acknowledgement,
            agent_feedback: week.agent_feedback
          }
          coachingService
            .save_agent_acknowledgement(week.id, objAcknowledgement)
            .then(response => {})
            .catch(() => {
              this.setState(state => ({
                ...state,
                saveAcknowledgement: false
              }))
            })
        }
      })
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
      if (isSubmit) {
        if (this.state.saveAcknowledgement) {
          toast.success('Acknowledgement saved successfully')
        } else {
          toast.error('Error while saving acknowledgement')
        }
      }
    }
  }

  showAcknowledgeButton = () => {
    const { coachingData, acknowledgementData, isLoading } = this.state
    let showButton = false
    let disableButton = true
    let currentWeeks = []

    if (coachingData && coachingData.length > 0) {     
      currentWeeks = coachingData[0].weeks      
      currentWeeks.map((week, index) => {
        const currentAcknowledgement = week.agent_acknowledgement
        const previousAcknowledgement =
          acknowledgementData[index].agent_acknowledgement
        if (week.is_completed) {
          showButton = true
        }
        if (
          !(
            ((previousAcknowledgement === '' ||
              previousAcknowledgement === 'False') &&
              (currentAcknowledgement === '' ||
                currentAcknowledgement === 'False')) ||
            (previousAcknowledgement !== '' &&
              previousAcknowledgement !== 'False' &&
              currentAcknowledgement !== '' &&
              currentAcknowledgement !== 'False')
          )
        ) {
          disableButton = false
        }
      })
    }
    const objButton = { showButton, disableButton }
    return objButton
  }


  fetchAgentDetails = (programId, selectedProgram,selectedWeek) => {
    const agentId = getUserId()
    const selected_week = selectedWeek.week.weekNo
    const selected_year = selectedWeek.week.startYear

    const week = getSelectedWeekDateRange(selected_week, selected_year)
    const start_date = moment()
      .year(week.startYear)
      .week(week.weekNo)
      .day('monday')
      .format('DD/MM/YYYY')
    const end_date = moment()
      .year(week.startYear)
      .week(week.weekNo)
      .day('monday')
      .add(5, 'days')
      .format('DD/MM/YYYY')
    const week_range = `${start_date} - ${end_date}`
    this.setState(state => ({
      ...state,
      weekRange: week_range,
    }))

    if (selectedProgram && programId && agentId) {
      this.setState(state => ({
        ...state,
        isLoading: true,
      }))
      Promise.all([
        coachingService.get_coaching_by_program(programId, agentId), // TODO, test only, remove it, use above line
        getWeeklyProgramMetrics(week.startYear, week.weekNo, programId, agentId)
      ])
        .then(([coachingData, metricData]) => {
          this.updateAgentCoachingState(
            false,
            selectedProgram,
            coachingData.data,
            metricData
          )
        })
        .catch(error => {
          toast.error('Error while fetching Agent coaching details.')
          this.updateAgentCoachingState(false, selectedProgram, [], [])
        })
    } else {
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
    }
  }
  

  updateAgentCoachingState = (
    loading,
    selectedProgram,
    coachingData,
    metricData
  ) => {
    const acknowledgementData = []
    if (coachingData && coachingData.length > 0) {
      const { weeks } = coachingData[0]
      weeks.map((week, index) => {
        acknowledgementData.push({
          agent_acknowledgement: week.agent_acknowledgement
        })
      })
    }
    this.setState(state => ({
      ...state,
      isLoading: loading,
      selectedProgram,
      coachingData: coachingData,
      acknowledgementData,
      metricData
    }))
  }

  render() {
    const { 
      isAuthorizedAccess, 
      isLoading,
      data,
      metricData,
      coachingData,
      acknowledgementData,
      agentFeedback,
      weekRange,
      showCoachingType,
      selectedProgram,
      selectedAgent,
      keyHeaderMetris,
      pageCount,
      totalRecords,
      intPageIndex,
      intPageSize,
      intWeekDropDownCount      
    } = this.state

    if (isAuthorizedAccess === null) {
      return <OverlayLoader />
    }

    if (isAuthorizedAccess !== null && !isAuthorizedAccess) {
      return <UnauthorizedCard />
    }

    if (isAuthorizedAccess !== null && isAuthorizedAccess) {      
      if (isAgent()) {        
        return (
          <AgentCoachingCard
            data={data}
            columns={this.columns}
            selectedProgram={selectedProgram}
            selectedAgent={selectedAgent}            
            showCoachingType={showCoachingType}
            programBar={this.props.programBar}
            keyHeaderMetris={keyHeaderMetris}
            fetchData={this.fetchData}
            pageCount={pageCount}
            totalRecords={totalRecords}
            pageIndex={intPageIndex}
            pageSize={intPageSize}
            intPageIndex={intPageIndex}
            intPageSize={intPageSize}
            coachingData={coachingData}
            metricData={metricData}
            isLoading={isLoading}
            setAgentAcknowledgement={this.setAgentAcknowledgement}
            saveAgentAcknowledgement={this.saveAgentAcknowledgement}
            showAcknowledgeButton={this.showAcknowledgeButton}
            acknowledgementData={acknowledgementData}
            agentFeedback={agentFeedback}
            weekRange={weekRange}
            onClickCoachingType={this.onClickCoachingType}            
          />
        )
      }
      return (
        <section>
          {this.showProgramSelectionBar()}
          <PmCoachingCard
            data={data}
            columns={this.columns}
            selectedProgram={selectedProgram}
            selectedAgent={selectedAgent}
            showCoachingType={showCoachingType}
            keyHeaderMetris={keyHeaderMetris}
            fetchData={this.fetchData}
            pageCount={pageCount}
            totalRecords={totalRecords}
            pageIndex={intPageIndex}
            pageSize={intPageSize}
            intPageIndex={intPageIndex}
            intPageSize={intPageSize}
            isLoading={isLoading}
            intWeekDropDownCount={intWeekDropDownCount}
            programBar={this.props.programBar}
          />
        </section>
      )
    }
  }
}

export default Coaching
