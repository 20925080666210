/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import { 
  ProgramSelectBar, 
  UnauthorizedCard,
  OverlayLoader
} from '../../components'
import MetricsUploadForm from '../../components/dashboard/MetricsUploadForm'
import {
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_SELECTED_WEEK,
  ERROR_METRIC_HEADER,
  ERROR_FETCH_PROGRAM_METRICS,
  ERROR_INVALID_METRICS_DATATYPE,
} from '../../constants'
import {
  isAdmin,
  isPm,
  isAgent,
  isProgramDirector,
  getDropDownProgram,
  getMetricColumns,
  getMetricRows,
  getUserData,
  getWeeklyProgramMetrics,
  isMetricHeaderSet,
  showNoMetricHeaderError,
  showToastGeneralError,
  storeMetricData,
  checkArrObjectValueExist
} from '../../helpers'
import { metricsService, programService } from '../../services'

/**
 * Component which allows the user to upload/edit metrics data for a program
 */
class Metrics extends Component {
  state = {
    selectedProgram: null, // {value:'', lable:'', program:{}}
    metricTableData: [], // metric list displayed in the table
    isLoading: false,
    showAlert: false,
    excelData: [], // data read from excel
    isFetchingProgram: false, // to indicate program details fetching,
    uploadResponse: {}, // holds the api response
    // weekOptions: [], // week drop down options
    selectedWeek: null, // selected week in the drop down
    columns: [], // metric table header, constructed dynamically
    isMetricUpdated: false,
    updatedMetricData: [],
    isDataImported: false,
    showUploadForm: false,
    isEditMetric: false,
    errors: {},
    uploadFileInfo: null,
    isAuthorizedAccess:null,
  }

  abortController = new AbortController()

  async componentDidUpdate() {}

  async componentDidMount() {
    if(isAdmin()) {   
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else { 
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: true,        
      }))
      this.props.updateHeaderTxt('Metrics List', 'Metrics')
      this.props.programBar.onAgentChange({
        agent: {},
        label: 'All',
        value: ''
      })

      // set the hook to props, so that, it gets called on corresponding drop down change
      // and this component receive hook callback
      this.props.programBar.onPmChangeHook = this.onPmChangeHook
      this.props.programBar.onWeekChangeHook = this.onWeekChangeHook
      this.props.programBar.setShowAgent(false)
      this.props.programBar.showWeeks = true
      this.getProgramMetrics()
    }
  }

  filterRequiredProgramMetrics = (userSelectedProgram, programMetrics) => {
    const programMetricHeaders = userSelectedProgram.program.metrics
    const programAgents = userSelectedProgram.program.agents
    const requiredColumKeys = []
    const requiredMetricRows = []
    const metricsValueType = []
    let requiredMetricCols = []
    let metricsColmnValues = []
    let metricDataTypeMismatch = []
    const colIndexCounter = 0
    let empCodeColIndex = 0
    let isCorrectAgent = false
    let isAgentExist = true

    programMetricHeaders.map((reqHeader, reqIndex) => {
      programMetrics[0].map((exlHeader, exlIndex) => {
        if (reqHeader.metrics_name === exlHeader) {
          requiredColumKeys.push(exlIndex)
          metricsColmnValues.push(reqHeader.metrics_name)
          metricsValueType.push(reqHeader.data_type)
        }
        if (
          reqHeader.metrics_name === exlHeader &&
          reqHeader.metrics_name === 'Employee Code'
        ) {
          empCodeColIndex = exlIndex
        }
      })
    })

    programMetrics.map((row, rowIndex) => {
      requiredMetricCols = []
      isCorrectAgent = false
      const esAtRisk = ["y", "n", "yes", "no"];
      row.map((col, colIndex) => {
        if (requiredColumKeys.indexOf(colIndex) > -1) {
          if(rowIndex > 0){
            if(colIndex === 3 && metricsColmnValues[requiredColumKeys.indexOf(colIndex)].toString().toUpperCase() === 'ES AT RISK (Y/N)'){
              if(!esAtRisk.includes(col.toString().toLowerCase())){
                metricDataTypeMismatch.push(metricsColmnValues[requiredColumKeys.indexOf(colIndex)])
              }
            }
            if(colIndex !== 3 && typeof col !== metricsValueType[requiredColumKeys.indexOf(colIndex)].toString().toLowerCase()){
              metricDataTypeMismatch.push(metricsColmnValues[requiredColumKeys.indexOf(colIndex)])
            }
          }
          requiredMetricCols.push(col)
          isAgentExist = checkArrObjectValueExist(
            programAgents,
            'employee_code',
            col
          ).is_exist
          if (rowIndex > 0 && colIndex === empCodeColIndex && isAgentExist) {
            isCorrectAgent = true
          }
        }
      })
      metricDataTypeMismatch = [...new Set(metricDataTypeMismatch)];
      requiredMetricRows.push(requiredMetricCols)
      /* if (rowIndex === 0 || isCorrectAgent) {
        requiredMetricRows.push(requiredMetricCols)
      } */
    })
    return [requiredMetricRows, metricDataTypeMismatch]
  }

  getProgramMetrics = isImportMetrics => {
    const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
    const selectedWeek = getUserData(LOCAL_STORAGE_SELECTED_WEEK)
    const { week } = selectedWeek
    const { metrics } = selectedProgram.program
    let flagSelectedProgram = false
    let flagSelectedWeek = false
    this.clearErrors()

    if (selectedProgram && selectedProgram.value) {
      flagSelectedProgram = true
    }

    if (selectedWeek && week) {
      flagSelectedWeek = true
    }

    if (flagSelectedProgram && flagSelectedWeek) {
      this.setState(state => ({
        ...state,
        isLoading: true,
        isFetchingProgram: true
      }))

      // check if Metric Header is set for the program
      if (isMetricHeaderSet(selectedProgram.program)) {
        getWeeklyProgramMetrics(
          week.startYear,
          week.weekNo,
          selectedProgram.value,
          null,
          isImportMetrics
        )
          .then(metricData => {
            let metricColumns = getMetricColumns(metricData.headers)
            var tempColumns = []
            if(Object.keys(metricColumns).length == 0){
              metrics.forEach((metric) =>
                tempColumns.push(metric.metrics_name)
              );
              metricColumns = getMetricColumns(tempColumns)
            }
            const metricRows = getMetricRows(metricData.data)
            this.updateMetricState(
              false,
              metricColumns,
              metricRows,
              false,
              selectedProgram,
              selectedWeek
            )
          })
          .catch(error => {
            toast.error(ERROR_FETCH_PROGRAM_METRICS)
            this.updateMetricState(
              false,
              [],
              [],
              false,
              selectedProgram,
              selectedWeek
            )
          })
      } else {        
        this.setErrors('metric_header', ERROR_METRIC_HEADER)        
        this.updateMetricState(
          false,
          [],
          [],
          false,
          selectedProgram,
          selectedWeek
        )
      }
    }
  }

  updateMetricState = (
    loading,
    metricColumns,
    metricData,
    isFetchingProgram,
    selectedProgram,
    selectedWeek
  ) => {
    this.setState(state => ({
      ...state,
      isLoading: loading,
      columns: metricColumns,
      metricTableData: metricData,
      showUploadForm: false,
      isEditMetric: false,
      isFetchingProgram,
      uploadResponse: {},
      selectedProgram,
      selectedWeek
    }))
  }

  setUploadFileInfo = value => {
    this.setState(state => ({
      ...state,
      uploadFileInfo: value
    }))
  }

  setShowUploadForm = value => {
    this.setState(state => ({
      ...state,
      showUploadForm: value
    }))
  }

  setIsEditMetric = value => {
    this.setState(state => ({
      ...state,
      isEditMetric: value
    }))
  }

  setErrors = (key, value) => {
    const { errors } = this.state
    errors[key] = value
    this.setState({ errors })
  }

  clearErrors = key => {
    const { errors } = this.state
    if (key) {
      delete errors[key]
      this.setState({ errors })
    } else {
      this.setState({ errors: {} })
    }
  }

  componentWillUnmount() {
    if (this.state.isLoading) this.abortController.abort()
  }

  changeLoadingState = loadingState => {
    this.setState(state => ({
      ...state,
      isLoading: loadingState
    }))
  }

  onWeekChangeHook = selectedWeek => {
    this.getProgramMetrics()
  }

  // we override Dashboard hook, to get onChange event callback
  onPmChangeHook = selectedProgram => {
    this.getProgramMetrics()
  }

  setFetchingProgramState = isFetching => {
    this.setState(state => ({
      ...state,
      isFetchingProgram: isFetching
    }))
  }

  handleSubmit = (data, setSubmitting) => {
    if (!this.state.selectedProgram) {
      setSubmitting(false)
      this.setErrors('select_program', 'Select the required program')      
      showToastGeneralError()
      return
    }

    if (!this.state.selectedWeek) {
      setSubmitting(false)
      this.setErrors('select_week', 'Select the required week')      
      showToastGeneralError()
      return
    }

    if (this.state.isEditMetric && !this.state.isMetricUpdated) {
      setSubmitting(false)
      toast.error('Metrics data is not modified')
      return
    }

    if (!this.state.isEditMetric) {
      if (this.state.uploadFileInfo && this.state.uploadFileInfo !== null) {        
        const fileInfo = this.state.uploadFileInfo
        const filename = this.state.uploadFileInfo.name
        if (fileInfo.size > 5242880) {
          setSubmitting(false)
          this.setErrors('upload_error', 'Please size should be below 5mb')
          showToastGeneralError()
          return
        }
        if (filename.substr(-4) !== 'xlsx' && filename.substr(-3) !== 'xls') {
          setSubmitting(false)
          this.setErrors('upload_error', 'File type should be .xlsx or .xls')
          showToastGeneralError()
          return
        }
      } else {
        setSubmitting(false)
        this.setErrors('upload_error', 'Please select the file')        
        showToastGeneralError()
        return
      }
    }

    const userSelectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
    const selectedWeek = this.state.selectedWeek.week
    const isImportMetrics = !this.state.isMetricUpdated
    const importedData = {}
    const metricMismatchDataType = []
    importedData.program_id = userSelectedProgram.value
    importedData.week_no = selectedWeek.weekNo
    importedData.year = selectedWeek.startYear
    importedData.error = metricMismatchDataType

    if (this.state.isMetricUpdated) {
      const updatedData = this.getConvertedMetricData(data.data)
      importedData.data = updatedData
    } else {
      const { excelData } = this.state
      const requiredMetrics = this.filterRequiredProgramMetrics(
        userSelectedProgram,
        excelData
      )

      importedData.data = requiredMetrics[0]
      importedData.error = requiredMetrics[1]
      importedData.type = 'upload'
    }

    if(importedData.error.length > 0){
      setSubmitting(false)
      const { url } = this.props.match
      // toast.error(error[0])
      toast.error(ERROR_INVALID_METRICS_DATATYPE + ' : ' + importedData.error)
      this.setState(state => ({
        ...state,
        showAlert: false,
        isLoading: false,
        showUploadForm : false,
        uploadFileInfo: null,
        uploadResponse: 'Invalid metric data type'
      }))
    } else {
        metricsService
        .import_metrics(importedData)
        .then(response => {
          setSubmitting(false)
          // store the data to local storage
          // storeMetricData(importedData) // commented this to get agent name from API
          this.setState(state => ({
            ...state,
            showAlert: false,
            isLoading: false,
            uploadResponse: response,
            showUploadForm: false,
            isEditMetric: false,
            uploadFileInfo: null
          }))
          this.getProgramMetrics(isImportMetrics)

          toast.success('Successfully uploaded the Metrics.')
        })
        .catch(error => {
          setSubmitting(false)        
        setSubmitting(false)        
          setSubmitting(false)        
          toast.error(error[0])
          this.setState(state => ({
            ...state,
            showAlert: false,
            isLoading: false,
            uploadResponse: error
          }))
        })

      setSubmitting(true)
    }
  }

  setExcelData = data => {
    this.setState(state => ({
      ...state,
      isDataImported: true,
      excelData: data
    }))
  }

  showUploadResponse = () => {
    if (this.state.uploadResponse !== {}) {
      return <span>{JSON.stringify(this.state.uploadResponse)}</span>
    }
    return null
  }

  updateMetricsTable = tableData => {
    this.setState(state => ({
      ...state,
      isMetricUpdated: true,
      updatedMetricData: tableData
    }))
  }

  getConvertedMetricData = data => {
    const metricArray = []

    const metricHeader = []
    this.state.columns.forEach(column => {
      metricHeader.push(column.Header)
    })

    // first push header
    metricArray.push(metricHeader)

    let metricData = [...this.state.updatedMetricData]
    if (data && data.length > 0) {
      metricData = data
    }

    // then push actual rows
    metricData.forEach(obj => {
      const objArray = []
      Object.keys(obj).forEach(key => {
        objArray.push(obj[key])
      })

      metricArray.push(objArray)
    })
    return metricArray
  }

  render() {
    const { isAuthorizedAccess } = this.state    
    if(isAuthorizedAccess === null) {      
      return <OverlayLoader />
    }

    if(isAuthorizedAccess) {
      return (<>
        <div>
          <ProgramSelectBar programBar={this.props.programBar} />
        </div>
        <Row>
          <Col>
            <ErrorBoundary>             
              <MetricsUploadForm
                selectedProgram={this.state.selectedProgram}
                handleSubmit={this.handleSubmit}
                isLoading={this.state.isLoading}
                showAlert={this.state.showAlert}
                setExcelData={this.setExcelData}
                isFetchingProgram={this.state.isFetchingProgram}
                setFetching={this.setFetchingProgramState}
                columns={this.state.columns}
                metricTableData={this.state.metricTableData}
                updateMetricsTable={this.updateMetricsTable}
                showUploadForm={this.state.showUploadForm}
                setShowUploadForm={this.setShowUploadForm}
                isEditMetric={this.state.isEditMetric}
                setIsEditMetric={this.setIsEditMetric}
                errors={this.state.errors}
                clearErrors={this.clearErrors}
                uploadFileInfo={this.state.uploadFileInfo}
                setUploadFileInfo={this.setUploadFileInfo}
              />              
            </ErrorBoundary>
          </Col>
        </Row>
      </>)
    } else {
      return <UnauthorizedCard />
    } 
  }  
}

export default Metrics