/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ErrorMessage, Form as FormikForm, Formik } from 'formik'
import React, { useRef } from 'react'
import {
  Alert,
  Button,
  Card,
  Form,
  Spinner,
  Col,
  Row,
  Container
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { isAgent, isMetricHeaderSet, isPm, isProgramDirector } from '../../helpers'
import '../../styles/base.scss'
import WeeklyMetricsTable from './WeeklyMetricsTable'
import { 
  NO_RECORDS,   
  METRIC_HEADER_EMP_NAME,
  METRIC_HEADER_EMP_CODE
} from '../../constants'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'

const MetricsUploadForm = ({ ...props }) => {
  const myRef = useRef()
  const { errors } = props
  let isFieldEditable = false
 
  if(props.selectedProgram && props.selectedProgram.program && props.selectedProgram.program.metrics) {    
    props.selectedProgram.program.metrics.map(row => {  
       if(row.editable) {
          return isFieldEditable = row.editable
       }
    })
  }
 
  /* const validationSchema = Yup.object().shape({
    excelupload: Yup
        .mixed()
        .required("A file is required")
        .test(
          "fileSize",
          "File too large",
          value => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Unsupported Format",
          value => value && SUPPORTED_FORMATS.includes(value.type)
        )   
  }) */

  const initialData = { excelupload: undefined }

  const hasRecords = props.metricTableData.length > 0
  const { showUploadForm } = props
  const { isEditMetric } = props
  const parseData = evt => {
    /* Parse data */
    const bstr = evt.target.result

    // https://docs.sheetjs.com/ for XLXS documentation
    const wb = XLSX.read(bstr, { type: 'binary' })

    /* Get first worksheet */
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    /* Convert array to json */
    const jsonData = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      blankrows: false,
      defval: ''
    })

    props.setExcelData(jsonData)
    props.setFetching(false)
    props.clearErrors()
  }

  const readXlsxFile = evt => {
    const { files } = evt.target
    const reader = new FileReader()

    reader.onload = evt1 => {
      try {
        parseData(evt1)
      } catch (error) {
        /* toast.error("Error while parsing...") */
        // showToastGeneralError()
        props.setFetching(false)
      }
    }

    // read the file
    reader.readAsBinaryString(files[0])
    props.setFetching(true)
  }

  /*const exportMetricsToExcel = () => {
    const metricHeaders = []
    const header = {}
    const selectedProgramMetrics = props.selectedProgram
      ? props.selectedProgram.program.metrics
      : []
    let counter = 0
    selectedProgramMetrics.forEach(item => {
      header[item.metrics_name] = ''
      if (counter < 1) {
        header['Employee Name'] = ''
      }
      counter++
    })

    metricHeaders.push(header)

    const ws = XLSX.utils.json_to_sheet(metricHeaders)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const fileType = 'application/vnd.ms-excel;charset=utf-8'

    let fileName = 'ProgramMetrics.xlsx'
    if (props.selectedProgram) {
      fileName = `${props.selectedProgram.label}.xlsx`
    }

    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName)
  }*/

  const exportMetricsToExcel = () => {
    const metricHeaders = []
    const header = {}
    const arrHeaders = []
    let counter = 0;
    let agents = (props.selectedProgram && props.selectedProgram.program) ? props.selectedProgram.program.agents : []    
    props.columns.forEach(item => {
      arrHeaders.push(item.Header)
      header[item.Header] = ''
      if (counter < 1) {
        arrHeaders.push(METRIC_HEADER_EMP_NAME)
        header[METRIC_HEADER_EMP_NAME] = ''
      }
      counter++
    })
    //metricHeaders.push(header)
    metricHeaders.push(arrHeaders)
    agents.map((agent, index) => {
      let agent_name = agent.l_name !== "" ? agent.f_name +" "+agent.l_name : agent.f_name
      let arrAgent = []
      arrAgent.push(agent.employee_code)
      arrAgent.push(agent_name)
      arrHeaders.map((header_name, rowIndex) => {
        if(header_name !== METRIC_HEADER_EMP_CODE && header_name !== METRIC_HEADER_EMP_NAME) {
          arrAgent.push("")
        }
      })
      metricHeaders.push(arrAgent)
    })

    const ws = XLSX.utils.json_to_sheet(metricHeaders, {skipHeader:true, origin:'A1'})    
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }    
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType = 'application/vnd.ms-excel;charset=utf-8'
    let fileName = 'ProgramMetrics.xlsx'
    if (props.selectedProgram) {
      fileName = `${props.selectedProgram.program.program_name}.xlsx`
    }

    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName)
  }

  const showExport = () => {
    if (showUploadForm && !errors.metric_header) {
      return (
        <div className='margin-auto'>
          <Button
            className='btn btn-sm btn-primary float-right mt-3'
            onClick={() => {
              exportMetricsToExcel()
            }}
          >
            Download Metrics Excel
          </Button>
        </div>
      )
    }
    return null
  }

  const showFetchingProgramDetails = () => {
    if (props.isFetchingProgram) {
      return (
        <OverlayLoader />
      )
    }
    return null
  }

  const getMetricsTable = () => {    
    if (showUploadForm) {
      return ''
    }
    return (
      <WeeklyMetricsTable
        metricTableData={props.metricTableData}
        updateMetricsTable={props.updateMetricsTable}
        columns={props.columns}
        selectedProgram={props.selectedProgram}
        ref={myRef}
        isEditMetric={isEditMetric}
        hasRecords={hasRecords}
        isFieldEditable={isFieldEditable}
      />
    )
  }

  const clickShowUploadForm = () => {
    props.setShowUploadForm(true)
    props.setIsEditMetric(false)
  }

  const clickEditMetric = () => {
    props.setIsEditMetric(true)
    props.setShowUploadForm(false)
  }

  const displayUploadOption = setFieldValue => {
    if (
      props.selectedProgram &&
      props.selectedProgram.program &&
      isMetricHeaderSet(props.selectedProgram.program)
    ) {
      return (
        <div>
          Upload Metrics Excel File
          <div className='input-group mb-3'>
            <div className='input-group-prepend' />
            <div className='custom-file'>
              <input
                type='file'
                className='custom-file-input file-input text-dark'
                id='excelupload'
                name='excelupload'
                onChange={event => {
                  props.setUploadFileInfo(event.currentTarget.files[0])
                  setFieldValue('excelupload', event.currentTarget.files[0])
                  readXlsxFile(event)
                  event.target.value = ''
                }}
              />
              <label className='custom-file-label' htmlFor='excelupload'>
                Choose file
              </label>
            </div>
          </div>
          {props.uploadFileInfo ? (
            <div>
              <h5>Selected File : {props.uploadFileInfo.name}</h5>
            </div>
          ) : null}
          <ErrorMessage
            name='excelupload'
            render={msg => <div className='text-danger'>{msg}</div>}
          />
        </div>
      )
    }
    return null
  }

  const showProcessMessage = () => {
    if (props.isFetchingProgram) {
      return ''
    }
    if (!props.showUploadForm && hasRecords) {
      return getMetricsTable()
    }

    if (Object.keys(errors).length > 0) {
      const arrErrors = []
      Object.keys(errors).map((key, index) => {
        arrErrors.push(
          <div className='text-danger' key={index}>
            {errors[key]}
          </div>
        )
      })
      return <div className='p-3 pl-4'>{arrErrors}</div>
    }

    if (!showUploadForm) {
      return (
        <div className='p-3 pl-4'>
          {NO_RECORDS}. Click 'Upload Program Metrics' button to import data.
        </div>
      )
    }
    return ''
  }

  return (
    <Card className='no-bgr bg-white'>
      <Card.Header>
        <h3 className='mb-0'> {isPm() || isProgramDirector() ? 'Program Metrics Upload' : 'Program Metrics'}</h3>
      </Card.Header>
      <Alert
        variant='success'
        show={props.showAlert}
        onClose={() => props.setShow()}
        dismissible
      >
        <p className='m-0'>
          <span>Metrics data is uploaded.</span>
          <br />
        </p>
      </Alert>

      <Formik
        initialValues={initialData}
        // validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.data = []
          if (myRef && myRef.current) {
            const data = myRef.current.getMyState()
            values.data = data
          }
          props.handleSubmit(values, setSubmitting)
        }}
      >
        {formik => (
          <FormikForm>
            {formik.isSubmitting && (
              <div><OverlayLoader /><Container>
              <Row className='mt-3'>
                <Col md={{ span: 6, offset: 3 }}>
                  <Alert variant='info'>
                    Submitting Information. Please wait...
                  </Alert>
                </Col>
              </Row>
            </Container></div>
            )}
            {showFetchingProgramDetails()}
            {showProcessMessage()}
            <Card.Body className='user-profile-card-body pb-2'>
              {showExport()}
              {showUploadForm && (
                <Form.Row>
                  <Form.Group>
                    {displayUploadOption(formik.setFieldValue)}
                  </Form.Group>
                </Form.Row>
              )}
              {(!props.isFetchingProgram && !props.isLoading && !isAgent()) ? (
              <Form.Row>
                <Link to='/dashboard'><Button
                  variant='light'
                  type='button'
                  className='mr-3'                  
                >
                  Cancel
                </Button></Link>
                {((hasRecords && isEditMetric) || showUploadForm) && (
                  <Button
                    variant='primary'
                    className='submit-button mr-1'
                    type='submit'
                    disabled={
                      formik.isSubmitting ||
                      props.isFetchingProgram ||
                      props.isLoading
                    }
                  >
                    {formik.isSubmitting ? (
                      <ShowSpinner buttonSpinner={true} />
                    ) : null} 
                    {showUploadForm ? 'Upload' : 'Save'}                  
                  </Button>
                )}
                &nbsp;
                {/* {!showUploadForm && !isEditMetric && hasRecords && (
                  <Button
                    variant='primary'
                    className='submit-button mr-1'
                    type='button'
                    onClick={e => clickEditMetric(e)}
                    disabled={!isFieldEditable}
                  >
                    Edit
                  </Button>
                )} */}
                {!showUploadForm && !isEditMetric && (
                  <Button
                    variant='primary'
                    className='submit-button mr-1'
                    type='button'
                    onClick={e => clickShowUploadForm(e)}
                    disabled={Object.keys(errors).length > 0}
                  >
                    Upload Program Metrics
                  </Button>
                )}
              </Form.Row>) : null}
            </Card.Body>
          </FormikForm>
        )}
      </Formik>
    </Card>
  )
}

export default MetricsUploadForm
