import { ExpandMoreRounded } from '@material-ui/icons'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { 
  ARRAY_USER_ROLES,
  ADD_MODE,
  EDIT_MODE,
  DELETE_MODE,
  VIEW_MODE,  
  RESET_PASSWORD_MODE,
  TEXT_ADD,
  TEXT_UPDATE,
  TEXT_DELETE,
  TEXT_SAVE,
  TEXT_RESET_PASSWORD
} from '../../constants'
import '../../styles/base.scss'
import { PopupModal } from '../../components'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'


const AddUserComponent = ({ ...props }) => {
  const { formData } = props
  const resetPasswordLink = `/dashboard/users/reset/${formData.public_id}`  
  const isAddPage = (props.pageMode === ADD_MODE)
  const isEditPage = (props.pageMode === EDIT_MODE)
  const isViewPage = (props.pageMode === VIEW_MODE)
  const isResetPage = (props.pageMode === RESET_PASSWORD_MODE)

  // Schema for yup
  let objValidations = {}
  
  if(isAddPage || isEditPage) {
    objValidations = {
      f_name: Yup.string()
        .required('First name is required')
        .min(2, 'Minimum 2 characters')
        .max(50, 'Maximum 50 characters')
        .matches(/^[a-zA-Z0-9]([\w ]*[a-zA-Z0-9])?$/,
          "Should be alpha numeric" //first letter should be alphanumeric, space, hypen
        ),       
        /*.matches(/^[a-zA-Z0-9 ]+$/,
          "Should be alpha numeric"
        ),*/
      l_name: Yup.string()    
      .max(50, 'Maximum 50 characters')
      .matches(/^[a-zA-Z0-9]([\w ]*[a-zA-Z0-9])?$/,
        "Should be alpha numeric"
      ),
      email: Yup.string()
      .required('Email address is required')    
      .email('Invalid email address'), 
      employee_code: Yup.string() 
      .required('Employee code is required')   
      .max(10, 'Maximum 10 characters')
      .matches(/^[a-zA-Z0-9]+$/,
        "Should be alpha numeric without space"
      )  
    }
  } 

  if(isAddPage || isResetPage) {
    objValidations['password'] = Yup.string() 
    .required('Password is required')   
    .max(12, 'Maximum 12 characters')
    .min(8, 'Minimum 8 characters')

    objValidations['confirm_password'] = Yup.string() 
    .required('Confirm password is required')   
    .max(12, 'Maximum 12 characters')
    .min(8, 'Minimum 8 characters')
    .oneOf([Yup.ref('password'), null],'Passwords must match')
  }

  const validationSchema = Yup.object().shape(objValidations) 

  // we get it with 2nd render and not with first
  useEffect(() => {
  }, [])   

  const userRolesSelectBox = () => {
    const userRole = formData.role_name !== "" ? formData.role_name : ARRAY_USER_ROLES[0].role_name    
    let options = []    
    ARRAY_USER_ROLES.map((value, key) => {
      options.push(<option value={value.role_name} key={key+1}>{value.role_description}</option>)
    })
    return (
      <Field
        name='role_name'
        id='role_name'
        as='select'
        //value={userRole}
        className='custom-select'
        disabled={isViewPage}
      >
        {options}
      </Field>
    )
  }  
  
  const showRequiredStarMark = () => {    
      return <span className="text-danger"> *</span>    
  }  

  const showDeleteUserPopup = () => {    
    let modalTitle = "Delete User"
    let modalBody = "Are you sure to delete this user?"
    let arrButtons = []
    arrButtons.push(<Button variant="primary" key={1} onClick={props.confirmDeleteUser}>
    Confirm
  </Button>)
    return <PopupModal showModal={props.showModal} handleModalClose={props.handleModalClose} buttons={arrButtons} modalTitle={modalTitle} modalBody={modalBody} />
  }
  
  return (
    <Row className='justify-content-center mt-5'>
      {props.isProcessing ? <OverlayLoader /> : null}
      <Col md={6}>
        <Card className="bg-transparent card-form no-bg">       
          <Card.Body className='user-profile-card-body'>
            <Formik
              enableReinitialize={isEditPage || isViewPage || isResetPage}
              initialValues={formData}
              validationSchema={validationSchema}              
              onSubmit={(values, { setSubmitting }) => {                
                props.handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}                  
                  <Form > 
                    {!isResetPage ? (<Form.Group>
                      <Form.Label className='req-star'>
                        First Name {isViewPage ? null : showRequiredStarMark()} 
                      </Form.Label>
                      <Field
                        name='f_name'
                        id='f_name'
                        type='text'
                        //defaultValue={formData.f_name}
                        className='form-control'
                        disabled={isViewPage}
                      />
                      <ErrorMessage
                        name='f_name'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {!isResetPage ? (<Form.Group>
                      <Form.Label className='req-star'>
                        Last Name 
                      </Form.Label>
                      <Field
                        name='l_name'
                        id='l_name'
                        type='text'
                        //defaultValue={formData.l_name}
                        className='form-control'
                        disabled={isViewPage}
                      />
                      <ErrorMessage
                        name='l_name'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {!isResetPage ? (<Form.Group>
                      <Form.Label className='req-star'>
                        Email Address {isViewPage ? null : showRequiredStarMark()} 
                      </Form.Label>
                      <Field
                        name='email'
                        id='email'
                        type='text'
                        //defaultValue={formData.email}
                        className='form-control'
                       // disabled={isEditPage || isViewPage}
                        disabled={isViewPage}
                      />
                      <ErrorMessage
                        name='email'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {!isResetPage ? (<Form.Group>
                      <Form.Label className='req-star'>
                        Employee Code {isViewPage ? null : showRequiredStarMark()} 
                      </Form.Label>
                      <Field
                        name='employee_code'
                        id='employee_code'
                        type='text'
                        //defaultValue={formData.employee_code}
                        className='form-control'
                        disabled={isEditPage || isViewPage}
                      />
                      <ErrorMessage
                        name='employee_code'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {!isResetPage ? (<Form.Group>
                      <Form.Label className='req-star'>
                        User Role {isViewPage ? null : showRequiredStarMark()}  
                      </Form.Label>
                      {userRolesSelectBox()}
                      <ErrorMessage
                        name='role_name'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {(isAddPage || isResetPage) ? (<Form.Group className='password_block'>
                      <Form.Label className='req-star'>
                        {isResetPage ? "New Password" : "Password"} {showRequiredStarMark()}  
                      </Form.Label>
                      <Field
                        name='password'
                        id='password'
                        type={props.viewPassword.password ? 'text' : 'password'}
                        //defaultValue={formData.password}
                        className='form-control'
                      />
                      <ErrorMessage
                        name='password'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                      <span
                  role='button'
                  tabIndex='-1'
                  className={props.viewPassword.password ? 'password_show' : 'password_hide'}
                  onClick={e=>props.togglePasswordDisplay(e, 'password')}
                >
                  Show/hide
                </span>
                    </Form.Group>) : null}

                    {(isAddPage || isResetPage) ? (<Form.Group className="password_block">
                      <Form.Label className='req-star'>
                      {isResetPage ? "Confirm New Password" : "Confirm Password"} {showRequiredStarMark()}  
                      </Form.Label>
                      <Field
                        name='confirm_password'
                        id='confirm_password'
                        type={props.viewPassword.confirm_password ? 'text' : 'password'}
                        //defaultValue={formData.confirm_password}
                        className='form-control'
                      />
                      <ErrorMessage
                        name='confirm_password'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                      <span
                        role='button'
                        tabIndex='-1'
                        className={props.viewPassword.confirm_password ? 'password_show' : 'password_hide'}
                        onClick={e=>props.togglePasswordDisplay(e, 'confirm_password')}
                      >
                        Show/hide
                      </span>
                    </Form.Group>) : null}                    
                   
                  </Form>
                  <div>                          
                    <Link to="/dashboard/users">
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'                      
                      /*disabled={props.isProcessing}*/
                    >
                      Cancel
                    </Button></Link>
                    {!isViewPage ? (<Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      disabled={formik.isSubmitting || props.isProcessing}
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null} 
                      {isAddPage ? TEXT_ADD : TEXT_UPDATE}                     
                    </Button>) : (<><Button
                      variant='primary'
                      className='submit-button'
                      type='button' 
                      onClick={e => props.onClickDeleteUser(e)}
                      /*disabled={props.isProcessing} */                                                        
                    >{props.isProcessing ? <ShowSpinner buttonSpinner={true} /> : null}{TEXT_DELETE}</Button>
                     <Link to={resetPasswordLink}><Button
                      variant='primary'
                      className='submit-button ml-2'
                      type='button'                                    
                    >{TEXT_RESET_PASSWORD}</Button></Link>
                    </>)}                                      
                  </div>
                </FormikForm>
              )}
            </Formik>
            {showDeleteUserPopup()}
          </Card.Body>
        </Card>
      </Col>
    </Row>    
  )
}

export default AddUserComponent
