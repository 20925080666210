import React, { Component } from 'react'
import { NotFound, UserNotFound } from '../../components'

class ErrorPage extends Component {
  state = { redirect: false }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ redirect: true })
    }, 3000)
  }

  render() {
    const { redirect } = this.state
    const { url } = this.props.match
    if (url && url.includes('user-notfound')) {
      // Failed retrieve user Info
      return <UserNotFound />
    }
    // 404 - Page not found
    return <NotFound url={url} redirect={redirect} />
  }
}

export default ErrorPage
