// Auth
export const AUTH_TOKEN_NAME = 'token'
export const AUTH_REFRESH_TOKEN_NAME = 'refresh_token'
export const SESSION_EXPIRY_TIMER = 1800 // 30 minutes
export const SESSION_REFRESH_TIME = 60 // 60 seconds
export const IS_TIMER_PAUSED = 'isTimerPaused'
export const SESSION_MODAL_CONTAINER = 'sessionModalContainer'
export const TOKEN_KEY = window.ENV.token_key

// User roles
export const USER_ROLE_NAME = 'role'
export const USER_ROLE_SUPER_ADMIN = 'SUPER_ADMIN'
export const USER_ROLE_AGENT = 'AGENT'
export const USER_ROLE_PROGRAM_MANAGER = 'PROGRAM_MANAGER'
export const USER_ROLE_PROGRAM_DIRECTOR = 'PROGRAM_DIRECTOR'

// LocalStorage
export const LOCAL_STORAGE_USER_DATA = 'USER_DATA'
export const USER_DETAILS = 'USER_DETAILS'
export const LOCAL_STORAGE_ALL_FIELDS = 'ALL_FIELDS'
export const LOCAL_STORAGE_TARGET_CATEGORIES = 'TARGET_CATEGORIES'
export const LOCAL_STORAGE_USER_PROFILE = 'USER_PROFILE'
export const LOCAL_STORAGE_USER_ACTIVITY = 'USER_ACTIVITY'
export const LOCAL_STORAGE_USERS = 'USERS_ALL'
export const LOCAL_STORAGE_USERS_QUERY = 'USERS_QUERY_ALL'
export const LOCAL_STORAGE_PROGRAMS = 'PROGRAMS_ALL'
export const LOCAL_STORAGE_PROGRAMS_QUERY = 'PROGRAMS_QUERY_ALL'
export const LOCAL_STORAGE_METRICS = 'METRICS_ALL'
export const LOCAL_STORAGE_4_WEEKS_METRICS = '4_WEEKS_METRICS'
export const LOCAL_STORAGE_METRICS_AGGREGATE = 'METRICS_AGGREGATE'
export const LOCAL_STORAGE_AGENTS = 'AGENTS_ALL'
export const LOCAL_STORAGE_PROGRAM_MANAGER = 'PROGRAM_MANAGER_ALL'
export const LOCAL_STORAGE_COACHING = 'GET_COACHING'
export const LOCAL_STORAGE_LIST_COACHING = 'LIST_COACHING'
export const LOCAL_STORAGE_COACHING_FOLDER = 'COACHING_FOLDER'
export const LOCAL_STORAGE_NOTES_FOLDER = 'NOTES_FOLDER'
export const LOCAL_STORAGE_METRICS_FOLDER = 'METRICS_FOLDER'
export const LOCAL_STORAGE_NOTES = 'NOTES'
export const LOCAL_STORAGE_DASHBOARD = 'DASHBOARD_ALL'

export const LOCAL_STORAGE_USER_URL = 'USER_URL'
export const LOCAL_STORAGE_SELECTED_PROGRAM = 'SELECTED_PROGRAM'
export const LOCAL_STORAGE_SELECTED_AGENT = 'SELECTED_AGENT'
export const LOCAL_STORAGE_SAVED_COACHING = 'SAVED_COACHING'
export const LOCAL_STORAGE_SELECTED_WEEK = 'SELECTED_WEEK'
export const LOCAL_STORAGE_SELECTED_TEAM_TYPE = 'SELECTED_TEAM_TYPE'
export const LOCAL_STORAGE_SELECTED_VIEW_TYPE = 'SELECTED_VIEW_TYPE'

// SessionStorage
export const FIELDS_MAPPING_STORAGE = 'FIELDS_MAPPING'
export const SELECTED_PLAN_STORAGE = 'SELECTED_PLAN'

// API
export const API_TIMEOUT = 250000

export const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}
export const shortDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}
export const activeUntillDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}
export const ARRAY_COACHING_TYPES = ['GlowTouch Coaching', 'Others']
export const ARRAY_PROGRAM_TYPES = ['Calls', 'Chat', 'Both']
export const ARRAY_DATA_TYPES = ['String', 'Number', 'Boolean']
export const ARRAY_AGGREGATION_TYPES = ['None', 'Sum', 'Average', 'Count']
export const ARRAY_STATUS_TYPES = ['Active', 'Inactive']
export const ARRAY_USER_ROLES = [
  { role_name: 'AGENT', role_description: 'Agent' },
  { role_name: 'PROGRAM_MANAGER', role_description: 'Program Manager' },
  { role_name: 'PROGRAM_DIRECTOR', role_description: 'Program Director' }
]
export const METRIC_HEADER_EMP_CODE = 'Employee Code'
export const METRIC_HEADER_TEAM = 'Team'
export const METRIC_HEADER_AT_RISK = 'ES At Risk (Y/N)'
export const METRIC_HEADER_EMP_NAME = 'Employee NAME'
export const TEXT_NOT_SPECIFIED = 'Not Specified'

export const TEXT_ADD = 'Add'
export const TEXT_SAVE = 'Save'
export const TEXT_EDIT = 'Edit'
export const TEXT_UPDATE = 'Update'
export const TEXT_VIEW = 'View'
export const TEXT_DELETE = 'Delete'
export const TEXT_RESET_PASSWORD = 'Reset Password'

export const ADD_MODE = 'ADD'
export const EDIT_MODE = 'EDIT'
export const DELETE_MODE = 'DELETE'
export const VIEW_MODE = 'VIEW'
export const LIST_MODE = 'LIST'
export const RESET_PASSWORD_MODE = 'RESET_PASSWORD'
