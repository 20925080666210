/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import ManageMetricsForm from '../../components/dashboard/ManageMetricsForm'
import {
  isAdmin,
  isPm,
  isProgramDirector,
  getUserData,
  setUserData,
  updateStoredProgramMetrics,
  deleteData
} from '../../helpers'
import { programService } from '../../services'
import { 
  ProgramSelectBar, 
  UnauthorizedCard,
  OverlayLoader
} from '../../components'
import { LOCAL_STORAGE_SELECTED_PROGRAM, LOCAL_STORAGE_METRICS_FOLDER } from '../../constants'
import { Redirect } from 'react-router-dom'

/**
 * Class which manages program metrics. User can select a program and
 * update its metrics
 */
class ManageMetrics extends Component {
  state = {
    programs: [], // program manager programs list
    programOptions: [], // programs drop down options
    selectedProgram: null, // selected program in the drop down
    metricTableData: [], // metric list displayed in the table
    isLoading: false,
    showAlert: false,
    isFetchingProgram: false, // to indicate program details fetching
    isAuthorizedAccess:null,
    isProcessing: false,    
  }

  abortController = new AbortController()

  componentDidMount() {
   if(!isPm() && !isProgramDirector()) {   
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.props.updateHeaderTxt('Manage Program Metrics', 'Settings')
      this.props.programBar.showAgents = false
      this.props.programBar.showWeeks = false
      this.props.programBar.showDateRange = false
      this.props.programBar.onPmChangeHook = this.onPmChange
  
      this.setState(state => ({
        ...state,
        isFetchingProgram: true,
        isAuthorizedAccess: true,        
      }))
  
      const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
      this.setFetchingProgramState(true)
      // this.getProgramDetails(selectedProgram.public_id)
  
      this.getProgramDetails(selectedProgram.program.public_id)
    }    
  }
  
  getDropDownProgram = program => {
    const dropDownProgram = {}

    dropDownProgram.value = program.program_name
    dropDownProgram.label = program.program_name
    dropDownProgram.id = program.public_id
    dropDownProgram.program = program

    return dropDownProgram
  }

  onPmChange = data => {
    this.setFetchingProgramState(true)
    this.getProgramDetails(data.program.public_id)
  }

  setFetchingProgramState = isFetching => {
    this.setState(state => ({
      ...state,
      isFetchingProgram: isFetching
    }))
  }

  getProgramDetails = programPublicId => {
    if (programPublicId) {
      programService
        .get_program_by_id(programPublicId)
        .then(data => {
          data.metrics.sort((a, b) => (a.priority > b.priority ? 1 : -1))
          this.setState(state => ({
            ...state,
            isFetchingProgram: false,
            metricTableData: data.metrics,
            selectedProgram: this.getDropDownProgram(data)
          }))
        })
        .catch(() => {
          // display error toast
          toast.error('Error while fetching Program details')
          this.setFetchingProgramState(false)
        })
    }
  }

  updateMetricsTable = tableData => {
    this.setState(state => ({
      ...state,
      metricTableData: tableData
    }))
  }

  showLoading = () => {
    if (this.state.isLoading) {      
      return <OverlayLoader />
    }
    return null
  }

  validateMetricName = (data) => {     
    let result = false   
    if (/[`~,.;':"\\[\]|{}=+]/.test(data)){
      result = true
    }
    return result  
  }

  handleSubmit = (data, setSubmitting) => {
    if (!this.state.selectedProgram) {
      setSubmitting(false)
      toast.error('Please select a Program')
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
      return
    }

    if (this.state.metricTableData.length === 0) {
      setSubmitting(false)
      toast.error('Please add Metrics details')
      return
    }

    const metricsData = this.state.metricTableData
    let flagMetricNameError = false
    let isValidMetricName = true
    metricsData.map(row => {
      if (row.metrics_name === '') {
        flagMetricNameError = true
        return true
      } else {
        if(this.validateMetricName(row.metrics_name)) {
          isValidMetricName = false
          return true
        }
      }
    })

    if (flagMetricNameError) {
      setSubmitting(false)
      toast.error('Please enter metrics name')
      return
    }

    if (!isValidMetricName) {
      setSubmitting(false)
      toast.error('Metrics name should be alpha numerics')
      return
    }

    const selectedProgram = { ...this.state.selectedProgram.program }
    const selectedAgentIds = []
    selectedProgram.agents.forEach(agent => {
      selectedAgentIds.push(agent.public_id)
    })

    const selectedPmIds = []
    selectedProgram.program_managers.forEach(pm => {
      selectedPmIds.push(pm.public_id)
    })

    const selectedPdIds = []
    selectedProgram.program_directors.forEach(pd => {
      selectedPdIds.push(pd.public_id)
    })

    // TODO: this is only for testing, as some of the
    // projects don't have actual data url. Remove it in Production
    if (!selectedProgram.calls_url) {
      selectedProgram.calls_url = ''
    }
    if (!selectedProgram.coaching_type) {
      selectedProgram.coaching_type = ''
    }
    if (!selectedProgram.coaching_url) {
      selectedProgram.coaching_url = ''
    }

    const objExisting = {
      public_id: selectedProgram.public_id,
      program_name: selectedProgram.program_name,
      program_type: selectedProgram.program_type,
      status: selectedProgram.status,
      calls_url: selectedProgram.calls_url,
      coaching_type: selectedProgram.coaching_type,
      coaching_url: selectedProgram.coaching_url,
      agent_ids: selectedAgentIds,
      program_manager_ids: selectedPmIds,
      program_director_ids: selectedPdIds,
      metrics: this.state.metricTableData
    }

    const objSpread = {
      ...selectedProgram,
      metrics: this.state.metricTableData
    }

    this.setState(state => ({
      ...state,
      isProcessing: true
    }))

    programService
      .add_program({
        public_id: selectedProgram.public_id,
        program_name: selectedProgram.program_name,
        program_type: selectedProgram.program_type,
        status: selectedProgram.status,
        calls_url: selectedProgram.calls_url,
        coaching_type: selectedProgram.coaching_type,
        coaching_url: selectedProgram.coaching_url,
        agent_ids: selectedAgentIds,
        program_manager_ids: selectedPmIds,
        program_director_ids: selectedPdIds,
        metrics: this.state.metricTableData
      })
      .then(response => {
        setSubmitting(false)
        this.setState(state => ({
          ...state,
          showAlert: true,
          isLoading: false
        }))
        const selectedProgram1 = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
        selectedProgram1.program = response
        this.props.programBar.selectedProgram.program = response
        setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram1)
        // updated the metric details in stored program
        updateStoredProgramMetrics(
          selectedProgram.public_id,
          this.state.metricTableData
        )        

        toast.success('Successfully updated the Metrics.')

        setTimeout(() => {
          this.setState(state => ({
            ...state,
            showAlert: false,
            isProcessing: false
          }))
        }, 6000)
      })
      .catch(error => {
        setSubmitting(false)
        this.setState(state => ({
          ...state,         
          isProcessing: false
        }))
        toast.error('Error while updating Program Metrics Header')
      })

    setSubmitting(true)
  }

  setShow = () => {
    this.setState(state => ({
      ...state,
      showAlert: false
    }))
  }

  render() {
    const { isAuthorizedAccess, isLoading, isFetchingProgram, isProcessing } = this.state
    if(isLoading || isFetchingProgram || isAuthorizedAccess === null) {     
      return <OverlayLoader />
    }

    if(isAuthorizedAccess) {
      return (<>
      {isProcessing ? <OverlayLoader /> : null}
      <div>
        <ProgramSelectBar programBar={this.props.programBar} />
      </div>
      <Row>
        <Col>
          <ErrorBoundary>
            {/*this.showLoading()*/}              
            <ManageMetricsForm              
              selectedProgram={this.state.selectedProgram}
              //isLoading={this.state.isLoading}
              handleSubmit={this.handleSubmit}
              showAlert={this.state.showAlert}
              onPmChange={this.onPmChange}
              metricTableData={this.state.metricTableData}
              updateMetricsTable={this.updateMetricsTable}
              //isFetchingProgram={this.state.isFetchingProgram}
              mode={2}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      </>)
    } else {
      return <UnauthorizedCard />
    } 
  }
}

export default ManageMetrics
