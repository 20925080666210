import { any } from 'prop-types'
import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_PROGRAM,
  GET_PM_PROGRAM,
  GET_DIRECTOR_PROGRAM,
  GET_AGENT_PROGRAM,
  LOCAL_STORAGE_PROGRAMS,
  LOCAL_STORAGE_PROGRAMS_QUERY,
  LOCAL_STORAGE_METRICS_FOLDER,
  LOCAL_STORAGE_COACHING_FOLDER,
  LOCAL_STORAGE_DASHBOARD  
} from '../constants'
import { getUserData, setUserData, getUserId, deleteData,
  isAdmin,
  isPm,
  isAgent,
  isProgramDirector } from '../helpers'

const options = AppService.options()

export const programService = {
  get_programs: async signal => {
    const programs = getUserData(LOCAL_STORAGE_PROGRAMS)

    if (!programs) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_PROGRAM,
        { ...HTTPHeaders() }
      )

      setUserData(LOCAL_STORAGE_PROGRAMS, response)

      return response
    }
    return programs
  },
  get_paginated_programs: async (query = '?page_size=20&page_no=2') => {
    let programs = getUserData(LOCAL_STORAGE_PROGRAMS_QUERY)

    if (!programs) {
      programs = {}
    }

    if (!programs[query]) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_PROGRAM + query,
        { ...HTTPHeaders() }
      )
      programs[query] = response
      setUserData(LOCAL_STORAGE_PROGRAMS_QUERY, programs)

      return response
    }
    return programs[query]
  },
  add_program: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + GET_PROGRAM, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_METRICS_FOLDER)
    deleteData(LOCAL_STORAGE_COACHING_FOLDER)
    deleteData(LOCAL_STORAGE_DASHBOARD)
    deleteData(LOCAL_STORAGE_PROGRAMS + data.public_id)

    return response
  },
  get_program_by_id: async publicId => {
    const programs = getUserData(LOCAL_STORAGE_PROGRAMS + publicId)
    if (!programs) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_PROGRAM + publicId,
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_PROGRAMS + publicId, response)
      return response
    }
    return programs
  },
  get_pm_programs: async () => {
    let url = GET_PM_PROGRAM
    if (isProgramDirector()) {
      url = GET_DIRECTOR_PROGRAM
    }
    const programs = getUserData(LOCAL_STORAGE_PROGRAMS + getUserId())
    if (!programs) {
      const response = await AppService.makeRequest(
        BASE_API_URL + url + getUserId(),
        { ...HTTPHeaders() }
      )

      setUserData(LOCAL_STORAGE_PROGRAMS + getUserId(), response)
      return response
    }
    return programs
  },
  get_Agent_by_program_id: async (programId, agentId) => {
    let program = getUserData(LOCAL_STORAGE_PROGRAMS + programId)

    if (!program) {
      program = await AppService.makeRequest(
        BASE_API_URL + GET_PROGRAM + programId,
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_PROGRAMS + programId, program)
    }
    let agentObj = null
    if (program.agents) {
      program.agents.map(agent => {
        if (agent.public_id === agentId) {
          agentObj = agent
        }
      })
    }
    return agentObj
  },
  get_agent_programs: async () => {
    const programs = getUserData(LOCAL_STORAGE_PROGRAMS + getUserId())
    if (!programs) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_AGENT_PROGRAM + getUserId(),
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_PROGRAMS + getUserId(), response)
      return response
    }
    return programs
  }
}
