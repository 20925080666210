export const weekModifier = (onDayClick, onDayMouseEnter, onWeekClick) => {
  return {
    selectedDays: [],
    hoverRange: [],
    showWeekNumbers: true,
    placeholder: 'Select Start Date',
    onDayClick,
    onDayMouseEnter,
    onWeekClick,
    value: 'Select Week',
    modifiers: {
      hoverRange: {},
      selectedRange: {}
    }
  }
}
