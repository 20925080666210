import { AppService, HTTPHeaders } from '.'
import { BASE_API_URL, GET_AGENT_ALL, LOCAL_STORAGE_AGENTS } from '../constants'
import { getUserData, setUserData } from '../helpers'

export const agentService = {
  get_agents: async signal => {
    const agents = getUserData(LOCAL_STORAGE_AGENTS)    
    if (!agents) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_AGENT_ALL,
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_AGENTS, response)

      return response
    }
    return agents
  }
}
