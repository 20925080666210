import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import { ResetPasswordFrom, OverlayLoader } from '../../components'
import { userService } from '../../services'

class ResetPassword extends Component {
  state = {
    submitted: false,
    loggedIn: false,
    password: '',
    viewPassword: false,
    isLoading: true,
    invalidResetCode: false
  }

  handleResetPassword = formdata => {
    this.setState(state => ({ ...state, submitted: true }))
    userService
      .resetPassword({
        password: formdata.password,
        conf: this.props.match.params.id // conf is received as query string
      })
      .then(() => {
        this.setState({ submitted: false })
      })
      .catch(error => {
        this.setState({ submitted: false })
        toast.error(error.message)
      })
  }

  handleBack = () => {
    this.setState(state => ({
      ...state,
      submitted: false
    }))
  }

  togglePasswordDisplay = () => {
    this.setState(state => ({
      ...state,
      viewPassword: !state.viewPassword
    }))
  }

  componentDidMount() {
    userService
      .validateResetCode({
        conf: this.props.match.params.id // conf is received as query string
      })
      .then(values => {
        if (!values) {
          this.setState(state => ({
            ...state,
            invalidResetCode: true
          }))
          toast.error('Link is invalid or expired')
        } else {
          this.setState(state => ({
            ...state,
            isLoading: false
          }))
        }
      })
      .catch(() => {})
  }

  render() {
    const { viewPassword, submitted, isLoading, invalidResetCode } = this.state
    if (invalidResetCode) {
      return <Redirect to='/' />
    }
    return (
      <div>
        {isLoading ? <OverlayLoader /> : (
          <ResetPasswordFrom
            onInputChange={this.onInputChange}
            handleResetPassword={this.handleResetPassword}
            isSubmitted={submitted}
            viewPassword={viewPassword}
            togglePasswordDisplay={this.togglePasswordDisplay}
          />
        )}
      </div>
    )
  }
}

export default ResetPassword
