import { ExpandMoreRounded } from '@material-ui/icons'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { components } from 'react-select'
import { SelectFetch } from 'react-select-fetch'
import * as Yup from 'yup'
import {
  BASE_API_URL
} from '../../constants'
import ShowSpinner from './ShowSpinner'
import '../../styles/base.scss'

const ChangePasswordForm = ({ ...props }) => { 
  const formData = props.formData 
 
  // Schema for yup
  let objValidations = {}
  objValidations['old_password'] = Yup.string() 
  .required('Old password is required')   
  .max(12, 'Maximum 12 characters')
  .min(8, 'Minimum 8 characters')  

  objValidations['password'] = Yup.string() 
  .required('Password is required')   
  .max(12, 'Maximum 12 characters')
  .min(8, 'Minimum 8 characters')
  .notOneOf([Yup.ref('old_password')],'New password and old password can not be same')

  objValidations['confirm_password'] = Yup.string() 
  .required('Confirm password is required')   
  .max(12, 'Maximum 12 characters')
  .min(8, 'Minimum 8 characters')
  .oneOf([Yup.ref('password')],'New password and confirm new password must match')

  const validationSchema = Yup.object().shape(objValidations) 

  // we get it with 2nd render and not with first
  useEffect(() => {
  }, [])       
  
  const showRequiredStarMark = () => {    
      return <span className="text-danger"> *</span>    
  }   
  
  return (
    <Row className='justify-content-center mt-5'>
      <Col md={4}>
        <Card className="card-form">       
          <Card.Body className='user-profile-card-body'>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={validationSchema}              
              onSubmit={(values, { setSubmitting }) => {                
                props.handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}                  
                  <Form > 
                  <Form.Group className="password_block">
                      <Form.Label className='req-star'>
                      Old Password {showRequiredStarMark()}  
                      </Form.Label>
                      <Field
                        name='old_password'
                        id='old_password'
                        type={props.viewPassword.old_password ? 'text' : 'password'}                        
                        className='form-control'
                      />
                      <ErrorMessage
                        name='old_password'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                      <span
                        role='button'
                        tabIndex='-1'
                        className={props.viewPassword.old_password ? 'password_show' : 'password_hide'}
                        onClick={e=>props.togglePasswordDisplay(e, 'old_password')}
                      >Show/hide</span>
                    </Form.Group>
                    <Form.Group className='password_block'>
                      <Form.Label className='req-star'>
                        New Password {showRequiredStarMark()}  
                      </Form.Label>
                      <Field
                        name='password'
                        id='password'
                        type={props.viewPassword.password ? 'text' : 'password'}
                        //defaultValue={formData.password}
                        className='form-control'
                      />
                      <ErrorMessage
                        name='password'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                      <span
                        role='button'
                        tabIndex='-1'
                        className={props.viewPassword.password ? 'password_show' : 'password_hide'}
                        onClick={e=>props.togglePasswordDisplay(e, 'password')}
                      >Show/hide</span>
                    </Form.Group>

                    <Form.Group className="password_block">
                      <Form.Label className='req-star'>
                      Confirm New Password {showRequiredStarMark()}  
                      </Form.Label>
                      <Field
                        name='confirm_password'
                        id='confirm_password'
                        type={props.viewPassword.confirm_password ? 'text' : 'password'}
                        //defaultValue={formData.confirm_password}
                        className='form-control'
                      />
                      <ErrorMessage
                        name='confirm_password'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                      <span
                        role='button'
                        tabIndex='-1'
                        className={props.viewPassword.confirm_password ? 'password_show' : 'password_hide'}
                        onClick={e=>props.togglePasswordDisplay(e, 'confirm_password')}
                      >Show/hide</span>
                    </Form.Group>           
                   
                  </Form>
                  <div>
                  <Link to="/dashboard/">
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'
                      disabled={(formik.isSubmitting || props.isSubmit)}                       
                    >
                      Cancel
                    </Button></Link>
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      disabled={formik.isSubmitting || props.isSubmit}
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Submit                      
                    </Button>
                    
                  </div>
                </FormikForm>
              )}
            </Formik>            
          </Card.Body>
        </Card>
      </Col>
    </Row>    
  )
}

export default ChangePasswordForm
