import base from '..'

export default {
  ...base, // inherit from base settings

  // customization goes here...
  brandName: 'Glowtouch',
  logo: {
    src: '/assets/img/logo.png',
    dashboardSrc: '/assets/img/dashboard-logo.png',
    alt: 'Glowtouch CCMS'
  },
  supportEmail: 'ccms-support@glowtouch.com',
  siteUrl: 'https://ccms.com/'
}
