import { RegionDropdown } from 'react-country-region-selector'
import { CountryRegionData } from '../constants/region.data'

class CustomRegionDropdown extends RegionDropdown {
  // eslint-disable-next-line class-methods-use-this
  getRegions(country) {
    if (!country) {
      return []
    }

    let regions = []

    regions = CountryRegionData.regions

    return regions.map(regionPair => {
      const regionName = regionPair.name
      const regionShortCode = regionPair.shortCode

      return { regionName, regionShortCode }
    })
  }
}

export default CustomRegionDropdown
