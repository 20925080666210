// eslint-disable-next-line import/no-extraneous-dependencies
import '@babel/polyfill'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { redirectNonWWW } from './helpers'
import { ScrollToTop } from './components'

// redirect traffic to non-www
redirectNonWWW()

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
