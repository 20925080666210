/* eslint-disable no-param-reassign */
import React, { Component } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import moment from 'moment'
import qs from 'querystringify'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import {
  isAgent,
  isPm,
  isProgramDirector,
  getAgentDisplayName,
  getAgentWeeklyProgramMetrics,
  getUserData,
  setUserData,
  getPreviousWeekDateRange,
  getAgentKeyMetrics,
  formatCoachingDates,
  populateAgentList,
  getWeekDateRange
} from '../../helpers'
import {
  AddCoachingMainComponent,
  AddCoachingWeeksComponent,
  UnauthorizedCard,
  OverlayLoader,
  ShowSpinner
} from '../../components'
import {
  LOCAL_STORAGE_SAVED_COACHING,
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_SELECTED_AGENT,
  PROGRAM_FETCH_ERROR
} from '../../constants'

import {
  coachingState,
  loadWeeks,
  assignMainForm,
  assignMainForm1,
  assignConversationForm,
  assignWeekForm
} from '../../constants/coaching.state.constants'
import { coachingService, metricsService, programService } from '../../services'

class AddCoaching extends Component {
  handleConversationSubmit = async (data, setSubmitting) => {
    const formData = {
      ...this.state.formData,
      conversation: assignConversationForm(data)
    }

    if (data.save === true) {
      data.conversation = true
      this.saveData(formData, data, setSubmitting)
    }

    if (data.traverse === -1) {
      const currentWeek = this.state.formData.weeks[
        this.state.formData.weeks.length + data.traverse
      ]
      this.setState(state => ({
        ...state,
        isCreatePage: false,
        isConversationPage: false,
        formData: {
          ...state.formData,
          ...formData,
          currentWeek: { ...currentWeek, formData }
        }
      }))
      setUserData(
        LOCAL_STORAGE_SAVED_COACHING +
          this.state.formData.program_id +
          this.state.formData.agent_id,
        this.state.formData
      )
    }
  }

  handleWeekSubmit = async (data, setSubmitting) => {
    const weekNo = this.state.formData.currentWeek.week_no
    setSubmitting(true)
    const { weeks } = this.state.formData

    weeks[weekNo] = assignWeekForm(data, weeks, weekNo)
    const currentWeek = this.state.formData.weeks[weekNo + data.traverse]
    const mainForm = assignMainForm1(
      this.state.formData,
      data,
      this.state.formData.weeks
    )

    const formData = {
      ...mainForm,
      weeks,
      currentWeek
    }
    if (data.save === true) {
      this.saveData(formData, data, setSubmitting)
    }

    let isCreatePage = false

    if (currentWeek === undefined) {
      if (data.traverse === -1) {
        isCreatePage = true
      } else {
        isCreatePage = false
      }
    }

    this.setState(state => ({
      ...state,
      isCreatePage,
      currentWeek,
      formData: {
        ...formData
      }
    }))
    setUserData(
      LOCAL_STORAGE_SAVED_COACHING + formData.program_id + formData.agent_id,
      formData
    )
  }

  handleCoachingDateChange = date => {
    this.setState(state => ({
      ...state,
      loadingKeyMetrics: true
    }))

    const week = getWeekDateRange(date)
    const programId = this.state.selectedProgram.value || null
    const agent = this.state.selectedAgent
    const agentId = agent ? agent.value !== '' ? agent.value : null : null

    const query = `?year=${week.startYear}&week=${week.weekNo}&program_id=${programId}&agent_id=${agentId}`
    metricsService.get_4_weeks_program_metrics(query).then(metricData => {
      this.setState(state => ({
        ...state,
        loadingKeyMetrics: false
      }))

      for (let i = 0; i < 4; i++) {
        const data11 = metricData[i]
        const { weeks } = this.state.formData
        const week1 = {
          ...weeks[i],
          key_metrics: data11.key_value
        }
        weeks[i] = week1
        this.setState(state => ({
          ...state,
          weeks
        }))
      }
    })
  }

  saveData = (formData, data, setSubmitting) => {
    formData.coaching_end_date = moment(
      formData.coaching_end_date,
      true
    ).format('YYYY-MM-DD')
    if (formData.coaching_end_date.toString() === 'Invalid date')
      formData.coaching_end_date = ''

    formData.call_date = moment(formData.call_date, true).format('YYYY-MM-DD')
    if (formData.call_date.toString() === 'Invalid date') {
      formData.call_date = ''
    }
    formData.weeks[0].coaching_date = formData.coaching_start_date
    
    formData.coaching_start_date = moment(
      formData.coaching_start_date,
      true
    ).format('YYYY-MM-DD')

    formData.weeks[0].coaching_date = formData.coaching_start_date

    if (formData.coaching_start_date.toString() === 'Invalid date')
      formData.coaching_start_date = ''
    let foundActive1 = false
    formData.weeks.map(week => {
      week.coaching_date = moment(week.coaching_date, true).format('YYYY-MM-DD')
      if (week.coaching_date.toString() === 'Invalid date')
        week.coaching_date = ''
      week.call_date = moment(week.call_date, true).format('YYYY-MM-DD')
      if (week.call_date.toString() === 'Invalid date') week.call_date = ''

      if (week.coaching_time) {
        if (week.coaching_time.isValid())
          week.coaching_time = week.coaching_time.format('h:mm a')
        else week.coaching_time = ''
      } else {
        week.coaching_time = ''
      }

      week.followup_date = moment(week.followup_date, true).format('YYYY-MM-DD')
      if (week.followup_date.toString() === 'Invalid date')
        week.followup_date = ''
      if (week.is_completed == null) week.is_completed = false
      if (week.was_resolved == null) week.was_resolved = false

      if (week.agent_recap == null) {
        week.agent_recap = ''
      }
      if (week.followup_date == null) {
        week.followup_date = ''
      }
      if (week.is_completed) {
        week.is_active = 'Completed'
      } else if (foundActive1) {
        week.is_active = ''
      } else {
        foundActive1 = true
        week.is_active = 'Active'
      }

      return week
    })
    this.setState(state => ({
      ...state,
      isLoading: true
    }))

    coachingService
      .save_coaching(formData)
      .then(response => {
        formatCoachingDates(response.coaching_plan)        
        const { weeks } = response.coaching_plan
        let isCreatePage = true
        if (response.coaching_plan.weeks) {
          let foundActive = false          
          response.coaching_plan.weeks.map(week1 => {
            if (!(week1.call_date instanceof Date)) {
              week1.call_date = ''
            }
            if (week1.is_completed) {
              week1.is_active = 'Completed'
            } else if (foundActive) {
              week1.is_active = ''
            } else {
              foundActive = true
              week1.is_active = 'Active'
              response.coaching_plan.currentWeek = week1
              isCreatePage = false
            }
            return week1
          })          
        }

        setSubmitting(false)
        this.setState(state => ({
          ...state,
          isLoading: false,
          showAlert: false,
          isCreatePage,
          formData: {
            ...state.formData,
            ...response.coaching_plan,
            weeks,
            currentWeek: response.coaching_plan.currentWeek
          }
        }))        
        this.handleCoachingDateChange(formData.coaching_start_date)

        if (data.proceed === true) {
          this.setState(state => ({
            ...state,
            isCreatePage: false,
            formData: {
              ...state.formData,
              ...response.coaching_plan,
              currentWeek: state.formData.weeks[0]
            }
          }))
        }
        
        if (data.close) {
          toast.success(
            'Successfully saved Coaching Plan. You will be redirect to Coaching Page in 10 seconds'
          )
          setTimeout(() => {
            this.props.history.replace('/dashboard/coaching/')
          }, 6000)
        } else {
          toast.success('Successfully saved Coaching Plan.')
        }
      })
      .catch(error => {
        setSubmitting(false)
        let error_msg = ''
        if (typeof error.coaching_plan !== 'undefined'){
          formatCoachingDates(error.coaching_plan)
          const { weeks } = error.coaching_plan        
          let isCreatePage = true
          if (error.coaching_plan && error.coaching_plan.weeks) {
            let foundActive = false
  
            error.coaching_plan.weeks.map(week1 => {
              if (!(week1.call_date instanceof Date)) {
                week1.call_date = ''
              }
              if (week1.is_completed) {
                week1.is_active = 'Completed'
              } else if (foundActive) {
                week1.is_active = ''
              } else {
                foundActive = true
                week1.is_active = 'Active'
                error.coaching_plan.currentWeek = week1
                isCreatePage = false
              }
              return week1
            })
          }

          error_msg = error.response_message.message
          this.setState(state => ({
            ...state,
            isLoading: false,
            showAlert: false,
            isCreatePage,
            formData: {
              ...state.formData,
              ...error.coaching_plan,
              weeks,
              currentWeek: error.coaching_plan.currentWeek
            }
          }))           
        } else {
          error_msg = 'Input payload validation failed'
          const { weeks } = formData.weeks   
          const { currentWeek } = formData.currentWeek   
          let isCreatePage = true          
          this.setState(state => ({
            ...state,
            showAlert: false,
            isLoading: false,
            isCreatePage,
            formData: {
              ...state.formData,
              ...error.coaching_plan,
              weeks,
              currentWeek,
            }            
          })) 
        }
        toast.error(
          'Error while creating coaching',
          error_msg
        )
        setTimeout(() => {
          this.setState(
            ...coachingState
          )
          this.props.history.replace('/dashboard/coaching/')
        }, 1000)        
      })
  }

  handleSubmit = async (data, setSubmitting) => {    
    setSubmitting(true)
    const formData = assignMainForm(
      this.state.formData,
      data,
      this.state.formData.weeks
    )
    
    if (data.save === true) {
      this.saveData(formData, data, setSubmitting)
    }
    if (data.navigate_page > 0) {
      this.setState(state => ({
        ...state,
        isCreatePage: false,
        formData: {
          ...state.formData,
          ...formData,
          currentWeek: state.formData.weeks[data.navigate_page - 1]
        }
      }))
    }
  }

  state = {
    ...coachingState
  }

  setShow = () => {
    this.setState(state => ({
      ...state,
      showAlert: false
    }))
  }

  abortController = new AbortController()

  onPmChangeHook = program => {
    this.setState(state => ({
      ...state,
      selectedProgram: program
    }))
  }

  populateAgentOptions = program => {
    const agentOptions = []
    const label = isAgent() ? 'Select' : 'All'
    agentOptions.push({
      value: '',
      label,
      agent: {}
    })

    if (program.agents) {
      program.agents.forEach(item => {
        agentOptions.push({
          value: item.public_id,
          label: getAgentDisplayName(item),
          agent: item
        })
      })
    }

    return agentOptions
  }

  onPmChangeLocal = selectedProgram => {
    if (selectedProgram.value) {
      this.setState(state => ({
        ...state,
        selectedProgram,
        isFetchingProgram: true
      }))

      programService
        .get_program_by_id(selectedProgram.value)
        .then(data => {
          const agentOptions = this.populateAgentOptions(data)
          this.setState(state => ({
            ...state,
            selectedProgram,
            isFetchingProgram: false,
            agentOptions,
            selectedAgent: agentOptions[0]
          }))
        })
        .catch(() => {
          // display error toast
          toast.error(PROGRAM_FETCH_ERROR)
          this.setState(state => ({
            ...state,
            isFetchingProgram: false
          }))
        })
    }
  }

  onAgentChangeLocal = agent => {
    const programId = this.state.selectedProgram.value || null
    const agentId = agent.value || null
    this.fetchData(programId, agentId)

    this.setState(state => ({
      ...state,
      selectedAgent: agent,
      formData: {
        ...state.formData,
        agent_id: agentId
      }
    }))
  }

  onAgentChangeHook = () => {
    const programId = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM).value || null
    const agentId = getUserData(LOCAL_STORAGE_SELECTED_AGENT).value || null

    this.fetchData(programId, agentId)
    this.setState(state => ({
      ...state,
      selectedProgram: this.props.programBar.selectedProgram,
      selectedAgent: this.props.programBar.selectedAgent
    }))
    this.props.programBar.setEnableSelection(true)
  }

  onCoachingDateChange = () => {}

  fetchData = (programId, agentId, weekNo, publicId) => {    
    // this week is used for fetching Key Metrics
    const week = getPreviousWeekDateRange()

    if (
      (programId !== null && agentId !== null && week != null) ||
      publicId !== null
    ) {      
      this.setState(state => ({
        ...state,
        isLoading: true
      }))
      Promise.all([
        coachingService.get_coaching_by_program_agent(
          programId,
          agentId,
          publicId
        ),
        programService.get_Agent_by_program_id(programId, agentId, publicId),
        getAgentWeeklyProgramMetrics(
          week.startYear,
          week.weekNo,
          programId,
          agentId
        )
      ])
        .then(([data, agent, metricData]) => {
          const { selectedProgram } = this.props.programBar
          const metricHeaders = selectedProgram
            ? selectedProgram.program.metrics
            : []
          const metricsHeaderName = []
          const keymetricHeaders = {}
          metricHeaders.forEach(metricsHeader => {
            if (metricsHeader.is_key_metrics) {
              metricsHeaderName.push(metricsHeader.metrics_name)
              keymetricHeaders[metricsHeader.metrics_name] = metricsHeader
            }
          })

          let message = 'Select Agent'                 
          if (data.data.length > 0) {            
            this.props.updateHeaderTxt('Update Coaching', 'Coachings')
            const data1 = data.data[0]
            
            formatCoachingDates(data1)
            let isCreatePage = true
            data1.navigate_page = 1
            data1.keymetricHeaders = keymetricHeaders
            if (data1.weeks) {
              let foundActive = false

              data1.weeks.map(week1 => {
                if (!(week1.call_date instanceof Date)) {
                  week1.call_date = ''
                }
                if (week1.is_completed) {
                  week1.is_active = 'Completed'
                } else if (foundActive) {
                  // do nothing
                  week1.is_active = ''
                } else {
                  foundActive = true
                  week1.is_active = 'Active'
                  data1.currentWeek = week1
                  isCreatePage = false
                }
                return week1
              })
            }
            if (weekNo != null && data1.weeks) {
              data1.currentWeek = data1.weeks[weekNo]
              isCreatePage = false
            }
            if (agent != null) {
              message = `Update Coaching for ${agent.f_name}`
            }

            this.setState(state => ({
              ...state,
              isLoading: false,
              isCreatePage,
              formData: data1,
              agent,
              message,
              metricsHeaderName
            }))
          } else {            
            this.props.updateHeaderTxt('Add Coaching', 'Coachings')
            const endDate = new Date()
            endDate.setDate(endDate.getDate() + 28)
            
            const formData = {
              ...coachingState.formData,
              program_id: programId,
              agent_id: agentId,
              coaching_start_date: new Date(),
              keymetricHeaders,
              coaching_end_date: endDate // This calculation is only to load new data, on component did mount we need to check if data exists for agent and program
            }

            const { weeks } = this.state.formData    
            if (agent != null) {
              message = `Create New Coaching for ${agent.f_name} ${agent.l_name} - ${agent.email}`
            }
            // get agent key metrics
            const keyMetricData = getAgentKeyMetrics(
              metricData.data,
              metricHeaders
            )

            if (Object.keys(keyMetricData).length > 0) {
              Object.keys(keyMetricData).map(key => {
                formData[key] = keyMetricData[key]
                return key
              })
            }

            loadWeeks(formData, weeks).then(() => {
              weeks[0].is_active = 'Active'
              this.setState(state => ({
                ...state,
                isCreatePage: true,
                isLoading: false,
                formData: {
                  ...formData,
                  plan_name: ''
                },
                agent,
                message,
                metricsHeaderName,
                keyMetricData
              }))
              setUserData(
                LOCAL_STORAGE_SAVED_COACHING +
                  this.state.formData.program_id +
                  this.state.formData.agent_id,
                this.state.formData
              )
            })

            this.handleCoachingDateChange(new Date())
          }
        })
        .catch(error => {
          toast.error('Coaching not found.', error)
        })
    } else {
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
    }
  }

  getData = async () => {
    await this.props.programBar.agentOptions
  }

  async componentDidMount() {
    if (!isPm() && !isProgramDirector()) {
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false
      }))

      setTimeout(() => {
        this.props.history.push({
          pathname: '/dashboard'
        })
      }, 2000)
    } else {
      const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
      this.onPmChangeLocal(selectedProgram)
      this.props.updateHeaderTxt('Add Coaching', 'Coachings')
      const queryParam = this.props.location.search
      let programId = selectedProgram.value || null
      let selectedAgent = null // getUserData(LOCAL_STORAGE_SELECTED_AGENT)
      let agentId = null
      let weekNo = null
      let publicId1 = null
      let coachingType = null
      if (queryParam !== '') {
        const { program, agent, week, id, ctype } = qs.parse(
          this.props.location.search
        )
        
        programId = program
        agentId = agent
        weekNo = week
        publicId1 = id
        coachingType = ctype
        await this.getData()
        // await this.props.programBar.agentOptions
        let { agentOptions } = this.props.programBar
        if (!agentOptions || agentOptions.length === 0) {
          agentOptions = populateAgentList(selectedProgram)
        }
        agentOptions.map(agentOption => {
          if (agentId === agentOption.value) {
            selectedAgent = agentOption
          }
          return agentOption
        })
        this.setState(state => ({
          ...state,
          selectedAgent,
          coachingType
        }))
      }      
      this.fetchData(programId, agentId, weekNo, publicId1)
    }
  }

  executeRender = () => {
    const {
      isCreatePage,
      showAlert,
      formData,
      agent,
      keyMetricData,
      metricsHeaderName,
      isLoading,
      isAuthorizedAccess
    } = this.state
    
    const { programBar } = this.props

    if (isAuthorizedAccess) {
      if (isLoading) {
        return <OverlayLoader />
      }

      if (isCreatePage) {
        return (
          <>
            <Row>
              <Col>
                <ErrorBoundary>
                  <AddCoachingMainComponent
                    setShow={this.setShow}
                    agentOptions={this.state.agentOptions}
                    selectedAgent={this.state.selectedAgent}
                    handleSubmit={this.handleSubmit}
                    onAgentChangeLocal={this.onAgentChangeLocal}
                    onPmChangeLocal={this.onPmChangeLocal}
                    message={this.state.message}
                    data={formData}
                    agent={agent}
                    showAlert={showAlert}
                    programBar={programBar}
                    mode={1}
                    selectedProgram={this.state.selectedProgram}
                    handleCoachingDateChange={this.handleCoachingDateChange}
                    loadingKeyMetrics={this.state.loadingKeyMetrics}
                  />
                </ErrorBoundary>
              </Col>
            </Row>
          </>
        )
      }

      if (this.state.formData.currentWeek) {
        return (
          <Row>
            <Col>
              <ErrorBoundary>
                <AddCoachingWeeksComponent
                  programBar={programBar}
                  message={this.state.message}
                  setShow={this.setShow}
                  handleSubmit={this.handleWeekSubmit}
                  data={{ ...formData.currentWeek, formData }}
                  isLoading={this.state.isLoading}
                  showAlert={showAlert}
                  mode={1}
                  metricsHeaderName={metricsHeaderName}
                  handleCoachingDateChange={this.handleCoachingDateChange}
                  keyMetricData={keyMetricData}
                  loadingKeyMetrics={this.state.loadingKeyMetrics}
                  coachingType={this.state.coachingType}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        )
      }

      return (
        <Row>
          <Col>
            <ErrorBoundary>Loading .....</ErrorBoundary>
          </Col>
        </Row>
      )
    }
    return <UnauthorizedCard />
  }

  render() {
    return this.executeRender()
  }
}

export default AddCoaching
