/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
//import React from 'react'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/base.scss'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import * as CustomInputField from 'cleave.js/react'
import { Switch } from '@material-ui/core'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'

const AddCoachingWeeksComponent = ({ ...props }) => {
  const isWeekCompleted = props.data && props.data.is_completed
  const is_coaching_completed = props.coachingType === 'completed'
  const disable_fields = isWeekCompleted || is_coaching_completed
  const agent_acknowledgement = props.data.agent_acknowledgement
  const agent_current_week_no = props.data.week_no
  const is_completed = false


  const validate = (values, props) => {

    const errors = {}
    errors.key_metrics = {}
    const is_completed = values.is_completed
    if (values.key_metrics) {
      const curErrorsKeys = Object.keys(values.key_metrics)
      curErrorsKeys.forEach(element => {
        const header = values.formData.keymetricHeaders[element]
        if (header) {
          if (values.key_metrics[element] == '') {
            errors.key_metrics[element] = 'Please enter a value'
          }
          else if (
            header.data_type === 'Number' &&
            Boolean(values.key_metrics[element])
          ) {
            if (isNaN(values.key_metrics[element]))
              errors.key_metrics[element] = 'Please enter a valid Number'
          } else if (
            header.data_type === 'Boolean' &&
            Boolean(values.key_metrics[element])
          ) {
            if (
              !(
                String(values.key_metrics[element]).toUpperCase() === 'YES' ||
                String(values.key_metrics[element]).toUpperCase() === 'Y' ||
                String(values.key_metrics[element]).toUpperCase() === 'NO' ||
                String(values.key_metrics[element]).toUpperCase() === 'N'
              )
            )
              errors.key_metrics[element] = 'Please enter a Yes or No'
          }
        }
      })
    }

    if (is_completed == true) {

      if (agent_acknowledgement == 'False' && agent_current_week_no == '3') {
        errors.is_completed = 'Agent has not acknowledged yet.You will be able to complete the coaching after agent\'s acknowledgement.'
        return errors
      }
    }



    if (Object.keys(errors.key_metrics).length === 0) delete errors.key_metrics

    return errors
  }

  if (props.data.week_no === 0) {
    var validationSchema = Yup.object({
      interaction_id: Yup.string().required('Interaction ID is required').nullable(),
      followup_date: Yup.date().required('Follow up date is required').nullable(),
      call_description: Yup.string().required('Call Description is required').nullable(),
      formData: Yup.object({
        what_agent_liked: Yup.string().required('What did you (agent) like about the call? is required').nullable(),
        what_manager_liked: Yup.string().required('What did I (manager) like about the call is required').nullable(),
        what_opportunity_agent: Yup.string().required('What opportunities did you (agent) find on this call? is required').nullable(),
        what_opportunity_manager: Yup.string().required('What opportunities did I (manager) find on this call? is required').nullable(),
        opportunity_or_focus: Yup.string().required('Opportunities (what should our focus for this coaching plan be for the next 30 days) is required').nullable(),
        root_cause: Yup.string().required('Root Cause (drill down to identify the root cause of the behavior) is required').nullable(),
        impact: Yup.string().required('Impact (please have agent explain the impact to the Member, their teams, and our client if they continue the behavior) is required').nullable(),
        smart_goal_1: Yup.string().required('Smart Goal 1 To Change Behavior is required').nullable()
      }),

    })
  }
  else if (props.data.week_no === 1) {
    var validationSchema = Yup.object({
      interaction_id: Yup.string().required('Interaction ID is required').nullable(),
      coaching_date: Yup.date().required('Coaching Date is required').nullable(),
      followup_date: Yup.date().required('Follow up date is required').nullable(),
      call_description: Yup.string().required('Call Description is required').nullable(),
      agent_behaviour_opportunity: Yup.string().required('What behaviors and opportunities are we focusing on (please have agent provide this answer) is required').nullable(),
      agent_smart_goal_commitment: Yup.string().required('What was our SMART goal commitment (please have agent provide this answer)? is required').nullable(),
      agent_smart_goal_update: Yup.string().required('Did agent meet SMART goal commitment(s)?If no, please document any additional support from you (manager) may be provided, if applicable. is required').nullable(),
    })
  }
  else if (props.data.week_no === 2) {
    var validationSchema = Yup.object({
      interaction_id: Yup.string().required('Interaction ID is required').nullable(),
      coaching_date: Yup.date().required('Coaching Date is required').nullable(),
      agent_behaviour_opportunity: Yup.string().required('What behaviors and opportunities are we focusing on (please have agent provide this answer) is required').nullable(),
      agent_smart_goal_commitment: Yup.string().required('What was our SMART goal commitment (please have agent provide this answer) is required').nullable(),
      agent_smart_goal_update: Yup.string().required('Did agent meet SMART goal commitment(s)?If no, please document any additional support from you (manager) may be provided, if applicable is required').nullable(),

    })
  }
  else if (props.data.week_no === 3) {
    var validationSchema = Yup.object({
      interaction_id: Yup.string().required('Interaction ID is required').nullable(),
      coaching_date: Yup.date().required('Coaching Date is required').nullable(),
      call_description: Yup.string().required('Call Description is required').nullable(),
      agent_behaviour_opportunity: Yup.string().required('What behaviors and opportunities are we focusing on (please have agent provide this answer) is required').nullable(),
      agent_smart_goal_commitment: Yup.string().required('What was our SMART goal commitment (please have agent provide this answer)? is required').nullable(),
      agent_smart_goal_update: Yup.string().required('id agent meet SMART goal commitment(s)?If no, please document any additional support from you (manager) may be provided, if applicable. is required').nullable(),
    })
  }
  const history = useHistory()

  const redirect = () => {
    //let redirect_url = `/dashboard/coaching`
    let redirect_url = `/dashboard/coaching?ctype=${props.coachingType}`
    history.push(redirect_url)
  }

  const requiredAsterisk = () => {
    return <span className='text-danger'> *</span>
  }

  const getWeekText = week => {
    const weekNo = week + 1
    let weekText = `Week ${weekNo}`

    switch (weekNo) {
      case 1:
        weekText += ' - Plan Development'
        break
      case 2:
        weekText += ' - Check Progress'
        break
      case 3:
        weekText += ' - Check Progress/Adjust Plan'
        break
      case 4:
        weekText += ' - Final Review of Progress'
        break
      default:
        break
    }
    return weekText
  }

  const getWeekSubText = week => {
    const weekNo = week + 1
    let weekText = ''

    switch (weekNo) {
      case 1:
        weekText =
          '30-45 minutes; build the plan through call review and discussion'
        break
      case 2:
        weekText = '15 minutes; check progress of plan'
        break
      case 3:
        weekText =
          '15 minutes; check progress of plan and adjust focus of plan as needed'
        break
      case 4:
        weekText = '15 minutes; review specialist success in executing plan'
        break
      default:
        break
    }
    return weekText
  }

  const initialVaules = {
    ...props.data,
    coaching_date: props.data.interaction_id ? props.data.coaching_date : null
  }

  return (
    <Row className='justify-content-center'>
      <Col md={8}>
        <Card className='bg-transparent card-form pt-5 no-bg'>
          <Card.Body className='user-profile-card-body '>
            <Row>
              <Col md={12}>
                <div className='justify-content-between txt_coaching_header text-center'>
                  <h2 className='mb-0'>{getWeekText(props.data.week_no)}</h2>
                  <h5 className='mt-1 mb-2'>
                    {getWeekSubText(props.data.week_no)}
                  </h5>
                  <h3 className='mb-3'>{props.message}</h3>
                </div>
              </Col>
            </Row>
            <Formik
              // initialValues={props.data}
              initialValues={initialVaules}
              validate={validate}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {

                props.handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm className='text_dark_coaching_form'>
                  {formik.isSubmitting ||
                    (props.isLoading && (
                      <div><OverlayLoader showSpinner={false} /><Alert variant='info'>
                        Submitting Information. Please wait...
                      </Alert></div>
                    ))}
                     <div className='form-section form-section-orange'></div>
                  {/* {formik.values.week_no === 0 && (
                    <div className='form-section form-section-orange'>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md='6'
                          controlId='coaching_end_date'
                        >
                          <Form.Label>Coaching Plan End Date:</Form.Label>
                          <DatePicker
                            //showWeekNumbers
                            name='coaching_end_date'
                            selected={formik.values.formData.coaching_end_date}
                            dateFormat='MM-dd-yyyy'
                            className='form-control'
                            disabled={disable_fields}
                            onChange={date => {
                              formik.setFieldValue(
                                'formData.coaching_end_date',
                                date,
                                true
                              )
                            }}
                          />
                          <ErrorMessage
                            name='coaching_end_date'
                            render={msg => (
                              <div className='text-danger'>{msg}</div>
                            )}
                          />
                        </Form.Group>
                      </Form.Row>
                    </div>
                  )} */}
                  {formik.values.week_no > 0 && (
                    <div className='form-section form-section-orange'>
                      <Form.Row>
                        <Form.Group as={Col} md='6' controlId='coaching_date'>
                          <Form.Label>
                            Coaching Date: {requiredAsterisk()}
                          </Form.Label>
                          <DatePicker
                            showWeekNumbers
                            name='coaching_date'
                            selected={formik.values.coaching_date}
                            dateFormat='MM-dd-yyyy'
                            className='form-control'
                            disabled={disable_fields}
                            onChange={date => {
                              formik.setFieldValue('coaching_date', date, true)
                            }}
                            placeholderText="Click to select a coaching date"
                          />
                          <ErrorMessage
                            name='coaching_date'
                            render={msg => (
                              <div className='text-danger'>{msg}</div>
                            )}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md='6' controlId='coaching_time'>
                          <Form.Label>Coaching Time:</Form.Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              margin='normal'
                              className='form-control mt-0 pt-2 pl-3 timepicker_border'
                              value={formik.values.coaching_time}
                              disabled={disable_fields}
                              onChange={time => {
                                formik.setFieldValue(
                                  'coaching_time',
                                  time,
                                  true
                                )
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <ErrorMessage
                            name='coaching_time'
                            render={msg => (
                              <div className='text-danger'>{msg}</div>
                            )}
                          />
                        </Form.Group>
                      </Form.Row>
                    </div>
                  )}
                  <div className='form-section form-section-purple'>
                    <h3 className='form-section-title'>
                      Call Review & Analysis{' '}
                    </h3>
                    <h5 className='form-section-subtitle'>
                      Use this section to review a call, and begin to building
                      your coaching plan for the month.
                    </h5>
                    <Form.Row>
                      <Form.Group as={Col} md='6' controlId='interaction_id'>
                        <Form.Label>Interaction ID {requiredAsterisk()}</Form.Label>
                        <CustomInputField
                          className='form-control'
                          id='interaction_id'
                          disabled={disable_fields}
                          options={{
                            blocks: [12],
                            numericOnly: false,
                            delimiter: '-'
                          }}
                          name='interaction_id'
                          value={formik.values.interaction_id}
                          onChange={e => {
                            formik.setFieldValue(
                              'interaction_id',
                              e.target.value,
                              true
                            )
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('interaction_id', true)
                          }}
                        /><ErrorMessage
                          name='interaction_id'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                 
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md='6' controlId='quality_score'>
                        <Form.Label>Quality Score MTD(percentage)</Form.Label>
                        <CustomInputField
                          className='form-control'
                          id='quality_score'
                          options={{
                            blocks: [4],
                            numericOnly: true,
                            delimiter: '-'
                          }}
                          name='quality_score'
                          value={formik.values.quality_score}
                          disabled={disable_fields}
                          onChange={e => {
                            formik.setFieldValue(
                              'quality_score',
                              e.target.value,
                              true
                            )
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('quality_score', true)
                          }}
                        />
                        <ErrorMessage
                          name='quality_score'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group noValidate as={Col} md='12'>
                        <Form.Label>
                          Call Description {requiredAsterisk()}
                        </Form.Label>
                        <Field
                          as='textarea'
                          name='call_description'
                          placeholder='Call Description'
                          rows='3'
                          className='form-control'
                          disabled={disable_fields}
                        />
                        <ErrorMessage
                          name='call_description'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md='6' controlId='was_resolved'>
                        <Form.Label className='w-100'>
                          Was everything resolved on this call?{' '}
                          {requiredAsterisk()}
                        </Form.Label>
                        <Switch
                          id='was_resolved'
                          className='float-left resolved_switch'
                          color='primary'
                          checked={formik.values.was_resolved}
                          disabled={disable_fields}
                          onChange={e => {
                            formik.setFieldValue(
                              'was_resolved',
                              e.target.checked,
                              true
                            )
                          }}
                        />
                        <div className='float-left mt-2 pt-1'>
                          {formik.values.was_resolved ? 'Yes' : 'No'}
                        </div>
                        <ErrorMessage
                          name='was_resolved'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    {formik.values.week_no === 0 && (
                      <>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='6'>
                            <Form.Label>
                              What did you (agent) like about the call?{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.what_agent_liked'
                              placeholder='What did you (agent) like about the call? *'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.what_agent_liked'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                          <Form.Group noValidate as={Col} md='6'>
                            <Form.Label>
                              What did I (manager) like about the call?{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.what_manager_liked'
                              placeholder='What did I (manager) like about the call? *'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.what_manager_liked'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='6'>
                            <Form.Label>
                              What opportunities did you (agent) find on this
                              call? {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.what_opportunity_agent'
                              placeholder='What opportunities did you (agent) find on this call? *'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.what_opportunity_agent'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                          <Form.Group noValidate as={Col} md='6'>
                            <Form.Label>
                              What opportunities did I (manager) find on this
                              call? {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.what_opportunity_manager'
                              placeholder='What opportunities did I (manager) find on this call? *'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.what_opportunity_manager'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                  </div>
                  <div className='form-section form-section-blue'>
                    <h3 className='form-section-title'>
                      Behavior Analysis, Next Steps & SMART Goals
                    </h3>
                    <h5 className='form-section-subtitle'>
                      Identify and agree on opportunities, discuss target cause
                      for any misses, and set SMART goals for improvement.
                    </h5>
                    {formik.values.week_no === 0 && (
                      <>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Opportunities (what should our focus for this
                              coaching plan be for the next 30 days):{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.opportunity_or_focus'
                              placeholder='Opportunities (what should our focus for this coaching plan be for the next 30 days):'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.opportunity_or_focus'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Root Cause (drill down to identify the root cause
                              of the behavior): {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.root_cause'
                              placeholder='Root Cause (drill down to identify the root cause of the behavior):'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.root_cause'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Impact (please have agent explain the impact to
                              the Member, their teams, and our client if they continue
                              the behavior): {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.impact'
                              placeholder='Impact (please have agent explain the impact to the Member, their teams, and our client if they continue the behavior):'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.impact'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Smart Goal 1 To Change Behavior:{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.smart_goal_1'
                              placeholder='Smart Goal 1 To Change Behavior:'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.smart_goal_1'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Smart Goal 2 To Change Behavior:
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.smart_goal_2'
                              placeholder='Smart Goal 2 To Change Behavior:'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.smart_goal_2'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Smart Goal 3 To Change Behavior:
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='formData.smart_goal_3'
                              placeholder='Smart Goal 3 To Change Behavior:'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='formData.smart_goal_3'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}

                    {formik.values.week_no > 0 && (
                      <>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              What behaviors and opportunities are we focusing
                              on (please have agent provide this answer):{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='agent_behaviour_opportunity'
                              placeholder='What behaviors and opportunities are we focusing on (please have agent provide this answer):'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='agent_behaviour_opportunity'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              What was our SMART goal commitment (please have
                              agent provide this answer)? {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='agent_smart_goal_commitment'
                              placeholder='What was our SMART goal commitment (please have agent provide this answer)?'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='agent_smart_goal_commitment'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group noValidate as={Col} md='12'>
                            <Form.Label>
                              Did agent meet SMART goal commitment(s)?If no,
                              please document any additional support from you
                              (manager) may be provided, if applicable.{' '}
                              {requiredAsterisk()}
                            </Form.Label>
                            <Field
                              as='textarea'
                              name='agent_smart_goal_update'
                              placeholder='Did agent meet SMART goal commitment(s)? If no, please document any additional support from you (manager) that may be provided, if applicable.'
                              rows='3'
                              className='form-control'
                              disabled={disable_fields}
                            />
                            <ErrorMessage
                              name='agent_smart_goal_update'
                              render={msg => (
                                <div className='text-danger'>{msg}</div>
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                  </div>
                  <div className='form-section form-section-purple'>
                    <h3 className='form-section-title'>
                      KPIs & Follow Up - for Week{' '}
                      {formik.values.key_metrics.week_no} -{' '}
                      {formik.values.key_metrics.year_no}
                    </h3>
                    <h5 className='form-section-subtitle'>
                      Please have the agent summarize and Slack you the recap of
                      coaching and copy below, and complete coaching session
                      coaching session by reviewi KPIs, attendance setting the
                      follow up date and time for your next session!
                    </h5>
                    <Form.Row>
                      {Object.keys(props.metricsHeaderName).length > 0 ? (
                        Object.keys(props.metricsHeaderName).map((key, index) => {
                          return (
                            <Form.Group as={Col} md='4' key={index}>
                              <Form.Label>
                                {props.metricsHeaderName[key]}
                              </Form.Label>
                              <Field
                                type='text'
                                className='form-control'
                                name={`key_metrics.${props.metricsHeaderName[key]}`}
                                disabled={disable_fields}
                              />
                              <ErrorMessage
                                name={`key_metrics.${props.metricsHeaderName[key]}`}
                                render={msg => (
                                  <div className='text-danger'>{msg}</div>
                                )}
                              />
                            </Form.Group>
                          )
                        })
                      ) : (
                        <Form.Group as={Col} md='12'>
                          KPI data unavailable. Please upload the metrics data.
                        </Form.Group>
                      )}
                    </Form.Row>
                    <Form.Row>
                      <Form.Group noValidate as={Col} md='12'>
                        <Form.Label>
                          Agent Recap of Coaching (Verbatim, and additional
                          agent feedback):
                        </Form.Label>
                        <Field
                          as='textarea'
                          name='agent_recap'
                          placeholder='Agent Recap of Coaching (Verbatim, and additional agent feedback) :'
                          rows='1'
                          className='form-control'
                          disabled={disable_fields}
                        />
                        <ErrorMessage
                          name='agent_recap'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md='12' controlId='followup_date'>
                        <Form.Label>Follow Up Date / Next Coaching:{requiredAsterisk()}</Form.Label>
                        <DatePicker
                          showWeekNumbers
                          selected={formik.values.followup_date}
                          dateFormat='MM-dd-yyyy'
                          className='form-control'
                          disabled={disable_fields}
                          onChange={date => {
                            formik.setFieldValue('followup_date', date, true)
                          }}
                        />
                        <ErrorMessage
                          name='followup_date'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  {formik.isSubmitting ||
                    (props.isLoading && (
                      <div><OverlayLoader showSpinner={false} /><Alert variant='info'>
                        Submitting Information. Please wait...
                      </Alert></div>
                    ))}

                  <div className='form-section form-section-orange'>
                    <Form.Row className='week_completed_checkbox'>
                      <Form.Group controlId='formBasicCheckbox'>
                        <Form.Check
                          type='checkbox'
                          label={
                            formik.values.week_no < 3
                              ? `Week${parseInt(formik.values.week_no) +
                              1} Completed`
                              : 'Mark as coaching completed'
                          }
                          name='is_completed'
                          checked={formik.values.is_completed}
                          disabled={disable_fields}
                          onChange={event => {
                            const { checked } = event.target
                            formik.setFieldValue('is_completed', checked, true)
                            if (checked) {
                              const today = moment().format('YYYY-MM-DD')
                              formik.setFieldValue('completed_on', today, true)
                            } else {
                              formik.setFieldValue('completed_on', '', true)
                            }
                          }}
                        />     <ErrorMessage
                          name='is_completed'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <Form.Row>
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'
                      onClick={() => {
                        if (formik.dirty) {
                          const shouldRedirect = confirm(
                            'You have modified the form? if you select okay your changes will be lost'
                          )
                          if (shouldRedirect) {
                            props.programBar.onAgentChange({
                              agent: {},
                              label: 'All',
                              value: ''
                            })
                            redirect()
                          }
                        } else {
                          props.programBar.onAgentChange({
                            agent: {},
                            label: 'All',
                            value: ''
                          })
                          redirect()
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      onClick={() => {
                        formik.setFieldValue('save', true)
                      }}
                      disabled={
                        formik.isSubmitting ||
                        props.isLoading ||
                        disable_fields
                      }
                    >
                      {props.isLoading ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Save
                    </Button>
                    &nbsp;
                    {is_coaching_completed ? null : (<Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      onClick={() => {
                        formik.setFieldValue('traverse', -1)
                      }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Back
                    </Button>)}
                    &nbsp;
                    {formik.values.week_no <= 2 && !is_coaching_completed && (
                      <Button
                        variant='primary'
                        className='submit-button ml-auto'
                        type='submit'
                        onClick={() => {
                          formik.setFieldValue('traverse', 1)
                        }}
                        disabled={formik.isSubmitting || is_coaching_completed || !isWeekCompleted}
                      >
                        {formik.isSubmitting ? (
                          <ShowSpinner buttonSpinner={true} />
                        ) : null}
                        Continue
                      </Button>
                    )}
                  </Form.Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AddCoachingWeeksComponent
