import React from 'react'

const UserNotFound = () => {
  return (
    <section className='container d-flex text-center justify-content-center align-items-center vh-100'>
      <div className='not-found'>
        <h4>You have encountered a error. Please contact customer support.</h4>
      </div>
    </section>
  )
}

export default UserNotFound
