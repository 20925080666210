import React from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import '../../styles/box-layout.scss'
import '../../styles/login.scss'
import ShowSpinner from '../dashboard/ShowSpinner'

const LoginForm = ({ ...props }) => {
  return (
    <section className='box-block'>
      <div className='box-block-content'>
        <h2 className='text-center'>Login</h2>
        {props.serverErrors && (
          <ul className='alert alert-danger'>{props.serverErrors}</ul>
        )}

        <Formik
          initialValues={{
            username: '', 
            password: ''
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .email('Invalid Email address')
              .required('Required'),
            password: Yup.string().required('Required')
          })}
          onSubmit={values => {
            props.handleLogin(values)
          }}
        >
          {formikprops => (
            <Form className='login' onSubmit={formikprops.handleSubmit}>
              <Form.Group controlId='formEmail'>
                <Form.Label>Email</Form.Label>
                <Field
                  className='form-control'
                  type='email'
                  placeholder='Email Address'
                  name='username'
                />

                <ErrorMessage
                  name='username'
                  render={msg => <div className='text-danger'>{msg}</div>}
                />
              </Form.Group>
              <Form.Group controlId='formPassword' className='passwordBlock'>
                <Form.Label>Password</Form.Label>
                <Field
                  className='form-control'
                  type={props.viewPassword ? 'text' : 'password'}
                  placeholder='Your Password'
                  name='password'
                />
                <ErrorMessage
                  name='password'
                  render={msg => <div className='text-danger'>{msg}</div>}
                />
                <span
                  role='button'
                  tabIndex='-1'
                  className={
                    props.viewPassword ? 'show-hide active' : 'show-hide'
                  }
                  onClick={props.togglePasswordDisplay}
                >
                  Show/hide
                </span>
              </Form.Group>
              <Form.Group
                controlId='buttonSubmit'
                className='mb-0 d-flex justify-content-center'
              >               
                  <Button
                    variant='primary'
                    type='submit'
                    className='btn-lg btn-block'
                    disabled={props.isSubmitted}
                  >
                    {props.isSubmitted ? <ShowSpinner buttonSpinner={true} /> : null}
                    Login
                  </Button>                
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default LoginForm
