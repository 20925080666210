import { AUTH_TOKEN_NAME } from '../constants'
import { getSessionVariable } from '../helpers'

export const AppService = {
  /**
   * Set http options
   * Default: POST, application/json
   * @default method POST
   * @default credentials 'include' required to accept and send cookies
   */
  options: () => {
    return {
      method: 'POST'
    }
  },

  /**
   * Set http options
   * Default: GET, application/json
   * @default method GET
   * @default credentials 'include' required to accept and send cookies
   */
  optionsGET: () => {
    return {
      method: 'GET'
    }
  },

  /**
   * Make a fetch API call
   * @param {string} url fully qualified url
   * @param {object} requestOptions options object from options()
   */

  makeRequest: async (url, requestOptions) => {
    const response = await fetch(url, requestOptions)
    let result = null

    if (!(response.error && response.error.name === 'AbortError')) {
      try {
        result = await response.json()
      } catch (error) {
        throw response
      }
    } else {
      throw response
    }

    if (response.status >= 400) {
      throw result
    }

    return result
  }
}

/**
 * Sets HTTP Headers with Authorization key
 * @returns {object} headers
 */
export const HTTPHeaders = () => {
  return {
    headers: {
      Authorization: `${getSessionVariable(AUTH_TOKEN_NAME)}`,
      'Content-type': 'application/json; charset=UTF-8'
    }
  }
}
