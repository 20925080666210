import React from 'react'
import ShowSpinner from './ShowSpinner'
import PopupModal from './PopupModal'

const handleModalClose = () => {
}

const UnauthorizedCard = ({ ...props }) => {
  const modalTitle = "Unauthorized!"
   const modalBody = "You are not an authorized user to acces this page"
   
   return (<div><ShowSpinner size='sm' />
   <PopupModal showModal={true} handleModalClose={handleModalClose} hideCloseButton={true} modalTitle={modalTitle} modalBody={modalBody} /></div>)
}

export default UnauthorizedCard
