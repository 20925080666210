/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react'
import '../../styles/metrics-card.scss'
import MaUTable from '@material-ui/core/Table'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce
} from 'react-table'
import { Row, Col, Pagination, Card, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../styles/base.scss'
import moment from 'moment'
import {
  isAgent,
  isPm,
  isProgramDirector,
  getAgentBehaviourOpportunities,
  getAgentSmartGoals,
  getAgentCoachingRecaps,
  getActiveWeek,
  getDueWeekAndDate,
  checkValueYesOrNot,
  showConsoleLog,
  checkArrObjectValueExist
} from '../../helpers'
// import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'
import { propTypes } from 'react-bootstrap/esm/Image'

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  myGlobalFilter,
  setMyGlobalFilter
}) => {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || '')
  }, 200)

  return (
    <Row className='p-3'>
      <Col md='auto' className='ml-auto'>
        <input
          className='form-control'
          type='text'
          placeholder='Search'
          value={globalFilter || ''}
          onChange={e => {
            setMyGlobalFilter(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

const PmCoachingCard = ({
  columns,
  data,
  fetchData,
  selectedProgram,
  selectedAgent,
  showCoachingType,
  keyHeaderMetris,
  pageCount: controlledPageCount,
  myGlobalFilter,
  setMyGlobalFilter,
  totalRecords,
  intPageIndex,
  intPageSize,
  isLoading,
  intWeekDropDownCount,
  programBar
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      selectedProgram,
      selectedAgent,
      showCoachingType,
      intPageIndex,
      intPageSize,
      keyHeaderMetris,
      initialState: { pageIndex: intPageIndex, pageSize: intPageSize },
      manualPagination: true,
      pageCount: controlledPageCount
    },
    useGlobalFilter,
    usePagination
  )
  const tableStateUpdateRef = React.useRef(false)
  const [count, setCount] = React.useState(intWeekDropDownCount);
  const [totalPagecount, setTotalPageCount] = React.useState(page.length);

  React.useEffect(() => {
    if (!isLoading) {
      tableStateUpdateRef.current = true
      fetchData({ pageIndex, pageSize })
      setCount(intWeekDropDownCount)
    }
  }, [
    // fetchData,
    pageIndex,
    pageSize,
    showCoachingType,
    // selectedProgram,
    selectedAgent
  ])

  // React.useEffect(() => {
  //   if (!tableStateUpdateRef.current) {
  //     gotoPage(0);
  //   }
  // }, [intPageIndex, gotoPage]);

  // clear our ref when the data is loaded, after we perform any side effects
  React.useEffect(() => {
    tableStateUpdateRef.current = false
  }, [intPageIndex])

  const getTableRows = (i, count) => {
    if (data && data.length > 0) {
      const index = i
      const row = data[index]
      const arrData = []
      const arrHeaders = []
      const metrics = row.key_metrics
      const programId = row.program_id
      const agentId = row.agent_id
      const flagShowLink = !!(programId && agentId)
      
      let viewlink = `/dashboard/coaching/add?program=${programId}&agent=${agentId}&id=${row.public_id}&week=${row.week_no}`
      viewlink += `&ctype=${showCoachingType}`

      let weekCircleClass = 'week-title dash_card_header_green mr-1 pull-left'
      const cardBorderClass = 'dash_card_border_bottom_green'
      if(row.week_no === count || count === '-1'){
        const current_date = moment().toDate() // This will return a copy of the Date that the moment uses
        current_date.setHours(0)
        current_date.setMinutes(0)
        current_date.setSeconds(0)
        current_date.setMilliseconds(0)
        const previous_date = moment(current_date)
          .subtract(1, 'd')
          .unix()
        const due_date = moment(row.due_date).unix()
        const agent_review = row.agent_acknowledgement ? 'Yes' : 'No'
        const agent_feedback = row.agent_feedback
        /*let dueDate = ''
        if (row.due_date) {
          dueDate = moment(new Date(row.due_date)).format('MMM-DD-YYYY')
        }*/

        let followup_date = ''
        if (row.followup_date) {
          followup_date = moment(new Date(row.followup_date)).format('MMM-DD-YYYY') 
          followup_date = followup_date === "Invalid date" ? '' : followup_date
        }
  
        weekCircleClass = row.is_completed
          ? weekCircleClass
          : due_date < previous_date
          ? 'week-title dash_card_header_red mr-1 pull-left'
          : 'week-title dash_card_header_blue mr-1 pull-left'
        if (Object.keys(metrics).length === 1 && metrics['*'] !== undefined) {
          arrHeaders.push(<Col key={1} />)
          arrData.push(
            <Col key={1}>
              <div className='coaching_week_section'>
                <h3 className='coaching_value mb-4'>No Metrics</h3>
              </div>
            </Col>
          )
        } else if (keyHeaderMetris) {
          keyHeaderMetris.map((header, columnIndex) => {
            let metricValue = <h3 className='metric_value'>{metrics[header]}</h3>
            const isAtRiskKey = header === 'ES At Risk (Y/N)'
  
            if (isAtRiskKey) {
              const atRiskClass = checkValueYesOrNot(metrics[header])
                ? 'badge badge-soft-danger'
                : 'badge badge-soft-success'
              metricValue = checkValueYesOrNot(metrics[header]) ? (
                <h2>
                  <span className={atRiskClass}>AT RISK</span>
                </h2>
              ) : (
                <h2>
                  <span className={atRiskClass}>NOT AT RISK</span>
                </h2>
              )
            }
  
            //First two columns out of metric headers
            if(columnIndex === 0) {
              arrHeaders.push(
                <Col key={columnIndex + 1}>
                  <span className='text-muted'>Follow-Up Date</span>
                </Col>
              )
              arrData.push(<Col key={columnIndex + 1}><h3 className='metric_value'>{followup_date}</h3></Col>)
  
              arrHeaders.push(
                <Col key={columnIndex + 2}>
                  <span className='text-muted'>Agent Reviewed?</span>
                </Col>
              )
              arrData.push(<Col key={columnIndex + 2}><h3 className='metric_value'>{agent_review}</h3></Col>)
  
              arrHeaders.push(
                <Col key={columnIndex + 3}>
                  <span className='text-muted'>Agent FeedBack</span>
                </Col>
              )
              arrData.push(<Col key={columnIndex + 3}><h3 className='metric_value'>{agent_feedback ? agent_feedback : '-'}</h3></Col>)            
            }
  
            //columnIndex + 3 becuase added first two columns
            arrHeaders.push(
              <Col key={columnIndex + 4}>
                <span className='text-muted'>{header}</span>
              </Col>
            )
            arrData.push(<Col key={columnIndex + 4}>{metricValue}</Col>)
            return header
          })
      }

    }

      return (
        arrData.length > 0 ? 
        <Link to={viewlink} key={index}>
          <Card className=''>
            <Card.Body>
              <Row md={12} className='align-items-center'>
                <Col md={3}>
                  <div className='d-flex'>
                    <div>
                      <h4 className='coaching_agent_name'>
                        <span className={weekCircleClass}>
                          {`W-${parseInt(row.week_no) + 1}`}
                        </span>
                      </h4>
                    </div>
                    <div className='flex-grow-1 align-self-center pl-3'>
                      <h4>
                        <span className='card-agent-name'>
                          {row.agent_name}
                        </span>
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col md={9}>
                  <Row md={12}>{arrData}</Row>
                  <Row md={12}>{arrHeaders}</Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Link>
        : ''
      )
    }
    return null
  }

  const showTableData = () => {
    return isLoading ? (
      <OverlayLoader />
    ) : data && data.length > 0 ? (
      <section>
        <Card className='bg-primary'>
          <Card.Body className='py-2'>
            <Row className='align-items-center'>
              {/* <Col md='auto'>
                <Form.Control
                  size='sm'
                  as='select'
                  className='mr-sm-2'
                  id='inlineFormCustomSelect'
                  custom
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map(pageSize1 => (
                    <option key={pageSize1} value={pageSize1}>
                      Show {pageSize1}
                    </option>
                  ))}
                </Form.Control>
              </Col> */}
              <Col md='auto'>
                <Form.Control
                  size='sm'
                  as='select'
                  className='mr-sm-2'
                  id='inlineFormCustomSelect1'
                  custom
                  onChange={e => {
                    setCount(e.target.value)
                  }}
                >
                  {['-1', '0', '1', '2', '3'].map(weekNo1 => (
                    <option key={weekNo1} value={weekNo1}>
                      {/* Week {weekNo1} */}
                      Week {weekNo1 === '-1' ?  'All' : Number(weekNo1)+1}
                    </option>
                  ))}
                </Form.Control>
              </Col>              
              <Col md='auto' className='ml-auto'>
                {/* Showing {totalPagecount} of {totalRecords} results */}
                Total {totalRecords} Records
              </Col>              
              <Col md='auto' className='ml-auto'>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <MaUTable {...getTableProps()} className='table table-sm card-table'>
          <TableBody {...getTableBodyProps()}>
            {isLoading || programBar.isFetchingProgram ? (
              <OverlayLoader />
            ) : null}
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>{getTableRows(i, count)}</TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
        {/* <Card>
          <Card.Body className='py-2'>
            <Row md={12} className='align-items-center'>
              <Col md={4}>
                <div className='form-inline'>
                  <span>Go to page: </span>
                  <Form.Control
                    size='sm'
                    className='ml-2'
                    type='number'
                    value={pageIndex + 1}
                    // defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page1 = e.target.value
                        ? Number(e.target.value) - 1
                        : 0
                      gotoPage(page1)
                    }}
                    style={{ width: '100px' }}
                  />
                </div>{' '}
              </Col>
              <Col md={4} className='text-center'>
                Showing {page.length} of {totalRecords} results
              </Col>
              <Col md='auto' className='ml-auto'>
                <Pagination className='mb-0'>
                  <Pagination.First
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}
      </section>
    ) : (
      <Card className='bg-primary'>
        <Card.Body className='py-2'>
          <Row md={12}>
            <Col>Records not found</Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
  return showTableData()
}

export default PmCoachingCard
