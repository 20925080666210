/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ExpandMoreRounded } from '@material-ui/icons'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { components } from 'react-select'
import { SelectFetch } from 'react-select-fetch'
import * as Yup from 'yup'
import {
  BASE_API_URL,
  GET_AGENT_ALL,
  GET_PROGRAM_DIRECTOR,
  GET_PROGRAM_MANAGER,
  ARRAY_COACHING_TYPES,
  ARRAY_PROGRAM_TYPES,
  ARRAY_STATUS_TYPES,
  TEXT_NOT_SPECIFIED,
  ADD_MODE,  
  EDIT_MODE, 
  VIEW_MODE,
  TEXT_ADD,
  TEXT_EDIT,
  TEXT_SAVE,
  TEXT_UPDATE,
} from '../../constants'
import { getAgentDisplayName, capitalizeFirstLetterOfWord } from '../../helpers'
import { AppService, HTTPHeaders } from '../../services'
import '../../styles/base.scss'
import EditableTable from './EditableTable'
import ScrollableTable from './ScrollableTable'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'


const Option = props => {  
  return (
    <components.Option {...props}>
      <input type='checkbox' checked={props.isSelected} onChange={() => null} />{' '}
      <span>{props.value} </span>
    </components.Option>
  )
}

/**
 * AddProgramComponent 
 */
const AddProgramComponent = ({ ...props }) => {  
  const formData = props.data
  const [toggleEvent, setToggleEvent] = useState({'0':false,'1':false,'2':false})
  const handleToggle = (e, eventKey) => {
    if(eventKey === 0){
      if(toggleEvent[0]){
        setToggleEvent({'0':false,'1':toggleEvent[1],'2':toggleEvent[2]}) 
      } else {
        setToggleEvent({'0':true,'1':toggleEvent[1],'2':toggleEvent[2]}) 
      }
    }
    if(eventKey === 1){
      if(toggleEvent[1]){
        setToggleEvent({'0':toggleEvent[0],'1':false,'2':toggleEvent[2]}) 
      } else {
        setToggleEvent({'0':toggleEvent[0],'1':true,'2':toggleEvent[2]}) 
      }
    }    
    if(eventKey === 2){
      if(toggleEvent[2]){
        setToggleEvent({'0':toggleEvent[0],'1':toggleEvent[1],'2':false}) 
      } else {
        setToggleEvent({'0':toggleEvent[0],'1':toggleEvent[1],'2':true}) 
      }
    }    
  }
  
  Yup.addMethod(Yup.string, 'customValidator', function(ref, msg) {
    return this.test({
      name: 'agents_list',
      message: msg,
      params: {
        reference: ref.path
      },
      test: () => {
        if(formData.agents === undefined || (formData.agents !== undefined && formData.agents === null)) {          
          return false
        }
        return true
      }
    })
  })

  // Schema for yup
  const validationSchema = Yup.object().shape({
    program_name: Yup.string()
      .required('Program name is required')
      .max(100, 'Program name is too long'),
    program_type: Yup.string()
      .oneOf(ARRAY_PROGRAM_TYPES, 'Please select valid program type')
      .required('Please select valid program type'),
    coaching_type: Yup.string()
      .oneOf(ARRAY_COACHING_TYPES, 'Please select valid coaching type')
      .required('Please select valid coaching type'),
    // this conditional validation is not working
    coaching_url: Yup.string().when('coaching_type', {
      is: coaching_type => coaching_type === 'Others',
      then: Yup.string().required('Coaching url is required').url('Please enter valid coaching url'),
      otherwise: Yup.string()
    }),     
    status: Yup.string()
      .oneOf(ARRAY_STATUS_TYPES, 'Please select valid program status')
      .required('Please select valid program status'),
    calls_url: Yup.string() 
      .required('Program call url is required')
      .url('Please enter valid program call url')
  })

  // ///////////////////////////////////////////
  // View or Edit Program related state and listeners
  // ///////////////////////////////////////////
  const [programData, onProgramDataChange] = useState(null)

  // observer for props.programData change,
  // we get it with 2nd render and not with first
  useEffect(() => {
    // to get the actual program data,
    // once set, will not change again
    if (formData && formData !== programData) {
      onProgramDataChange(props.programData)
      udpateUIdata()
    }
  }, [formData])

  const udpateUIdata = () => {
    const programAgents = formData.agents
    const agentOptions = []
    const tableData = []

    if (programAgents) {
      programAgents.forEach(agent => {
        agentOptions.push({
          label: agent.public_id,
          value: getAgentDisplayName(agent),
          agent
        })
      })
      // update agent dropdown options
      onSelectedAgentsChange(agentOptions)

      programAgents.forEach(agent => {
        tableData.push({
          name: getAgentDisplayName(agent),
          id: agent.public_id
        })
      })
      // update agent table data
      onAgentsTableDataChange(tableData)
    }

    // update table pm data and selected pms data
    const programPms = formData.program_managers
    const pmOptions = []
    const pmTableData = []
    if (programPms) {
      programPms.forEach(pm => {
        pmOptions.push({
          label: pm.public_id,
          value: getPmDisplayName(pm),
          pm
        })
      })
      // update pm dropdown options
      onSelectedPmsChange(pmOptions)

      programPms.forEach(pm => {
        pmTableData.push({
          name: getPmDisplayName(pm),
          id: pm.public_id
        })
      })
      // update pm table data
      onPmsTableDataChange(pmTableData)
    }

    // update table pd data and selected pds data
    const programPds = formData.program_directors
    const pdOptions = []
    const pdTableData = []
    if (programPds) {
      programPds.forEach(pd => {
        pdOptions.push({
          label: pd.public_id,
          value: getPdDisplayName(pd),
          pd
        })
      })
      // update pd dropdown options
      onSelectedPdsChange(pdOptions)

      programPds.forEach(pd => {
        pdTableData.push({
          name: getPdDisplayName(pd),
          id: pd.public_id
        })
      })
      // update pd table data
      onPdsTableDataChange(pdTableData)
    }
  }

  // ///////////////////////////////////////////
  // ///////////////////////////////////////////

  // ///////////////////////////////////////////
  // Agent related state and listeners
  // ///////////////////////////////////////////
  // state which holds the selected agents and table data
  const [selectedAgents, onSelectedAgentsChange] = useState(null)
  const [agentTableData, onAgentsTableDataChange] = useState(null)

  const onAgentSelectedChanged = () => {
    const tableData = []
    if (selectedAgents) {
      selectedAgents.forEach(agent => {
        tableData.push({
          name: getAgentDisplayName(agent.agent),
          id: agent.agent.public_id
        })
      })

      // sort data based on name
      tableData.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      onAgentsTableDataChange(tableData)
    }
  }

  // selected agents change callback
  useEffect(() => {
    // update the agent table data
    onAgentSelectedChanged()
  }, [selectedAgents])

  const deleteAgent = agent => {
    const selectedAgentsLocal = [...selectedAgents]

    // remove item from selectedAgents
    const index = selectedAgentsLocal.findIndex((item, i) => {
      return item.agent.public_id === agent.id
    })

    selectedAgentsLocal.splice(index, 1)

    // update states
    onSelectedAgentsChange(selectedAgentsLocal)
  }

  const getPaginatedAgents = async (url, params) => {
    const { search, page } = params

    let query = '?page_size=10&page_no=1&sort_by=f_name&sort_order=asc'
    if (page > 1) {
      query = `?page_size=10&page_no=${page}&sort_by=f_name&sort_order=asc`
    }
    if (search.length > 0) {
      query += `&filter_query=${search}`
    }
    /*if (search.length > 0) {
      // user entered search query
      query = `?filter_query=${search}`
    } else if (page > 1) {
      query = `?page_size=10&page_no=${page}&sort_by=f_name&sort_order=asc`
    }*/

    const response = await AppService.makeRequest(
      BASE_API_URL + GET_AGENT_ALL + query,
      { ...HTTPHeaders() }
    )

    const agentList = []

    response.data.forEach(agent => {
      agentList.push({
        label: agent.public_id,
        value: getAgentDisplayName(agent),
        agent
      })
    })

    return {
      options: agentList,
      hasMore: response.has_next
    }
  }

  // ///////////////////////////////////////////
  // ///////////////////////////////////////////

  // ///////////////////////////////////////////
  // PM related state and listeners
  // ///////////////////////////////////////////
  // state which holds the selected agents and table data
  const [selectedPms, onSelectedPmsChange] = useState(null)
  const [pmTableData, onPmsTableDataChange] = useState(null)

  const getPmDisplayName = pm => {
    return `${pm.f_name} ${pm.l_name} - ${pm.employee_code}`
  }

  const onPmSelectedChanged = () => {
    const tableData = []
    if (selectedPms) {
      selectedPms.forEach(pm => {
        tableData.push({
          name: getPmDisplayName(pm.pm),
          id: pm.pm.public_id
        })
      })

      // sort data based on name
      tableData.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      onPmsTableDataChange(tableData)
    }
  }
  // selected pms change callback
  useEffect(() => {
    // update the pm table data
    onPmSelectedChanged()
  }, [selectedPms])

  const deletePm = pm => {
    const selectedPmsLocal = [...selectedPms]

    // remove item from selectedPms
    const index = selectedPmsLocal.findIndex(item => {
      return item.pm.public_id === pm.id
    })

    selectedPmsLocal.splice(index, 1)

    // update states
    onSelectedPmsChange(selectedPmsLocal)
  }

  const getPaginatedPms = async (url, params) => {
    const { search, page } = params

    let query = '?page_size=10&page_no=1&sort_by=f_name&sort_order=asc'
    if (page > 1) {
      query = `?page_size=10&page_no=${page}&sort_by=f_name&sort_order=asc`
    }
    if (search.length > 0) {
      query += `&filter_query=${search}`
    }
    const response = await AppService.makeRequest(
      BASE_API_URL + GET_PROGRAM_MANAGER + query,
      { ...HTTPHeaders() }
    )

    const pmList = []

    response.data.forEach(pm => {
      pmList.push({
        label: pm.public_id,
        value: getPmDisplayName(pm),
        pm
      })
    })

    return {
      options: pmList,
      hasMore: response.has_next
    }
  }
  // ///////////////////////////////////////////
  // ///////////////////////////////////////////

  // ///////////////////////////////////////////
  // Program Director related state and listeners
  // ///////////////////////////////////////////
  // state which holds the selected agents and table data
  const [selectedPds, onSelectedPdsChange] = useState(null)
  const [pdTableData, onPdsTableDataChange] = useState(null)

  const getPdDisplayName = pd => {
    return `${pd.f_name} ${pd.l_name} - ${pd.employee_code}`
  }

  const onPdSelectedChanged = () => {
    const tableData = []
    if (selectedPds) {
      selectedPds.forEach(pd => {
        tableData.push({
          name: getPdDisplayName(pd.pd),
          id: pd.pd.public_id
        })
      })

      // sort data based on name
      tableData.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      onPdsTableDataChange(tableData)
    }
  }
  // selected pms change callback
  useEffect(() => {
    // update the pd table data
    onPdSelectedChanged()
  }, [selectedPds])

  const deletePd = pd => {
    const selectedPdsLocal = [...selectedPds]

    // remove item from selectedPms
    const index = selectedPdsLocal.findIndex(item => {
      return item.pd.public_id === pd.id
    })

    selectedPdsLocal.splice(index, 1)

    // update states
    onSelectedPdsChange(selectedPdsLocal)
  }

  const getPaginatedPds = async (url, params) => {
    const { search, page } = params
    let query = '?page_size=10&page_no=1&sort_by=f_name&sort_order=asc'
    if (page > 1) {
      query = `?page_size=10&page_no=${page}&sort_by=f_name&sort_order=asc`
    }
    if (search.length > 0) {
      query += `&filter_query=${search}`
    }

    const response = await AppService.makeRequest(
      BASE_API_URL + GET_PROGRAM_DIRECTOR + query,
      { ...HTTPHeaders() }
    )

    const pdList = []

    response.data.forEach(pd => {
      pdList.push({
        label: pd.public_id,
        value: getPdDisplayName(pd),
        pd
      })
    })

    return {
      options: pdList,
      hasMore: response.has_next
    }
  }
  // ///////////////////////////////////////////
  // ///////////////////////////////////////////

  const getHeader = () => {
    if (props.mode === ADD_MODE) {
      return 'Add New Program'
    }
    if (props.mode === EDIT_MODE) {
      return 'Edit Program'
    }
    return 'View Program'
  }

  const displayProgramName = () => {    
    return (
      <Field
        name='program_name'
        id='program_name'
        type='text'
        defaultValue={formData.program_name}
        className='form-control'
      />
    )
  }

  const displayProgramType = () => {   
    const programType = formData.program_type ? capitalizeFirstLetterOfWord(formData.program_type) : ""    
    let options = []
    options.push(<option value='' key={0}>- Select -</option>)
    ARRAY_PROGRAM_TYPES.map((value, key) => {
      options.push(<option value={value} key={key+1}>{value}</option>)
    })

    return (
      <Field
        name='program_type'
        id='program_type'
        as='select'
        defaultValue={programType}        
        className='custom-select'
      >
        {options}
      </Field>
    )
  }

  const onChangeCoachingType = (e) => {    
  }

  const displayCoachingType = () => {     
    let coachingType = formData.coaching_type   
    let options = []
    options.push(<option value='' key={0}>- Select -</option>)
    ARRAY_COACHING_TYPES.map((value, key) => {
      options.push(<option value={value} key={key+1}>{value}</option>)
    })
    return (
      <Field
        name='coaching_type'
        id='coaching_type'
        as='select'
        defaultValue={coachingType}
        className='custom-select'        
        //onChange={e=>onChangeCoachingType(e)}
      >
      {options}
      </Field>
    )
  }

  const displayCoachingUrl = formik => {      
    let formCoachingType = formik.values.coaching_type
    formCoachingType = formCoachingType ? formCoachingType.toLowerCase() : ""
    let coachingType = formData.coaching_type ? formData.coaching_type.toLowerCase() : ""    
    
    if (props.mode !== VIEW_MODE && formCoachingType === 'others') {
      return (
        <Form.Group>
          <Form.Label className='req-star'>Coaching Url</Form.Label>
          {getCoachingUrlField()}
          <ErrorMessage
            name='coaching_url'
            render={msg => <div className='text-danger'>{msg}</div>}
          />
        </Form.Group>
      )
    }
    return null
  }

  const getCoachingUrlField = () => {    
    return (
      <Field
        name='coaching_url'
        id='coaching_url'
        type='text'
        defaultValue={formData.coaching_url}
        className='form-control'
      />
    )
  }

  const displayProgramStatus = () => {
    let programStatus = formData.status ? capitalizeFirstLetterOfWord(formData.status) : ""
    let options = []
    options.push(<option value='' key={0}>- Select -</option>)
    ARRAY_STATUS_TYPES.map((value, key) => {
      options.push(<option value={value} key={key+1}>{value}</option>)
    })
    return (
      <Field
        name='status'
        id='status'
        as='select'
        defaultValue={programStatus}
        className='custom-select'
      >
        {options}
      </Field>
    )
  }

  const displayProgramCallUrl = () => {    
    return (
      <Field
        name='calls_url'
        id='calls_url'
        type='text'
        defaultValue={formData.calls_url}
        className='form-control'
      />
    )
  }

  const showTableView = () => {
    return <table className="table table-striped tbldata">   
    <tbody>
      <tr>        
        <td><span className="font-weight-bold">Name of the program</span><br/>{formData.program_name}</td>
        <td><span className="font-weight-bold">Type of Program</span><br/>{formData.program_type}</td>
        <td><span className="font-weight-bold">Coaching Type</span><br/>{formData.coaching_type}</td>
      </tr>
      <tr>        
        <td><span className="font-weight-bold">Coaching Url</span><br/>{formData.coaching_url !== "" ? formData.coaching_url : TEXT_NOT_SPECIFIED}</td>
        <td><span className="font-weight-bold">Status of Program</span><br/>{formData.status}</td>
        <td><span className="font-weight-bold">Program Call Url</span><br/>{formData.calls_url}</td>
      </tr>
    </tbody>
  </table>
  }

  const showRequiredStarMark = () => {
    if (props.mode !== VIEW_MODE) {
      return <span className="text-danger"> *</span>
    }
    return null
  }

  const columns = [
    {
      Header: 'Selected Agents',
      columns: [
        {
          Header: 'First Name',
          accessor: 'name'
        },
        {
          Header: 'Action',
          accessor: 'action'
        }
      ]
    }
  ]

  const columnsPm = [
    {
      Header: 'Selected Program Managers',
      columns: [
        {
          Header: 'Name',
          accessor: 'name'
        },
        {
          Header: 'Action',
          accessor: 'action'
        }
      ]
    }
  ]

  const columnsPd = [
    {
      Header: 'Selected Program Directors',
      columns: [
        {
          Header: 'Name',
          accessor: 'name'
        },
        {
          Header: 'Action',
          accessor: 'action'
        }
      ]
    }
  ]

  return (
    <Row className='justify-content-center mt-5'>
      {props.isProcessing ? <OverlayLoader /> : null}
      <Col md={8}>
        <Card className="bg-transparent card-form no-bg">
       
          <Card.Body className='user-profile-card-body'>
            <Alert
              variant='success'
              show={props.showAlert}
              onClose={() => props.setShow()}
              dismissible
            >
              <p className='m-0'>
                <span>Program details are submitted.</span>
                <br />
              </p>
            </Alert>

            <Formik
              enableReinitialize={props.mode === EDIT_MODE}
              initialValues={formData}
              validationSchema={
                (props.mode === ADD_MODE || props.mode === EDIT_MODE) && validationSchema
              }
              onSubmit={(values, { setSubmitting }) => {
                values.selectedAgents = selectedAgents
                values.selectedPms = selectedPms
                values.selectedPds = selectedPds
                props.handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}

                  <Form> 
                    {props.mode === VIEW_MODE ? showTableView() : null}                                  
                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label className='req-star'>
                        Name of the Program{showRequiredStarMark()}
                      </Form.Label>
                      {displayProgramName()}
                      <ErrorMessage
                        name='program_name'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Type of Program{showRequiredStarMark()}</Form.Label>
                      {displayProgramType()}
                      <ErrorMessage
                        name='program_type'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Coaching Type{showRequiredStarMark()}</Form.Label>
                      {displayCoachingType()}
                      <ErrorMessage
                        name='coaching_type'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {displayCoachingUrl(formik)}

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Status of Program{showRequiredStarMark()}</Form.Label>
                      {displayProgramStatus()}
                      <ErrorMessage
                        name='status'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Program Call Url{showRequiredStarMark()}</Form.Label>
                      {displayProgramCallUrl()}
                      <ErrorMessage
                        name='calls_url'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>) : null}

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Select Program Director</Form.Label>
                      {props.mode === VIEW_MODE ? null : (
                        <SelectFetch
                          closeMenuOnSelect={false}
                          value={selectedPds}
                          onChange={onSelectedPdsChange}
                          get={getPaginatedPds}
                          queryParams={{
                            limit: 10
                          }}
                          isMulti
                          hideSelectedOptions={false}
                          defaultValue='- Select -'
                          controlShouldRenderValue={false}
                          components={{ Option }}
                        />
                      )}
                    </Form.Group>) : null}

                    <Form.Group>
                      <Accordion>                                                                     
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey='0'
                            className='selected_multi_input cursor-pointer'  
                            onClick={e=>handleToggle(e, 0)}                                                  
                          >
                          {toggleEvent && toggleEvent['0'] ? 'Hide' : 'Show'} Selected Program Directors
                            <ExpandMoreRounded className='float-right' />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey='0'>
                            <Card.Body className='p-0'>
                              { (pdTableData && pdTableData.length > 0) ? (
                                <ScrollableTable
                                  columns={columnsPd}
                                  data={pdTableData}
                                  deleteHandler={deletePd}
                                  disabled={props.mode === VIEW_MODE}
                                  displayHeaders={false}
                                />
                              ) : <small>No program directors were added</small>}
                            </Card.Body>
                          </Accordion.Collapse>                        
                      </Accordion>
                    </Form.Group>

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Select Program Manager{showRequiredStarMark()}</Form.Label>
                      {props.mode === VIEW_MODE ? null : (
                        <SelectFetch
                          closeMenuOnSelect={false}
                          value={selectedPms}
                          onChange={onSelectedPmsChange}
                          get={getPaginatedPms}
                          queryParams={{
                            limit: 10
                          }}
                          isMulti
                          hideSelectedOptions={false}
                          defaultValue='- Select -'
                          controlShouldRenderValue={false}
                          components={{ Option }}
                        />
                      )}
                    </Form.Group>) : null}

                    <Form.Group>
                      <Accordion>                       
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey='1'
                            className='selected_multi_input cursor-pointer'
                            onClick={e=>handleToggle(e, 1)}
                          >
                            {toggleEvent && toggleEvent['1'] ? 'Hide' : 'Show'} Selected Program Managers
                            {/* {toggleEvent && toggleEvent.key === 1 ? toggleEvent.show ? 'Hide' : 'Show' : 'Show' } Selected Program Managers */}
                            <ExpandMoreRounded className='float-right' />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey='1'>
                            <Card.Body className='p-0'>
                              { (pmTableData && pmTableData.length > 0) ?(
                                <ScrollableTable
                                  columns={columnsPm}
                                  data={pmTableData}
                                  deleteHandler={deletePm}
                                  disabled={props.mode === VIEW_MODE}
                                />
                              ) : <small>No program managers were added</small>}
                            </Card.Body>
                          </Accordion.Collapse>                       
                      </Accordion>
                    </Form.Group>

                    {props.mode !== VIEW_MODE ? (<Form.Group>
                      <Form.Label>Select Agents{showRequiredStarMark()}</Form.Label>

                      {props.mode === VIEW_MODE ? null : (
                        <SelectFetch
                          closeMenuOnSelect={false}
                          value={selectedAgents}
                          onChange={onSelectedAgentsChange}
                          get={getPaginatedAgents}
                          queryParams={{
                            limit: 10
                          }}
                          isMulti
                          hideSelectedOptions={false}
                          //defaultValue='Select......'
                          controlShouldRenderValue={false}
                          components={{ Option }}
                        />
                      )}
                    </Form.Group>) : null}

                    <Form.Group>
                      <Accordion>                        
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey='2'
                            className='selected_multi_input cursor-pointer'
                            onClick={e=>handleToggle(e, 2)}
                          >
                          {toggleEvent && toggleEvent['2'] ? 'Hide' : 'Show'} Selected Agents
                          {/* {toggleEvent && toggleEvent.key === 2 ? toggleEvent.show ? 'Hide' : 'Show' : 'Show' } Selected Agents */}
                            <ExpandMoreRounded className='float-right' />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey='2'>
                            <Card.Body className='p-0'>
                              { (agentTableData && agentTableData.length > 0) ? (
                                <ScrollableTable
                                  columns={columns}
                                  data={agentTableData}
                                  deleteHandler={deleteAgent}
                                  disabled={props.mode === VIEW_MODE}
                                />
                              ): <small>No agents were added</small>}
                            </Card.Body>
                          </Accordion.Collapse>                        
                      </Accordion>
                    </Form.Group>
                    <Card className='no-bg'>                   
                    <Card.Body className="pb-0"><h4>Metrics</h4>
                    <div className="d-grid">
                      <Form.Group className="overflow-auto"> 
                       <EditableTable
                       metricTableData={props.metricTableData}
                       updateMetricsTable={props.updateMetricsTable}
                       mode={props.mode}
                       metricErrors={props.metricErrors}
                     /></Form.Group></div></Card.Body></Card>             
                    
                  </Form>

                  <div>
                    <Link to='/dashboard/programs'><Button
                      variant='light'
                      type='button'
                      className='mr-3'
                    >
                      Cancel
                    </Button></Link>
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      disabled={formik.isSubmitting}                      
                    >                      
                      {(formik.isSubmitting) ? (                        
                        <ShowSpinner buttonSpinner={true} />
                      ) : null} 
                      {(props.mode === ADD_MODE) ? TEXT_ADD : (props.mode === EDIT_MODE) ? TEXT_UPDATE : TEXT_EDIT}                     
                    </Button>                                  
                  </div>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AddProgramComponent
