export { default as Layout } from './Layout'
// Landing exports
export { default as Home } from './landing/Home'
export { default as Login } from './landing/Login'
export { default as AdminLogin } from './landing/AdminLogin'
export { default as ForgotPassword } from './landing/ForgotPassword'
export { default as ResetPassword } from './landing/ResetPassword'
export { default as ErrorBoundary } from './landing/ErrorBoundary'
export { default as ErrorPage } from './landing/ErrorPage'

// Dashboard exports
export { default as Dashboard } from './dashboard/Dashboard'
export { default as Overview } from './dashboard/Overview'
export { default as Coaching } from './dashboard/Coaching'
export { default as ChangePassword } from './dashboard/ChangePassword'
export { default as AccountSettings } from './dashboard/AccountSettings'
export { default as Programs } from './dashboard/Programs'
export { default as Metrics } from './dashboard/Metrics'
export { default as ManageMetrics } from './dashboard/ManageMetrics'
export { default as FormSubmit } from './dashboard/FormSubmit'
export { default as AddProgram } from './dashboard/AddProgram'
export { default as AddCoaching } from './dashboard/AddCoaching'
export { default as AgentView } from './dashboard/AgentView'

export { default as Users } from './dashboard/Users'