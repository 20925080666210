if (typeof window === 'undefined') {
  global.window = {
    ENV: {       
      api_endpoint:'https://ccmsapi.teststage1.com/'                 
    },
    location: {
      protocol: 'http',
      host: 'glowtouch.com'
    }
  }
}
// export const BASE_API_URL = 'http://18.217.99.72:3200/' || 'http://localhost/'
// export const BASE_API_URL = 'http://127.0.0.1:5000/' || 'http://localhost/'
export const BASE_API_URL = window.ENV.api_endpoint || 'http://localhost/'
export const RESET_PASSWORD_URL = `${window.location.protocol}//${window.location.host}/reset-password`
export const MEMBER = 'member/'
export const USER = 'user/'
export const COMMAN_API = 'api'
export const GET_ALL_TARGET_CATEGORIES = `${MEMBER}protection/`
export const GET_PROGRAM = 'program/'
export const GET_DIRECTOR_PROGRAM = 'program/program_director/'
export const GET_PM_PROGRAM = 'program/program_manager/'
export const GET_AGENT_PROGRAM = 'program/agent/'
export const GET_METRICS = 'metrics/'
export const GET_NOTES = 'notes/'
export const GET_PROGRAM_METRICS = 'metrics/get_metrics/'
export const GET_PROGRAM_4_WEEKS_METRICS = 'metrics/get_4_week_metrics/'
export const GET_METRICS_AGGREGATE = 'metrics/aggregate/'
export const METRICS_IMPORT = 'metrics/import-data/'
export const GET_AGENT_ALL = 'user/agent'
export const GET_PROGRAM_MANAGER = 'user/program_manager'
export const GET_PROGRAM_DIRECTOR = 'user/program_director'
export const UPDATE_USER = 'user/update_user/'
export const RESET_USER_PASSWORD = 'user/reset_password'
export const CHANGE_USER_PASSWORD = 'user/change_password'
export const GET_DASHBOARD = 'dashboard/'
export const GET_PROFILE = `${MEMBER}profile/`
export const CHANGE_PASSWORD = `${MEMBER}change-password`
export const LOGIN = 'auth/login'
export const ADMIN_LOGIN = 'auth/admin_login'
export const LOGOUT = `auth/accounts/logout/`
export const REFRESH_TOKEN = `auth/accounts/refresh_token/`
export const REQUEST_PASSWORD_RESET = `auth/passwords/request_reset`
export const RESET_PASSWORD = `auth/passwords/set`
export const UPDATE_MEMBER = `${MEMBER}update-member/`
export const SEND_SUPPORT_MAIL = `${MEMBER}send_support_mail/`
export const VALIDATE_RESET_CODE = `auth/passwords/is_valid_code`
export const GET_COACHING = 'coaching/'
export const GET_WEEKLY_COACHING = 'coaching/weekly/'
export const SAVE_COACHING_ACKNOWLEDGEMENT = 'coaching/agent-acknowledgement/'


// Router paths
export const COACHING_URL = '/coaching'
export const NOTIFICATION_URL = '/notification'
export const METRICS_URL = '/metrics'
export const FORM_SUBMIT_YUP_URL = '/form-submit'
export const USER_NOT_FOUND_URL = '/user-notfound'
export const SUPPORT_URL = '/support'
export const ACCOUNT_SETTINGS_URL = '/dashboard/settings'
export const PROGRAMS_URL = '/programs'
export const MANAGE_METRICS_URL = '/settings/managemetrics'
export const DEFAULT_PROGRAM_SETTINGS_URL = '/settings/defaultprogram'
//export const ADD_PROGRAM_URL = '/dashboard/addprogram'
export const USERS_URL = '/users'
export const CHANGE_PASSWORD_URL = '/changepassword/'