import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import '../../styles/landing/hero.scss'

const Hero = () => {
  return (
    <Container fluid>
      <div className='hero-section d-flex h-100'>
        <Row className='justify-content-center align-items-center'>
          <Col md={5}>
              <h1 className='mb-3 hero-home'><span className='text-blue font-weight-bold'>GlowTouch</span> <br /> Coaching <br /> Platform</h1>
              <div className='pt-1 w-25 mb-3 mt-5'>
                <img src='assets/img/shine-borders.png' alt='Glowtouch CCMS'></img>
              </div>
              <p className='font-weight-bold'><span className='text-blue'>Built-in</span> <span className='text-orange text-uppercase'>Coaching forms</span></p>
          </Col>
          <Col className='pt-5 text-right' md={7}>
            <img src='assets/img/office.png' className='img-fluid' alt='Welcome' />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Hero
