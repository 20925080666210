/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import { Form as FormikForm, Formik } from 'formik'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import { programService, userService } from '../../services'
import { 
  ProgramSelectBar, 
  UnauthorizedCard,
  OverlayLoader,
  ShowSpinner
} from '../../components'
import { 
  isPm,
  isAdmin,
  isProgramDirector,
  getUserId,
  getUserData,  
  setUserData  
} from '../../helpers'
import {
  LOCAL_STORAGE_SELECTED_PROGRAM,  
  USER_DETAILS  
} from '../../constants'

/**
 * Class which manages user default program. User can select a program and mark it as default
 */
class ManageMetrics extends Component {
  state = {
    programs: [], // program manager programs list
    programOptions: [], // programs drop down options
    selectedProgram: null, // selected program in the drop down
    isLoading: false,
    showAlert: false,
    isFetchingProgram: false, // to indicate program details fetching
    isAuthorizedAccess:null,
    isProcessing:false
  }

  abortController = new AbortController()

  componentDidMount() {
    if(!isPm() && !isProgramDirector()) {   
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.setState(state => ({
        ...state,
        isLoading: true,
        isAuthorizedAccess: true,
      }))
      this.props.updateHeaderTxt('Manage Deafult Program', 'Settings')
      this.props.programBar.showAgents = false
      this.props.programBar.showWeeks = false
      this.props.programBar.showDateRange = false
      this.props.programBar.onPmChangeHook = this.onPmChangeHook      
      let selectedProgram = this.props.programBar.selectedProgram

      this.setState(state => ({
        ...state,
        isLoading: false,
        selectedProgram
      }))
    }    
  }

  componentWillUnmount() {
    if (this.state.isLoading) this.abortController.abort()
  }

  getDropDownProgram = (program) => {
    let dropDownProgram = {}

    dropDownProgram.value = program.program_name
    dropDownProgram.label = program.program_name
    dropDownProgram.id = program.public_id
    dropDownProgram.program = program

    return dropDownProgram
  }

  onPmChangeHook = data => {
    this.setFetchingProgramState(true)
    this.getProgramDetails(data.program.public_id)
  }

  setFetchingProgramState = isFetching => {
    this.setState(state => ({
      ...state,
      isFetchingProgram: isFetching
    }))
  }

  getProgramDetails = programPublicId => {
    if (programPublicId) {
      programService
        .get_program_by_id(programPublicId)
        .then(data => {          
          this.setState(state => ({
            ...state,
            isFetchingProgram: false,            
            selectedProgram: this.getDropDownProgram(data)
          }))
        })
        .catch(() => {
          // display error toast
          toast.error('Error while fetching Program details')
          this.setFetchingProgramState(false)
        })
    }
  }

  handleSubmit = (data, setSubmitting) => {
    if (!this.state.selectedProgram) {
      setSubmitting(false)
      toast.error('Please select a Program')
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
      return
    }

    this.setState(state => ({
      ...state,
      isProcessing: true
    }))

    const { selectedProgram } = this.state    
    userService
      .update_user({
        public_id: getUserId(),
        default_program: selectedProgram.program.public_id
       })
      .then(response => {        
        setSubmitting(false)
        this.setState(state => ({
          ...state,
          showAlert: true,
          isLoading: false
        }))

        // set the default program
        setUserData(LOCAL_STORAGE_SELECTED_PROGRAM, selectedProgram)

        // updated the default program of stored user data
        let user = getUserData(USER_DETAILS)
        user.default_program = selectedProgram.program.public_id        
        setUserData(USER_DETAILS, user)

        toast.success(
          'Successfully updated the default Program. You will be redirect to Settings Page in 10 seconds'
        )

        setTimeout(() => {
          this.setState(state => ({
            ...state,
            isProcessing: false
          }))
          this.props.history.replace('/dashboard/settings/')
        }, 6000)
      })
      .catch(error => {
        setSubmitting(false) 
        this.setState(state => ({
          ...state,
          isProcessing: false
        }))       
        toast.error("Error while setting default Program")
      })

    setSubmitting(true)
  }

  render() {
    const { isAuthorizedAccess, isLoading, isProcessing } = this.state
    
    if(isLoading || isAuthorizedAccess === null) {
      return <OverlayLoader />
    }

    if(isAuthorizedAccess) {
      return (<>
        {isProcessing ? <OverlayLoader /> : null}
        <div>
          <ProgramSelectBar programBar={this.props.programBar} />
        </div>
        <Row>
          <Col>
            <ErrorBoundary>             
              <Card className='bg-primary'>                
                <Card.Body className='user-profile-card-body'>
                  <Alert
                    variant='success'
                    show={this.state.showAlert}
                    onClose={() => this.setShow()}
                    dismissible
                  >
                    <p className='m-0'>
                      <span>Default program is set.</span>
                      <br />
                    </p>
                  </Alert>

                  <Formik
                    initialValues={this.state.programs}
                    values={this.state.selectedProgram}
                    onSubmit={(values, { setSubmitting }) => {
                      this.handleSubmit(values, setSubmitting)
                    }}
                  >
                    {formik => (
                      <FormikForm>
                        {formik.isSubmitting && (
                          <Alert variant='info'>
                            Submitting Information. Please wait...
                          </Alert>
                        )}
                        <Form.Row className="pt-3 pb-3">Click the button below to set the above selected program.</Form.Row>
                        <Form.Row>
                          <Link to='/dashboard/settings'><Button
                            variant='light'
                            type='button'
                            className='mr-3'
                          >
                            Cancel
                          </Button></Link>
                          <Button
                            variant='primary'
                            className='submit-button'
                            type='submit'
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting ? (
                              <ShowSpinner buttonSpinner={true} />
                            ) : null}
                            Set as Default Program
                          </Button>
                        </Form.Row>
                      </FormikForm>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </ErrorBoundary>
          </Col>
        </Row>
      </>)
    } else {
      return <UnauthorizedCard />
    }
  }  
}

export default ManageMetrics
