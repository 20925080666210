import React from 'react'
import { Link } from 'react-router-dom'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ShowSpinner from './ShowSpinner'
import moment from 'moment'

const NewNotes = ({ data, handleSubmit, showModal }) => {
  const validationSchema = Yup.object({})
  return (
    <Row className='justify-content-center'>
      <Col md={12}>
        <Card className='no-bg'>  
          <Card.Header>
            <h2 className='mb-0'>Add Notes</h2>
          </Card.Header>
          <Card.Body className='user-profile-card-body'>
            <Formik
              initialValues={data}
              validationSchema={validationSchema}
              // enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}

                  <Form.Row>
                    <Form.Group as={Col} md='6' controlId='title'>
                      <Form.Label>Title</Form.Label>
                      <Field
                        type='text'
                        name='title'
                        placeholder='Notes Title'
                        className='form-control'
                      />
                      <ErrorMessage
                        name='title'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md='6'>
                      <Form.Label>Notify</Form.Label>
                      <DatePicker
                        /*showWeekNumbers*/
                        selected={formik.values.notifyOn}
                        dateFormat='MM-dd-yyyy'
                        className='form-control'
                        minDate={moment().toDate()}
                        onChange={date => {
                          formik.setFieldValue('notifyOn', date, true)
                        }}
                      />
                      <ErrorMessage
                        name='notifyOn'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md='12' controlId='content'>
                      <Form.Label>Content</Form.Label>
                      <Field
                        as='textarea'
                        name='content'
                        placeholder='Content'
                        className='form-control'
                      />
                      <ErrorMessage
                        name='content'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'                     
                      onClick={() => showModal(false)}                      
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='primary'
                      className='submit-button'                      
                      type='submit'
                      onClick={() => {
                        formik.setFieldValue('traverse', 1)
                      }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}  
                      Save                    
                    </Button>
                  </Form.Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
export default NewNotes
