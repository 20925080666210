import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FormSubmitComponent } from '../../components'
import { ErrorBoundary } from '..'

class FormSubmit extends Component {
  state = {
    category: '',
    feedback: '',
    username: 'initial value is set here',
    showAlert: false,
    isLoading: true,
    missingFieldsLength: 0
  }

  handleSubmit = async (data, setSubmitting) => {
    if (/(<([^>]+)>)/gi.test(data.feedback)) {
      toast.info(
        'Only plain text allowed in contact support form. Any other special characters will be stripped out.'
      )
    }
    const message = data.feedback.replace(/(<([^>]+)>)/gi, '').trim()
    setSubmitting(true)    
  }

  setShow = () => {
    this.setState(state => ({
      ...state,
      showAlert: false
    }))
  }

  abortController = new AbortController()

  componentDidMount() {}

  render() {
    return (
      <Row>
        <Col>
          <ErrorBoundary>
            <FormSubmitComponent
              setShow={this.setShow}
              handleSubmit={this.handleSubmit}
              data={this.state}
            />
          </ErrorBoundary>
        </Col>
      </Row>
    )
  }
}

export default FormSubmit
