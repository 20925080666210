/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import React from 'react'
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import '../../styles/base.scss'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ShowSpinner from './ShowSpinner'
import { ADD_MODE, EDIT_MODE, VIEW_MODE } from '../../constants'

const AddCoachingConversationComponent = ({ ...props }) => {
  // Schema for yup
  const validationSchema = Yup.object({})
  const history = useHistory()

  const redirect = () => {
    history.push('/dashboard/coaching')
  }

  const requiredAsterisk = () => {  
    return <span className="text-danger"> *</span>
  }

  const getHeader = () => {
    if (props.mode === ADD_MODE) {
      return 'Coaching Conversation'
    }
    if (props.mode === EDIT_MODE) {
      return 'Edit Conversation'
    }
    return 'View Conversation'
  }

  const getSubHeader = () => {
    return 'Any conversations or follow ups, memos, notices, team communications, outside of scheduled weekly coaching'
  }

  return (
    <Row className='justify-content-center'>
      <Col md={8}>
        <Card className='bg-transparent card-form pt-5'>
          <Card.Body className='user-profile-card-body'>
            <Row>
              <Col md={12}>
                <div className='txt_coaching_header text-center'>
                  <h2 className='mb-0'>{getHeader()}</h2>
                  <h5 className='mt-1 mb-2'>{getSubHeader()}</h5>
                  <h3 className='mb-3'>{props.message}</h3>
                </div>
              </Col>
            </Row>
            <Formik
              initialValues={props.data}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                props.handleSubmit(values, setSubmitting)
              }}
            >
              {formik => (
                <FormikForm className='text_dark_coaching_form'>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}
                  <div className='form-section form-section-orange'>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md='12'
                        controlId='conversation_date'
                      >
                        <Form.Label>Conversation Date: {requiredAsterisk()}</Form.Label>
                        <DatePicker
                          /*showWeekNumbers*/
                          selected={formik.values.conversation_date}
                          dateFormat='MM-dd-yyyy'
                          className='form-control'
                          onChange={date => {
                            formik.setFieldValue(
                              'conversation_date',
                              date,
                              true
                            )
                          }}
                        />
                        <ErrorMessage
                          name='conversation_date'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md='12'
                        controlId='conversation_type'
                      >
                        <Form.Label>Type of Conversation:</Form.Label>
                        <Field
                          name='conversation_type'
                          id='conversation_type'
                          as='select'
                          defaultValue={props.data.program_type}
                          className='custom-select'
                        >
                          <option value=''>Select</option>
                          <option value='CHAT'>Quality Coaching</option>
                          <option value='CALLS'>
                            Team Communications - HR
                          </option>
                          <option value='BOTH'>
                            Team Communications - Client
                          </option>
                          <option value='BOTH'>
                            Attendance - Point Accrual Notice
                          </option>
                          <option value='BOTH'>Break / Lunch</option>
                          <option value='BOTH'>
                            Process Change / Knowledge Check
                          </option>
                          <option value='BOTH'>Behavior</option>
                          <option value='BOTH'>Performance</option>
                          <option value='BOTH'>Others</option>
                        </Field>
                        <ErrorMessage
                          name='conversation_type'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className='form-section form-section-purple'>                    
                        <h3 className='form-section-title form-section-title-purple'>Conversation Details</h3>
                        <h5 className='form-section-subtitle'>Manager and Agent Verbatim</h5>                      
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md='12'
                        controlId='manager_interaction_details'
                      >
                        <Form.Label>
                          Managers: Please enter any details, interaction IDs or
                          dates, other supporting information for reason for
                          conversation should go below.
                        </Form.Label>
                        <Field
                          as='textarea'
                          name='manager_interaction_details'
                          placeholder='Manager Interaction Details'
                          rows='3'
                          className='form-control'
                        />
                        <ErrorMessage
                          name='manager_interaction_details'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md='12' controlId='agent_response'>
                        <Form.Label>Agent Response:</Form.Label>
                        <Field
                          as='textarea'
                          name='agent_response'
                          placeholder='Conversation Details'
                          rows='3'
                          className='form-control'
                        />
                        <ErrorMessage
                          name='agent_response'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Form.Row>
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'
                      //as={Link}
                      // to='/dashboard/coaching'
                      onClick={() => {
                        if (formik.dirty) {
                          const shouldRedirect = confirm(
                            'You have modified the form? if you select okay your changes will be lost'
                          )
                          if (shouldRedirect) {
                            props.programBar.onAgentChange({
                              agent: {},
                              label: 'All',
                              value: ''
                            })
                            redirect()
                          }
                        } else {
                          props.programBar.onAgentChange({
                            agent: {},
                            label: 'All',
                            value: ''
                          })
                          redirect()
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      onClick={() => {
                        formik.setFieldValue('save', true)
                        formik.setFieldValue('close', true)
                      }}
                      disabled={
                        formik.isSubmitting ||
                        props.isFetchingProgram ||
                        props.isLoading
                      }
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Submit
                    </Button>
                    &nbsp;
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'                      
                      onClick={() => {
                        formik.setFieldValue('traverse', -1)
                      }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Back
                    </Button>
                  </Form.Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AddCoachingConversationComponent
