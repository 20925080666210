/* eslint-disable radix */
import React from 'react'
import { useTable } from 'react-table'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import MaUTable from '@material-ui/core/Table'
import '../../styles/metrics-card.scss'
import '../../styles/base.scss'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PanTool, Cancel } from '@material-ui/icons'
import { 
  ARRAY_DATA_TYPES, 
  ARRAY_AGGREGATION_TYPES, 
  METRIC_HEADER_EMP_CODE,
  METRIC_HEADER_TEAM,
  METRIC_HEADER_AT_RISK,
  ADD_MODE,
  EDIT_MODE,
  VIEW_MODE
 } from '../../constants'

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  return value
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  aggregationType,
  updateMyData,
  handleChange,
  handleIntChange,
  handleDataTypeChange,
  handleChangeCheckBox,
  skipPageReset,
  handleRemoveRow,
  setData,
  pageMode,
  onBlur,
  updateMetricsTable
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns,
    data,
    defaultColumn,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
    handleChange,
    handleIntChange,
    handleDataTypeChange,
    handleChangeCheckBox,
    setData,
    pageMode,
    onBlur,
    aggregationType,
    updateMetricsTable
  })
  const moveRow = (dragIndex, hoverIndex) => {
    const newData = []
    const dragRecord = data[dragIndex]

    data.map((row, index) => {
      if (index !== dragIndex) {
        if (dragIndex < hoverIndex) {
          newData.push(row)
          newData[parseInt(newData.length) - 1].priority = newData.length
          if (index === hoverIndex) {
            newData.push(dragRecord)
            newData[parseInt(newData.length) - 1].priority = newData.length
          }
        } else if (dragIndex > hoverIndex) {
          if (index === hoverIndex) {
            newData.push(dragRecord)
            newData[parseInt(newData.length) - 1].priority = newData.length
          }
          newData.push(row)
          newData[parseInt(newData.length) - 1].priority = newData.length
        }
      }
    })
    setData(newData)
    updateMetricsTable(newData)
  }

  const handleCellClick = (e, cell) => {
    handleRemoveRow(cell)
  }

  const showTableBody = () => {
    if (data.length > 0)
      return (
        <TableBody {...getTableBodyProps()}>          
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <TblRow
                key={index}
                index={index}
                row={row}
                moveRow={moveRow}
                handleCellClick={handleCellClick}
                handleChange={handleChange}
                handleIntChange={handleIntChange}
                handleDataTypeChange={handleDataTypeChange}
                handleChangeCheckBox={handleChangeCheckBox}
                pageMode={pageMode}
                data={data}
                aggregationType={aggregationType}
                onBlur={onBlur}
                /* height='20px' */
                {...row.getRowProps()}
              />
            )
          })}
        </TableBody>
      )
    return (
      <TableBody {...getTableBodyProps()}>
        <TableRow>
          <TableCell colSpan='6'>No metrics were added</TableCell>
        </TableRow>
      </TableBody>
    )
  }

  // Render the UI for table
  return (
    <DndProvider backend={HTML5Backend}>
      <MaUTable {...getTableProps()} className='table table-sm card-table'>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...column.getHeaderProps({
                    style: { width: `${column.width}px` }
                  })}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {showTableBody()}
      </MaUTable>
    </DndProvider>
  )
}

const DND_ITEM_TYPE = 'row'

const TblRow = ({
  row,
  index,
  moveRow,
  handleCellClick,
  handleChange,
  handleIntChange,
  handleDataTypeChange,
  handleChangeCheckBox,
  pageMode,
  data,
  aggregationType,
  onBlur
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const options1 = [
    { value: '', label: 'None', icon: 'bar' },
    { value: 'item1', label: 'Item 1', icon: 'bar' },
    { value: 'icon-folder', label: 'icon-folder', icon: 'folder' }
  ]
  preview(drop(dropRef))
  drag(dragRef)

  const getDefaultSettings = (row) => {
    let cells = row.cells
    let objDefaultSettings = {disable_metric:false, disable_data_type:false, disable_aggregation_type:false, disable_is_editable:false, disable_key_metric:false, delete:true }
    cells.map((cell, index) => {
      if(cell.value === METRIC_HEADER_EMP_CODE) {
        objDefaultSettings = {disable_metric:true, disable_data_type:true, disable_aggregation_type:true, disable_is_editable:true, disable_key_metric:true, delete:false }
      }
      if(cell.value === METRIC_HEADER_TEAM) {
        objDefaultSettings = {disable_metric:true, disable_data_type:true, disable_aggregation_type:true, disable_is_editable:true, disable_key_metric:true, delete:false }
      }
      if(cell.value === METRIC_HEADER_AT_RISK) {
        objDefaultSettings = {disable_metric:true, disable_data_type:true, disable_aggregation_type:true, disable_is_editable:false, disable_key_metric:true, delete:false }
      }
    })
    return objDefaultSettings    
  }

  const showTableCells = (cell, deafultSettings) => { 
    if (cell.column.Header === 'Action') {      
      return (
        <OverlayTrigger
          key='top'
          placement='top'
          overlay={<Tooltip id='tooltip-top'>Remove</Tooltip>}
        >
          <Button
            type='button'
            variant='link'
            className='btn btn-sm text-danger'
            onClick={e => handleCellClick(e, cell)}
            disabled={!deafultSettings.delete || pageMode === VIEW_MODE}
          >
            <Cancel />
          </Button>
        </OverlayTrigger>
      )
    }
    if (cell.column.Header === 'Metrics' || cell.column.id === 'units') {
      const inputWidth = cell.column.Header === 'Metrics' ? '150px' : '50px'      
      return pageMode === VIEW_MODE ? (
        cell.value
      ) : (
        <input
          value={cell.value}
          disabled={(cell.column.Header === 'Metrics' && deafultSettings.disable_metric) || pageMode === VIEW_MODE}
          onChange={e => handleChange(e, cell)}
          className='form-control form-control-sm'
          style={{ width: inputWidth }}
        />
      )
    }
    if (cell.column.Header === 'Data Type') {
      const options = []
      if (cell.value === 'String') aggregationType[cell.row.index] = ['None']
      else if (cell.value === 'Number')
        aggregationType[cell.row.index] = ['None', 'Sum', 'Average']
      else if (cell.value === 'Boolean')
        aggregationType[cell.row.index] = ['None', 'Count']
      else if (cell.value === 'Percentage')
        aggregationType[cell.row.index] = ['None', 'Average']

      ARRAY_DATA_TYPES.map((value, key) => {
        options.push(
          <option
            value={value}
            key={key + 1}
            onChange={e => {
              handleDataTypeChange(e, cell)
            }}
          >
            {value}
          </option>
        )
      })

      return pageMode === VIEW_MODE ? (
        cell.value
      ) : (
        <div className='selectdiv tblCustomDropdown'>
          <select
            value={cell.value}
            className='form-control form-control-sm'
            onChange={e => {
              handleChange(e, cell)
              handleDataTypeChange(e, cell)
            }}
            style={{ width: '100px' }}
            disabled={deafultSettings.disable_data_type}
          >
            {options}
          </select>
        </div>
      )
    }
    if (cell.column.id === 'icon') {
      const options = []
      options1.map((item, key) => {
        options.push(
          <option value={item.value} key={key + 1}>
            {item.label}
          </option>
        )
      })
      return pageMode === VIEW_MODE ? (
        cell.value
      ) : (
        <div className='selectdiv tblCustomDropdown'>
          <select
            value={cell.value}
            className='form-control form-control-sm'
            onChange={e => handleChange(e, cell)}
            style={{ width: '100px' }}
          >
            {options}
          </select>
        </div>
      )
    }
    if (cell.column.id === 'aggregation') {
      const options = []      
      const aggregationLoop = aggregationType[cell.row.index] || ARRAY_AGGREGATION_TYPES
      aggregationLoop.map((value, key) => {
        options.push(
          <option value={value} key={key + 1}>
            {value}
          </option>
        )
      })
      return pageMode === VIEW_MODE ? (
        cell.value
      ) : (
        <div className='selectdiv tblCustomDropdown'>
          <select
            value={cell.value}
            className='form-control form-control-sm'
            onChange={e => handleChange(e, cell)}
            style={{ width: '100px' }}
            disabled={deafultSettings.disable_aggregation_type}            
          >
            {options}
          </select>
        </div>
      )
    }
    if (cell.column.Header === 'Threshold') {
      const fieldValue = cell.value
      return pageMode === VIEW_MODE ? (
        fieldValue
      ) : (
        <input
          value={fieldValue}
          onChange={e => handleIntChange(e, cell, false)}
          onBlur={e => handleIntChange(e, cell, true)}
          className='form-control form-control-sm'
          style={{ width: '50px' }}
        />
      )
    }
    if (cell.column.Header === 'Field Editable') {
      const fieldValue = cell.value
      return pageMode === VIEW_MODE ? (
        fieldValue
      ) : (
        <input
          value={fieldValue}
          type='checkbox'
          onChange={e => handleChange(e, cell)}
          onBlur={e => onBlur(e, cell)}
          className='form-control form-control-sm'
        />
      )
    }
    if (cell.column.id === 'editable' || cell.column.id === 'is_key_metrics') {
      const fieldValue = cell.value
      // eslint-disable-next-line no-nested-ternary
      return pageMode === VIEW_MODE ? (
        fieldValue ? (
          'Yes'
        ) : (
          'No'
        )
      ) : (
        <input
          type='checkbox'
          checked={fieldValue}
          value={fieldValue}
          onChange={e => handleChangeCheckBox(e, cell)}
          disabled={(cell.column.id === 'editable' && deafultSettings.disable_is_editable) || (cell.column.id === 'is_key_metrics' && deafultSettings.disable_key_metric)}
        />
      )
    }
    if (cell.column.Header === 'Sort') {
      return data.length > 1 ? (
        <div className='rowDragHandle canDrag'>
          <OverlayTrigger
            key='top'
            placement='top'
            overlay={<Tooltip id='tooltip-top'>Drag</Tooltip>}
          >
            <Button
              type='button'
              variant='link'
              className='btn btn-sm text-primary'
              disabled={pageMode === VIEW_MODE}
            >
              <PanTool />
            </Button>
          </OverlayTrigger>
        </div>
      ) : (
        <div />
      )
    }
    return cell.render('Cell')
  }

  return (
    <tr ref={dropRef}>      
      {row.cells.map((cell, cellIndex) => {
        if (cell.column.Header === 'Sort') {
          return (
            <td ref={dragRef} key={cellIndex}>
              {showTableCells(cell, getDefaultSettings(row))}
            </td>
          )
        }
        return (
          <td {...cell.getCellProps()} key={cellIndex}>
            {showTableCells(cell, getDefaultSettings(row))}
          </td>
        )
      })}
    </tr>
  )
}

const EditableTable = ({ ...props }) => {
  const columns = React.useMemo(() => [
    {
      Header: 'priority',
      accessor: 'priority'
    },
    {
      Header: 'Metrics',
      accessor: 'metrics_name'
    },
    {
      Header: 'Data Type',
      accessor: 'data_type'
    },
    {
      Header: 'Aggregation Type',
      accessor: 'aggregation'
    },
    // {
    //   Header: 'Icon Image',
    //   accessor: 'icon'
    // },
    {
      Header: 'Units',
      accessor: 'units'
    },
    {
      Header: 'Threshold',
      accessor: 'threshold'
    },
    {
      Header: 'Is Editable?',
      accessor: 'editable'
    },
    {
      Header: 'Is Key Metrics',
      accessor: 'is_key_metrics'
    }
  ])

  if (props.mode !== VIEW_MODE) {
    columns.push({
      Header: 'Action',
      accessor: 'action'
    })
    columns.push({
      Header: 'Sort',
      accessor: 'order_sort'
    })
  }
  React.useEffect(() => {
    setData(props.metricTableData)
    setPageMode(props.mode)
    setMetricErrors(props.metricErrors)
  }, [props.metricTableData, props.mode, props.metricErrors])

  const [data, setData] = React.useState(() => {
    return props.metricTableData
  })

  const [pageMode, setPageMode] = React.useState(props.mode)
  const [metricErrors, setMetricErrors] = React.useState(props.metricErrors)
  const [originalData] = React.useState(data)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [aggregationType, getAggregationType] = React.useState(() => {
    return {}
  })
  const handleDataTypeChange = (e, cell) => {
    //['None', 'Sum', 'Average', 'Count']

    if (e.target.value === 'String') aggregationType[cell.row.index] = ['None']
    else if (e.target.value === 'Number')
      aggregationType[cell.row.index] = ['None', 'Sum', 'Average']
    else if (e.target.value === 'Boolean')
      aggregationType[cell.row.index] = ['None', 'Count']
    else if (e.target.value === 'Percentage')
      aggregationType[cell.row.index] = ['None', 'Average']
  }

  const handleChange = (e, cell) => {    
    const fieldValue = e.target.value
    data[cell.row.index][cell.column.id] = fieldValue
    setData(data)
    props.updateMetricsTable(data)
  }
  const handleIntChange = (e, cell, onBlur) => {
    const fieldValue = parseFloat(e.target.value)
    if (!onBlur || Number.isNaN(fieldValue)) {
      data[cell.row.index][cell.column.id] = e.target.value
    } else {
      data[cell.row.index][cell.column.id] = fieldValue
    }
    setData(data)
    props.updateMetricsTable(data)
  }
  const handleChangeCheckBox = (e, cell) => {
    const fieldValue = e.target.checked
    data[cell.row.index][cell.column.id] = fieldValue
    setData(data)
    props.updateMetricsTable(data)
  }
  const onBlur = (e, cell) => {
    const fieldValue = e.target.value
    data[cell.row.index][cell.column.id] = fieldValue
    setData(data)
    props.updateMetricsTable(data)
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          }
        }
        return row
      })
    )
    props.updateMetricsTable(data)
  }

  const handleRemoveRow = cell => {
    const removeIndex = cell.row.index
    const newData = []
    data.map((row, index) => {
      if (index !== removeIndex) {
        newData.push(row)
        newData[parseInt(newData.length) - 1].priority = newData.length
      }
    })
    setData(newData)
    props.updateMetricsTable(newData)
  }

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const addNewRow = () => {
    const highestPriority = data.length
    const obj = {
      metrics_name: '',
      data_type: 'String',
      aggregation: 'None',
      threshold: 0.0,
      icon: '',
      units: '',
      priority: parseInt(highestPriority) + 1,
      editable: false,
      is_key_metrics: false
    }

    data.push(obj)
    setData(data)
    props.updateMetricsTable(data)
  }

  return (
    <>
      <div className='d-grid'>
        <div className='overflow-auto'>
          <Table
            columns={columns}
            data={data}
            updateMyData={updateMyData}
            handleChange={handleChange}
            handleIntChange={handleIntChange}
            handleDataTypeChange={handleDataTypeChange}
            handleChangeCheckBox={handleChangeCheckBox}
            handleRemoveRow={handleRemoveRow}
            skipPageReset={skipPageReset}
            setData={setData}
            pageMode={pageMode}
            onBlur={onBlur}
            aggregationType={aggregationType}
            updateMetricsTable={props.updateMetricsTable}
          />
        </div>
      </div>
      <div className='text-danger'>
        {metricErrors && metricErrors.isError ? metricErrors.errorMsg : ''}
      </div>
      {pageMode === VIEW_MODE ? (
        ''
      ) : (
        <Button
          className='btn btn-sm btn-primary mt-3 mb-3 ml-2'
          onClick={addNewRow}
          disabled={pageMode === VIEW_MODE}
        >
          {' '}
          Add Metrics Row
        </Button>
      )}
    </>
  )
}

export default EditableTable