import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { AdminOverviewCard, OverlayLoader, OverviewCard } from '../../components'
import {
  LOCAL_STORAGE_SELECTED_AGENT,
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_SELECTED_WEEK,
  USER_DETAILS
} from '../../constants'
import {
  isAdmin,
  isPm,
  isAgent,
  isProgramDirector,
  getUserData,
  getWeeklyProgramMetrics,
  getWeeklyProgramMetricQuery
} from '../../helpers'
import { metricsService } from '../../services'
import { notesService } from '../../services/notes.service'

class Overview extends Component {
  state = {
    isLoading: false,
    notes: [],
    newModal: false,
    agents: [],
    programs: [],
    categories: [],
    metricData: [],
    selectedProgram: null,
    notesPageIndex: 0,
    hasNextNotes: false,
    areNotesLoading: false,
    selectedViewType: null,
    loggedUser: null,
    savingNote: false,
    updatingNote: false, 
    notesFullData:[],
    notePageSize:5,
    showPopupModal: false,
    noteId: ''
  }

  abortController = new AbortController()

  componentDidMount = async () => {
    this.props.updateHeaderTxt('Dashboard', '')
    this.props.programBar.onAgentChange({
      agent: {},
      label: 'All',
      value: ''
    })

    this.setState(state => ({
      ...state,
      isLoading: this.props.isLoading
    }))

    this.props.programBar.showAgents = !isAgent() // only PM and PD have Agent Dropdown
    this.props.programBar.onPmChangeHook = this.onPmChangeHook
    this.props.programBar.onAgentChangeHook = this.onAgentChangeHook
    this.props.programBar.onWeekChangeHook = this.onWeekChangeHook
    this.props.programBar.onViewTypeChangeHook = this.onViewTypeChangeHook
    this.props.programBar.showWeeks = true
    /*if (isPm() || isProgramDirector()) {
      this.props.programBar.showAgents = true //
    }*/
    
    this.getMetrics()
    const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
    if (selectedProgram) {
      this.getProgramNotes(0, selectedProgram)
    }
  }

  getMetrics = () => {
    const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
    const selectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)
    const selectedWeek = getUserData(LOCAL_STORAGE_SELECTED_WEEK)
    if (selectedProgram && selectedWeek) {
      this.setState(state => ({
        ...state,
        isLoading: true
      }))
      const { week } = selectedWeek
      const selectedProgramId = selectedProgram.value
      const userData = getUserData(USER_DETAILS)
      let agentId = null
      if (isAgent()) {
        agentId = userData ? userData.public_id : null
      } else {
        agentId = selectedAgent ? selectedAgent.value : null
      }

      const query = getWeeklyProgramMetricQuery(
        week.startYear,
        week.weekNo,
        selectedProgramId,
        agentId
      )

      Promise.all([
        metricsService.get_program_metrics(query),
        metricsService.get_program_metrics_aggregate(query, isAgent())
      ]).then(values => {
          const [metricData, aggregate] = values
          this.setState({
            selectedProgram,
            selectedWeek,
            metricData,
            aggregate,
            isLoading: false
          })
        })
        .catch(error => {
          toast.error('Error while fetching program metrics details', error)
        })

      getWeeklyProgramMetrics(
        week.startYear,
        week.weekNo,
        selectedProgramId,
        agentId
      )
        .then(data => {
          this.setState({
            selectedProgram,
            selectedWeek,
            metricData: data
          })
        })
        .catch(error => {
          toast.error(
            'Error while fetching weekly program metrics details',
            error
          )
        })
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: this.props.isLoading
      })
    }
  }

  getProgramNotes = (notesPageIndex, selectedProgram) => {  
    const { notePageSize } = this.state
    const programId =  selectedProgram.program.public_id 

    if (programId) {      
      this.setState({
        areNotesLoading: true
      })
      let queryString = `?page_size=${notePageSize}&page_no=${notesPageIndex + 1}&program_id=${programId}`       
      if (isAgent()) {
        queryString = `?page_size=${notePageSize}&page_no=${notesPageIndex + 1}`
      }

      Promise.all([notesService.get_paginated_notes(queryString)])
        .then(([data]) => {                 
          this.setNotesState(data, data.has_next, false)
        })
        .catch(() => {
          this.setNotesState([], false, false)
        })
    }
  }

  setNotesState = (notesData, hasNext, loading) => {
    const { notePageSize } = this.state
    let notes = notesData.data
    let notesPageIndex =  notesData.total > 0 ? (Math.ceil(notesData.total/notePageSize)-1) : 0    
    this.setState(state => ({
      ...state,
      notesFullData: notesData,
      notes,
      hasNextNotes: hasNext,
      areNotesLoading: loading,
    }))
  }

  onPmChangeHook = program => {
    this.setState(state => ({
      ...state,
      selectedProgram: program,
      notesPageIndex: 0,
      isLoading: true
    }))
    this.getMetrics()
    this.getProgramNotes(0, program)
  }

  onViewTypeChangeHook = viewType => {
    this.setState(state => ({
      ...state,
      selectedViewType: viewType
    }))
  }

  onAgentChangeHook = () => {
    this.getMetrics()
  }

  onWeekChangeHook = () => {
    this.getMetrics()
  }

  componentWillUnmount() {
    if (this.state.isLoading === true) this.abortController.abort()
  }

  saveNotes = (data, setSubmitting) => {
    const userData = getUserData(USER_DETAILS)
    setSubmitting(true)
    this.setState(state => ({
      ...state,
      savingNote: true,
      showAlert: true
    }))
    const obj = {
      title: data.title,
      content: data.content,
      note_type: 'SELF',
      status: 'Active',
      attachment_url: '',
      notify_on: data.notifyOn,
      linked_entity_type: '',
      linked_entity_id: '',
      users_ids: [userData ? userData.public_id : '']
    }  
    
    notesService.add_notes(obj).then((response) => {
      this.setState(state => ({
        ...state,
        showAlert: false,         
        newModal: false
      }))
      let prevTotalRecords = this.state.notesFullData.total
      let newTotalRecords = prevTotalRecords + 1
      let newPageIndex = Math.ceil(newTotalRecords/this.state.notePageSize)-1
      toast.success('Successfully added the notes')
      this.getProgramNotes(newPageIndex, this.state.selectedProgram)
      this.setState(state => ({
        ...state,
        savingNote: false,          
      }))
      setSubmitting(false)      
    }).catch((error) => {      
      this.setState(state => ({
        ...state,        
        savingNote: false,
        newModal: false
      }))    
      setSubmitting(false)        
      toast.error('Error while creating new Note')
    })
  }

  showModal = show => {
    this.setState(state => ({
      ...state,
      newModal: show
    }))
  }

  updateSelectedNotes = async(id) => {
    this.setState(state => ({
      ...state,
      updatingNote: true
    }))
    
    this.state.notes.map(note => {
      if (note.public_id === id) {
        note.status= 'Completed'
        notesService.add_notes(note).then((response) => {
          toast.success('Successfully marked note as completed')
          let prevTotalRecords = this.state.notesFullData.total
          let newTotalRecords = prevTotalRecords > 0 ? prevTotalRecords - 1 : 0
          let newPageIndex = newTotalRecords > 0 ? Math.ceil(newTotalRecords/this.state.notePageSize)-1 : 0
          this.getProgramNotes(newPageIndex, this.state.selectedProgram)
          this.setState(state => ({
            ...state,
            updatingNote: false,          
          }))
        }).catch((error) => {      
          this.setState(state => ({
            ...state,        
            updatingNote: false,            
          }))             
          toast.error('Error while marking note as completed')
        })
      }
    })
  }

  toggleSelected = e => {
    this.state.notes.map(note => {
      if (note.public_id === e.target.id) {
        if (e.target.checked) {
          note.status = 'Active'
        } else {
          note.status = 'Completed'
        }
      }

      return note
    })
    this.setState(state => ({
      ...state,
      notes: state.notes
    }))
  }

  showMoreNotes = () => {      
    if (this.state.hasNextNotes && this.state.selectedProgram.program) {
      const nextIndex = this.state.notesPageIndex + 1
      this.getProgramNotes(nextIndex, this.state.selectedProgram)
      this.setState(state => ({
        ...state,
        notesPageIndex: nextIndex
      }))
    } else {
      // toast.error('No Plans Found.')
    }
  }

  showPreviousNotes = () => {
    if (
      this.state.notesFullData &&
      this.state.notesFullData.current_page > 1 &&
      this.state.selectedProgram.program
    ) {
      const previousIndex = this.state.notesPageIndex - 1
      this.getProgramNotes(previousIndex, this.state.selectedProgram)
      this.setState(state => ({
        ...state,
        notesPageIndex: previousIndex
      }))
    } else {
      // toast.error('No Plans Found.')
    }
  }

  handleModalClose = () => {    
    this.setState(state => ({
      ...state,      
      showPopupModal: false
    }))
  }

  onClickNotesComplete = (e, public_id) => {      
    this.setState(state => ({
      ...state,      
      showPopupModal: true, 
      noteId: public_id     
    }))
  }

  confirmNotesComplete = () => {
    this.setState(state => ({
      ...state,      
      showPopupModal: false,       
    }))
    this.updateSelectedNotes(this.state.noteId)    
  }

  render() {
    const { isLoading } = this.state
    if (isAdmin()) {
      return <AdminOverviewCard programBar={this.props.programBar} />
    }
    return (
      <section>         
        <OverviewCard
          programBar={this.props.programBar}
          notes={this.state.notes}
          toggleSelected={this.toggleSelected}
          updateSelectedNotes={this.updateSelectedNotes}
          categories={this.state.categories}
          newModal={this.state.newModal}
          showModal={this.showModal}
          saveNotes={this.saveNotes}
          metricData={this.state.metricData}
          aggregate={this.state.aggregate}
          showMoreNotes={this.showMoreNotes}
          showPreviousNotes={this.showPreviousNotes}
          areNotesLoading={this.state.areNotesLoading}
          isLoading={this.state.isLoading}
          hasNextNotes={this.state.hasNextNotes}
          savingNote={this.state.savingNote}
          updatingNote={this.state.updatingNote}
          notesFullData={this.state.notesFullData}
          confirmNotesComplete={this.confirmNotesComplete}
          showPopupModal={this.state.showPopupModal}
          onClickNotesComplete={this.onClickNotesComplete}
          handleModalClose={this.handleModalClose}
        />
      </section>
    )
  }
}

export default Overview