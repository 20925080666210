import React, { useState, useEffect } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { setInitialLoginTime, setUserData, deleteData } from '../../helpers'
import { userService } from '../../services'
import { IS_TIMER_PAUSED } from '../../constants'
import ShowSpinner from './ShowSpinner'

const SessionValidator = ({ ...props }) => {
  const [show, setShow] = useState(false)
  const [inTime, setInTime] = useState(props.inTime)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleContinue = () => {
    setUserData(IS_TIMER_PAUSED, 'true')
    setIsLoading(true)
    userService
      .refresh_token()
      .then(response => {
        deleteData(IS_TIMER_PAUSED)
        setInitialLoginTime(response)
        setIsLoading(false)
        setShow(false)
      })
      .catch(() => {})
  }

  useEffect(() => {
    setShow(props.show)
    setInTime(props.inTime)
  }, [props.show, props.inTime])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Session Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        <p>
          Your login session will expire in&nbsp;
          {inTime >= 0 ? Math.floor(inTime) : 0}
          &nbsp;seconds!
        </p>
        <p>Do you wish to continue or logout?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={handleContinue} disabled={isLoading}>
          {isLoading && (
            <ShowSpinner buttonSpinner={true} />
          )}
          Yes, Continue
        </Button>
        <Button variant='primary' onClick={userService.logout}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SessionValidator
