import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getSessionVariable, handleURLSearchParams } from '../helpers'
import { VALIDATE_SESSION_MSG, AUTH_TOKEN_NAME } from '../constants'
import { LoadingSpinner } from '../components'

class PrivateRoute extends Component {
  timeout = null

  state = { timeToRedirect: false }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        timeToRedirect: true
      })
    }, 6000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { component: WrappedComponent, ...rest } = this.props
    const { timeToRedirect } = this.state
    if (!timeToRedirect && !getSessionVariable(AUTH_TOKEN_NAME)) {
      return <LoadingSpinner text={VALIDATE_SESSION_MSG} />
    }
    return (
      <Route
        {...rest}
        render={props => {
          handleURLSearchParams(props.location)

          return getSessionVariable('token') ? (
            <WrappedComponent {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }}
      />
    )
  }
}

export default PrivateRoute
