import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Settings from '@settings'
import { isLoggedIn } from '../../helpers'

const HomeFooter = () => {
  const d = new Date()

  const showAdminLogin = () => {   
    if (!isLoggedIn()) {      
      return <React.Fragment>
        <span className='mx-2'>|</span>
        <a className='font-weight-light' href={'/admin-login'}>
          Admin login
         </a>
      </React.Fragment>
    }
    return null
  }

  return (
    <footer className='py-4 text-blue bg-white font-weight-bold'>
        <Container fluid>
          {!isLoggedIn() ?
            <Row>
              <Col md="6" sm="12">
              <span><img src='assets/img/glowtouch-footer-logo.png' alt='Glowtouch CCMS' className='pr-4'></img> </span>
              <span>&copy; {d.getFullYear()}</span> {Settings.brandName}. |  All Rights Reserved.
              </Col>
              <Col md="6" sm="12" className='text-right'>
              <a
                className='text-blue font-weight-bold text-lowercase'
                href={`mailto:${Settings.supportEmail}`}
              >
                {Settings.supportEmail}
              </a>
              {/* { showAdminLogin() } */}
              </Col>
            </Row>
          : null }
        </Container>
    </footer>
  )
}

export default HomeFooter