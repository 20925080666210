/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react'
import '../../styles/metrics-card.scss'
import MaUTable from '@material-ui/core/Table'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce
} from 'react-table'
import { Row, Col, Pagination, Card, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../styles/base.scss'
import {
  isAgent,
  isPm,
  isProgramDirector,
  getAgentBehaviourOpportunities,
  getAgentSmartGoals,
  getAgentCoachingRecaps,
  getActiveWeek,
  getDueWeekAndDate,
  checkValueYesOrNot,
  showConsoleLog,
  checkArrObjectValueExist,
  checkArrValueExist
} from '../../helpers'
import moment from 'moment'

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  myGlobalFilter,
  setMyGlobalFilter
}) => {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || '')
  }, 200)

  return (
    <Row className='p-3'>
      <Col md='auto' className='ml-auto'>
        <input
          className='form-control'
          type='text'
          placeholder='Search'
          value={globalFilter || ''}
          onChange={e => {
            setMyGlobalFilter(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

const PmOverviewCard = ({
  columns,
  data,
  metricData,
  objMetricHeaders, 
  selectedProgram,  
  loading,
  pageCount: controlledPageCount,  
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      metricData,
      objMetricHeaders,
      selectedProgram,         
      initialState: { pageIndex: 0 },
      manualPagination: false,
      manualSortBy: false,
      pageCount: controlledPageCount
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )  
  
  const getButton = cell => {
    const linkTo = `/dashboard/metrics/edit/${cell.row.original.public_id}`
    return (
      <Link to={linkTo}>
        <Button
        type='button'
        className='btn btn-sm btn-primary float-right'        
      >
        View Metrics Details
      </Button>
      </Link>
    )
  }

  React.useEffect(() => {
    //fetchData({ pageIndex, pageSize })
  }, [pageIndex, pageSize])

  const getTableRows = (i) => {    
    let arrMetrics = []
    let lastIsKeyMetricIndex = 0
    let metricHeaders = []
    let programId = selectedProgram.value

    objMetricHeaders.map((header, headerIndex) => {
      metricHeaders.push(header.metrics_name)
      if (header.is_key_metrics || header.is_key_metrics > 0) {
        lastIsKeyMetricIndex = headerIndex
      }
    })

    let index = (pageIndex * pageSize) + i
    let row = metricData[index]   
    let arrData = []
    let arrHeaders = []
    let arrTopHeaders = []
    let objRiskHeaderExist = checkArrValueExist(
      metricHeaders,
      'ES At Risk (Y/N)'
    )
    let atRiskHeaderIndex = objRiskHeaderExist.index
    let objEmpCodeHeaderExist = checkArrValueExist(
      metricHeaders,
      'Employee Code'
    )
    let empCodeHeaderIndex = objEmpCodeHeaderExist.index
    let agentId = row[parseInt(row.length - 1)]
    let viewlink = `/dashboard/agentview?program=${programId}&agent=${agentId}`

    objMetricHeaders.map((header, headerIndex) => {
      let agentName = ''
      if (
        header.is_key_metrics ||
        header.is_key_metrics > 0 ||
        header.metrics_name.toLowerCase() === 'employee code' ||
        header.metrics_name.toLowerCase() === 'team'
      ) {
        let value = row[headerIndex]
        let headerName = (
          <span className=' dash_header'>
            {header.metrics_name}
          </span>
        )
        let columnValue1 = row[headerIndex]
        let columnValue = <h3 className=''>{columnValue1}</h3>
        if (header.threshold > 0 && columnValue1 < header.threshold)
          columnValue = <h3 className='text-danger'>{columnValue1}</h3>
        if (header.threshold < 0 && columnValue1 > -header.threshold)
          columnValue = <h3 className='text-danger'>{columnValue1}</h3>
        let rightBorder =
          headerIndex < lastIsKeyMetricIndex ? 'right-split-border' : ''
        if (empCodeHeaderIndex === headerIndex) {
          agentName = row[parseInt(row.length - 2)]
          if (objRiskHeaderExist.is_exist) {           
            let atRiskClass = checkValueYesOrNot(row[atRiskHeaderIndex])
              ? 'badge badge-soft-danger'
              : 'badge badge-soft-success'
            columnValue = checkValueYesOrNot(row[atRiskHeaderIndex]) ? (
              <h3>
                <span className={atRiskClass}>AT RISK</span>
              </h3>
            ) : (
              <h3>
                <span className={atRiskClass}>NOT AT RISK</span>
              </h3>
            )
            headerName = (
              <span className='dash_header text-uppercase'>
                {objMetricHeaders[atRiskHeaderIndex].metrics_name}
              </span>
            )
          } else {
            columnValue = null
            headerName = null
          }
        }
        if (header === 'Weekly Coaching') {
          columnValue =
            value || value === 'Y' ? (
              <h3 className=''>Yes</h3>
            ) : (
              <h3 className=''>No</h3>
            )
        }

        if (atRiskHeaderIndex !== headerIndex) {
          arrHeaders.push(
            <Col key={headerIndex} className={rightBorder}>
              {headerName}
            </Col>
          )
          arrTopHeaders.push(
            <Col
              key={headerIndex}
              className={`${rightBorder} py-1 text-uppercase`}
            >
              {agentName}
            </Col>
          )
          arrData.push(
            <Col key={headerIndex} className={rightBorder}>
              {columnValue}
            </Col>
          )
        }
      }
    })

    arrMetrics.push(<Link to={viewlink} className='card-link' key={index}>            
            <Card>
              <Card.Body>
                <Row>{arrTopHeaders}</Row>
                <Row>{arrData}</Row>
                <Row>{arrHeaders}</Row>
              </Card.Body>
            </Card>
          </Link>)
    
    return arrMetrics
  }

  // Render the UI for your table
  if(data && data.length > 0) {
    return (
      <section>
        <Card className='bg-primary'>
          <Card.Body className='py-2'>         
            <Row className='align-items-center'>
              <Col md='auto'>
                <Form.Control
                  size='sm'
                  as='select'
                  className='mr-sm-2'
                  id='inlineFormCustomSelect'
                  custom
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map(pageSize1 => (
                    <option key={pageSize1} value={pageSize1}>
                      Show {pageSize1}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col md='auto' className='ml-auto'>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
          <MaUTable {...getTableProps()} className='table table-sm card-table'>          
            <TableBody {...getTableBodyProps()}>              
              {page.map((row, i) => {               
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {getTableRows(i)}
                  </TableRow>
                )
              })}
              {loading ? (<TableRow>               
                  <TableCell colSpan='10000'>Loading...</TableCell>              
              </TableRow>) : null}
            </TableBody>
          </MaUTable>        
        <Card className='bg-primary'>
          <Card.Body className='py-2'>          
            <Row md={12} className='align-items-center'>
              <Col md={4}>
                <div className='form-inline'>
                  <span>Go to page: </span>
                  <Form.Control
                    size='sm'
                    className='ml-2'
                    type='number'
                    value={pageIndex + 1}
                    //defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page1 = e.target.value
                        ? Number(e.target.value) - 1
                        : 0
                      gotoPage(page1)
                    }}
                    style={{ width: '100px' }}
                  />
                </div>{' '}
              </Col>
              <Col md={4} className="text-center">Showing {page.length} of {data.length} results</Col>
              <Col md='auto' className='ml-auto'>
                <Pagination className='mb-0'>
                  <Pagination.First
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>        
          </Card.Body>
        </Card>
      </section>
    )
  } else {
    return (<Card className='bg-primary'>
      <Card.Body className='py-2'>          
        <Row md={12}><Col>Records not found</Col></Row></Card.Body>
        </Card>)
  }
  
}

export default PmOverviewCard