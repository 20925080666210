import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment';
import { isAdmin } from '../../helpers'
import { userService } from '../../services'
import { ROLE_ADMIN, USER_DETAILS } from '../../constants';
import { ChangePasswordForm } from '../../components'
import {
  Row,
  Col,
  Pagination,
  Card,
  Form,
  Button,
  Spinner
} from 'react-bootstrap'
import { ErrorBoundary } from '..'
import { toast } from 'react-toastify'
import { getUserData } from '../../helpers'

class ChangePassword extends Component {
  state = {    
    user_data: [],           
    isLoading: false,  
    isSubmit: false, 
    formData: {      
      password: '',
      confirm_password: '',
      old_password: ''           
    },    
    viewPassword:{
      password:false,
      confirm_password:false,
      old_password: false,
    }
  }

  componentDidMount = async () => {
    this.props.updateHeaderTxt('Change Password', 'CCMS')           
  } 

  componentDidUpdate = async () => {          
  }

  togglePasswordDisplay = (e, field_name) => {
    const { viewPassword } = this.state
    viewPassword[field_name] = !viewPassword[field_name]
    this.setState({viewPassword:viewPassword})    
  }  

  handleSubmit = async (data, setSubmitting) => {
    const userDetails = getUserData(USER_DETAILS)    
    let saveData = {email:userDetails.email, password:data.old_password, new_password:data.password}
    this.setState(state => ({
      ...state,      
      isSubmit:true          
    }))  

    userService
      .change_user_password(saveData)
      .then(() => {   
        toast.success(
          'Successfully changed the password, you can re-login with the new password. You will be redirected to dashboard in 10 seconds.'
        )

        setTimeout(() => {
          setSubmitting(false)        
          this.setState(state => ({
            ...state,           
            isSubmit:false          
          })) 
                    
          this.props.history.push({
            pathname: "/dashboard",							
          });
        }, 6000)
      })
      .catch(error => {
        setSubmitting(false)
        this.setState(state => ({
          ...state,           
          isSubmit:false          
        }))        
        toast.error('Error while resetting the password. Please check the old password.')
      })
  }   

  render() { 
    const { data, formData, viewPassword, isSubmit } = this.state    
    return (<Row><Col>
                <ErrorBoundary>                
                <ChangePasswordForm                  
                  handleSubmit={this.handleSubmit}
                  data={data}                 
                  formData={formData}                 
                  viewPassword={viewPassword}
                  togglePasswordDisplay={this.togglePasswordDisplay}
                  isSubmit={isSubmit}
                />
                </ErrorBoundary>
              </Col></Row>)
  }
}

export default ChangePassword
