/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react'
import '../../styles/metrics-card.scss'
import MaUTable from '@material-ui/core/Table'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce
} from 'react-table'
import {
  Row,
  Col,
  Pagination,
  Card,
  Form,
  Button,
  Spinner
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import makeData from './makeData'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'
import '../../styles/base.scss'

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  myGlobalFilter,
  setMyGlobalFilter
}) => {
  const onChange = useAsyncDebounce(value => {
    value = value === '' ? ' ' : value
    setGlobalFilter(value)
  }, 200)

  return (
    <Row className='py-1'>
      <Col md='auto' className='ml-auto'>
        <input
          className='form-control form-control-sm'
          type='text'
          placeholder='Search'
          value={globalFilter}
          onChange={e => {
            setMyGlobalFilter(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

const ProgramTable = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  handleNew,
  myGlobalFilter,
  setMyGlobalFilter,
  totalRecords,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: 'created_on', desc: true }]
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  
  const getButton = cell => {
    const editlink = `/dashboard/programs/edit/${cell.row.original.public_id}`
    const viewlink = `/dashboard/programs/${cell.row.original.public_id}`
    return (
      <div>
        <Button
          type='button'
          className='btn btn-sm btn-primary'
          as={Link}
          to={editlink}
        >
          Edit
        </Button>{' '}
        &nbsp;
        <Button
          type='button'
          className='btn btn-sm btn-primary'
          as={Link}
          to={viewlink}
        >
          View
        </Button>
      </div>
    )
  }

  const showSortIcons = column => {
    return (
      <span>
        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
      </span>
    )
  }

  const showTableData = () => {
    if (loading)
      // Use our custom loading state to show a loading indicator
    return (<section><OverlayLoader showSpinner={false} /><TableBody {...getTableBodyProps()}>
          <TableRow align='center'>
            <TableCell><ShowSpinner class_name='mr-3 ml-3' /></TableCell>            
          </TableRow>
        </TableBody></section>)

    return (
      <TableBody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.column.Header === 'Action'
                      ? getButton(cell)
                      : cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell colSpan='10000'>
            Showing {page.length} of ~{totalRecords} results
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  React.useEffect(() => {       
    fetchData({ pageIndex, pageSize, sortBy, globalFilter })
  }, [sortBy, fetchData, pageIndex, pageSize, globalFilter])

  // Render the UI for your table
  return (
    <section>
      <Row className='align-items-center'>            
            <Col md='auto' className='ml-auto mb-3'>
              <Button
                type='button'
                className='btn btn-sm btn-primary mb-2'
                as={Link}
                to='/dashboard/programs/add'
              >
                Add New Program
              </Button>
            </Col>
          </Row>
      <Card className='no-bg'>  
      <Card.Body className='py-2'>        
          <Row className='align-items-center'>
            <Col md='auto'>
              <Form.Control
                size='sm'
                as='select'
                className='mr-sm-2'
                id='inlineFormCustomSelect'
                custom
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize1 => (
                  <option key={pageSize1} value={pageSize1}>
                    Show {pageSize1}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col md='auto' className='ml-auto'>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={myGlobalFilter}
                setGlobalFilter={setGlobalFilter}
                myGlobalFilter={myGlobalFilter}
                setMyGlobalFilter={setMyGlobalFilter}
              />
            </Col>
          </Row>
        </Card.Body>
        <MaUTable {...getTableProps()} className='table table-sm card-table'>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell 
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        style: { width: `${column.width}px` },
                        className:column.isSorted ? 'cellSortIcon' : ''
                      })
                    )} 
                   
                  >
                    {column.render('Header')}
                    {showSortIcons(column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {showTableData()}
        </MaUTable>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <Card.Footer>
          <Row className='align-items-center justify-content-between'>
            <Col md='auto'>
              <div className='form-inline'>
                <span className='text-muted'>Go to page: </span>
                <Form.Control
                  size='sm'
                  className='ml-2'
                  type='number'
                  value={pageIndex + 1}
                  /* defaultValue={pageIndex + 1} */
                  onChange={e => {
                    const page1 = e.target.value
                      ? Number(e.target.value) - 1
                      : 0
                    gotoPage(page1)
                  }}
                  style={{ width: '100px' }}
                />
              </div>{' '}
            </Col>

            <Col md='auto'>
              <span className='mr-2'>
                <span className='text-muted mr-2'>Page</span>
                <span>
                  {pageIndex + 1}  <span className='text-muted'>of {pageOptions.length}</span>
                </span>
              </span>
            </Col>

            <Col md='auto'>
              <Pagination className='mb-0'>
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </section>
  )
}

export default ProgramTable
