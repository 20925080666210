import React from 'react'
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import '../../styles/login.scss'
import ShowSpinner from '../dashboard/ShowSpinner'

const ForgotPasswordForm = ({ ...props }) => {
  return (
    <section className='box-block'>
      <div className='box-block-content'>
        <h4 className='text-center'>Reset password</h4>
        <Formik
          initialValues={{
            username: ''
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .email('Invalid Email address')
              .required('Required')
          })}
          onSubmit={values => {
            props.handleForgotPassword(values)
          }}
        >
          {formikprops => (
            <Form className='login' onSubmit={formikprops.handleSubmit}>
              {props.message ? (
                <center>
                  <Row>{props.message}</Row>
                  <Row className='mt-3'>
                    <Col>
                      <Button
                        variant='secondary'
                        type='button'
                        className='btn-md btn-block'
                        as={Link}
                        to={{
                          pathname: '/login'
                        }}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </center>
              ) : (
                  <div>
                    <Form.Group controlId='formEmail'>
                      <Form.Label>Email</Form.Label>
                      <Field
                        className='form-control'
                        type='email'
                        placeholder='Email address'
                        name='username'
                      />
                      <ErrorMessage
                        name='username'
                        render={msg => <div className='text-danger'>{msg}</div>}
                      />
                    </Form.Group>
                    <div className='text-danger'>{props.errorMsg}</div>
                    <Form.Group
                      controlId='buttonSubmit'
                      className='d-flex mt-3'
                    >                   
                          <>
                            <Button
                              variant='light'
                              type='button'
                              className='btn-md'
                              as={Link}
                              to={{
                                pathname: '/login'
                              }}
                              disabled={props.isSubmitted}
                            >
                            {props.isSubmitted ? <ShowSpinner buttonSpinner={true} /> : null}
                              Back
                            </Button>
                            <Button
                              variant='primary'
                              type='submit'
                              className='btn-md ml-auto'
                              disabled={props.isSubmitted}
                            >
                              {props.isSubmitted ? <ShowSpinner buttonSpinner={true} /> : null}
                              Reset
                            </Button>
                          </>                       
                    </Form.Group>
                  </div>
                )}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default ForgotPasswordForm
