/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import React, {useState} from 'react'
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import '../../styles/base.scss'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import ShowSpinner from './ShowSpinner'
import moment from 'moment'

const AddCoachingMainComponent = ({ ...props }) => {   
  let is_coaching_date_disabled =  false
  let weeks_data = props.data.weeks
  let btn_save = false
  if(props.data.weeks[0]){
    if('agent_acknowledgement' in props.data.weeks[0]){
      btn_save = true
    }
  }

  const history = useHistory()

  const redirect = () => {
    history.push('/dashboard/coaching')
  }

  if (typeof weeks_data !== 'undefined'){
    if (weeks_data.length > 0) {
      weeks_data.map((row, i) => {
        if (row.is_completed) {
          is_coaching_date_disabled =  true
        }      
      })
    }
  }
  
  // Schema for yup
  const validationSchema = Yup.object({
    agent_id: Yup.string()
      .nullable()
      .required('Please select agent'),
    coaching_start_date: Yup.date().required('Coaching start date is required').nullable()
  })

  const requiredAsterisk = () => {
    return <span className='text-danger'> *</span>
  }

  const getListenToCallOption = selectedProgram => {
    let url = ''
    if (selectedProgram) {
      url = selectedProgram.program.calls_url
    }

    return (
      <Button
        type='button'
        className='btn btn-sm btn-primary ml-1 call_btn mt-2 float-left'
        onClick={() => window.open(url, '_blank')}
      >
        LISTEN TO CALL
      </Button>
    )
  }

  const [isSaved, setIsSaved] = useState(false);

  return (
    <Row>
      <Col md={2}>{getListenToCallOption(props.selectedProgram)}</Col>
      <Col md={8} disabled>
        <Card className='bg-transparent card-form pt-5 no-bgr'>
          <Card.Header className='pl-3 mb-3 border-bottom-0'>
            <div className='justify-content-between txt_coaching_header text-center'>
              {props.selectedProgram && (
                <h1 className='mb-4 pt-3 coaching_title'>
                  Coaching Portal - {props.selectedProgram.program.program_name}
                </h1>
              )}
              <h3 className='mb-2 coaching_title'>{props.message}</h3>
              <h5>
                Please select the appropriate option below to proceed with
                filling out the Form you are looking for! This form should NOT
                be used for disciplinary actions. The email address you enter
                below should be the Manager that is delivering the Coaching or
                Conversation. This is also where a completed copy of the form{' '}
              </h5>
            </div>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={props.data}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values, { setSubmitting, isSubmitting }) => {
                setSubmitting(true);
                props.handleSubmit(values, setSubmitting, isSubmitting)
                  .then(() => {
                    if (values.save) {
                      setIsSaved(true);
                    }
                    setSubmitting(false);
                  })
                  .catch(error => {
                    //handle the error if needed
                    console.error(error);
                    setSubmitting(false);
                  });
              }}
            >
              {formik => (
                <FormikForm className='text_dark_coaching_form'>
                  {formik.isSubmitting && (
                    <Alert variant='info'>
                      Submitting Information. Please wait...
                    </Alert>
                  )}
                  <div className='form-section form-section-orange'>
                    <Form.Row>
                      <Form.Group as={Col} md='6' className='mb-3'>
                        <Form.Label>Program: {requiredAsterisk()}</Form.Label>
                        <Select
                          className='border-0 mt-design'
                          id='inlineFormCustomSelect'
                          value={props.selectedProgram}
                          isDisabled={props.isFetchingProgram}
                          onChange={e => {
                            if (formik.dirty) {
                              const shouldRedirect = confirm(
                                'You have modified the form? if you select okay your changes will be lost'
                              )
                              if (shouldRedirect) {
                                props.onPmChangeLocal(e)
                              }
                            } else {
                              props.onPmChangeLocal(e)
                            }
                          }}
                          options={props.programBar.programOptions}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6' className='mb-3'>
                        <Form.Label>Agent: {requiredAsterisk()}</Form.Label>
                        <Select
                          className='border-0 mt-design'
                          value={props.selectedAgent}
                          isDisabled={props.isFetchingProgram}
                          onChange={e => props.onAgentChangeLocal(e)}
                          options={props.agentOptions}
                        />
                        {props.selectedAgent &&
                          props.selectedAgent.value !== '' &&
                          props.agent && (
                            <span>Email address: {props.agent.email}</span>
                          )}
                        <ErrorMessage
                          name='agent_id'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md='12'
                        controlId='coaching_start_date'
                      >
                        <Form.Label>
                          Coaching Date (for week 1, this will be the start date
                          for your 4 week coaching plan as well):{' '}
                          {requiredAsterisk()}
                        </Form.Label>
                                        
                        <h4>{formik.values.coaching_start_date ? 'Week - '+(moment(formik.values.coaching_start_date, 'MM-DD-YYYY').week()) : null}</h4>
                        <DatePicker
                          showWeekNumbers
                          selected={Date.parse(formik.values.coaching_start_date)}
                          dateFormat='MM-dd-yyyy'
                          className='form-control'
                          disabled={is_coaching_date_disabled}
                          onChange={date => {
                            props.handleCoachingDateChange(date)
                            formik.setFieldValue(
                              'coaching_start_date',
                              date,
                              true
                            )
                          }}
                        /> 
                        <ErrorMessage
                          name='coaching_start_date'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        /> 
                                          
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md='6' controlId='navigate_page'>
                        <Form.Label>
                          Available Coaching Portal Forms: {requiredAsterisk()}
                        </Form.Label>
                        <Field
                          name='navigate_page'
                          id='navigate_page'
                          as='select'
                          defaultValue={props.data.program_type}
                          className='custom-select'
                        >
                          <option value='1'>Week 1 – Plan Development</option>
                          <option value='2'>Week 2 – Check Progress</option>
                          <option value='3'>
                            Week 3 – Check Progress/Adjust Plan
                          </option>
                          <option value='4'>Week 4 – Review Success</option>
                        </Field>
                      </Form.Group>
                      <Form.Group as={Col} md='6' controlId='status'>
                        <Form.Label>
                          Status: {requiredAsterisk()}{' '}                          
                        </Form.Label>
                        <div className='selectdiv '>
                          <Field
                            name='status'
                            id='status'
                            className='form-control'
                            as='select'
                            defaultValue={props.data.status}
                            disabled={props.data.status === 'Closed'}
                          >
                            <option value='Active'>Active</option>
                            <option value='Closed'>Closed</option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name='status'
                          render={msg => (
                            <div className='text-danger'>{msg}</div>
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Form.Row>
                    <Button
                      variant='light'
                      type='button'
                      className='mr-3'                                      
                      onClick={() => {
                        if (formik.dirty) {
                          const shouldRedirect = confirm(
                            'You have modified the form? if you select okay your changes will be lost'
                          )
                          if (shouldRedirect) {
                            props.programBar.onAgentChange({
                              agent: {},
                              label: 'All',
                              value: ''
                            })
                            redirect()
                          }
                        } else {
                          props.programBar.onAgentChange({
                            agent: {},
                            label: 'All',
                            value: ''
                          })
                          redirect()
                        }
                      }}
                    >
                      Cancel
                    </Button>                   
                    <Button
                      variant='primary'
                      className='submit-button'
                      type='submit'
                      onClick={() => {
                        formik.setFieldValue('save', true);
                        formik.handleSubmit();                        
                      }}
                      disabled={
                        formik.isSubmitting ||
                        props.isFetchingProgram ||
                        props.isLoading ||
                        props.loadingKeyMetrics ||
                        btn_save
                      }
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null}
                      Save
                    </Button>
                    &nbsp;
                    <Button
                      variant='primary'
                      className='submit-button ml-auto'
                      type='submit'
                      onClick={() => {
                        // formik.setFieldValue('save', true)
                        formik.setFieldValue('traverse', true);
                        formik.handleSubmit();
                      }}
                      // disabled={formik.isSubmitting || props.loadingKeyMetrics}
                      disabled={
                        !isSaved || //disable until form is saved
                        formik.isSubmitting ||
                        props.loadingKeyMetrics
                      }
                    >
                      {formik.isSubmitting ? (
                        <ShowSpinner buttonSpinner={true} />
                      ) : null} 
                      Continue                     
                    </Button>
                  </Form.Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AddCoachingMainComponent