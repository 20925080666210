import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment';
import { UserTable} from '../../components'
import makeData from '../../components/dashboard/makeData'
import { 
  isAdmin,
  isPm,
  isAgent,
  isProgramDirector,
  deleteUserData 
} from '../../helpers'
import { userService } from '../../services'
import { 
  ROLE_ADMIN, 
  ARRAY_USER_ROLES,
  ADD_MODE,
  EDIT_MODE,
  DELETE_MODE,
  VIEW_MODE,
  LIST_MODE,
  RESET_PASSWORD_MODE
} from '../../constants';
import { 
  AddUserComponent, 
  PopupModal, 
  ShowSpinner, 
  UnauthorizedCard,
  OverlayLoader
} from '../../components'
import {
  Row,
  Col,
  Pagination,
  Card,
  Form,
  Button  
} from 'react-bootstrap'
import { ErrorBoundary } from '..'
import { toast } from 'react-toastify'

class Users extends Component {
  state = {    
    data: [],
    pageCount: 1,        
    isLoading: false,    
    myGlobalFilter : '',
    totalRecords:0,
    formData: {
      f_name: '',
      l_name: '',
      email: '',
      employee_code: '',
      password: '',
      confirm_password: '',
      role_name: ARRAY_USER_ROLES[0].role_name,
      public_id: '',      
    },    
    pageMode:LIST_MODE,
    showModal:false,
    viewPassword:{
      password:false,
      confirm_password:false
    },
    isAuthorizedAccess:null, 
    isProcessing:false,   
  }

  componentDidMount = async () => {  
    if(!isAdmin()) {        
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      const userPublicId = this.props.match.params.id
      const path = String(this.props.match.path)
      
      let pageMode  = LIST_MODE
      if(path === "/dashboard/users/:id") {
        pageMode = VIEW_MODE
        this.props.updateHeaderTxt('View User', 'Users')
      } else if(path === "/dashboard/users/edit/:id") {
        pageMode = EDIT_MODE
        this.props.updateHeaderTxt('Edit User', 'Users') 
      } else if(path === "/dashboard/users/reset/:id") {
        pageMode = RESET_PASSWORD_MODE
        this.props.updateHeaderTxt('Reset User Password', 'Users') 
      } else if(path === "/dashboard/users/add") {
        pageMode = ADD_MODE
        this.props.updateHeaderTxt('Add User', 'Users')  
      } else {
        this.props.updateHeaderTxt('Users List', 'Users')
      }
      
      if(userPublicId) {
        this.getUserById(userPublicId)       
      }
      
      this.setState(state => ({
        ...state,
        pageMode,
        isAuthorizedAccess: true,      
      })) 
    }       
  } 

  componentDidUpdate = async () => {        
  }

  togglePasswordDisplay = (e, field_name) => {
    const { viewPassword } = this.state
    viewPassword[field_name] = !viewPassword[field_name]
    this.setState({viewPassword:viewPassword})   
  }

  getUserById = async (userPublicId) => {
    this.setState(state => ({
      ...state,          
      isLoading:true
    })) 

    userService
    .get_user_by_id(userPublicId)
    .then(data => {        
      if(data) {        
        this.setState(state => ({
          ...state, 
          isLoading:false,         
          formData:{
            f_name: data.f_name,
            l_name: data.l_name,
            email: data.email,
            employee_code: data.employee_code,            
            role_name: data.role.role_name,
            public_id: userPublicId,
            password:"",
            confirm_password:"",              
          }    
        })) 
      } 
    })
    .catch((error) => {
      this.setState(state => ({
        ...state,          
        isLoading:false
      }))
      toast.error('Error while fetching user details')
    })
  }

  onClickDeleteUser = (e) => {    
    this.setState({showModal: true})
  }

  fetchData = ({ pageIndex, pageSize, sortBy}) => {
    this.setState(state => ({
      ...state,
      isLoading: true
    }))
    
    let queryString = `?page_size=${pageSize}&page_no=${pageIndex+1}`

    if (sortBy.length === 1) {      
      let sort_by = sortBy[0].id
      queryString += `&sort_by=${sort_by}&sort_order=${sortBy[0].desc ? 'desc' : 'asc'}`
    }      
    
    if(this.state.myGlobalFilter !== "") {
      queryString += `&filter_query=${this.state.myGlobalFilter}`
    }
    
    setTimeout(() => {
        userService
        .get_paginated_users(queryString)
        .then(data => {          
          this.setState(state => ({
            ...state,
            data: data.data,
            pageCount: Math.ceil(data.total / pageSize),
            isLoading: false,  
            totalRecords: data.total             
          }))
        })
        .catch(() => {})
    }, 500)
  }

  columns = [
    {
      Header: 'First Name',
      accessor: 'f_name'      
    },
    {
      Header: 'Last Name',
      accessor: 'l_name'      
    },
    {
      Header: 'Email Address',
      accessor: 'email'     
    },  
    {
      Header: 'Employee Code',
      accessor: 'employee_code'     
    }, 
    {
      Header: 'Role',
      accessor: 'role.role_name',
      disableSortBy: true     
    },  
    {
      Header: 'Registered On',
      accessor: 'registered_on',
      Cell : (props)=> {
        // props.value will contain your date
        // you can convert your date here
        const customDate = moment(props.value).format('DD/MM/YYYY')
        return <span>{customDate}</span>
      }           
    },          
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true     
    }
  ]

  setMyGlobalFilter = (globalFilter) => {      
    this.setState({
      myGlobalFilter: globalFilter
    })       
  }

  handleSubmit = async (data, setSubmitting) => {
    this.setState(state => ({
      ...state,          
      isProcessing: true
    }))

    const { pageMode } = this.state 
    let saveData = {}    
    if(pageMode === RESET_PASSWORD_MODE) {
      saveData = {email:data.email, password:data.password}
    } else {
      saveData = {
        f_name: data.f_name, 
        l_name: data.l_name, 
        email: data.email, 
        employee_code: data.employee_code,            
        role_name: data.role_name        
      }
      
      if(pageMode === EDIT_MODE) {
        saveData['public_id'] = data.public_id
      }
       
      if(pageMode === ADD_MODE) {
        saveData['password'] = data.password
      }
    }      
    
    if(pageMode === ADD_MODE) {      
      userService
      .add_user(saveData)
      .then(() => {
        // delete the stored users
        deleteUserData()
        toast.success(
          'Successfully created the user. You will be redirected to Users Page in 10 seconds'
        )

        setTimeout(() => {  
          setSubmitting(false)        
          this.setState(state => ({
            ...state,          
            isProcessing: false,            
          }))        
          this.props.history.push({
            pathname: "/dashboard/users",							
          });
        }, 6000)
      })
      .catch(error => {
        this.setState(state => ({
          ...state,          
          isProcessing: false
        }))
        setSubmitting(false)        
        if(error.message) {          
          toast.error(error.message)
        } else {
          toast.error('Error while creating user')
        }        
      })
      //setSubmitting(true)      
    } else {
      if(pageMode === EDIT_MODE) {
        userService
        .update_user(saveData)
        .then(() => {
          setSubmitting(false)
          // delete the stored users
          deleteUserData()

          toast.success(
            'Successfully updated the user. You will be redirected to Users Page in 10 seconds'
          )

          setTimeout(() => {              
            this.setState(state => ({
              ...state,            
              isProcessing: false,                       
            }))  
            this.props.history.push({
              pathname: "/dashboard/users",							
            });             
          }, 6000)
        })
        .catch(error => {
          this.setState(state => ({
            ...state,          
            isProcessing: false
          }))
          setSubmitting(false)
          toast.error('Error while updating the user')
        })
      } else if(pageMode === RESET_PASSWORD_MODE) {
        userService
        .reset_user_password(saveData)
        .then(() => {          
          // delete the stored users
          //deleteUserData()
          toast.success(
            'Successfully reset the password. You will be redirected to Users Page in 10 seconds'
          )

          setTimeout(() => {           
            setSubmitting(false) 
            this.setState(state => ({
              ...state,
              isProcessing: false,                         
            }))
            this.props.history.push({
              pathname: "/dashboard/users",							
            });
          }, 6000)
        })
        .catch(error => {
          this.setState(state => ({
            ...state,          
            isProcessing: false
          }))
          setSubmitting(false)          
          toast.error('Error while resetting the user password')
        })
      }      
    }    
  }

  handleModalClose = () => {    
    this.setState(state => ({
      ...state,      
      showModal: false
    }))
  }

  confirmDeleteUser = () => {
    this.setState(state => ({
      ...state,      
      showModal: false,
      isProcessing:true      
    }))
    let data = {public_id:this.state.formData.public_id, is_active: false}
    userService
      .update_user(data)
      .then(() => {
        // delete the stored users
        deleteUserData()

        toast.success(
          'Successfully deleted the user. You will be redirected to Users Page in 10 seconds'
        )

        setTimeout(() => {           
            this.setState(state => ({
              ...state,
              isProcessing: false,                           
            }))
            this.props.history.push({
              pathname: "/dashboard/users",							
            });                 
        }, 6000)
      })
      .catch(error => { 
        this.setState(state => ({
          ...state,          
          isProcessing: false
        }))       
        toast.error('Error while deleting the user')
      }) 
  }

  executeRender = () => {
    const { data, pageMode, formData, showModal, viewPassword, isLoading, isProcessing, isAuthorizedAccess } = this.state     
    
    if(isAuthorizedAccess === null) {
      return <OverlayLoader />
    }

    if(isAuthorizedAccess !== null && isAuthorizedAccess) {
      if(pageMode === LIST_MODE) {
        return (<UserTable
          columns={this.columns}
          data={this.state.data}
          fetchData={this.fetchData}
          loading={this.state.isLoading}
          pageCount={this.state.pageCount}              
          myGlobalFilter={this.state.myGlobalFilter}
          setMyGlobalFilter={this.setMyGlobalFilter}
          totalRecords={this.state.totalRecords}                                      
        />)
      } else {
        if(isLoading) {
          return <OverlayLoader />
        }  
        return (<Row><Col>
                  <ErrorBoundary>
                    <AddUserComponent                  
                      handleSubmit={this.handleSubmit}
                      data={data}                 
                      pageMode={pageMode}
                      formData={formData}                   
                      onClickDeleteUser={this.onClickDeleteUser}  
                      showModal={showModal}  
                      handleModalClose={this.handleModalClose}
                      confirmDeleteUser={this.confirmDeleteUser}
                      viewPassword={viewPassword}
                      togglePasswordDisplay={this.togglePasswordDisplay}
                      isProcessing={isProcessing}                      
                    />
                  </ErrorBoundary>
                </Col></Row>)        
      }
    } else {
      return <UnauthorizedCard />
    }    
  }

  render() {
    return this.executeRender()
  }  
}

export default Users
