/* eslint-disable import/named */
import { AssignmentIndOutlined, ShopTwoOutlined } from '@material-ui/icons'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { dashboardService } from '../../services/dashboard.service'
import '../../styles/overview.scss'
import { Link } from 'react-router-dom'
import OverlayLoader from './OverlayLoader'

class AdminOverviewCard extends Component {
  state = {
    isLoading: false,
    totalAgents: 115,
    totalPrograms: 6
  }

  abortController = new AbortController()

  componentDidMount() {
    this.setState(state => ({
      ...state,
      isLoading: true
    }))

    dashboardService
      .get_admin_dashboard_data()
      .then(data => {
        this.setState(state => ({
          ...state,
          isLoading: false,
          totalAgents: data.agent_count,
          totalPrograms: data.program_count
        }))
      })
      .catch(() => {})
  }

  componentWillUnmount() {
    if (this.state.isLoading === true) this.abortController.abort()
  }

  render() {
    const { isLoading, totalAgents, totalPrograms } = this.state
    const programsLink = '/dashboard/programs'
    return (
      <>
        <Row>
          <Col>
            {isLoading && (<OverlayLoader />)}
            {!isLoading && (
              <div>
                <Row>
                  <Col lg={6} xl={4}>
                    <React.Fragment key={0}>
                      <Link to={programsLink} className='card-link'>
                        <Card key={0} className='card-left-border-red no-bg'>
                          <Card.Body>
                            <Row className='align-items-center'>
                              <Col>
                                <h2>Number of Programs</h2>
                                <h2 className='count'>{totalPrograms}</h2>
                              </Col>
                              <Col md='auto'>
                                <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                                  <ShopTwoOutlined />
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Link>
                    </React.Fragment>
                  </Col>
                  <Col lg={6} xl={4}>
                    <React.Fragment key={1}>
                      <Card key={1} className='card-left-border-green no-bg'>
                        <Card.Body>
                          <Row className='align-items-center'>
                            <Col>
                              <h2>Agents</h2>
                              <h2 className='count'>{totalAgents}</h2>
                            </Col>
                            <Col md='auto'>
                              <div className='icon icon-shape bg-success text-white rounded-circle shadow'>
                                <AssignmentIndOutlined />
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </React.Fragment>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    )
  }
}

export default AdminOverviewCard
