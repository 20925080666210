import moment, { weekdaysShort } from 'moment'
import { toast } from 'react-toastify'
import {
  LOCAL_STORAGE_COACHING,
  LOCAL_STORAGE_SELECTED_PROGRAM,
  LOCAL_STORAGE_PROGRAMS
} from '../constants'
import { coachingService } from '../services'
import { getUserData, setUserData } from './functions'

const DUE_WEEK_FOUR_INDEX = 2
const ACTIVE_WEEK_FOUR_INDEX = 3
const NO_DUE = 'No Due'
export const getProgramCoachings = (programId, agentId) => {
  return new Promise((resolve, reject) => {
    coachingService
      .get_coaching_by_program_agent(programId, agentId)
      .then(coachingData => {        
        // sometimes resolve is not called,
        // hence after 100ms we call it, it works
        setTimeout(() => {
          resolve(coachingData)
        }, 100)
      })
      .catch(error => {        
        reject(null)
      })
  })
}

export const getAgentBehaviourOpportunities = coachingData => {
  const opportunities = []
  if (coachingData && coachingData.weeks) {
    coachingData.weeks.forEach(week => {      
      let opportunity = week.week_no > 0 ? week.agent_behaviour_opportunity : coachingData.opportunity_or_focus      
      //if (opportunity.length > 0) {
        opportunities.push(opportunity)
      //}
    })
  }
  return opportunities
}

export const getAgentSmartGoals = coachingData => {
  const behaviourSmartGoals = []
  if (coachingData && coachingData.weeks) {
    coachingData.weeks.forEach(week => {
      let smartGoal = week.week_no > 0 ? week.agent_smart_goal_commitment : coachingData.smart_goal_1
     
      //if (smartGoal.length > 0) {
        behaviourSmartGoals.push(smartGoal)
      //}
    })
  }
  return behaviourSmartGoals
}

export const getAgentCoachingRecaps = coachingData => {
  const recaps = []
  if (coachingData && coachingData.weeks) {
    coachingData.weeks.forEach(week => {
      const agentRecaps = week.agent_recap      
      //if (smartGoalCommitment.length > 0) {
        recaps.push(agentRecaps)
      //}
    })
  }
  return recaps
}


export const getAgentCoachingFeedBack = coachingData => {
  const recaps = []
  if (coachingData && coachingData.weeks) {
    coachingData.weeks.forEach(week => {
      const agentFeedBack = week.agent_feedback      
      //if (smartGoalCommitment.length > 0) {
        recaps.push(agentFeedBack)
      //}
    })
  }
  return recaps
}


export const getActiveWeek = coachingData => {
  if (coachingData && coachingData.weeks) {
    let isCompleted = false
    let completedWeekIndex = 0

    // {"id":1,"week_no":0,"is_completed":null,"coaching_date":"2021-01-09 00:00:00"}
    coachingData.weeks.forEach(week => {      
      if (week.is_completed) {
        isCompleted = true
        completedWeekIndex = week.week_no
      }
    })

    if (!isCompleted) {
      // none of the week is completed
      return 'Week 1'
    }
    // we have a completed week
    if (completedWeekIndex == ACTIVE_WEEK_FOUR_INDEX) {
      // final week is completed
      return 'Coaching is completed'
    }
    return `Week ${completedWeekIndex + 2}`
  }
  return 'None'
}

export const isEmptyDate = date => {
  if (String(date).length === 0) {
    return true
  }

  if (String(date).indexOf('0000-00-00') > -1) {
    return true
  }
  return false
}
const getCoachingDate = (coachingData, weekNo) => {
  let date = ''
  if (coachingData && coachingData.weeks) {
    const week = coachingData.weeks.find(week => week.week_no === weekNo)
    if (week !== undefined) {
      const weekDate = week.coaching_date
      if (!isEmptyDate(weekDate)) {
        date = String(weekDate).substring(0, 10)
      }
    }
  }

  return date
}

const getDisplayDueString = (nextWeek, dueDate) => {
  let date = ''
  if (!isEmptyDate(dueDate)) {
    date = moment(dueDate, 'YYYY-MM-DD').format('Do MMM, YYYY')
  }

  return `Week ${nextWeek} due ${date}`
}

const getDueDate = (coachingData, weekNo) => {
  const nextWeekIndex = weekNo + 2 // 0th index based
  const nextWeek = nextWeekIndex + 1
  const dueDate = getCoachingDate(coachingData, nextWeekIndex)

  return getDisplayDueString(nextWeek, dueDate)
}

export const getDueWeekAndDate = coachingData => {
  if (coachingData && coachingData.weeks) {
    let isCompleted = false
    let completedWeekIndex = 0

    coachingData.weeks.forEach(week => {
      if (week.is_completed) {
        isCompleted = true
        completedWeekIndex = week.week_no
      }
    })

    if (!isCompleted) {
      // none of the week is completed
      const dueDate = getCoachingDate(coachingData, completedWeekIndex + 1)
      return getDisplayDueString(2, dueDate)
    }
    // we have a completed week    
    if (completedWeekIndex == DUE_WEEK_FOUR_INDEX) {
      // final week is completed
      return NO_DUE
    }
    return getDueDate(coachingData, completedWeekIndex)
  }
  return NO_DUE
}

export const test_CoachingActiveAndDueDate = () => {
  const coaching = {}
  coaching.weeks = [
    {
      id: 1,
      week_no: 0,
      is_completed: null,
      coaching_date: '2021-01-09 00:00:00'
    },
    {
      id: 2,
      week_no: 1,
      is_completed: null,
      coaching_date: '2021-01-16 00:00:00'
    },
    {
      id: 3,
      week_no: 2,
      is_completed: null,
      coaching_date: '2021-01-23 00:00:00'
    },
    {
      id: 4,
      week_no: 3,
      is_completed: null,
      coaching_date: '2021-01-30 00:00:00'
    }
  ]

  const coaching1 = JSON.parse(JSON.stringify(coaching))
  coaching1.weeks[0].is_completed = true

  const coaching2 = JSON.parse(JSON.stringify(coaching))
  coaching2.weeks[1].is_completed = true

  const coaching3 = JSON.parse(JSON.stringify(coaching))
  coaching3.weeks[2].is_completed = true

  const coaching4 = JSON.parse(JSON.stringify(coaching))
  coaching4.weeks[3].is_completed = true
}
