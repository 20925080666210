import React, { Component } from 'react'
import { Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import {
  AvTimer,
  PolicyOutlined,
  FlipCameraAndroidOutlined,
  VerifiedUserOutlined
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { isAdmin, isPm, isProgramDirector } from '../../helpers'
import {
  DEFAULT_PROGRAM_SETTINGS_URL,
  MANAGE_METRICS_URL
} from '../../constants'
import {  
  UnauthorizedCard,
  OverlayLoader
} from '../../components'

class AccountSettings extends Component {
  state = {
    isManageMetrics: true,
    isManageDefaultProgram: false,
    isAuthorizedAccess: null,
  }

  componentDidMount = async () => {
    if (!isPm() && !isProgramDirector()) {     
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: true,        
      }))  
      this.props.updateHeaderTxt('Settings', '')
    }    
  }

  getManageMetricsOption = () => {
    if (isPm()) {
      const link = `/dashboard${MANAGE_METRICS_URL}`
      return (
        <Button
          type='button'
          className='btn btn-sm btn-primary ml-auto'
          as={Link}
          to={link}
        >
          Manage Metrics Header
        </Button>
      )
    } 
    return null
  }

  getDefaultProgramOption = () => {
    if (!isAdmin()) {
      const link = `/dashboard${DEFAULT_PROGRAM_SETTINGS_URL}`
      return (
        <Button
          type='button'
          className='btn btn-sm btn-primary ml-auto'
          as={Link}
          to={link}
        >
          Manage Default Program
        </Button>
      )
    } 
    return null
  }

  showManageMetrics = () => {
    this.setState({
      isManageMetrics: true,
      isManageDefaultProgram: false
    })
  }

  showDefaultProgram = () => {
    this.setState({
      isManageMetrics: false,
      isManageDefaultProgram: true
    })
  }

  render() {
    const { isAuthorizedAccess } = this.state
    
    if(isAuthorizedAccess === null) {      
      return <OverlayLoader />
    }

    if(isAuthorizedAccess) {
      return (<p>
          You can manage the program metric headers and the default program under settings.
        </p>)
    } else {
      return <UnauthorizedCard />
    }    
  }
}

export default AccountSettings
