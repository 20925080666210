import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../styles/base.scss'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

const PopupModal = ({ ...props }) => {
  return (
    <>    
      <Modal show={props.showModal} onHide={props.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.modalBody}</Modal.Body>
        <Modal.Footer>
          {props.hideCloseButton ? null : <Button variant="secondary" onClick={props.handleModalClose}>
            Close
          </Button>}
          {props.buttons ? props.buttons : null}          
        </Modal.Footer>
      </Modal>
    </>
  ) 
}

export default PopupModal
