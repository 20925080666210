import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_ALL_TARGET_CATEGORIES,
  LOCAL_STORAGE_TARGET_CATEGORIES
} from '../constants'

import { getUserData, setUserData } from '../helpers'

const options = AppService.options()

export const targetService = {
  get_all_target_categories: async () => {
    const targetCategories = getUserData(LOCAL_STORAGE_TARGET_CATEGORIES)

    if (!targetCategories) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_ALL_TARGET_CATEGORIES,
        { ...options, ...HTTPHeaders() }
      )

      setUserData(LOCAL_STORAGE_TARGET_CATEGORIES, response)

      return response
    }
    return targetCategories
  }
}
