import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import Settings from '@settings'
import '../../styles/ie11.scss'

// IE11 only widget
const IE11Widget = () => {
  const [showWidget, setShowWidget] = useState(true)

  const handleClose = () => {
    setShowWidget(false)
  }

  return (
    showWidget && (
      <section className='ie11-widget py-2'>
        <Container fluid className='text-center'>
          <p className='m-0 ie11-widget-text'>
            We have detected that you are using IE11.{' '}
            <small>
              {Settings.brandName} is not compatible with IE11, we suggest you
              use Edge.
            </small>
            <button
              type='button'
              className='ie-widget-close ml-2'
              onClick={() => handleClose()}
            >
              close
            </button>
          </p>
        </Container>
      </section>
    )
  )
}

export default IE11Widget
