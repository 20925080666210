import { TableCell, TableHead, TableRow } from '@material-ui/core'
import MaUTable from '@material-ui/core/Table'
import React, { useEffect, useImperativeHandle } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  useAsyncDebounce,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table'
import { isAgent, isPm, isProgramDirector } from '../../helpers'
import '../../styles/base.scss'

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, setMyGlobalFilter }) => {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || '')
  }, 200)

  return (
    <Row className='p-1'>
      <Col md='auto' className='ml-auto'>
        <input
          className='form-control form-control-sm'
          type='text'
          placeholder='Search'
          value={globalFilter || ''}
          onChange={e => {
            setMyGlobalFilter(e.target.value)
            onChange(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className='form-control form-control-sm'
    />
  )
  // return value
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  handleChange,
  skipPageReset,
  selectedProgram,
  myGlobalFilter,
  setMyGlobalFilter,
  isEditMetric,
  hasRecords
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      handleChange,
      selectedProgram,
      isEditMetric,
      hasRecords
    },
    useGlobalFilter,
    useSortBy
  )

  const getAgentLink = (row, cell) => {
    const programId = selectedProgram.value
    const isOtherUrl = selectedProgram.program.coaching_type === 'OTHERS'
    const viewlink = isOtherUrl
      ? selectedProgram.program.coaching_url
      : `/dashboard/coaching/add?program=${programId}&agent=${
          row.original[Object.keys(row.original).length - 1]
        }`
    if (isOtherUrl) {
      return (
        <div>
          {row.original[Object.keys(row.original).length - 2]} 
          {!isAgent() ? <a target='blank' href={viewlink}>{' ['+cell.value+']'}</a> : ' ['+cell.value+']'}
        </div>
      )
    }
    return (
      <div>
        {/* {row.original[Object.keys(row.original).length - 2]} 
        {!isAgent() ? <Link to={viewlink}>{' ['+cell.value+']'}</Link> : ' ['+cell.value+']'} */}
        {!isAgent() ? row.original[Object.keys(row.original).length - 2] : ''} 
        {!isAgent() ? <Link to={viewlink}>{' ['+cell.value+']'}</Link> : cell.value}        
      </div>
    )
  }

  const showTableCells = (row, cell, index) => {
    const { metrics } = selectedProgram.program
    const isEditable = metrics[index] ? metrics[index].editable : false
    const threshold = metrics[index] ? metrics[index].threshold : 0
    const columnValue = cell.value
    let classNameObj = ''

    if (threshold > 0 && columnValue < threshold) classNameObj = 'text-danger'
    if (threshold < 0 && columnValue > -threshold) classNameObj = 'text-danger'
    if (cell.column.Header === 'Employee Code') {
      return getAgentLink(row, cell)
    }
    if (cell.column.Header === 'Team') {
      return <div>{cell.value}</div>
    }
    if (isEditable && isEditMetric) {
      return cell.render('Cell')
    }
    return <div className={classNameObj}>{cell.value}</div>

    /* else if (cell.column.Header === 'Team') {
      return <div>{cell.value}</div>
    } 
    else {
      return (cell.render('Cell'))
    } */
  }

  // Render the UI for your table
  return (
    <>
      {hasRecords && (
        <Row className='p-3'>
          <Col md='auto' className='ml-auto'>
            <GlobalFilter
              globalFilter={myGlobalFilter}
              setGlobalFilter={setGlobalFilter}
              setMyGlobalFilter={setMyGlobalFilter}
            />
          </Col>
        </Row>
      )}
    <div className='d-grid'>
      <div className='table-responsive'>
        <MaUTable {...getTableProps()} className='table table-sm card-table'>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <tbody {...getTableBodyProps()}>
            {hasRecords &&
              rows.map(row => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {showTableCells(row, cell, index)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </tbody>
        </MaUTable>
      </div>
      </div>
    </>
  )
}

const WeeklyMetricsTable = React.forwardRef(({ ...props }, ref) => {
  // ({ ...props }) => {

  const [data, setData] = React.useState(() => {
    return props.metricTableData
  })

  useImperativeHandle(
    ref,
    () => ({
      getMyState: () => {
        return data
      }
    }),
    [data]
  )

  useEffect(() => {
    setData(props.metricTableData)
  }, [props.metricTableData])

  const [myGlobalFilter, setMyGlobalFilter] = React.useState('')

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const handleChange = (e, cell) => {
    data[cell.row.index].data_type = e.target.value
    setData(data)
    props.updateMetricsTable(data)
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)

    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          }
        }
        return row
      })
    )
    props.updateMetricsTable(data)
  }

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  return (
    <>
      <Table
        columns={props.columns}
        data={data}
        updateMyData={updateMyData}
        handleChange={handleChange}
        skipPageReset={skipPageReset}
        selectedProgram={props.selectedProgram}
        myGlobalFilter={myGlobalFilter}
        setMyGlobalFilter={setMyGlobalFilter}
        isEditMetric={props.isEditMetric}
        hasRecords={props.hasRecords}
      />
    </>
  )
})

export default WeeklyMetricsTable
