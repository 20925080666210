import { any } from 'prop-types'
import { AppService, HTTPHeaders } from '.'
import {
  BASE_API_URL,
  GET_NOTES,
  LOCAL_STORAGE_NOTES,
  LOCAL_STORAGE_NOTES_FOLDER
} from '../constants'
import { deleteData, getFolderUserData, setFolderUserData } from '../helpers'

const options = AppService.options()

export const notesService = {
  get_notes: async signal => {
    const notes = getFolderUserData(
      LOCAL_STORAGE_NOTES_FOLDER,
      LOCAL_STORAGE_NOTES
    )
    if (!notes) {
      const response = await AppService.makeRequest(BASE_API_URL + GET_NOTES, {
        ...HTTPHeaders()
      })
      setFolderUserData(
        LOCAL_STORAGE_NOTES_FOLDER,
        LOCAL_STORAGE_NOTES,
        response
      )

      return response
    }
    return notes
  },
  get_paginated_notes: async query => {
    const notes = getFolderUserData(
      LOCAL_STORAGE_NOTES_FOLDER,
      LOCAL_STORAGE_NOTES + query
    )
    if (!notes) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_NOTES + query,
        { ...HTTPHeaders() }
      )

      setFolderUserData(
        LOCAL_STORAGE_NOTES_FOLDER,
        LOCAL_STORAGE_NOTES + query,
        response
      )
      return response
    }
    return notes
  },
  add_notes: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + GET_NOTES, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_NOTES_FOLDER)
    return response
  }
}
