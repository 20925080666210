import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from '../containers'
import { getSessionVariable } from '../helpers'
import { AUTH_TOKEN_NAME } from '../constants'

export const PublicRoute = ({
  component: Component,
  restricted,
  layout,
  url,
  ...rest
}) => (
 
  <Route 
    {...rest}
    render={props => {
      if (restricted && getSessionVariable(AUTH_TOKEN_NAME)) {        
        return <Redirect to='/dashboard' />
      }      
      return layout === false ? (
        <Component {...props} url={url} />
      ) : (
        <Layout url={url}>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)
