import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddCoachingConversationComponent,
  AddCoachingMainComponent,
  AddCoachingWeeksComponent,  
  CoachingCard,
  AgentViewCard,
  UnauthorizedCard,
  OverlayLoader
} from '../../components'
import { 
  isAdmin, 
  isPm, 
  isAgent, 
  isProgramDirector, 
  getProgramCoachings, 
  getDropDownProgram, 
  deleteData, 
  getUserData, 
  getUserId,
  setUserData,
  getWeeklyProgramMetrics,
  getThisWeekDateRange,
  getSelectedWeekDateRange 
} from '../../helpers'
import { ErrorBoundary } from '..'
import { LOCAL_STORAGE_SELECTED_PROGRAM, 
  LOCAL_STORAGE_SELECTED_AGENT, 
  LOCAL_STORAGE_SELECTED_WEEK,
  USER_DETAILS } from '../../constants'
import { convertDateFormat } from '../../constants/coaching.state.constants'
import { coachingService } from '../../services'
import qs from 'querystringify'
import moment from 'moment'

class AgentView extends Component {
  state = {    
    data: [],
    selectedProgram: null, // {value:'', lable:'', program:{}}
    pageCount: 1,
    fetchIdRef:0,
    target_category: this.props.match.params.target_category,    
    isLoading: true, 
    selectedAgent:null, 
    showCoachingType:'',
    metricData: {},
    programId:null,
    agentId:null, 
    weekRange:'',
    isAuthorizedAccess:true,     
  }

  abortController = new AbortController()

  componentDidMount = async () => {
    if(!isPm() && !isProgramDirector()) {
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.props.updateHeaderTxt('Agent View', 'Metrics')   
      const queryParam = this.props.location.search
      //let program = null   
      if (queryParam !== '') {
        const { program, agent} = qs.parse(this.props.location.search)
        this.fetchAgentDetails(program, agent)      
        this.setState({ 
          programId: program,
          agentId:agent        
        })
      }
    }    
  }

  componentDidUpdate = (prevProps) => {      
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({ 
        isLoading: this.props.isLoading
       })
    }
  } 
  
  updateCoachingState = (loading, coachingData) => {
    this.setState(state => ({
      ...state,
      isLoading: loading,      
      data: coachingData
    }))
  }

  onPmChangeHook = () => {
    this.fetchData()
    this.setState(state => ({
      ...state,
      selectedProgram: this.props.programBar.selectedProgram
    }))
  }

  onAgentChangeHook = () => {
    const selectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)       
    this.fetchData()
    this.setState(state => ({
      ...state,
      selectedAgent: this.props.programBar.selectedAgent,      
    }))
  }

  fetchData = () => {
    this.setState(state => ({
      ...state,
      isLoading: true
    }))

    const selectedProgram = getUserData(LOCAL_STORAGE_SELECTED_PROGRAM)
    const selectedAgent = getUserData(LOCAL_STORAGE_SELECTED_AGENT)      
    const selectedWeek = getUserData(LOCAL_STORAGE_SELECTED_WEEK)    
    
    const { week } = selectedWeek
    const programId = selectedProgram.value
    const userData = getUserData(USER_DETAILS) 

    const {showCoachingType} = this.state    
    let agentId = null
    if (isAgent()) {
      agentId = userData.public_id
    }

    if (isPm()) {
      agentId = selectedAgent ? selectedAgent.value : null      
    }
    
    if (isPm() || isProgramDirector()) {       
      coachingService.get_weekly_coaching(programId, agentId, showCoachingType)
      .then(coachingData => {         
        this.updateCoachingState(false, selectedProgram, coachingData)
      })
      .catch(error => {        
        toast.error('Error while fetching program coaching details.')
        this.updateCoachingState(false, selectedProgram, [])
      })   
    }

    if (isAgent()) {
      if (selectedProgram) {
        this.fetchAgentDetails(programId, selectedProgram)
      } 
    }
  }  

  componentWillUnmount() {
    if (this.state.isLoading) this.abortController.abort()
  }

  fetchAgentDetails = (programId, agentId) => {    
    // get this week date range
    // const week = getThisWeekDateRange(0) 
    const selectedWeek = getUserData(LOCAL_STORAGE_SELECTED_WEEK)
    const selected_week = selectedWeek.week.weekNo
    const selected_year = selectedWeek.week.startYear

    const week = getSelectedWeekDateRange(selected_week, selected_year)    
    const userData = getUserData(USER_DETAILS) 
    const publicId = userData.public_id
    const userRoleId = userData.role.id
    let start_date = moment().year(week.startYear).week(week.weekNo).day('monday').format('DD/MM/YYYY') 
    let end_date = moment().year(week.startYear).week(week.weekNo).day('monday').add(5,'days').format('DD/MM/YYYY')  
    let week_range = start_date+" - "+end_date
    this.setState(state => ({
      ...state,
      weekRange: week_range
    }))
    if (programId && week && agentId) {      
      Promise.all([        
        coachingService.get_coaching_by_program(programId, agentId, publicId, userRoleId), // TODO, test only, remove it, use above line
        getWeeklyProgramMetrics(
          week.startYear,
          week.weekNo,
          programId,
          agentId
        )
      ])
      .then(([coachingData, metricData]) => {        
        this.updateAgentCoachingState(false, null, coachingData.data, metricData)
      })
      .catch(error => {        
        toast.error('Error while fetching Agent coaching details.')
        this.updateAgentCoachingState(false, null, [], [])
      })
    } else {     
      this.setState(state => ({
        ...state,
        isLoading: false
      }))
    }
  }

  updateAgentCoachingState = (loading, selectedProgram, coachingData, metricData) => {
    this.setState(state => ({
      ...state,
      isLoading: loading,
      selectedProgram, 
      data: coachingData,
      metricData
    }))
  }

  
  executeRender = () => {
    const { isAuthorizedAccess, isLoading } = this.state    
    if(isAuthorizedAccess) {
      if(isLoading) {        
        return <OverlayLoader />
      } else {
        return <AgentViewCard 
            programBar={this.props.programBar}
            selectedProgram={this.state.selectedProgram}
            coachingData={this.state.data}              
            data={this.state.data}
            metricData={this.state.metricData}
            showCoachingType={this.state.showCoachingType}
            weekRange={this.state.weekRange}
          />
      }     
    } else {
      return <UnauthorizedCard />
    }    
  }

  render() {
    return this.executeRender()
  }  
}

export default AgentView