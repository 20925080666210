import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import { Alert, Button, Card, Col, Form, Row, Spinner, ListGroup } from 'react-bootstrap'
import '../../styles/coaching-card.scss'
import { ArrowUpwardOutlined, AccountCircleOutlined } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  isAgent,
  isPm,
  isProgramDirector,
  getAgentBehaviourOpportunities,
  getAgentSmartGoals,
  getAgentCoachingRecaps,
  getAgentCoachingFeedBack,
  getActiveWeek,
  getDueWeekAndDate,
  checkValueYesOrNot,
  showConsoleLog,
  checkArrObjectValueExist
} from '../../helpers'
import ProgramSelectBar from './ProgramSelectBar'
import { NO_METRICS } from '../../constants'
import OverlayLoader from './OverlayLoader'
import makeData from './makeData'
import ShowSpinner from './ShowSpinner'
import AgentCoachingList from './AgentCoachingList'

const AgentCoachingCard = ({...props}) => {  
  let coachingData = props.coachingData    
  let addlink = `/dashboard/coaching/add`
  const totalActivePlans = coachingData ? coachingData.length : ''   
  const showCompletedCoaching = props.showCoachingType === 'completed' 
    
  const showAgentKeyMetrics = () => {
    const metricData = props.metricData.data ? props.metricData.data[0] : []
    const { selectedProgram } = props.programBar
    const metricHeaders = selectedProgram ? selectedProgram.program.metrics : []
    const metricGridColumns = []    
    if (metricData !== undefined && metricHeaders !== undefined) {
      if (metricData && metricData.length > 0 && metricHeaders.length > 0) {
        const headerLen = metricHeaders.length
        let metricValue
        let keyMetric
        for (let i = 0; i < metricData.length - 1; i++) {
          if(i !== 2){ //To avoid display ES At Risk for Agents
            metricValue = metricData[i]
            if (i < headerLen) {
              keyMetric = metricHeaders[i].is_key_metrics
              if (keyMetric || keyMetric > 0) {
                metricGridColumns.push(
                  <Col key={i}>
                    <Card className='bg-primary'>
                      <Card.Body>
                        <Row className='align-items-center'>
                          <Col>
                            <h3 className='name'>{metricValue}</h3>
                            <h4 className='mb-1'>
                              {metricHeaders[i].metrics_name} 
                            </h4>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              }
            }
          }
        }
        return <Row className='row-cols-3'>{metricGridColumns}</Row>
      }
    } else {
      return (
        <Row>
          <Col>
            <Card className='bg-primary'>
              <Card.Body>{"No metrics data available for the week "+props.weekRange}</Card.Body>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  const showAgentContentTable = (latest_coaching) => {     
    const acknowledgementData = props.acknowledgementData       
    const arrRows = []
    const smartGoals = getAgentSmartGoals(latest_coaching)
    const oppertunities = getAgentBehaviourOpportunities(latest_coaching)
    const recaps = getAgentCoachingRecaps(latest_coaching)
    const feedBack = getAgentCoachingFeedBack(latest_coaching)
    for (let i = 0; i < 4; i++) {
      const smartGoalContent = smartGoals[i] ? smartGoals[i] : '-'
      const oppertunityContent = oppertunities[i] ? oppertunities[i] : '-'
      const recapContent = recaps[i] ? recaps[i] : '-'
      const feedBackContent = feedBack[i] ? feedBack[i] : '-'
      //let previous_acknowledgement = acknowledgementData[i].agent_acknowledgement
      let field_name = 'agent_acknowledgement_'+i
      let disable_checkbox = (smartGoals[i] === "" && oppertunities[i] === "" && recaps[i] === "")

      //Disabled checkbox if manager has not completed the coahing, previous week is not acknowledged
      /*let disable_checkbox = (latest_coaching && latest_coaching.weeks && (!latest_coaching.weeks[i].is_completed || 
                                (latest_coaching.weeks[i].agent_acknowledgement !== "" &&
                                latest_coaching.weeks[i].agent_acknowledgement !== "False") &&
                                (acknowledgementData && acknowledgementData[i]['agent_acknowledgement'] !== "" &&
                                acknowledgementData[i]['agent_acknowledgement'] !== "False"))) ? true : false*/
      let checked = (latest_coaching && latest_coaching.weeks && 
                      latest_coaching.weeks[i].agent_acknowledgement !== "" && 
                      latest_coaching.weeks[i].agent_acknowledgement !== "False") ? true : false
                      
      if(checked && !disable_checkbox){
        disable_checkbox = true
      }
      
      arrRows.push(
        <tr key={i}>
          <td>{`W${i + 1}`}</td>
          <td className='pr-3'>{oppertunityContent}</td>
          <td className='pr-3'>{smartGoalContent}</td>
          <td className='pr-3'>{recapContent}</td>
          {/* <td className='pr-3'>{feedBackContent}</td> */}
          {checked
            ? <td className='pr-3'>{feedBackContent}</td> 
            : <td className='pr-3'>           
                <Form.Group controlId='formAcknowledgement'>
                  <Form.Control
                    as="textarea"
                    placeholder="your feedback here"
                    onChange={event => {
                      props.agentFeedback = event.target
                    }}                
                  />              
                </Form.Group>
              </td> 
          }
          <td className='pr-3'>           
          <Form.Group controlId='formBasicCheckbox'>
            <Form.Check
              type='checkbox'                           
              name={field_name}                
              checked={checked} 
              disabled={disable_checkbox}                           
              onChange={event => {
                const { checked } = event.target
                props.setAgentAcknowledgement(i, checked, props.agentFeedback)
              }}
            />
          </Form.Group>
          </td>
        </tr>
      )
    }
    return arrRows    
  } 

  const agentCoachingList = () => {    
      return <section>                        
              <AgentCoachingList 
               data={props.data} 
               columns={props.columns}             
               selectedProgram={props.selectedProgram}
               selectedAgent={props.selectedAgent}
               showCoachingType={props.showCoachingType}
               keyHeaderMetris={props.keyHeaderMetris}
               fetchData={props.fetchData}
               pageCount={props.pageCount}
               totalRecords={props.totalRecords}
               pageIndex={props.intPageIndex}
               pageSize={props.intPageSize}
               intPageIndex={props.intPageIndex}
               intPageSize={props.intPageSize}
               isLoading={props.isLoading}
              />
            </section>
  }

  const showAgentStatusBar = () => {
    let latest_coaching = coachingData && coachingData.length > 0 ? coachingData[0] : [] 
    
    return (<Row key={0}>
            <Col md={12}>
              <Card className='card-left-border no-bg'>
                <Card.Body className='py-3 pl-3 text-sm'>
                  <Row className='align-items-center'>
                  <Col md={4} className='text-uppercase'>
                        <span className='text-primary font-weight-bold'>
                          Active -{' '}
                        </span>
                        {getActiveWeek(latest_coaching)}
                      </Col>
                      
                      <Col md={6} className="text-right text-uppercase">
                      <a
                          href='#'
                          onClick={e => props.onClickCoachingType(e, props.showCoachingType === 'completed' ? '' : 'completed')}
                          /*className={
                            props.showCoachingType === 'completed'
                              ? 'text-dark'
                              : ''
                          }*/
                        >
                          {props.showCoachingType === 'completed' ? 'Back To Coaching' : 'Completed Coaching'}
                        </a>
                      </Col>
                      <Col
                        md={2}
                        className='coaching_controls text-uppercase'
                      >
                      <h3 className='mb-1'>
                          <span className='badge badge-soft-success mt-1 mr-4'>
                            {getDueWeekAndDate(latest_coaching)}
                          </span>
                        </h3>
                      </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>)
  }

  const showAgentScreen = () => { 
    let latest_coaching = coachingData && coachingData.length > 0 ? coachingData[0] : [] 
    let objButton = props.showAcknowledgeButton()       
    return (
      props.isLoading ? <OverlayLoader /> : (coachingData.length > 0 ? <section> 
       
        {showAgentKeyMetrics()}
        <Row>
          <Col md={12}>
            <Card className='no-bg'>
              <Card.Body>
              
      <table width='100%' className='table table-striped tbl2'>
        <thead>
          <tr>
          <th className='pl-3 border-top-0 bg-muted'>Weeks</th>
          <th className='pl-0 border-top-0'>
            Opportunities to Focus on
          </th>
          <th className='pl-0 border-top-0'>Smart Goal To Change</th>
          <th className='pl-0 border-top-0'>Recap of Coaching</th>
          <th className='pl-0 border-top-0'>Agent Feed Back</th>
          <th className='pl-0 border-top-0'>Reviewed?</th>
        </tr>  
        </thead>
        <tbody>
        {showAgentContentTable(latest_coaching)}
        {/* {objButton.showButton ?
        <tr><td className='pl-0 border-top-0 border-bottom-0'>
            <Button
              variant='primary'
              className='btn btn-primary btn-sm'
              type='button' 
              disabled={objButton.disableButton || props.isLoading}
              onClick={event => {                
                props.saveAgentAcknowledgement()
              }}              
            >
            {props.isLoading ? <ShowSpinner buttonSpinner={true} /> : null} 
            Save           
          </Button>
        </td></tr> : null} */}
        </tbody>
      </table>      
      </Card.Body>
            </Card>
          </Col>          
        </Row>        
      </section>
      : 
      <section>
        <Row>
          <Col md={12}>
            <Card className='bg-primary'>
              <Card.Body>
                No Coaching Records.
              </Card.Body>
            </Card>        
          </Col>
        </Row>
      </section>
      )
    )
  } 

  return (
    <section>
      {showAgentStatusBar()}
      <Row key={1}>
                <Col md={12}>
                  <ProgramSelectBar programBar={props.programBar} />
                </Col>
              </Row>
      {showCompletedCoaching ? agentCoachingList() : showAgentScreen()}
    </section>
  )
}

export default AgentCoachingCard