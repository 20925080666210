/**
 * Component renderer for global components that share props across components/containers and outside react.
 * CAUTION: Use only for absolute needs like Session Popup, Toast Message or any that appears globally across site.
 * For any other needs, stick with React principles.
 */
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Custom render session modal component in a given element(sessionModalContainer).
 * @param {object} Component React component to be rendered
 * @param {string} elementID HTML element id
 * @param {object} props parameters: show, inTime
 */
export const renderGlobalComponent = (Component, elementID, props) => {
  ReactDOM.render(<Component {...props} />, document.getElementById(elementID))
}
