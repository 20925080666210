import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import { 
  AddProgramComponent, 
  UnauthorizedCard,
  OverlayLoader
} from '../../components'
import { isAdmin, isPm, isAgent, isProgramDirector, deleteProgramData, isValidURL } from '../../helpers'
import { programService } from '../../services'
import {  
  ADD_MODE,  
  EDIT_MODE, 
  VIEW_MODE,
} from '../../constants'

/**
 * Component which is used to View or Edit the program details.
 */
class ViewOrEditProgram extends Component {
  state = {
    program_type: '', // program type mapped to Form field
    program_name: '', // program name mapped to Form field
    // metrics dropdown and table states
    metricOptions: [], // metric list displayed in the dropdown
    selectedMetric: [], // array of selected metric_id from dropdown
    metricTableData: [], // metric list displayed in the table
    metricsDataLoaded: false,
    metricKeys: {},
    showAlert: false, // used for show/hide alert
    programId: '', // id of the program to be edited
    programData: {}, // program details
    isLoading: true,
    mode: EDIT_MODE, 
    metricErrors: {},
    isAuthorizedAccess:null,
    isProcessing:false
  }

  validateMetricName = (data) => {     
    let result = false   
    if (/[`~,.;':"\\[\]|{}=+]/.test(data)){
      result = true
    }
    return result  
  }

  handleSubmit = async (data, setSubmitting) => {
    if (this.state.mode === VIEW_MODE) {
      setSubmitting(false)
      const linkTo = `/dashboard/programs/edit/${this.state.programId}`
      this.props.history.replace(linkTo)

      return
    }

    if (!data.selectedAgents || data.selectedAgents.length === 0) {
      setSubmitting(false)
      toast.error('Please add Agents to the Program to proceed')
      return
    }

    if (!data.selectedPms || data.selectedPms.length === 0) {
      setSubmitting(false)
      toast.error('Please select Program Manager')
      return
    }

    if (!data.selectedPds || data.selectedPds.length === 0) {
      setSubmitting(false)
      toast.error('Please select Program Director')
      return
    }

    if (!data.coaching_url) {
      data.coaching_url = ''
    }

    if (data.coaching_type === 'OTHERS' && !isValidURL(data.coaching_url)) {
      setSubmitting(false)
      toast.error('Please enter valid Coaching Url')
      return
    }

    const metricsData = this.state.metricTableData
    let priority = 1
    const decimalPattern = new RegExp(/^\d{1,3}(\.\d{1,2})?$/);
    const numberPattern = new RegExp(/^[0-9]$/)
    let metricErrors = { isError: false, errorMsg: "" }

    metricsData.map(row => {
      if (row.metrics_name === '') {
        metricErrors = {
          isError: true,
          errorMsg: 'Please enter metric name to all the defined metrics'
        }
        return
      } else {
        if(this.validateMetricName(row.metrics_name)) {          
          metricErrors = {
            isError: true,
            errorMsg: 'Metrics name should be alphanumeric'
          }
          return
        }
      }
      if (row.threshold === '') {
        metricErrors = {
          isError: true,
          errorMsg: 'Please enter threshold value to all the defined metrics'
        }
        return
      }
      if (
        row.data_type === 'Percentage' &&
        !decimalPattern.test(row.threshold.toString())
      ) {
        metricErrors = {
          isError: true,
          errorMsg:
            'Threshold value should be a number or decimal(2 decimal point) for the data type as Percentage'
        }
        return
      }
      if (
        row.data_type === 'Number' &&
        !numberPattern.test(row.threshold.toString())
      ) {
        metricErrors = {
          isError: true,
          errorMsg: 'Threshold value should be a number for data type as Number'
        }
        return
      }
      row.priority = priority++
      row.threshold = Number(row.threshold)
    })

    this.setState(state => ({
      ...state,
      metricErrors
    }))

    if (metricErrors.isError) {
      setSubmitting(false)
      toast.error('Error, please check.')
      return
    }

    const selectedAgentIds = []
    data.selectedAgents.forEach(item => {
      selectedAgentIds.push(item.agent.public_id)
    })

    const selectedPmIds = []
    data.selectedPms.forEach(item => {
      selectedPmIds.push(item.pm.public_id)
    })

    const selectedPdIds = []
    data.selectedPds.forEach(item => {
      selectedPdIds.push(item.pd.public_id)
    })

    this.setState(state => ({
      ...state,
      isProcessing: true,      
    }))

    programService
      .add_program({
        public_id: data.public_id,
        program_name: data.program_name,
        program_type: data.program_type,
        status: data.status,
        calls_url: data.calls_url,
        coaching_type: data.coaching_type,
        coaching_url: data.coaching_url,
        agent_ids: selectedAgentIds,
        program_manager_ids: selectedPmIds,
        program_director_ids: selectedPdIds,
        metrics: this.state.metricTableData
      })
      .then(response => {
        setSubmitting(false)
        this.setState(state => ({
          ...state,
          showAlert: true,
          isLoading: false
        }))

        // delete the stored programs
        deleteProgramData(data.public_id)

        toast.success(
          'Successfully updated the Program. You will be redirect to Programs Page in 10 seconds'
        )

        setTimeout(() => {
          this.setState(state => ({
            ...state,
            isProcessing: false,      
          }))
          this.props.history.replace('/dashboard/programs/')
        }, 6000)
      })
      .catch(error => {
        setSubmitting(false)
        toast.error('Error while updating Program', error)
      })
    setSubmitting(true)
  }

  getSelectedMetrics = () => {
    const metricsSelected = []
    let priority = 0

    this.state.selectedMetric.forEach(metricId => {
      // get the metric from id
      const metric = this.state.metricKeys[metricId]
      // set the priority
      metric.priority = priority
      // push it to array
      metricsSelected.push(metric)
      // increament the priority
      priority += 1
    })
    return metricsSelected
  }

  setShow = () => {
    this.setState(state => ({
      ...state,
      showAlert: false
    }))
  }

  abortController = new AbortController()

  componentDidMount() { 
    if(!isAdmin()) {        
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      const programId = this.props.match.params.id
      const path = String(this.props.match.path)
      let mode = EDIT_MODE

      // if view, change the mode
      if (path == "/dashboard/programs/:id") {
        mode = VIEW_MODE
      }

      let pageHeader = (mode === VIEW_MODE) ? 'View Program' : 'Edit Program'
      this.props.updateHeaderTxt(pageHeader, 'Programs')
      this.setState(state => ({
        ...state,        
        isAuthorizedAccess: true, 
      }))
      programService
        .get_program_by_id(programId)
        .then(data => {
          let metricsData = []
          if (data.metrics.length === 0) {
            const empCode = {
              metrics_name: 'Employee Code',
              data_type: 'String',
              threshold: 0,
              editable: false,
              is_key_metrics: false,
              aggregation: '',
              icon: '',
              units: '',
              priority: 1
            }
            const team = {
              metrics_name: 'Team',
              data_type: 'String',
              threshold: 0,
              editable: false,
              is_key_metrics: false,
              aggregation: '',
              icon: '',
              units: '',
              priority: 2
            }
            const at_risk = {
              metrics_name: METRIC_HEADER_AT_RISK,
              data_type: 'Boolean',
              threshold: 0,
              editable: false,
              is_key_metrics: true,
              aggregation: 'Count',
              icon: '',
              units: '',
              priority: 3
            }
            metricsData.push(empCode)
            metricsData.push(team)
            metricsData.push(at_risk)
          } else {
            data.metrics.sort((a, b) => (a.priority > b.priority ? 1 : -1))
            metricsData = data.metrics
          }

          // sort agents based on first name
          data.agents.sort((a, b) => {
            if (a.f_name < b.f_name) return -1
            if (a.f_name > b.f_name) return 1
            return 0
          })

          // sort pm based on first name
          data.program_managers.sort((a, b) => {
            if (a.f_name < b.f_name) return -1
            if (a.f_name > b.f_name) return 1
            return 0
          })
          
          this.setState(state => ({
            ...state,
            isLoading: false,
            programId,
            mode,
            programData: data,
            metricTableData: metricsData
          }))
        })
        .catch(() => {
          // display error toast
          toast.error('Program not found, You are redirected to Program page.')
          this.redirectToPrograms()
        })
    }
  }

  // redirect to programs page
  redirectToPrograms = () => {
    this.props.history.replace('/dashboard/programs')
  }
  
  onMetricSelectedChanged = data => {    
    const tableData = []
    data.forEach(key => {
      const metric = this.state.metricKeys[key]
      tableData.push({
        name: metric.metrics_name,
        id: metric.public_id
      })
    })

    this.setState(state => ({
      ...state,
      selectedMetric: data,
      metricTableData: tableData
    }))
  }

  deleteMetric = metric => {    
    const selectedMetrics = [...this.state.selectedMetric]
    const tableMetrics = [...this.state.metricTableData]

    // remove item from selectedMetrics
    const index = selectedMetrics.findIndex((item, i) => {
      return item === metric.id
    })
    selectedMetrics.splice(index, 1)

    // remove item from metricTableData
    const itemindex = tableMetrics.findIndex((item, i) => {
      return item.id === metric.id
    })
    tableMetrics.splice(itemindex, 1)

    this.setState(state => ({
      ...state,
      selectedMetric: selectedMetrics,
      metricTableData: tableMetrics
    }))
  }

  updateMetricsTable = tableData => {    
    this.setState(state => ({
      ...state,
      metricTableData: tableData
    }))
  }

  showLoading = () => {
    if (this.state.isLoading) {      
      return <OverlayLoader />
    }
    return null
  }

  executeRender = () => {
    const { isAuthorizedAccess, isLoading } = this.state
    
    if(isAuthorizedAccess === null || isLoading) {
      return <OverlayLoader />
    }

    if(isAuthorizedAccess !== null && isAuthorizedAccess) {
      return (<Row>
        <Col>
          <ErrorBoundary>
            {this.showLoading()}
            <AddProgramComponent
              setShow={this.setShow}
              handleSubmit={this.handleSubmit}
              data={this.state.programData}
              metricOptions={this.state.metricOptions}
              selectedMetrics={this.state.selectedMetric}
              onMetricSelectedChanged={this.onMetricSelectedChanged}
              metricTableData={this.state.metricTableData}
              updateMetricsTable={this.updateMetricsTable}
              metricErrors={this.state.metricErrors}
              showAlert={this.state.showAlert}
              mode={this.state.mode}
              isProcessing={this.state.isProcessing}
            />
          </ErrorBoundary>
        </Col>
      </Row>)
    } else {
      return <UnauthorizedCard />
    }    
  }

  render() {
    return this.executeRender()
  }
}

export default ViewOrEditProgram
