import React from 'react'
import { Card } from 'react-bootstrap'

const MetricsCardLoader = () => {
  return (
    <section className='category-cards'>
      {[1, 2, 3, 4, 5].map(key => {
        return (
          <Card className='item' key={key}>
            <Card.Body>
              <div className='d-flex'>
                <div
                  className='animated-background mb-3 mr-3'
                  style={{ width: '10%' }}
                />
                <div className='animated-background w-75 mb-3' />
              </div>
              <div className='scan-results'>
                <div className='scan-results-col targets pr-2'>
                  <div className='animated-background mb-2 w-25' />
                  <div className='animated-background w-100' />
                </div>
                <div className='scan-results-col harvested pr-2'>
                  <div className='animated-background mb-2 w-25' />
                  <div className='animated-background w-100' />
                </div>
                <div className='scan-results-col pending'>
                  <div className='animated-background mb-2 w-25' />
                  <div className='animated-background w-100' />
                </div>
              </div>
             
            </Card.Body>
          </Card>
        )
      })}
    </section>
  )
}

export default MetricsCardLoader
