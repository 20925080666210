import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ErrorBoundary } from '..'
import { 
  AddProgramComponent, 
  UnauthorizedCard,
  OverlayLoader 
} from '../../components'
import { isAdmin, isAgent, isPm, isProgramDirector, deleteProgramData, isValidURL } from '../../helpers'
import { metricsService, programService } from '../../services'
import {
  METRIC_HEADER_EMP_CODE,
  METRIC_HEADER_TEAM,
  METRIC_HEADER_AT_RISK,
  ADD_MODE
} from '../../constants'

class AddProgram extends Component {
  state = {
    program_type: '', // program type mapped to Form field
    program_name: '', // program name mapped to Form field
    coaching_type: '', // coaching type mapped to Form field
    coaching_url: '',
    status: '',
    calls_url: '',
    // metrics dropdown and table states
    metricOptions: [], // metric list displayed in the dropdown
    selectedMetric: [], // array of selected metric_id from dropdown
    metricTableData: [], // metric list displayed in the table
    metricKeys: {},
    isLoading: true,
    showAlert: false, // used for show/hide alert
    metricErrors: {},
    isAuthorizedAccess:null,
    isProcessing: false
  }

  validateMetricName = (data) => {     
    let result = false   
    if (/[`~,.;':"\\[\]|{}=+]/.test(data)){
      result = true
    }
    return result  
  }

  handleSubmit = async (data, setSubmitting) => {
    if (!data.selectedAgents || data.selectedAgents.length === 0) {
      setSubmitting(false)
      toast.error('Please add Agents to the Program to proceed')
      return
    }

    if (!data.selectedPms || data.selectedPms.length === 0) {
      setSubmitting(false)
      toast.error('Please select Program Manager')
      return
    }

    if (!data.selectedPds || data.selectedPds.length === 0) {
      setSubmitting(false)
      toast.error('Please select Program Director')
      return
    }

    if (!data.coaching_url) {
      data.coaching_url = ''
    }

    if (data.coaching_type === 'OTHERS' && !isValidURL(data.coaching_url)) {
      setSubmitting(false)
      toast.error('Please enter Coaching Url')
      return
    }

    const metricsData = this.state.metricTableData
    let priority = 1
    const decimalPattern = new RegExp(/^\d{1,3}(\.\d{1,2})?$/)
    const numberPattern = new RegExp(/^[0-9]+$/)
    let metricErrors = { isError: false, errorMsg: '' }

    metricsData.map(row => {
      if (row.metrics_name === '') {
        metricErrors = {
          isError: true,
          errorMsg: 'Please enter metric name to all the defined metric'
        }
        return
      } else {
        if(this.validateMetricName(row.metrics_name)) {          
          metricErrors = {
            isError: true,
            errorMsg: 'Metrics name should be alphanumeric'
          }
          return
        }
      }
      if (row.threshold === '') {
        metricErrors = {
          isError: true,
          errorMsg: 'Please enter threshold value to all the defined metrics'
        }
        return
      }
      if (
        row.data_type === 'Percentage' &&
        !decimalPattern.test(row.threshold.toString())
      ) {
        metricErrors = {
          isError: true,
          errorMsg:
            'Threshold value should be a number or decimal(2 decimal point) for the data type as Percentage'
        }
        return
      }
      if (
        row.data_type === 'Number' &&
        !numberPattern.test(row.threshold.toString())
      ) {
        metricErrors = {
          isError: true,
          errorMsg: 'Threshold value should be a number for data type as Number'
        }
        return
      }
      row.priority = priority++
      row.threshold = Number(row.threshold)
    })
    this.setState(state => ({
      ...state,
      metricErrors
    }))

    if (metricErrors.isError) {
      setSubmitting(false)
      toast.error('Error, please check.')
      return
    }

    const selectedAgentIds = []
    data.selectedAgents.forEach(item => {
      selectedAgentIds.push(item.agent.public_id)
    })

    const selectedPmIds = []
    data.selectedPms.forEach(item => {
      selectedPmIds.push(item.pm.public_id)
    })

    const selectedPdIds = []
    data.selectedPds.forEach(item => {
      selectedPdIds.push(item.pd.public_id)
    })

    // Just for testing purpose, once implemeneted, remove this
    this.state.metricTableData.map(item => {
      item.threshold = 0
    })

    this.setState(state => ({
      ...state,
      isProcessing: true,      
    }))

    programService
      .add_program({
        program_name: data.program_name,
        program_type: data.program_type,
        status: data.status,
        calls_url: data.calls_url,
        coaching_type: data.coaching_type,
        coaching_url: data.coaching_url,
        agent_ids: selectedAgentIds,
        program_manager_ids: selectedPmIds,
        program_director_ids: selectedPdIds,
        metrics: this.state.metricTableData
      })
      .then(() => {
        setSubmitting(false)
        this.setState(state => ({
          ...state,
          showAlert: true,
          isLoading: false
        }))

        // delete the stored programs
        deleteProgramData()

        toast.success(
          'Successfully created the Program. You will be redirect to Programs Page in 10 seconds'
        )

        setTimeout(() => {
          this.setState(state => ({
            ...state,
            isProcessing: false,      
          }))
          this.props.history.goBack()
        }, 6000)
      })
      .catch(error => {
        setSubmitting(false)
        toast.error('Error while creating new Program')
      })

    setSubmitting(true)
  }

  setShow = () => {
    this.setState(state => ({
      ...state,
      showAlert: false
    }))
  }

  abortController = new AbortController()

  componentDidMount() {
    if(!isAdmin()) {        
      this.setState(state => ({
        ...state,
        isAuthorizedAccess: false,        
      }))  

      setTimeout(() => {      
        this.props.history.push({
          pathname: "/dashboard",							
        });     
      }, 2000)
    } else {
      this.props.updateHeaderTxt('Add Program', 'Programs')
      this.setState(state => ({
        ...state,
        isLoading: true,
        isAuthorizedAccess: true, 
      }))
      const empCode = {
        metrics_name: 'Employee Code',
        data_type: 'String',
        threshold: 0,
        editable: false,
        is_key_metrics: false,
        aggregation: '',
        icon: '',
        units: '',
        priority: 1
      }
      const team = {
        metrics_name: 'Team',
        data_type: 'String',
        threshold: 0,
        editable: false,
        is_key_metrics: false,
        aggregation: '',
        icon: '',
        units: '',
        priority: 2
      }
      const at_risk = {
        metrics_name: METRIC_HEADER_AT_RISK,
        data_type: 'Boolean',
        threshold: 0,
        editable: false,
        is_key_metrics: true,
        aggregation: 'Count',
        icon: '',
        units: '',
        priority: 3
      }
      this.state.metricTableData.push(empCode)
      this.state.metricTableData.push(team)
      this.state.metricTableData.push(at_risk)

      Promise.all([metricsService.get_metrics()]).then(values => {
        const [metrics] = values

        const metricOptions = []
        const metricKeys = {}
        metrics.data.forEach(metric => {
          metricOptions.push({
            value: metric.public_id,
            label: `${metric.metrics_name}`
          })
          metricKeys[metric.public_id] = metric
        })

        this.setState(state => ({
          ...state,
          isLoading: false,
          metricKeys,
          metricOptions
        }))
      })
    }    
  }

  onMetricSelectedChanged = data => {
    const tableData = []
    data.forEach(key => {
      const metric = this.state.metricKeys[key]
      tableData.push({
        name: metric.metrics_name,
        id: metric.public_id
      })
    })

    this.setState(state => ({
      ...state,
      selectedMetric: data,
      metricTableData: tableData
    }))
  }

  updateMetricsTable = tableData => {
    this.setState(state => ({
      ...state,
      metricTableData: tableData
    }))
  }

  deleteMetric = metric => {
    const selectedMetrics = [...this.state.selectedMetric]
    const tableMetrics = [...this.state.metricTableData]

    // remove item from selectedMetrics
    const index = selectedMetrics.findIndex((item, i) => {
      return item === metric.id
    })
    selectedMetrics.splice(index, 1)

    // remove item from metricTableData
    const itemindex = tableMetrics.findIndex((item, i) => {
      return item.id === metric.id
    })
    tableMetrics.splice(itemindex, 1)

    this.setState(state => ({
      ...state,
      selectedMetric: selectedMetrics,
      metricTableData: tableMetrics
    }))
  }

  showLoading = () => {
    if (this.state.isLoading) {      
      return <OverlayLoader />
    }
    return null
  }

  executeRender = () => {
    const { isAuthorizedAccess, isLoading } = this.state
    
    if(isAuthorizedAccess === null || isLoading) {
      return <OverlayLoader />
    }

    if(isAuthorizedAccess !== null && isAuthorizedAccess) {
      return (<Row>
        <Col>
          <ErrorBoundary>
            {/*this.showLoading()*/}
            <AddProgramComponent
              setShow={this.setShow}
              handleSubmit={this.handleSubmit}
              data={this.state}
              metricOptions={this.state.metricOptions}
              selectedMetrics={this.state.selectedMetric}
              onMetricSelectedChanged={this.onMetricSelectedChanged}
              metricTableData={this.state.metricTableData}
              updateMetricsTable={this.updateMetricsTable}
              deleteMetric={this.deleteMetric}
              showAlert={this.state.showAlert}
              mode={ADD_MODE}
              metricErrors={this.state.metricErrors}
              isProcessing={this.state.isProcessing}
            />
          </ErrorBoundary>
        </Col>
      </Row>)
    } else {
      return <UnauthorizedCard />
    }    
  }

  render() {
    return this.executeRender()
  }
}

export default AddProgram