/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { Form as FormikForm, Formik } from 'formik'
import React from 'react'
import { Alert, Button, Card, Col, Form, Spinner, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../styles/base.scss'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import EditableTable from './EditableTable'
import {  
  METRIC_HEADER_EMP_NAME,
  METRIC_HEADER_EMP_CODE,
  VIEW_MODE 
 } from '../../constants'
import ShowSpinner from './ShowSpinner'
import OverlayLoader from './OverlayLoader'

/**
 * Form which displays Manage Metrics UI
 */
const ManageMetricsForm = ({ ...props }) => {
  const getMetricsTable = () => {
    return (
      <EditableTable
        metricTableData={props.metricTableData}
        updateMetricsTable={props.updateMetricsTable}
        mode={props.mode}
      />
    )
  } 

  const exportMetricsToExcel = () => {
    const metricHeaders = []
    const header = {}
    const arrHeaders = []
    let counter = 0;
    let agents = (props.selectedProgram && props.selectedProgram.program) ? props.selectedProgram.program.agents : []    
    props.metricTableData.forEach(item => {
      arrHeaders.push(item.metrics_name)
      header[item.metrics_name] = ''
      if (counter < 1) {
        arrHeaders.push(METRIC_HEADER_EMP_NAME)
        header[METRIC_HEADER_EMP_NAME] = ''
      }
      counter++
    })
    //metricHeaders.push(header)
    metricHeaders.push(arrHeaders)
    agents.map((agent, index) => {
      let agent_name = agent.l_name !== "" ? agent.f_name +" "+agent.l_name : agent.f_name
      let arrAgent = []
      arrAgent.push(agent.employee_code)
      arrAgent.push(agent_name)
      arrHeaders.map((header_name, rowIndex) => {
        if(header_name !== METRIC_HEADER_EMP_CODE && header_name !== METRIC_HEADER_EMP_NAME) {
          arrAgent.push("")
        }
      })
      metricHeaders.push(arrAgent)
    })

    const ws = XLSX.utils.json_to_sheet(metricHeaders, {skipHeader:true, origin:'A1'})    
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }    
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType = 'application/vnd.ms-excel;charset=utf-8'
    let fileName = 'ProgramMetrics.xlsx'
    if (props.selectedProgram) {
      fileName = `${props.selectedProgram.value}.xlsx`
    }

    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName)
  }

  const showExport = () => {
    return (
      <Button 
        className='btn btn-sm btn-primary float-right mt-3' 
        onClick={() => {
          exportMetricsToExcel()
        }}
      >
        Download Metrics Excel
      </Button>
    )
  }

  return (
    <Card className="no-bgr bg-white">     
      <Row><Col className='mr-3 mb-2'>{showExport()}</Col></Row>
      <Card.Body className='user-profile-card-body'>
        <Alert
          variant='success'
          show={props.showAlert}
          onClose={() => props.setShow()}
          dismissible
        >
          <p className='m-0'>
            <span>Details are submitted.</span>
            <br />
          </p>
        </Alert>

        <Formik
          initialValues={props}
          values={props}
          onSubmit={(values, { setSubmitting }) => {
            props.handleSubmit(values, setSubmitting)
          }}
        >
          {formik => (
            <FormikForm>              
              {formik.isSubmitting && (
                <Alert variant='info'>
                  Submitting Information. Please wait...
                </Alert>
              )}
              <Form.Row>
                <Form.Group as={Col} md={props.mode === VIEW_MODE ? '8' : '12'}>
                  {getMetricsTable()}
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Link to='/dashboard'><Button
                  variant='light'
                  type='button'
                  className='mr-3'                
                >
                  Cancel
                </Button></Link>
                <Button
                  variant='primary'
                  className='submit-button'
                  type='submit'
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <ShowSpinner buttonSpinner={true} />
                  ) : null}    
                  Save              
                </Button>
              </Form.Row>
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )
}

export default ManageMetricsForm
