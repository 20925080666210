import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import Settings from '@settings'
import '../../styles/sidebar.scss'
import {
  Dashboard,
  GridOnOutlined,
  LocalLibraryOutlined,
  WorkOutlineOutlined,
  Shop,
  FormatShapes
} from '@material-ui/icons'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import SendIcon from '@material-ui/icons/Send'
import StarBorder from '@material-ui/icons/StarBorder'
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import {
  DEFAULT_PROGRAM_SETTINGS_URL,
  MANAGE_METRICS_URL
} from '../../constants'
import { isAdmin, isAgent, isPm, isProgramDirector } from '../../helpers'

const Sidebar = ({ ...props }) => {
  let header = 'header'
  const defaultProgramLink = `/dashboard${DEFAULT_PROGRAM_SETTINGS_URL}`
  const manageMetricsLink = `/dashboard${MANAGE_METRICS_URL}`
  const [openSettings, setOpenSettings] = React.useState(false)

  if (props.url && props.url.includes('dashboard')) header = 'dashboard'

  let sidebarMenu = ''

  const navOverview = (
    <Nav.Link
      as={NavLink}
      to='/dashboard'
      exact
      activeClassName='active'
      /* onClick={props.triggerSidebar ? props.handleSidebar : null} */
      onClick={e => clickMenuItem(e)}
    >
      <Dashboard />
      Dashboard
    </Nav.Link>
  )

  const navCoaching = (
    <Nav.Link
      as={NavLink}
      to='/dashboard/coaching'
      exact
      activeClassName='active'
      /* onClick={props.triggerSidebar ? props.handleSidebar : null} */
      onClick={e => clickMenuItem(e)}
    >
      <LocalLibraryOutlined />
      Coaching
    </Nav.Link>
  )

  const navMetrics = (
    <Nav.Link
      as={NavLink}
      to='/dashboard/metrics'
      exact
      activeClassName='active'
      /* onClick={props.triggerSidebar ? props.handleSidebar : null} */
      onClick={e => clickMenuItem(e)}
    >
      <GridOnOutlined />
      Metrics
    </Nav.Link>
  )
  const navFormSubmit = (
    <Nav.Link
      as={NavLink}
      to='/dashboard/form-submit'
      exact
      activeClassName='active'
      onClick={props.triggerSidebar ? props.handleSidebar : null}
    >
      <FormatShapes />
      Form Submit YUP
    </Nav.Link>
  )
  const navPrograms = (
    <Nav.Link
      as={NavLink}
      to='/dashboard/programs'
      exact
      activeClassName='active'
      onClick={e => clickMenuItem(e)}
      /* onClick={props.triggerSidebar ? props.handleSidebar : null} */
    >
      <WorkOutlineOutlined />
      Programs
    </Nav.Link>
  )
  const navUsers = (
    <Nav.Link
      as={NavLink}
      to='/dashboard/users'
      exact
      activeClassName='active'
      /* onClick={props.triggerSidebar ? props.handleSidebar : null} */
      onClick={e => clickMenuItem(e)}
    >
      <PeopleIcon />
      Users
    </Nav.Link>
  )

  const openSettingsMenu = () => {
    setOpenSettings(!openSettings)
  }

  const clickMenuItem = e => {
    if (openSettings) setOpenSettings(!openSettings)
    props.triggerSidebar ? props.handleSidebar() : null
  }

  const navSettings = (
    <div>
      <Nav.Link
        as={NavLink}
        to='/dashboard/settings'
        exact
        activeClassName='active'
        // onClick={props.triggerSidebar ? props.handleSidebar : null}
        onClick={openSettingsMenu}
      >
      <SettingsIcon/>
        Settings
        <span className='float-right d-inline'>
          {openSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}{' '}
        </span>
      </Nav.Link>
      <Collapse
        className='settings-nav'
        in={openSettings}
        timeout='auto'
        unmountOnExit
      >
        <Nav className='flex-column w-100'>
          <Nav.Link as={NavLink} to={manageMetricsLink} exact>
            <ArrowRightOutlinedIcon className='mr-0 ml-3' /> Manage Metrics
            Header
          </Nav.Link>
          <Nav.Link as={NavLink} to={defaultProgramLink} exact>
            <ArrowRightOutlinedIcon className='mr-0 ml-3' /> Manage Default
            Program
          </Nav.Link>
        </Nav>
      </Collapse>
    </div>
  )

  if (header === 'dashboard') {
    sidebarMenu = (
      <Navbar.Collapse className='w-100'>
        <Nav className='flex-column w-100'>
          {navOverview}
          {isAdmin() ? null : navCoaching}
          {isPm() ? navMetrics : null}
          {isProgramDirector() ? navMetrics : null}
          {isAgent() ? navMetrics : null}
          {isAdmin() ? navPrograms : null}
          {isAdmin() ? navUsers : null}
          {isPm() ? navSettings : null}
          {isProgramDirector() ? navSettings : null}
        </Nav>
      </Navbar.Collapse>
    )
  }

  return (
    <>
      {props.triggerSidebar && (
        <div
          className='sidebar-overlay'
          role='button'
          onClick={props.handleSidebar}
          tabIndex='0'
        >
          Sidebar
        </div>
      )}
      <aside
        className={
          props.triggerSidebar
            ? 'sidebar sidebar-visible'
            : 'sidebar sidebar-hidden'
        }
      >
        <section>
          <Navbar className='flex-column'>
            <button
              type='button'
              onClick={props.handleSidebar}
              className='mt-2 d-lg-none d-xl-none close-sidebar'
            >
              <svg
                version='1.1'
                width='18px'
                height='18px'
                viewBox='0 0 18 18'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g fill='none' fillRule='evenodd'>
                  <g
                    transform='translate(-134 -18)'
                    fill='#fff'
                    fillRule='nonzero'
                  >
                    <g transform='translate(134 18)'>
                      <polygon points='17.4 0 9 8.43 0.57 0 0 0.57 8.4 9 0 17.43 0.57 18 9 9.57 17.4 18 18 17.43 9.57 9 18 0.57' />
                    </g>
                  </g>
                </g>
              </svg>
            </button>

            {Settings.showDashboardSidebarLogo && (
              <Navbar.Brand
                as={NavLink}
                to='/'
                className='bg-primary d-flex align-items-center my-3'
              >
                <img
                  src={Settings.logo.dashboardSrc}
                  alt={Settings.logo.alt}
                  className=''
                />{' '}
                {/* <span className='text-white'>CCMS</span> */}
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls='pm-navbar-nav' />
            {sidebarMenu}
          </Navbar>
<div className='dashboard-footer'>
Copyright © 2022 GlowTouch. <br></br>
All Rights Reserved.
</div>

        </section>
      </aside>
    </>
  )
}

export default Sidebar
