/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify'
import jwt from 'jsonwebtoken'
import { AppService, HTTPHeaders } from '.'

import {
  getUserData,
  setUserData,
  clearAllSession,
  storeToken,
  setInitialLoginTime,
  isAdmin,
  getUserId,
  deleteData
} from '../helpers'
import {
  USER_DETAILS,
  BASE_API_URL,
  LOGIN,
  LOGOUT_SUCCESSFUL,
  USER,
  GET_AGENT_ALL,
  GET_PROGRAM_MANAGER,
  LOCAL_STORAGE_AGENTS,
  LOCAL_STORAGE_PROGRAM_MANAGER,
  UPDATE_USER,
  ADMIN_LOGIN,
  LOCAL_STORAGE_USERS_QUERY,
  RESET_USER_PASSWORD,
  LOCAL_STORAGE_DASHBOARD,
  CHANGE_USER_PASSWORD,
  LOCAL_STORAGE_COACHING_FOLDER
} from '../constants'

const options = AppService.options()

export const userService = {
  login: async (data, signal) => {
    // sessionTimer(false) // stop
    const requestData = {
      email: data.username,
      password: data.password
    }
    let response = null
    response = await AppService.makeRequest(BASE_API_URL + LOGIN, {
      ...options,
      signal,
      ...HTTPHeaders(),
      body: JSON.stringify(requestData)
    })

    const jwtToken = jwt.decode(response.Authorization)
    const { exp } = jwtToken
    storeToken(response)
    setInitialLoginTime(response)
  },
  admin_login: async data => {
    // sessionTimer(false) // stop

    const requestData = {
      email: data.username,
      password: data.password
    }
    let response = null
    response = await AppService.makeRequest(BASE_API_URL + ADMIN_LOGIN, {
      ...options,
      ...HTTPHeaders(),
      body: JSON.stringify(requestData)
    })

    const jwtToken = jwt.decode(response.Authorization)
    const { exp, role } = jwtToken
    storeToken(response)
    setInitialLoginTime(response)
    return response
  },
  logout: async () => {
    let href_value = '/login'
    if(isAdmin()){
      href_value = '/admin-login'
    }
    clearAllSession()
    toast.success(LOGOUT_SUCCESSFUL)
    setTimeout(() => {
      window.location.href = href_value
    }, 3000)
  },
  getUserDetail: async signal => {
    const user = getUserData(USER_DETAILS)
    if (!user) {
      const response = await AppService.makeRequest(
        BASE_API_URL + USER + getUserId(),
        {
          signal,
          ...HTTPHeaders()
        }
      )

      setUserData(USER_DETAILS, response)
      return response
    }
    return user
  },
  get_agents: async signal => {
    const agents = getUserData(LOCAL_STORAGE_AGENTS)
    if (!agents) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_AGENT_ALL,
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_AGENTS, response)

      return response
    }
    return agents
  },
  get_program_manager: async signal => {
    const programManager = getUserData(LOCAL_STORAGE_PROGRAM_MANAGER)
    if (!programManager) {
      const response = await AppService.makeRequest(
        BASE_API_URL + GET_PROGRAM_MANAGER,
        { ...HTTPHeaders() }
      )
      setUserData(LOCAL_STORAGE_PROGRAM_MANAGER, response)

      return response
    }
    return programManager
  },
  add_user: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + USER, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    return response
  },
  update_user: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(BASE_API_URL + UPDATE_USER, {
      ...options,
      ...headers,
      body: JSON.stringify(data)
    })
    deleteData(LOCAL_STORAGE_COACHING_FOLDER)
    deleteData(LOCAL_STORAGE_DASHBOARD)
    return response
  },
  reset_user_password: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(
      BASE_API_URL + RESET_USER_PASSWORD,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data)
      }
    )
    return response
  },
  change_user_password: async data => {
    const headers = HTTPHeaders()
    headers.headers['Content-type'] = 'application/json'
    const response = await AppService.makeRequest(
      BASE_API_URL + CHANGE_USER_PASSWORD,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data)
      }
    )
    return response
  },
  get_paginated_users: async (
    query = '?page_size=20&page_no=2&sort_by=public_id'
  ) => {
    let users = getUserData(LOCAL_STORAGE_USERS_QUERY)

    if (!users) {
      users = {}
    }

    if (!users[query]) {
      const response = await AppService.makeRequest(
        BASE_API_URL + USER + query,
        { ...HTTPHeaders() }
      )
      users[query] = response
      setUserData(LOCAL_STORAGE_USERS_QUERY, users)

      return response
    }
    return users[query]
  },
  get_user_by_id: async publicId => {
    const response = await AppService.makeRequest(
      BASE_API_URL + USER + publicId,
      { ...HTTPHeaders() }
    )
    return response
  }
}
