import React from 'react'
import { Card, Col, Row, ListGroup } from 'react-bootstrap'
import '../../styles/coaching-card.scss'
import { ArrowUpwardOutlined, AccountCircleOutlined } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  isAgent,
  isPm,
  isProgramDirector,
  getAgentBehaviourOpportunities,
  getAgentSmartGoals,
  getAgentCoachingRecaps,
  getActiveWeek,
  getDueWeekAndDate,
  checkValueYesOrNot
} from '../../helpers'
import ProgramSelectBar from './ProgramSelectBar'
import { NO_DATA, NO_METRICS } from '../../constants'

const AgentViewCard = ({ ...props }) => {   
  const { coachingData, metricData } = props  
  const { selectedProgram } = props.programBar
  const arrMetricData = metricData.data ? metricData.data[0] : []  
  let agentName = (arrMetricData && arrMetricData.length > 0) ? arrMetricData[arrMetricData.length-2].toUpperCase() : ""
  
  const showAgentScreen = () => {    
    let coaching_data = props.data && props.data.length > 0 ? props.data[0] : []     
    return (
      <section>       
        <Row><Col className="mb-1"><h3 className="page-lable">{agentName}</h3></Col></Row>
        <Row key={0}>
        <Col md={12}>
          <Card className="card-left-border no-bg">
            <Card.Body className="py-0 pl-3 text-sm">
              <Row className='align-items-center'>
              <Col md={4} className="text-uppercase"><span className="text-primary font-weight-bold">Active - </span>{getActiveWeek(coaching_data)}</Col>
                <Col md={8} className='text-right coaching_controls text-uppercase'>
                <h3 className="mb-1">
                      <span className='badge badge-soft-success mt-1'>
                        {getDueWeekAndDate(coaching_data)}
                      </span>
                    </h3>{' '}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showAgentKeyMetrics()}
        <Row>
        <Col md={12}>
          <Card className='no-bg'>
            <Card.Body>              
              {showAgentContentTable()}           
            </Card.Body>
          </Card>
          </Col>         
        </Row>
        
      </section>
    )
  }

  const showAgentContentTable = () => {
    let coaching_data = props.data && props.data.length > 0 ? props.data[props.data.length-1] : []
    const arrRows = []
    const arrCols = []    
    const smartGoals = getAgentSmartGoals(coaching_data)
    const oppertunities = getAgentBehaviourOpportunities(coaching_data)
    const recaps = getAgentCoachingRecaps(coaching_data)    
    for (let i=0; i < 4; i++) {
      let smartGoalContent = smartGoals[i] ? smartGoals[i] : "No Data"
      let oppertunityContent = oppertunities[i] ? oppertunities[i] : "No Data"
      let recapContent = recaps[i] ? recaps[i] : "No Data"
      arrRows.push(<tr key={i}><td>{"W"+(i+1)}</td><td className="pr-3">{oppertunityContent}</td><td className="pr-3">{smartGoalContent}</td><td className="pr-3">{recapContent}</td></tr>)
    }
    return <table width="100%" className="table table-striped tbl2">
            <tr><th className="pl-3 border-top-0 bg-muted">Weeks</th><th className="pl-0 border-top-0">Opportunities to Focus on</th><th className="pl-0 border-top-0">Smart Goal To Change</th><th className="pl-0 border-top-0">Recap of Coaching</th></tr>
            {arrRows}           
          </table>
  }
  
  const showAgentKeyMetrics = () => {      
    const metricHeaders = selectedProgram ? selectedProgram.program.metrics : []
    const metricGridColumns = []    
    if (arrMetricData !== undefined && metricHeaders !== undefined) {
      if (arrMetricData && arrMetricData.length > 0 && metricHeaders.length > 0) {
        const headerLen = metricHeaders.length
        let metricValue, keyMetric, metricName 
        
        for (let i = 0; i < arrMetricData.length - 1; i++) {         
          if (i < headerLen) {
            keyMetric = metricHeaders[i].is_key_metrics
            if(metricHeaders[i].metrics_name.toLowerCase() === "employee code") {              
              //metricName = "Employee"
            } else {
              metricValue = arrMetricData[i]
              metricName = metricHeaders[i].metrics_name              
              metricGridColumns.push(
                <Col key={i}>
                  <Card>
                    <Card.Body>
                      <Row
                        className='align-items-center'                       
                      >
                        <Col>
                          <h3 className="name">{metricValue ? metricValue : "-"}</h3>
                          <h4 className='text-secondary mb-1'>
                            {metricName}
                          </h4>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )              
            }            
          }
        }
        return <Row className='row-cols-3'>{metricGridColumns}</Row>
      }
    } else {
      return (
        <Row>
          <Col>
            <Card className='bg-primary'>
              <Card.Body>{"No metrics data available for the week "+props.weekRange}</Card.Body>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  return (
    <section>
      {showAgentScreen()}      
    </section>
  )
}

export default AgentViewCard
